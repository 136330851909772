import {
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  Select,
  Tooltip
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ArrayField, Controller, useFormContext } from 'react-hook-form';
import { Offer } from '../models/motion';

import './VehicleCoverageEdit.scss';
import { ReactComponent as ToolTipIcon } from '../images/tool-tip.svg';
import { getMenuListWithDisplayAndValue } from '../utils/selectList';
import { getVehicleCoveragesByTier } from './VehicleLogic';

export interface VehicleCoverageEditProps {
  tier: string;
  offer: Offer;
  limits: any;
  index: number;
  vehicle: Partial<ArrayField<Record<string, any>, 'id'>>;
}

const VehicleCoverageEdit: React.FC<VehicleCoverageEditProps> =
  ({ limits, offer, tier, index, vehicle }) => {
    const methods = useFormContext();
    const { setValue, watch } = methods;
    const watchPrimaryDriverState = watch('drivers[0].state');
    const watchUninsuredMotorist = watch('umLimit');
    const watchCollision = watch(`vehicles[${index}].collDeductible`);
    const [collMenuList, setCollMenuList] = useState([]);
    const [compMenuList, setCompMenuList] = useState([]);
    const [rentalMenuList, setRentalMenuList] = useState([]);
    const [loanMenuList, setLoanMenuList] = useState([]);
    const [umpdMenuList, setUmpdMenuList] = useState([]);
    const [roadsideMenuList, setRoadsideMenuList] = useState([]);
    const showUmpd = watchPrimaryDriverState
      && watchPrimaryDriverState.toLowerCase() === 'ut'
      && watchCollision
      && watchCollision.toLowerCase() === 'declined'
      && watchUninsuredMotorist
      && watchUninsuredMotorist.toLowerCase() !== 'none'
      && watchUninsuredMotorist.toLowerCase() !== 'declined';
    useEffect(() => {
      if (limits) {
        if (limits.coll_limit) {
          const { options: collOptions } = limits.coll_limit;
          setCollMenuList(
            collOptions.map((limit: { display: string, value: string }) =>
              getMenuListWithDisplayAndValue(limit))
          );
        }
        if (limits.comp_limit) {
          const { options: compOptions } = limits.comp_limit;
          setCompMenuList(
            compOptions.map((limit: { display: string, value: string }) =>
              getMenuListWithDisplayAndValue(limit))
          );
        }
        if (limits.rent_limit) {
          const { options: rentOptions } = limits.rent_limit;
          setRentalMenuList(
            rentOptions.map((limit: { display: string, value: string }) =>
              getMenuListWithDisplayAndValue(limit))
          );
        }
        if (limits.loan_limit) {
          const { options: loanOptions } = limits.loan_limit;
          setLoanMenuList(
            loanOptions.map((limit: { display: string, value: string }) =>
              getMenuListWithDisplayAndValue(limit))
          );
        }
        if (limits.umpd_limit) {
          const { options: umpdOptions } = limits.umpd_limit;
          setUmpdMenuList(
            umpdOptions.map((limit: { display: string, value: string }) =>
              getMenuListWithDisplayAndValue(limit))
          );
        }
        if (limits.roadside_limit) {
          const { options: roadsideOptions } = limits.roadside_limit;
          setRoadsideMenuList(
            roadsideOptions.map((limit: { display: string, value: string }) =>
              getMenuListWithDisplayAndValue(limit))
          );
        }
      }
    }, [limits, setCollMenuList]);

    const getDeclineValueForCoverage =
      (options: [{ display: string, value: string }]) => {
        const result = options.filter(o =>
          o.value.toLowerCase() === 'none'
          || o.value.toLowerCase() === 'declined')[0].value;
        return result;
      };

    useEffect(() => {
      if (offer && tier && limits && collMenuList.length > 0) {
        let factors = offer[`${tier}_deductible`];
        // factors will be an array if the tier is requested
        // factors will be reassigned to the appropriate index of the array
        // or factors will default to the only item on the offer
        // this defaults the coverages of newly added cars to match existing vehicle(s)
        if (Array.isArray(factors)) {
          if (factors.filter(f => f.vin === vehicle.vin)[0]) {
            [factors] = factors.filter(f => f.vin === vehicle.vin);
          } else {
            [factors] = factors;
          }
        }
        const coverages = getVehicleCoveragesByTier(tier, factors);
        setValue(`vehicles[${index}].collDeductible`, coverages.collisionDeductible.toLowerCase() === 'none'
          || coverages.collisionDeductible.toLowerCase() === 'declined'
          ? getDeclineValueForCoverage(limits.coll_limit.options)
          : coverages.collisionDeductible
        );
        setValue(`vehicles[${index}].compDeductible`, coverages.comprehensiveDeductible.toLowerCase() === 'none'
          || coverages.comprehensiveDeductible.toLowerCase() === 'declined'
          ? getDeclineValueForCoverage(limits.comp_limit.options)
          : coverages.comprehensiveDeductible
        );
        setValue(`vehicles[${index}].rental`, coverages.rental.toLowerCase() === 'none'
          || coverages.rental.toLowerCase() === 'declined'
          ? getDeclineValueForCoverage(limits.rent_limit.options)
          : coverages.rental
        );
        setValue(`vehicles[${index}].loan`, coverages.loan.toLowerCase() === 'none'
          || coverages.loan.toLowerCase() === 'declined'
          ? getDeclineValueForCoverage(limits.loan_limit.options)
          : coverages.loan
        );
        setValue(`vehicles[${index}].towingLimit`, coverages.towing.toLowerCase() === 'none'
          || coverages.towing.toLowerCase() === 'declined'
          ? getDeclineValueForCoverage(limits.roadside_limit.options)
          : coverages.towing
        );
      }
    }, [offer, tier, setValue, index, collMenuList, limits, vehicle]);

    useEffect(() => {
      if (showUmpd && umpdMenuList.length > 0 && limits) {
        let factors = offer[`${tier}_deductible`];
        // factors will be an array if the tier is requested
        // factors will be reassigned to the appropriate index of the array
        // or factors will default to the only item on the offer
        // this defaults the coverages of newly added cars to match existing vehicle(s)
        if (Array.isArray(factors)) {
          if (factors.some(f => f.vin === vehicle.vin)) {
            [factors] = factors.filter(f => f.vin === vehicle.vin);
          } else {
            [factors] = factors;
          }
        }
        const coverages = getVehicleCoveragesByTier(tier, factors);

        if (coverages && coverages.uninsuredMotoristPropertyDamage === undefined) {
          setValue(
            `vehicles[${index}].umpdLimit`,
            getDeclineValueForCoverage(limits.umpd_limit.options)
          );
        } else {
          setValue(`vehicles[${index}].umpdLimit`,
            coverages
              && coverages.uninsuredMotoristPropertyDamage
              && (
                coverages.uninsuredMotoristPropertyDamage.toLowerCase() === 'none'
                || coverages.uninsuredMotoristPropertyDamage.toLowerCase() === 'declined'
                || coverages.uninsuredMotoristPropertyDamage.toLowerCase() === ''
              )
              ? getDeclineValueForCoverage(limits.umpd_limit.options)
              : coverages.uninsuredMotoristPropertyDamage
          );
        }

      }
    }, [setValue, tier, index, offer, showUmpd, umpdMenuList, limits, vehicle]);

    const watchCompDeductible = methods.watch(`vehicles[${index}].compDeductible`);
    const watchOwnershipType = methods.watch(`vehicles[${index}].ownershipType`);
    const [isLoanInputDisabled, setIsLoanInputDisabled] = useState(false);
    const [isRentalInputDisabled, setIsRentalInputDisabled] = useState(false);

    useEffect(() => {
      if ((watchOwnershipType === 'Own') || (watchCompDeductible === 'DECLINED')) {
        setValue(`vehicles[${index}].loan`, 'DECLINED');
        setIsLoanInputDisabled(true);
      } else {
        setIsLoanInputDisabled(false);
      }
      if (watchCompDeductible === 'DECLINED') {
        setValue(`vehicles[${index}].rental`, 'DECLINED');
        setIsRentalInputDisabled(true);
      } else {
        setIsRentalInputDisabled(false);
      }
    }, [watchOwnershipType, watchCompDeductible, setValue, setIsLoanInputDisabled, setIsRentalInputDisabled, index]);


    return (
      <>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Controller
            control={methods.control}
            name={`vehicles[${index}].compDeductible`}
            defaultValue=''
            render={({ onChange, value, name, onBlur }) => (
              <FormControl
                variant='outlined'
                fullWidth
                error={
                  !!(
                    methods.errors &&
                    methods.errors.vehicles &&
                    methods.errors.vehicles[index] &&
                    methods.errors.vehicles[index].compDeductible
                  )
                }
              >
                <InputLabel>Comprehensive deductible</InputLabel>
                <Select
                  id={`vehicles[${index}].compDeductible`}
                  label='Comprehensive deductible'
                  value={value}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  startAdornment={
                    <InputAdornment position='start'>
                      <Tooltip
                        enterTouchDelay={0}
                        leaveTouchDelay={3500}
                        style={{ marginRight: '1em' }}
                        className='tooltip'
                        title={
                          limits
                            && limits.comp_limit ?
                            limits.comp_limit.description
                            : 'Info is currently unavailable'
                        }
                        placement='top-start'
                      >
                        <ToolTipIcon />
                      </Tooltip>
                    </InputAdornment>
                  }
                >
                  {compMenuList}
                </Select>
                <FormHelperText id={`vehicles[${index}].compDeductibleErrorText`}>
                  {
                    methods.errors &&
                      methods.errors.vehicles &&
                      methods.errors.vehicles[index] &&
                      methods.errors.vehicles[index].compDeductible
                      ? methods.errors.vehicles[index].compDeductible.message
                      : ''
                  }
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Controller
            control={methods.control}
            name={`vehicles[${index}].collDeductible`}
            defaultValue=''
            render={({ onChange, value, name, onBlur }) => (
              <FormControl
                variant='outlined'
                fullWidth
                error={
                  !!(
                    methods.errors &&
                    methods.errors.vehicles &&
                    methods.errors.vehicles[index] &&
                    methods.errors.vehicles[index].collDeductible
                  )
                }
              >
                <InputLabel>Collision deductible</InputLabel>
                <Select
                  id={`vehicles[${index}].collDeductible`}
                  label='Collision deductible'
                  value={value}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  startAdornment={
                    <InputAdornment position='start'>
                      <Tooltip
                        enterTouchDelay={0}
                        leaveTouchDelay={3500}
                        style={{ marginRight: '1em' }}
                        className='tooltip'
                        title={

                          limits
                            && limits.coll_limit ?
                            limits.coll_limit.description
                            : 'Info is currently unavailable'
                        }
                        placement='top-start'
                      >
                        <ToolTipIcon />
                      </Tooltip>
                    </InputAdornment>
                  }
                >
                  {collMenuList}
                </Select>
                <FormHelperText id={`vehicles[${index}].collDeductibleErrorText`}>
                  {
                    methods.errors &&
                      methods.errors.vehicles &&
                      methods.errors.vehicles[index] &&
                      methods.errors.vehicles[index].collDeductible
                      ? methods.errors.vehicles[index].collDeductible.message
                      : ''
                  }
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        {
          showUmpd ?
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Controller
                control={methods.control}
                name={`vehicles[${index}].umpdLimit`}
                defaultValue=''
                render={({ onChange, value, name, onBlur }) => (
                  <FormControl
                    variant='outlined'
                    fullWidth
                    error={
                      !!(
                        methods.errors &&
                        methods.errors.vehicles &&
                        methods.errors.vehicles[index] &&
                        methods.errors.vehicles[index].umpdLimit
                      )
                    }
                  >
                    <InputLabel>Uninsured motorist property damage</InputLabel>
                    <Select
                      id={`vehicles[${index}].umpdLimit`}
                      label='Uninsured motorist property damage'
                      value={value}
                      name={name}
                      onChange={onChange}
                      onBlur={onBlur}
                      startAdornment={
                        <InputAdornment position='start'>
                          <Tooltip
                            enterTouchDelay={0}
                            leaveTouchDelay={3500}
                            style={{ marginRight: '1em' }}
                            className='tooltip'
                            title={
                              limits &&
                                limits.umpd_limit ?
                                limits.umpd_limit.description
                                : 'Info is currently unavailable'
                            }
                            placement='top-start'
                          >
                            <ToolTipIcon />
                          </Tooltip>
                        </InputAdornment>
                      }
                    >
                      {umpdMenuList}
                    </Select>
                    <FormHelperText id={`vehicles[${index}].umpdLimitErrorText`}>
                      {
                        methods.errors &&
                          methods.errors.vehicles &&
                          methods.errors.vehicles[index] &&
                          methods.errors.vehicles[index].umpdLimit
                          ? methods.errors.vehicles[index].umpdLimit.message
                          : ''
                      }
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            : null
        }
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Controller
            control={methods.control}
            name={`vehicles[${index}].rental`}
            defaultValue=''
            render={({ onChange, value, name, onBlur }) => (
              <FormControl
                variant='outlined'
                fullWidth
                error={
                  !!(
                    methods.errors &&
                    methods.errors.vehicles &&
                    methods.errors.vehicles[index] &&
                    methods.errors.vehicles[index].rental
                  )
                }
              >
                <InputLabel>Car rental</InputLabel>
                <Select
                  id={`vehicles[${index}].rental`}
                  label='Car rental'
                  value={value}
                  name={name}
                  onChange={onChange}
                  disabled={isRentalInputDisabled}
                  onBlur={onBlur}
                  startAdornment={
                    <InputAdornment position='start'>
                      <Tooltip
                        enterTouchDelay={0}
                        leaveTouchDelay={3500}
                        style={{ marginRight: '1em' }}
                        className='tooltip'
                        title={
                          limits
                            && limits.rent_limit ?
                            limits.rent_limit.description
                            : 'Info is currently unavailable'
                        }
                        placement='top-start'
                      >
                        <ToolTipIcon />
                      </Tooltip>
                    </InputAdornment>
                  }
                >
                  {rentalMenuList}
                </Select>
                <FormHelperText id={`vehicles[${index}].rentalLimitErrorText`}>
                  {
                    methods.errors &&
                      methods.errors.vehicles &&
                      methods.errors.vehicles[index] &&
                      methods.errors.vehicles[index].rental
                      ? methods.errors.vehicles[index].rental.message
                      : ''
                  }
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Controller
            control={methods.control}
            name={`vehicles[${index}].loan`}
            defaultValue=''
            render={({ onChange, value, name, onBlur }) => (
              <FormControl
                variant='outlined'
                fullWidth
                error={
                  !!(
                    methods.errors &&
                    methods.errors.vehicles &&
                    methods.errors.vehicles[index] &&
                    methods.errors.vehicles[index].loan
                  )
                }
              >
                <InputLabel>Loan payoff limit</InputLabel>
                <Select
                  id={`vehicles[${index}].loan`}
                  label='Loan payoff limit'
                  value={value}
                  disabled={isLoanInputDisabled}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  startAdornment={
                    <InputAdornment position='start'>
                      <Tooltip
                        enterTouchDelay={0}
                        leaveTouchDelay={3500}
                        style={{ marginRight: '1em' }}
                        className='tooltip'
                        title={
                          limits
                            && limits.loan_limit ?
                            limits.loan_limit.description
                            : 'Info is currently unavailable'
                        }
                        placement='top-start'
                      >
                        <ToolTipIcon />
                      </Tooltip>
                    </InputAdornment>
                  }
                >
                  {loanMenuList}
                </Select>
                <FormHelperText id={`vehicles[${index}].loanLimitErrorText`}>
                  {
                    methods.errors &&
                      methods.errors.vehicles &&
                      methods.errors.vehicles[index] &&
                      methods.errors.vehicles[index].loan
                      ? methods.errors.vehicles[index].loan.message
                      : ''
                  }
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Controller
            control={methods.control}
            name={`vehicles[${index}].towingLimit`}
            defaultValue=''
            render={({ onChange, value, name, onBlur }) => (
              <FormControl
                variant='outlined'
                fullWidth
                error={
                  !!(
                    methods.errors &&
                    methods.errors.vehicles &&
                    methods.errors.vehicles[index] &&
                    methods.errors.vehicles[index].towingLimit
                    ? methods.errors.vehicles[index].towingLimit.message
                      : ''
                  )
                }
              >
                <InputLabel>Roadside assistance</InputLabel>
                <Select
                  id={`vehicles[${index}].towingLimit`}
                  label='Roadside assistance'
                  value={value}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  startAdornment={
                    <InputAdornment position='start'>
                      <Tooltip
                        enterTouchDelay={0}
                        leaveTouchDelay={3500}
                        style={{ marginRight: '1em' }}
                        className='tooltip'
                        title={
                          limits
                            && limits.limits_deduct
                            && limits.limits_deduct.roadside_limit
                            && limits.limits_deduct.roadside_limit.description ?
                            limits.limits_deduct.roadside_limit.description
                            : 'Info is currently unavailable'
                        }
                        placement='top-start'
                      >
                        <ToolTipIcon />
                      </Tooltip>
                    </InputAdornment>
                  }
                >
                  {roadsideMenuList}
                </Select>
                <FormHelperText id={`vehicles[${index}].towingLimitErrorText`}>
                  {
                    methods.errors &&
                      methods.errors.vehicles &&
                      methods.errors.vehicles[index] &&
                      methods.errors.vehicles[index].towingLimit
                      ? methods.errors.vehicles[index].towingLimit.message
                      : ''
                  }
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
      </>
    );
  };

export default VehicleCoverageEdit;