import axios from 'axios';
import { InitialPayment, MakePaymentInput, QuoteModel, UserAccount } from '../models/motion';
import { RateCallEvent } from '@novo/rate-call-event-validation'

export const initiateQuote =
  async (obj: { input: RateCallEvent }) => {
    const { data } = await axios.post(`${process.env.REACT_APP_Q2B_HOST}/q2b/execute-step-function`, obj.input);
    return data;
  };

export const MakeInitialPayment = async (payload: InitialPayment) => {
  const { data } =
    await axios.post(`${process.env.REACT_APP_Q2B_HOST}/accountsReceivablePayment/initial-payment`, payload);
  return data;
};

export const BindNewPolicy = async (payload: any) => {
  const { data } =
    await axios.post(`${process.env.REACT_APP_Q2B_HOST}/bind`, payload);

  return data;
};

export const CreateUserAccount = async (payload: UserAccount) => {
  const { data } = await axios.post(`${process.env.REACT_APP_USER_PASSWORD_HOST}/user/sign-up`, payload);

  return data;
};

export const ScanId = async (imageBase64Encoding: string) => {
  const { data } = await axios.post(`${process.env.REACT_APP_Q2B_HOST}/q2b/id-scan`, imageBase64Encoding);

  return data;
};

export const paymentBind = async (payload: MakePaymentInput) => {
  const { data } = await axios.post(`${process.env.REACT_APP_Q2B_HOST}/policy/bind`, payload);

  return data;
};

export const incidentMvr = [
  [
  {
    "date": "05-4-2021",
    "group": "ACCIDENT",
    "type": "AAF",
    "source": "self",
    "provider": "self",
    "disclosed": true,
    "claimAmount": false,
    "code": "c"
  },
  {
    "date": "06-4-2020",
    "group": "ACCIDENT",
    "type": "NAF",
    "source": "self",
    "provider": "self",
    "disclosed": true,
    "claimAmount": false,
    "code": "d"
  },
  {
    "date": "07-4-2021",
    "group": "VIOLATION",
    "type": "n",
    "source": "self",
    "provider": "self",
    "disclosed": true,
    "code": "e"
  },
  {
    "date": "10-4-2020",
    "group": "VIOLATION",
    "type": "q",
    "source": "self",
    "provider": "self",
    "disclosed": true,
    "code": "b"
  }
  ],
  [
  {
    "date": "11-4-2020",
    "group": "VIOLATION",
    "type": "q",
    "source": "self",
    "provider": "self",
    "disclosed": true,
    "code": "a"
  },
  {
    "date": "10-4-2020",
    "group": "Accident",
    "type": "q",
    "source": "self",
    "provider": "self",
    "disclosed": true,
    "code": "c"
  }
  ]]

  export const incidentMvrRep = [
    {
      "date": "05-4-2021",
      "group": "ACCIDENT",
      "type": "AAF",
      "source": "self",
      "provider": "self",
      "disclosed": true,
      "claimAmount": false,
      "code": "c"
    },
    {
      "date": "06-4-2020",
      "group": "ACCIDENT",
      "type": "NAF",
      "source": "self",
      "provider": "self",
      "disclosed": true,
      "claimAmount": false,
      "code": "d"
    },
    {
      "date": "07-4-2021",
      "group": "VIOLATION",
      "type": "n",
      "source": "self",
      "provider": "self",
      "disclosed": true,
      "code": "e"
    },
    {
      "date": "10-4-2020",
      "group": "VIOLATION",
      "type": "q",
      "source": "self",
      "provider": "self",
      "disclosed": true,
      "code": "b"
    }
    ]