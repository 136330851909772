import React from 'react';
// import NumberFormat from 'react-number-format';
import { Tooltip } from '@material-ui/core';
import { Deductible, Vehicle } from '../models/motion';
import { ReactComponent as ToolTipIcon } from '../images/tool-tip.svg';
import './VehicleCoverage.scss';

export interface VehicleCoverageProps {
  vehicle: Vehicle;
  factors: Deductible;
  tier: string;
  index: number;
  limits: any;
}

const VehicleCoverage: React.FC<VehicleCoverageProps> =
  ({ vehicle, factors, tier, index, limits }) => {
    const { year, make, model, imageUrl, vin } = vehicle;
    const showUmpd = ((limits && limits.state.toLowerCase() === 'ut') || (limits && limits.state.toLowerCase() === 'tn'))
      && factors
      && factors.coll_limit
      && factors.coll_limit.toLowerCase() === 'declined'
      && factors.umbi_limit
      && factors.umbi_limit.toLowerCase() !== 'none';

    // if none is returned from rating engine rewrite to declined
    if (limits && limits.state.toLowerCase() === 'ut') {
      factors.rent_limit = factors.rent_limit && factors.rent_limit.toLowerCase() === 'none' ? 'DECLINED' : factors.rent_limit;
      factors.umpd_limit = factors.umpd_limit && factors.umpd_limit.toLowerCase() === 'none' ? 'DECLINED' : factors.umpd_limit;
      factors.loan_limit = factors.loan_limit && factors.loan_limit.toLowerCase() === 'none' ? 'DECLINED' : factors.loan_limit;
    }
    if (limits && limits.state.toLowerCase() === 'az') {
      factors.loan_limit = factors.loan_limit && factors.loan_limit.toLowerCase() === 'none' ? 'DECLINED' : factors.loan_limit;
      factors.rent_limit = factors.rent_limit && factors.rent_limit.toLowerCase() === 'none' ? 'DECLINED' : factors.rent_limit;
    }

    return (
      <div>
        <div className='vehicle-coverage-header'>
          <div>
            <img
              src={imageUrl}
              className='vehicle-coverage-image'
              alt={`${year} ${make} ${model}`}
            />
          </div>
          <div className='vehicle-coverage-description'>
            <span>{`${year} ${make} ${model}`}</span>
            <div className='vehicle-coverage-vin'>{`VIN: ${vin}`}</div>
          </div>
        </div>
        <div className='vehicle-coverage-factor-wrapper'>
          <div className='vehicle-coverage-factor-row'>
            <div>
              Comprehensive deductible
            </div>
            <div className='vehicle-coverage-factor-right'>
              <div>
                {
                  limits && limits.limits_deduct.comp_limit ?
                    limits
                      .limits_deduct
                      .comp_limit
                      .options
                      .filter((o: { display: string, value: string }) =>
                        o.value === factors.comp_limit)[0]
                      .display
                    : ''
                }
              </div>
              <div className='vehicle-coverage-tooltip'>
                <Tooltip
                  enterTouchDelay={0}
                  leaveTouchDelay={3500}
                  className='tooltip'
                  title={
                    limits ?
                      limits.limits_deduct.comp_limit.description
                      : 'Information not currently available'
                  }
                  placement='top-start'
                >
                  <ToolTipIcon />
                </Tooltip>
              </div>
            </div>
          </div>
          <div className='vehicle-coverage-factor-row'>
            <div>
              Collision deductible
            </div>
            <div className='vehicle-coverage-factor-right'>
              <div>
                {
                  limits && limits.limits_deduct.coll_limit ?
                    limits
                      .limits_deduct
                      .coll_limit
                      .options
                      .filter((o: { display: string, value: string }) =>
                        o.value === factors.coll_limit)[0]
                      .display
                    : ''
                }
              </div>
              <div className='vehicle-coverage-tooltip'>
                <Tooltip
                  enterTouchDelay={0}
                  leaveTouchDelay={3500}
                  className='tooltip'
                  title={
                    limits ?
                      limits.limits_deduct.coll_limit.description
                      : 'Information not currently available'
                  }
                  placement='top-start'
                >
                  <ToolTipIcon />
                </Tooltip>
              </div>
            </div>
          </div>
          {
            showUmpd ?
              <div className='vehicle-coverage-factor-row'>
                <div>
                  Uninsured motorist property damage
                </div>
                <div className='vehicle-coverage-factor-right'>
                  <div>
                    {
                      limits && limits.limits_deduct.umpd_limit ?
                        limits
                          .limits_deduct
                          .umpd_limit
                          .options
                          .filter((o: { display: string, value: string }) =>
                            o.value === factors.umpd_limit)[0]
                          .display
                        : ''
                    }
                  </div>
                  <div className='vehicle-coverage-tooltip'>
                    <Tooltip
                      enterTouchDelay={0}
                      leaveTouchDelay={3500}
                      className='tooltip'
                      title={
                        limits ?
                          limits.limits_deduct.umpd_limit.description
                          : 'Information not currently available'
                      }
                      placement='top-start'
                    >
                      <ToolTipIcon />
                    </Tooltip>
                  </div>
                </div>
              </div>
              : null
          }
          <div className='vehicle-coverage-factor-row'>
            <div>
              Car rental
            </div>
            <div className='vehicle-coverage-factor-right'>
              <div>
                {
                  limits && limits.limits_deduct.rent_limit ?
                    limits
                      .limits_deduct
                      .rent_limit
                      .options
                      .filter((o: { display: string, value: string }) =>
                        o.value === factors.rent_limit)[0]
                      .display
                    : ''
                }
              </div>
              <div className='vehicle-coverage-tooltip'>
                <Tooltip
                  enterTouchDelay={0}
                  leaveTouchDelay={3500}
                  className='tooltip'
                  title={
                    limits ?
                      limits.limits_deduct.rent_limit.description
                      : 'Information not currently available'
                  }
                  placement='top-start'
                >
                  <ToolTipIcon />
                </Tooltip>
              </div>
            </div>
          </div>
          <div className='vehicle-coverage-factor-row'>
            <div>
              Loan payoff limit
            </div>
            <div className='vehicle-coverage-factor-right'>
              <div>
                {
                  limits && limits.limits_deduct.loan_limit ?
                    limits
                      .limits_deduct
                      .loan_limit
                      .options
                      .filter((o: { display: string, value: string }) =>
                        o.value === factors.loan_limit)[0]
                      .display
                    : ''
                }
              </div>
              <div className='vehicle-coverage-tooltip'>
                <Tooltip
                  enterTouchDelay={0}
                  leaveTouchDelay={3500}
                  className='tooltip'
                  title={
                    limits ?
                      limits.limits_deduct.loan_limit.description
                      : 'Information not currently available'
                  }
                  placement='top-start'
                >
                  <ToolTipIcon />
                </Tooltip>
              </div>
            </div>
          </div>
          <div className='vehicle-coverage-factor-row'>
            <div>Roadside Assistance</div>
            <div className='vehicle-coverage-factor-right'>
              {
                limits && limits.limits_deduct.roadside_limit &&
                  limits
                    .limits_deduct
                    .roadside_limit
                    .options
                    .filter((o: { display: string, value: string }) =>
                      o.value === factors.tow_limit).length > 0 ?
                  limits
                    .limits_deduct
                    .roadside_limit
                    .options
                    .filter((o: { display: string, value: string }) =>
                      o.value === factors.tow_limit)[0]
                    .display
                  : ''
              }
              <div className='vehicle-coverage-tooltip'>
                <Tooltip
                  enterTouchDelay={0}
                  leaveTouchDelay={3500}
                  className='tooltip'
                  title={
                    limits ?
                      limits.limits_deduct.roadside_limit.description
                      : 'Information not currently available'
                  }
                  placement='top-start'
                >
                  <ToolTipIcon />
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

export default VehicleCoverage;