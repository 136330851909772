import { Grid, InputAdornment, TextField, Tooltip } from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { ReactComponent as CreditCardIcon } from '../images/credit-card-icon.svg';
import { ReactComponent as CreditCardIconRow } from '../images/credit-card-icon-row.svg';
import { ReactComponent as CvvIcon } from '../images/cvv.svg';
import { ReactComponent as ToolTipIcon } from '../images/tool-tip.svg';
import { CardConnectTokenVariables, getCardConnectToken } from '../utils/cardConnect';

export interface CreditCardDetailsProps {

}



const CreditCardDetails: React.FC<CreditCardDetailsProps> = () => {
  const { control, errors, setValue } = useFormContext();
  const watchCreditCardNumber = useWatch({
    control,
    name: 'creditCardNumber',
    defaultValue: ''
  });
  const watchCreditCardExpiration = useWatch({
    control,
    name: 'creditCardExpiration',
    defaultValue: ''
  });
  const watchCvv = useWatch({
    control,
    name: 'cvv',
    defaultValue: ''
  });

  const memoizedHandleGetToken = useCallback(async () => {
    const payload = {
      account: watchCreditCardNumber,
      expiry: watchCreditCardExpiration.replace('/', ''),
      cvv: watchCvv,
    } as CardConnectTokenVariables;

    const tokenResponse = await getCardConnectToken(payload);

    setValue('cardConnectToken', tokenResponse.token);
  }, [watchCreditCardNumber, watchCreditCardExpiration, watchCvv, setValue]);

  useEffect(() => {
    if (watchCreditCardNumber.length >= 18 && watchCreditCardExpiration.length === 5 && watchCvv.length >= 3) {
      memoizedHandleGetToken();
    }
  }, [watchCreditCardNumber, watchCreditCardExpiration, watchCvv, memoizedHandleGetToken]);

  return (
    <div className='credit-card-wrapper'>
      <div className='payment-information-header credit-card-details'>
        Credit card details
      </div>
      <Grid container spacing={2} justify='flex-start'>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Controller
            control={control}
            alwaysShowMask={false}
            name='creditCardNumber'
            maskChar=''
            defaultValue=''
            as={
              <InputMask mask='9999 9999 9999 9999'>
                {() => {
                  return (
                    <TextField
                      id='creditCardNumber'
                      error={
                        !!(
                          errors &&
                          errors.creditCardNumber
                        )
                      }
                      helperText={
                        errors &&
                          errors.creditCardNumber
                          ? errors.creditCardNumber.message
                          : ''
                      }
                      placeholder='1234 1234 1234 1234'
                      label='Credit card number'
                      variant='outlined'
                      type='tel'
                      fullWidth
                      InputProps={{
                        endAdornment: <InputAdornment position='start'><CreditCardIcon /></InputAdornment>,
                      }}
                    />
                  );
                }}
              </InputMask>
            }
          />
          <Controller
            control={control}
            name='cardConnectToken'
            defaultValue=''
            as={
              <input
                type='hidden'
              />
            }
          />
        </Grid>

        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Controller
            control={control}
            alwaysShowMask={false}
            name='creditCardExpiration'
            maskChar=''
            defaultValue=''
            as={
              <InputMask mask='99/99'>
                {() => {
                  return (
                    <TextField
                      id='creditCardExpiration'
                      error={
                        !!(
                          errors &&
                          errors.creditCardExpiration
                        )
                      }
                      helperText={
                        errors &&
                          errors.creditCardExpiration
                          ? errors.creditCardExpiration.message
                          : ''
                      }
                      placeholder='MM/YY'
                      label='Expiration date'
                      variant='outlined'
                      type='tel'
                      fullWidth
                    />
                  );
                }}
              </InputMask>
            }
          />
        </Grid>

        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Controller
            control={control}
            alwaysShowMask={false}
            name='cvv'
            maskChar=''
            defaultValue=''
            as={
              <InputMask mask='9999'>
                {() => {
                  return (
                    <TextField
                      id='cvv'
                      error={
                        !!(
                          errors &&
                          errors.cvv
                        )
                      }
                      helperText={
                        errors &&
                          errors.cvv
                          ? errors.cvv.message
                          : ''
                      }
                      placeholder='123'
                      label='CVV'
                      variant='outlined'
                      type='tel'
                      fullWidth
                    />
                  );
                }}
              </InputMask>
            }
          />
        </Grid>
        <Grid item lg={1} md={1} sm={1} xs={1}>
          <div className='payment-tooltip'>
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={3500}
              className='tooltip'
              title={<CvvIcon />}
              placement='top-start'
            >
              <ToolTipIcon />
            </Tooltip>
          </div>
        </Grid>
      </Grid>
      <div className='accepted-payment-wrapper'>
        <div>Payment types accepted</div>
        <div className='card-icon-wrapper'>
          <CreditCardIconRow />
        </div>
      </div>
    </div>
  );
};

export default CreditCardDetails;