import { Button } from '@material-ui/core';
import React, { Dispatch, SetStateAction } from 'react';
import { Driver } from '../models/motion';
import { mergeDriverPrefill } from '../utils/prefill';

import './PrimaryInsuredCandidates.scss';


export interface PrimaryInsuredCandidatesProps {
  primaryInsuredCandidates: Driver[];
  primaryInsured: Driver;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

const PrimaryInsuredCandidates: React.FC<PrimaryInsuredCandidatesProps> = ({ primaryInsuredCandidates, primaryInsured, setIsModalOpen }) => {

  const handleClick = (index: number) => {
    // merge candidate at provided index with pni driver in session storage
    mergeDriverPrefill(primaryInsuredCandidates, index, primaryInsured);
    setIsModalOpen(false);
  };
  return (
    <div>
      {
        primaryInsuredCandidates.map((c, index) => {
          return (
            <div key={c.licenseNumber} className='pni-candidate-row'>
              <div>
                <div className='pni-candidate-item'>First name: {c.firstName}</div>
                <div className='pni-candidate-item'>Last name: {c.lastName}</div>
                <div className='pni-candidate-item'>Birth date: {c.birthDate}</div>
              </div>
              <div className='pni-candidate-item'>
                <Button id={`pniCandidateSelect-${index}`} variant='contained' color={index === 0 ? 'primary' : 'secondary'} onClick={() => handleClick(index)}>Select</Button>
              </div>
            </div>
          );
        })
      }
    </div>
  );
};

export default PrimaryInsuredCandidates;