import { Button, Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, IconButton, InputLabel, MenuItem, Radio, RadioGroup, Select, withStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { monthList } from '../models/stateCodes';
import { ReactComponent as TrashCanIcon } from '../images/trash-can.svg';
import { accidentList, violationList } from '../models/incidents';

export interface AccidentViolationProps {
  index: number;
}

const CustomFormControlLabel = withStyles({
  label: {
    fontSize: '0.875em',
  }
})(FormControlLabel);

const AccidentViolation: React.FC<AccidentViolationProps> = ({ index }) => {
  const methods = useFormContext();
  const { control, register, errors, watch, reset } = methods;

  const accidentViolationJson = window.sessionStorage.getItem('drivers');
  const accidentViolation = JSON.parse(accidentViolationJson!)[index] || {};
  const [accidentsInfo, setAccidentsInfo] = useState(accidentViolation.accidentsInfo || [{ year: '', month: '', faultAccident: false, accidentType: '', claimAmount: false }])
  const [trafficViolation, setTrafficViolation] = useState(accidentViolation.violationInfo || [{ year: '', month: '', violationType: '' }])
  const [checkedTermsAgreement, setCheckedTermsAgreement] = useState(false);

  const hasAccidents = useWatch({
    control,
    name: `drivers[${index}].hasAccidents`,
    defaultValue: accidentViolation.hasAccidents ? accidentViolation.hasAccidents : '',
  });

  const hasViolations = useWatch({
    control,
    name: `drivers[${index}].hasViolations`,
    defaultValue: accidentViolation.hasViolations ? accidentViolation.hasViolations : '',
  });
  useWatch({
    control,
    name: `drivers[${index}].accidentsInfo`,
    defaultValue: accidentViolation.accidentsInfo ? accidentViolation.accidentsInfo : { year: '', month: '', faultAccident: false, accidentType: '', claimAmount: false },
  });
  useWatch({
    control,
    name: `drivers[${index}].violationInfo`,
    defaultValue: accidentViolation.violationInfo ? accidentViolation.violationInfo : { year: '', month: '', violationType: '' },
  });

  useEffect(() => {
    if (!accidentViolation.accidentsInfo) {
      accidentalAppend(accidentViolation.accidentsInfo ? accidentViolation.accidentsInfo : { year: '', month: '', faultAccident: false, accidentType: '', claimAmount: false })
    }
    if (!accidentViolation.violationInfo) {
      violationAppend(accidentViolation.violationInfo ? accidentViolation.violationInfo : { year: '', month: '', violationType: '' })
    }

  }, [])

  const {
    fields: accidentalFields,
    append: accidentalAppend,
    remove: accidentalRemove,

  } = useFieldArray({
    control,
    name: `drivers[${index}].accidentsInfo`
  });

  const {
    fields: violationFields,
    append: violationAppend,
    remove: violationRemove
  } = useFieldArray({
    control,
    name: `drivers[${index}].violationInfo`
  });

  const [selectionMonthList] = React.useState(() => {
    return monthList.map((s: any) => {
      return (
        <MenuItem key={s.value} value={s.value}>
          {s.display}
        </MenuItem>
      );
    });
  });

  const [selectionYearList] = React.useState(() => {
    const currentYear = new Date().getFullYear()
    const years = []
    for (let i = currentYear; i > (currentYear - 5); i--) {
      years.push(i)
    }
    return years.map((s: any) => {
      return (
        <MenuItem key={s} value={s}>
          {s}
        </MenuItem>
      );
    });
  });

  const [selectionViolationList] = React.useState(() => {
    return violationList.map((s: any, index:number) => {
      const value = `${s.value}/${index}`
      return (
        <MenuItem key={index} value={value}>
          {s.display}
        </MenuItem>
      );
    });
  });


  const [selectionAccidentList] = React.useState(() => {
    return accidentList.map((s: any, index:number) => {
      return (
        <MenuItem key={index} value={s.value}>
          {s.display}
        </MenuItem>
      );
    });
  });


  const handleCheckTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedTermsAgreement(event.target.checked);
  };

  return (
    <div style={{ marginTop: '1em' }}>
      <Grid container spacing={2} justify='flex-start'>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Controller
            control={control}
            name={`drivers[${index}].hasAccidents`}
            defaultValue={accidentViolation.hasAccidents || ''}
            render={({ onChange, value, name, onBlur }) => (
              <FormControl
                variant='outlined'
                fullWidth
                error={
                  !!(
                    methods.errors &&
                    methods.errors.drivers &&
                    methods.errors.drivers[index] &&
                    methods.errors.drivers[index].hasAccidents
                  )
                }
              >
                <Grid container spacing={2} justify='flex-start'>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <FormLabel style={{ color: 'black' }}>Have you had any at fault accidents in the past 3 years?</FormLabel>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <RadioGroup value={value} onChange={onChange} onBlur={onBlur} row aria-label='hasAccidents' name={name}>
                      <span style={{ marginTop: '-9px' }}>
                        <FormControlLabel value='yes' control={<Radio
                          color="primary" />} label='Yes' />
                        <FormControlLabel value='no' control={<Radio
                          color="primary" />} label='No' />
                      </span>
                    </RadioGroup>
                    <FormHelperText>
                      {methods.errors &&
                        methods.errors.drivers &&
                        methods.errors.drivers[index] &&
                        methods.errors.drivers[index].hasAccidents
                        ? methods.errors.drivers[index].hasAccidents.message
                        : ''}
                    </FormHelperText>
                  </Grid>
                </Grid>
              </FormControl>
            )}
          />
        </Grid>

        {hasAccidents === 'yes' ?
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {accidentalFields?.map((item: any, ind: number) => {
              return (<Grid container spacing={4} id={`grid-dom-${ind}`} key={item.id}>
                <Grid item lg={1} md={1} sm={1} xs={1} />
                <Grid item lg={10} md={10} sm={10} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item lg={4} md={4} sm={4} xs={4}>
                      <Controller
                        control={control}
                        name={`drivers[${index}].accidentsInfo[${ind}].month`}
                        defaultValue={item.month}
                        render={({ onChange, value, name, onBlur }) => (
                          <FormControl
                            variant='outlined'
                            fullWidth
                            error=
                            {
                              !!(
                                methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index].accidentsInfo &&
                                methods.errors.drivers[index].accidentsInfo[ind] &&
                                methods.errors.drivers[index].accidentsInfo[ind].month
                              )
                            }
                          >
                            <InputLabel>Month</InputLabel>
                            <Select
                              id={`drivers[${index}].accidentsInfo[${ind}].month`}
                              label='month'
                              value={value}
                              name={name}
                              onChange={onChange}
                              onBlur={onBlur}
                            >
                              {selectionMonthList}
                            </Select>
                            <FormHelperText>
                              {methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index].accidentsInfo
                                && methods.errors.drivers[index].accidentsInfo[ind] ?
                                methods.errors.drivers[index].accidentsInfo[ind].month?.message
                                : ''}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={4}>
                      <Controller
                        control={control}
                        name={`drivers[${index}].accidentsInfo[${ind}].year`}
                        defaultValue={item.year}
                        render={({ onChange, value, name, onBlur }) => (
                          <FormControl
                            variant='outlined'
                            fullWidth
                            error=
                            {
                              !!(
                                methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index].accidentsInfo &&
                                methods.errors.drivers[index].accidentsInfo[ind] &&
                                methods.errors.drivers[index].accidentsInfo[ind].year
                              )
                            }
                          >
                            <InputLabel>Year</InputLabel>
                            <Select
                              id={`drivers[${index}].accidentsInfo[${ind}].year`}
                              label='year'
                              value={value}
                              name={name}
                              onChange={onChange}
                              onBlur={onBlur}
                            >
                              {selectionYearList}
                            </Select>
                            <FormHelperText>
                              {methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index].accidentsInfo
                                && methods.errors.drivers[index].accidentsInfo[ind] ?
                                methods.errors.drivers[index].accidentsInfo[ind].year?.message
                                : ''}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={4}>
                      <CustomFormControlLabel
                        control={
                          <Checkbox
                            id={`drivers[${index}].accidentsInfo[${ind}].faultAccident`}
                            color='primary'
                            name={`drivers[${index}].accidentsInfo[${ind}].faultAccident`}
                            onChange={(e) => {
                              let accidentsInfoArr = [...accidentsInfo]
                              accidentsInfoArr[ind]['faultAccident'] = e.target.checked
                              setAccidentsInfo(accidentsInfoArr)
                              item.faultAccident = e.target.checked
                            }}
                            defaultChecked={item.faultAccident}
                            // checked={accidentViolation.accidentsInfo[ind] ? accidentViolation.accidentsInfo[ind].faultAccident : false}
                            inputRef={register}
                          />
                        }
                        label={
                          <div style={{
                            marginTop: '-4.9em',
                            fontSize: '14px',
                            color: 'rgba(0,0,0,0.6)'
                          }} className='terms-statement'>
                            This was an at-fault accident
                          </div>
                        }
                      />

                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={4}>
                      <Controller
                        control={control}
                        name={`drivers[${index}].accidentsInfo[${ind}].accidentType`}
                        defaultValue={item.accidentType}
                        render={({ onChange, value, name, onBlur }) => (
                          <FormControl
                            variant='outlined'
                            fullWidth
                            error=
                            {
                              !!(
                                methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index].accidentsInfo &&
                                methods.errors.drivers[index].accidentsInfo[ind] &&
                                methods.errors.drivers[index].accidentsInfo[ind].accidentType
                              )
                            }
                          >
                            <InputLabel>Accident Type</InputLabel>
                            <Select
                              id={`drivers[${index}].accidentsInfo[${ind}].accidentType`}
                              label='accidentType'
                              value={value}
                              name={name}
                              onChange={onChange}
                              onBlur={onBlur}
                            >
                              {selectionAccidentList}
                            </Select>
                            <FormHelperText>
                              {methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index].accidentsInfo
                                && methods.errors.drivers[index].accidentsInfo[ind] ?
                                methods.errors.drivers[index].accidentsInfo[ind].accidentType?.message
                                : ''}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={4}>
                      <CustomFormControlLabel
                        control={
                          <Checkbox
                            id={`drivers[${index}].accidentsInfo[${ind}].claimAmount`}
                            color='primary'
                            name={`drivers[${index}].accidentsInfo[${ind}].claimAmount`}
                            onChange={handleCheckTerms}
                            defaultChecked={item.claimAmount}
                            //  checked={accidentViolation.accidentsInfo[ind] ? accidentViolation.accidentsInfo[ind].faultAccident : false}
                            inputRef={register}
                          />
                        }
                        label={
                          <div style={{
                            marginTop: '-4.9em',
                            fontSize: '14px',
                            color: 'rgba(0,0,0,0.6)'
                          }} className='terms-statement'>
                            Claim over $1000
                          </div>
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {ind > 0 && <Grid item lg={1} md={1} sm={1} xs={1} >
                  <IconButton onClick={() => {
                    accidentalRemove(ind)
                  }} >
                    <TrashCanIcon />
                  </IconButton>
                </Grid>}

              </Grid>
              )
            })}

            <Grid container spacing={0}>
              <Grid item lg={1} md={1} sm={1} />
              <Grid item lg={10} md={10} sm={10} xs={12}>
                <Grid container spacing={3}>
                  <Grid item lg={4} md={4} sm={4} >
                    <Button
                      id='accidentAdd'
                      className='secondary-info-import-submit'
                      type='button'
                      color='primary'
                      variant='contained'
                      onClick={() => {
                        let obj = { year: '', month: '', faultAccident: false, accidentType: '', claimAmount: false }
                        const accInfo = [...accidentsInfo]
                        accInfo.push(obj)
                        setAccidentsInfo(accInfo)
                        accidentalAppend(obj)
                      }}
                    >
                      Add
                    </Button>
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>

                  </Grid>
                  <Grid item lg={4} md={4} sm={4} />
                </Grid>
                <Grid item lg={1} md={1} sm={1} />
              </Grid>
            </Grid>
          </Grid>
          : null
        }

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Controller
            control={control}
            name={`drivers[${index}].hasViolations`}
            defaultValue={accidentViolation.hasViolations ? accidentViolation.hasViolations : ''}
            render={({ onChange, value, name, onBlur }) => (
              <FormControl
                variant='outlined'
                fullWidth
                error={
                  !!(
                    methods.errors &&
                    methods.errors.drivers &&
                    methods.errors.drivers[index] &&
                    methods.errors.drivers[index].hasViolations
                  )
                }
              >

                <Grid container spacing={2} justify='flex-start'>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <FormLabel style={{ color: 'black' }}>Have you had any traffic violations in the past 3 years?</FormLabel>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <RadioGroup value={value} onChange={onChange} onBlur={onBlur} row aria-label='hasViolations' name={name}>
                      <span style={{ marginTop: '-9px' }}>
                        <FormControlLabel value='yes' control={<Radio
                          color="primary" />} label='Yes' />
                        <FormControlLabel value='no' control={<Radio
                          color="primary" />} label='No' />
                      </span>
                    </RadioGroup>
                    <FormHelperText>
                      {methods.errors &&
                        methods.errors.drivers &&
                        methods.errors.drivers[index] &&
                        methods.errors.drivers[index].hasViolations
                        ? methods.errors.drivers[index].hasViolations.message
                        : ''}
                    </FormHelperText>
                  </Grid>
                </Grid>
              </FormControl>
            )}
          />
        </Grid>
        {hasViolations === 'yes' ?
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {violationFields?.map((item: any, ind: number) => {
              return (<Grid container spacing={4} key={item.id}>
                <Grid item lg={1} md={1} sm={1} />
                <Grid item lg={10} md={10} sm={10} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item lg={4} md={4} sm={4} xs={4}>

                      <Controller
                        control={control}
                        name={`drivers[${index}].violationInfo[${ind}].month`}
                        defaultValue={item.month}
                        render={({ onChange, value, name, onBlur }) => (
                          <FormControl
                            variant='outlined'
                            fullWidth
                            error=
                            {
                              !!(
                                methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index].violationInfo &&
                                methods.errors.drivers[index].violationInfo[ind] &&
                                methods.errors.drivers[index].violationInfo[ind].month
                              )
                            }
                          >
                            <InputLabel>Month</InputLabel>
                            <Select
                              id='month'
                              label='month'
                              value={value}
                              name={name}
                              onChange={onChange}
                              onBlur={onBlur}
                            >
                              {selectionMonthList}
                            </Select>
                            <FormHelperText>
                              {methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index].violationInfo
                                && methods.errors.drivers[index].violationInfo[ind] ?
                                methods.errors.drivers[index].violationInfo[ind].month?.message
                                : ''}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={4}>
                      <Controller
                        control={control}
                        name={`drivers[${index}].violationInfo[${ind}].year`}
                        defaultValue={item.year}
                        render={({ onChange, value, name, onBlur }) => (
                          <FormControl
                            variant='outlined'
                            fullWidth
                            error=
                            {
                              !!(
                                methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index].violationInfo &&
                                methods.errors.drivers[index].violationInfo[ind] &&
                                methods.errors.drivers[index].violationInfo[ind].year
                              )
                            }
                          >
                            <InputLabel>Year</InputLabel>
                            <Select
                              id='year'
                              label='year'
                              value={value}
                              name={name}
                              onChange={onChange}
                              onBlur={onBlur}
                            >
                              {selectionYearList}
                            </Select>
                            <FormHelperText>
                              {methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index].violationInfo
                                && methods.errors.drivers[index].violationInfo[ind] ?
                                methods.errors.drivers[index].violationInfo[ind].year?.message
                                : ''}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={4}>
                      <Controller
                        control={control}
                        name={`drivers[${index}].violationInfo[${ind}].violationType`}
                        defaultValue={item.violationType}
                        render={({ onChange, value, name, onBlur }) => (
                          <FormControl
                            variant='outlined'
                            fullWidth
                            error=
                            {
                              !!(
                                methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index].violationInfo &&
                                methods.errors.drivers[index].violationInfo[ind] &&
                                methods.errors.drivers[index].violationInfo[ind].violationType
                              )
                            }
                          >
                            <InputLabel>Violation type</InputLabel>
                            <Select
                              id='violationType'
                              label='violationType'
                              value={value}
                              name={name}
                              onChange={onChange}
                              onBlur={onBlur}
                            >
                              {selectionViolationList}
                            </Select>
                            <FormHelperText>
                              {methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index].violationInfo
                                && methods.errors.drivers[index].violationInfo[ind] ?
                                methods.errors.drivers[index].violationInfo[ind].violationType?.message
                                : ''}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {ind > 0 && <Grid item lg={1} md={1} sm={1} >
                  <IconButton onClick={() => { violationRemove(ind) }} >
                    <TrashCanIcon />
                  </IconButton>
                </Grid>}
              </Grid>)
            })}
            <Grid container spacing={0}>
              <Grid item lg={1} md={1} sm={1} />
              <Grid item lg={10} md={10} sm={10} xs={12}>
                <Grid container spacing={3}>
                  <Grid item lg={4} md={4} sm={4} >
                    <Button
                      id='violationAdd'
                      className='secondary-info-import-submit'
                      type='button'
                      color='primary'
                      variant='contained'
                      onClick={() => {
                        let obj = { year: '', month: '', violationType: '' }
                        const trafficViolationArr = [obj, ...trafficViolation]
                        setTrafficViolation(trafficViolationArr)
                        violationAppend(obj)
                      }}
                    >
                      Add
                    </Button>
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>

                  </Grid>
                  <Grid item lg={4} md={4} sm={4} />
                </Grid>
                <Grid item lg={1} md={1} sm={1} />
              </Grid>
            </Grid>
          </Grid>
          : null
        }
      </Grid>
    </div >
  );
};

export default AccidentViolation;