import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

import { ReactComponent as ExpandIcon } from '../images/expand-icon.svg';
import { Driver } from '../models/motion';
import MotionModal from './MotionModal';

export interface DisclosureAccordionProps {
  disclosure: {
    disclosureTitle: string;
    disclosureText: string[];
  }
  isExpanded: boolean;
  index: number;
  primaryDriver: Driver;
}

const Accordion = withStyles({
  root: {
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    color: 'black',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
    fontFamily: 'AcherusGrotesqueBold',
    fontSize: '0.875em',
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    fontSize: '0.875em',
  },
}))(MuiAccordionDetails);

const DisclosureAccordion: React.FC<DisclosureAccordionProps> =
  ({ disclosure, isExpanded, index, primaryDriver }) => {
    const [expanded, setExpanded] = React.useState<string | false>('panel');
    const [accepted, setAccepted] = React.useState(false);

    useEffect(() => {
      setExpanded(isExpanded ? 'panel1' : 'panel');

      if ((index === 0 && isExpanded) || (index === 0 && !isExpanded)) {
        setExpanded('panel1');
      }
    }, [isExpanded, setExpanded, index]);
    const electronicSignature = () => {
      setAccepted(true)
      window.sessionStorage.setItem('iAgreeESignature', JSON.stringify(true));
      window.sessionStorage.setItem('iAgreeESignatureTime', new Date(new Date().toUTCString()).toISOString());
    };
    const handleChange = (panel: string) =>
      (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
      };

    return (
      <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        {
          disclosure.disclosureTitle !== 'Electronic Signature' ?
            <AccordionSummary
              aria-controls='panel1d-content'
              id='panel1d-header'
              expandIcon={<ExpandIcon />}
            >
              {disclosure.disclosureTitle}
            </AccordionSummary>
            :null
        }
        {
        disclosure.disclosureTitle !== 'Electronic Signature' ?
          <AccordionDetails>
            <div>
              {
                disclosure.disclosureText.map(
                  (t, disclosureIndex: number) => <p key={`disclosureText-${disclosureIndex + 1}`}>{t.replace('<<print name of applicant >>', `${primaryDriver.firstName} ${primaryDriver.lastName}`)}</p>
                )   
            }
            </div>
            </AccordionDetails>
            : null
        }
        { 
          disclosure.disclosureTitle === 'Electronic Signature' ?
            <MotionModal
              title='Electronic Signature'
              body={
                <AccordionDetails>
                  <div>
                    {
                      disclosure.disclosureText.map(
                        (t, disclosureIndex: number) => <p key={`disclosureText-${disclosureIndex + 1}`}>{t.replace('<<print name of applicant >>', `${primaryDriver.firstName} ${primaryDriver.lastName}`)}</p>
                      )
                    }
                  </div>
                </AccordionDetails>
              }
              open={accepted === false}
              handleClose={electronicSignature}
              closeText='I Agree'
              disclosureText={true}
            />
          : null
        }
      </Accordion>
    );
  };

export default DisclosureAccordion;