import React, { useContext } from 'react';
import { ReactComponent as LogoFull } from '../images/motion-agent.svg';
import { ReactComponent as NovoLogo } from '../images/partnerImages/novo-logo.svg';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme } from 'pretty-format';
import { Grid, TextField, Paper, IconButton, InputAdornment, Dialog, DialogTitle, CircularProgress, Button, DialogActions } from '@material-ui/core';
import './AgentLogin.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import WarningIcon from '@material-ui/icons/Warning';
import { useHistory } from 'react-router';
import { Helmet } from 'react-helmet';
import { Agentuser } from '../models/motion';
import { GetAgentLogin } from '../utils/queries';
import { agentLoginInfoCapture } from '../utils/partnerTracking';
import { BrandContext } from '../utils/style';
import MotionNovoModal from '../components/MotionNovoModal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      textAlign: 'center',
      padding: '1.5em ',
      paddingBottom: '6em'
    },
  }),
);


const AgentLogin: React.FC = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const history = useHistory();
  const classes = useStyles();
  const { brand } = useContext(BrandContext);
  const urlParams = new URLSearchParams(window.location.search);
  const redirect = urlParams.get('redirect') === 'motion' ? true : false
  const [motionNovoOpen, setMotionNovoOpen] = React.useState(redirect);
  const displayContent = `We're making the necessary changes to our site.  Effective October 1, 2021 we are placing a moratorium on all new business until further notice. We'll continue to service your existing book of business as usual.  Please log in with your existing credentials to see your customer list.`;
  const buttonContent = 'Continue to Log in';

  const schema = yup.object().shape({
    email: yup.string().email().required('Please enter your email').trim(),
    password: yup.string().required('Please enter your password').trim(),
  });
  const { register, handleSubmit, errors, } = useForm<Agentuser>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: Agentuser) => {
    setOpen(true);
    setIsError(false);
    setMessage('');
    const resp = await GetAgentLogin(data);
    if (resp) {
      if (resp.status === 'failed') {
        setIsError(true);
        setOpen(true);
        setMessage('Invalid username or password');
      } else {
        setOpen(false);
        resp.email = data.email;
        agentLoginInfoCapture(resp.agent_id);
        window.localStorage.setItem('agentData', JSON.stringify({ agentId: resp.agent_id, email: resp.email, channel: resp.channel }));
        if (window.location.href.indexOf('agent?quoteId') > -1) {
          const quoteId = history.location.search.split('quoteId')[1];
          if (quoteId) {
            history.push(`/partner-quote?transactionId${quoteId}`);
          }
        } else {
          history.push('/agent-dash');
        }
      }
    }

  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleForgotPassword = () => {
    history.push('/agent-forgot');
  };
  const updateMotionNovoOpen = (state: boolean) => {
    setMotionNovoOpen(state);
  };
  return (
    <>
      <Helmet>
        <title>{`Initial Info - ${brand === 'motion' ? 'Motion Insurance' : 'Novo Insurance'}`}</title>
        {
          brand === 'motion' ?
            <link rel='icon' href='/favicon.ico' />
            :
            <link rel='icon' href='/novo-fav.ico' />
        }
      </Helmet>
      <MotionNovoModal content={displayContent} buttonContent={buttonContent} motionNovoOpen={motionNovoOpen} isAgent={true} updateMotionNovoOpen={updateMotionNovoOpen} />
      <div className={classes.root}>
        <Dialog
          open={open}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{!isError && <CircularProgress />} {isError && message}</DialogTitle>
          {isError && <DialogActions>
            <Button onClick={() => setOpen(false)} color='primary' autoFocus>
              Ok
            </Button>
          </DialogActions>}

        </Dialog>
        <Grid container spacing={3}>
          <Grid item lg={12} component={Paper} elevation={1} className='logo-paper'>
            <div className='logo-full'>
              {brand === 'novo' &&
                <a href='https://www.novo.us' target='_blank'>
                  <NovoLogo />
                </a>
              }
              {brand === 'motion' &&
                <LogoFull />
              }
            </div>
          </Grid>
          <Grid container spacing={3} className='agent-login'>
            <Grid item lg={4} />
            <Grid item lg={4}>
              <Paper className={classes.paper}>
                <h2>Agent Log in</h2>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Grid container spacing={3}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <TextField
                        id='email'
                        error={errors.email?.message !== undefined}
                        helperText={
                          errors.email?.message !== undefined
                            ? errors.email?.message
                            : ''
                        }
                        label='Email'
                        variant='outlined'
                        type='text'
                        name='email'
                        inputRef={register}
                        InputProps={{
                          endAdornment:
                            <InputAdornment position='end'>
                              {errors.email?.message !== undefined && <WarningIcon color='error' />}
                            </InputAdornment>
                          ,
                        }}
                        fullWidth
                      />
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <TextField
                        error={errors.password?.message !== undefined}
                        helperText={
                          errors.password?.message !== undefined
                            ? errors.password?.message
                            : ''
                        }
                        label='Password'
                        variant='outlined'
                        type={showPassword ? 'text' : 'password'}
                        name='password'
                        inputRef={register}
                        InputProps={{
                          endAdornment:
                            <InputAdornment position='end'>
                              <IconButton
                                onClick={handleClickShowPassword}
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ,
                        }}
                        fullWidth

                      />
                    </Grid>

                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Button
                      id='lookUpMyInfo'
                      className='import-submit'
                      type='submit'
                      color='primary'
                      variant='contained'
                    >
                      Login
                    </Button>
                  </Grid>
                </form>
                <h4 className='forgot-pass' onClick={handleForgotPassword}>Forgot Password ?</h4>
              </Paper>
            </Grid>
            <Grid item lg={4} />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default AgentLogin;