import React, { useEffect, useState, useContext } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import jwt_decode from "jwt-decode";
import InputMask from 'react-input-mask';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as Avatar } from '../images/avatar.svg';
import { ReactComponent as AvatarNovo } from '../images/avatar-novo.svg';
import './InitialInfo.scss';
import { usStateList } from '../models/stateCodes';
import { Driver } from '../models/motion';
import DisclosuresModal from '../components/DisclosuresModal';
import verisk from '../utils/veriskResponse.json';
// import { getVehicles, getVeriskDrivers } from '../utils/verisk';
// import { VeriskData, VeriskVehicle } from '../models/verisk';
import { VeriskVehicle } from '../models/verisk';
import MotionModal from '../components/MotionModal';
import { LIVE_STATES } from '../utils/state';
import { sendActiveCampaignEvent } from '../utils/activeCampaign';
import { Partner } from '../models/partner';
import PlacesAutoComplete from '../components/PlacesAutoComplete';
import { BrandContext, getBrand, } from '../utils/style';
import IdScan from '../components/IdScan';
import MotionNovoModal from '../components/MotionNovoModal';

const addressRegex = /^(?!(.*(P.O. Box|PO Box|P O Box|P.O Box|Post Office Box).*))/i;
const schema = yup.object().shape({
  firstName: yup.string().required('Please enter your first name').trim(),
  lastName: yup.string().required('Please enter your last name').trim(),
  address: yup.string().matches(addressRegex, {
    message: 'Please enter your garaging address',
    excludeEmptyString: true,
  }).required('Please enter your address').trim(),
  apartmentNumber: yup.string().trim(),
  city: yup.string().required('Please enter your city').trim(),
  state: (process.env.REACT_APP_ENV === 'production'&& !window.location.hostname.includes('stage')) ? yup.string().required('Please select a state').trim() :
    yup.string().oneOf(LIVE_STATES, 'We aren\'t live in your state yet. Please check back soon!').required('Please select a state').trim(),
  zip: yup
    .string()
    .matches(/(^\d{5}$)/, {
      message: 'Please submit a valid zip code',
      excludeEmptyString: true,
    })
    .required('Please enter your zip code')
    .trim(),
  email: yup.string().email().required('Please enter your email').trim(),
  confirmEmail: yup
    .string()
    .oneOf([yup.ref('email'), ''], 'Email does not match')
    .required('Please verify your email address')
    .trim(),
  phone: yup
    .string()
    .matches(/(^\d{10}$)|(^\d{3}-\d{3}-\d{4}$)/, {
      message: 'Please submit a valid phone number 555-555-5555',
      excludeEmptyString: true,
    })
    .required('Please enter your phone number')
    .trim(),
});

const InitialInfo: React.FC<Driver> = () => {
  if (!window.sessionStorage.getItem('sessionId')) {
    const sessionId = uuidv4();
    window.sessionStorage.setItem('sessionId', sessionId);
  }
  const [sessionUser, setSessionUser]: any = useState<any>('');

  useEffect(() => {
    (window as any).nid('start', window.location.pathname);

    setTimeout(() => {
      const gaClientId = window.sessionStorage.getItem('gaClientId');
      const neuroUserID = gaClientId ? gaClientId : null;
      if (neuroUserID) {
        (window as any).nid('setUserId', neuroUserID);
      }
      Object.keys(window.localStorage).forEach(key => {
        if (key.endsWith('idToken')) {
          const idToken = window.localStorage.getItem(key)
          const decoded: any = idToken ? jwt_decode(idToken) : '';
          setSessionUser(decoded)
        }
      })

    }, 2000);
  }, []);

  const { brand } = useContext(BrandContext);
  const history = useHistory();
  const partnerInfoJson = window.sessionStorage.getItem('partner');
  const partnerInfo = JSON.parse(partnerInfoJson!) as Partner || {} as Partner;
  const driversJson = window.sessionStorage.getItem('drivers') || '[]';
  const drivers = JSON.parse(driversJson) as Driver[];
  const vehiclesJson = window.sessionStorage.getItem('vehicles') || '[]';
  const vehicles = JSON.parse(vehiclesJson) as VeriskVehicle[];
  const primaryInsured =
    drivers.filter((d: Driver) => d.isPrimaryInsured)[0] || ({} as Driver);
  const [liveStateModal, setLiveStateModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const redirect = urlParams.get('redirect') === 'motion' ? true : false
  const [motionNovoOpen, setMotionNovoOpen] = useState(redirect);

  const methods = useForm<Driver>({
    defaultValues: {
      firstName: primaryInsured.firstName,
      lastName: primaryInsured.lastName,
      address: primaryInsured.address,
      apartmentNumber: primaryInsured.apartmentNumber,
      city: primaryInsured.city,
      state: primaryInsured.state,
      zip: primaryInsured.zip,
      email: primaryInsured.email,
      phone: primaryInsured.phone,
      // due to InputMask phone number default value is set below
    },
    resolver: yupResolver(schema),
  });
  const { register, handleSubmit, errors, control, watch, setValue } = methods;
  const watchState = watch('state');
  const displayContent = `We’re so glad you’re here. If you’re a Motion customer, we got you. Sign into the app to manage your account.
  New to Novo? Look around and get a quote.`;
  const buttonContent = 'Continue to quote';
  const queryParams = window.location.search
  if (queryParams) {
    window.sessionStorage.setItem('queryParams', queryParams);
  }

  // places autocomplete
  const [streetNumber, setStreetNumber] = useState('');
  const [streetName, setStreetName] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');

  useEffect(() => {
    if (streetNumber !== '' && streetName !== '') {
      setValue('address', `${streetNumber} ${streetName}`);
    }
    if (city) {
      setValue('city', `${city}`);
    }
    if (state) {
      setValue('state', `${state}`);
    }
    if (zipCode) {
      setValue('zip', `${zipCode}`);
    }
  }, [setValue, streetNumber, streetName, city, state, zipCode]);

  useEffect(() => {
    if (!LIVE_STATES.includes(watchState!)) {
      setLiveStateModal(true);
    }
  }, [watchState]);

  const onSubmit = (data: Driver) => {
    // save mock verisk data and parse drivers and vehicles
    // TODO: make verisk request
    window.sessionStorage.setItem('veriskData', JSON.stringify(verisk));
    const veriskDrivers = [] as Driver[]; // getVeriskDrivers((verisk as unknown) as VeriskData);
    let modifiedDrivers: Driver[] = [];
    if (veriskDrivers !== null && veriskDrivers.length > 0) {
      modifiedDrivers = veriskDrivers.map((d: Driver, index: number) => {
        let modifiedDriver = d;
        // update the primary insured from verisk with data from InitialInfo
        if (modifiedDriver.isPrimaryInsured) {
          modifiedDriver = { ...d, ...primaryInsured, ...data };
        } else {
          modifiedDriver = { ...d, ...drivers[index] };
        }
        return modifiedDriver;
      });
    } else {
      modifiedDrivers.push({
        ...primaryInsured,
        ...data,
      } as Driver);
      if (drivers.length > 1) {
        drivers.forEach((value, index) => {
          if (index !== 0) {
            modifiedDrivers.push(drivers[index]);
          }
        });
      }
    }
    window.sessionStorage.setItem('drivers', JSON.stringify(modifiedDrivers));
    const veriskVehicles = [] as VeriskVehicle[]; // getVehicles((verisk as unknown) as VeriskData);
    let modifiedVehicles = vehicles && vehicles.length > 0 ? vehicles : [] as VeriskVehicle[];
    if (veriskVehicles !== null && veriskVehicles.length > 0) {
      modifiedVehicles = veriskVehicles.map(
        (v: VeriskVehicle, index: number) => {
          return { ...v, ...vehicles[index] };
        }
      );
      if (modifiedVehicles && modifiedVehicles.length > 0) {
        window.sessionStorage.setItem('vehicles', JSON.stringify(modifiedVehicles));
      }
    }
    window.sessionStorage.setItem('vehicles', JSON.stringify(modifiedVehicles));

    const agentData = window.localStorage.getItem('agentData');
    setOpenModal(true)

    window.scrollTo(0, 0);
    if (process.env.REACT_APP_ENV !== 'production' || window.location.hostname.includes('stage')) {
      history.push(`/secondary-info${queryParams}`);
    }
  };


  const [stateMenuList] = React.useState(() => {
    return usStateList.map((s) => {
      return (
        <MenuItem key={s.value} value={s.value}>
          {s.display}
        </MenuItem>
      );
    });
  });

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleOnLiveStateClose = () => {
    setLiveStateModal(!liveStateModal);
  };
  const updateMotionNovoOpen = (state: boolean) => {
    setMotionNovoOpen(state);
  };

  const paste = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
  };

  return (
    <>
      <Helmet>
        <title>{`Primary Info - ${brand === 'motion' ? 'Motion Insurance' : 'Novo Insurance'}`}</title>
        {
          brand === 'motion' ?
            <link rel='icon' href='/favicon.ico' />
            :
            <link rel='icon' href='/novo-fav.ico' />
        }
        <meta name='description' content={`Get a ${brand === 'motion' ? 'Motion' : 'Novo'} Auto Insurance quote and buy a policy`} />
      </Helmet>
      <MotionNovoModal content={displayContent} buttonContent={buttonContent} motionNovoOpen={motionNovoOpen} isAgent={false} updateMotionNovoOpen={updateMotionNovoOpen} />
      {!motionNovoOpen && <DisclosuresModal />}
      {(process.env.REACT_APP_ENV === 'production' && !window.location.hostname.includes('stage')) ?
        <MotionModal
          title='Oops...'
          body={
            `It looks like we are not yet live in your state.
          We are adding new states rapidly.
          Thank you for your interest in ${brand === 'motion' ? 'Motion Insurance' : 'Novo Insurance'},
          please let us know if you'd like to be notified when we are available in your state.`
          }
          open={openModal}
          handleClose={handleModalClose}
          closeText='Ok, got it!'
        /> :
        <MotionModal
          title='Oops...'
          body={
            `It looks like we are not yet live in your state.
          We are adding new states rapidly.
          Thank you for your interest in ${brand === 'motion' ? 'Motion Insurance' : 'Novo Insurance'},
          and we look forward to serving you in the near future.`
          }
          open={liveStateModal}
          handleClose={handleOnLiveStateClose}
          closeText='Ok, got it!'
        />
      }
      <div className='import-message-wrapper'>
        {
          brand === 'motion' ?
            <Avatar />
            :
            <AvatarNovo />
        }
        <span className='import-title'>Import your information</span>
      </div>
      <span className='import-message'>
        Don't want to fill out forms? No problem! We make it easy. Just scan your driver's license (or type in the info below if you're on a desktop) and we'll pull up the info on your card, as well as any vehicles in your name.
      </span>
      <FormProvider {...methods}>
        <IdScan />
        <form
          id='initialForm'
          className='import-form'
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Controller
                as={
                  <TextField
                    id='firstName'
                    label='First name'
                    variant='outlined'
                    fullWidth
                    error={
                      !!(
                        errors &&
                        errors.firstName
                      )
                    }
                    helperText={
                      errors &&
                        errors.firstName
                        ? errors.firstName.message
                        : ''
                    }
                  />
                }
                name='firstName'
                control={control}
                defaultValue=''
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Controller
                as={
                  <TextField
                    id='lastName'
                    label='Last name'
                    variant='outlined'
                    fullWidth
                    error={
                      !!(
                        errors &&
                        errors.lastName
                      )
                    }
                    helperText={
                      errors &&
                        errors.lastName
                        ? errors.lastName.message
                        : ''
                    }
                  />
                }
                name='lastName'
                control={control}
                defaultValue=''
              />
            </Grid>
            <Grid item lg={12} xs={12}>
              <PlacesAutoComplete
                id='garagingAddress'
                name='address'
                defaultValue=''
                label='Garaging Address'
                error={
                  !!(
                    errors &&
                    errors.address
                  )
                }
                helperText={
                  errors &&
                    errors.address &&
                    errors.address.message
                    ? errors.address.message
                    : ''
                }
                setStreetNumber={setStreetNumber}
                setStreetName={setStreetName}
                setCity={setCity}
                setState={setState}
                setZipCode={setZipCode}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Controller
                as={
                  <TextField
                    id='apartmentNumber'
                    label='Apartment Number'
                    variant='outlined'
                    fullWidth
                    error={
                      !!(
                        errors &&
                        errors.apartmentNumber
                      )
                    }
                    helperText={
                      errors &&
                        errors.apartmentNumber
                        ? errors.apartmentNumber.message
                        : ''
                    }
                  />
                }
                name='apartmentNumber'
                control={control}
                defaultValue=''
              />
            </Grid>
          </Grid>
          <div className='import-form-second-half'>
            <Grid container spacing={3}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Controller
                  as={
                    <TextField
                      id='city'
                      label='City'
                      variant='outlined'
                      fullWidth
                      error={
                        !!(
                          errors &&
                          errors.city
                        )
                      }
                      helperText={
                        errors &&
                          errors.city
                          ? errors.city.message
                          : ''
                      }
                    />
                  }
                  name='city'
                  control={control}
                  defaultValue=''
                />
              </Grid>
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <Controller
                  control={control}
                  name='state'
                  defaultValue=''
                  render={({ onChange, value, name, onBlur }) => (
                    <FormControl
                      variant='outlined'
                      fullWidth
                      error={errors.state?.message !== undefined}
                    >
                      <InputLabel>State</InputLabel>
                      <Select
                        id='state'
                        label='State'
                        value={value}
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                      >
                        {stateMenuList}
                      </Select>
                      <FormHelperText>
                        {errors.state?.message !== undefined
                          ? errors.state?.message
                          : ''}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <Controller
                  as={
                    <TextField
                      id='zipCode'
                      label='Zip code'
                      variant='outlined'
                      type='tel'
                      fullWidth
                      error={
                        !!(
                          errors &&
                          errors.zip
                        )
                      }
                      helperText={
                        errors &&
                          errors.zip
                          ? errors.zip.message
                          : ''
                      }
                      inputProps={{ maxLength: 5 }}
                    />
                  }
                  name='zip'
                  control={control}
                  defaultValue=''
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  id='email'
                  error={errors.email?.message !== undefined}
                  helperText={
                    errors.email?.message !== undefined
                      ? errors.email?.message
                      : ''
                  }
                  label='Email'
                  variant='outlined'
                  type='email'
                  name='email'
                  inputRef={register}
                  fullWidth
                  value={sessionUser['email']}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  id='confirmEmail'
                  error={errors.confirmEmail?.message !== undefined}
                  helperText={
                    errors.confirmEmail?.message !== undefined
                      ? errors.confirmEmail?.message
                      : ''
                  }
                  label='Re-type your email'
                  variant='outlined'
                  type='confirmEmail'
                  name='confirmEmail'
                  inputRef={register}
                  fullWidth
                  autoComplete='off'
                  onPaste={paste}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Controller
                  control={control}
                  alwaysShowMask={false}
                  maskChar=''
                  name='phone'
                  defaultValue={primaryInsured.phone || ''}
                  as={
                    <InputMask mask='999-999-9999'>
                      {() => {
                        return (
                          <TextField
                            id='phoneNumber'
                            error={errors.phone?.message !== undefined}
                            helperText={
                              errors.phone?.message !== undefined
                                ? errors.phone?.message
                                : ''
                            }
                            label='Phone Number'
                            variant='outlined'
                            type='tel'
                            fullWidth
                          />
                        );
                      }}
                    </InputMask>
                  }
                />
              </Grid>
            </Grid>

            <Button
              id='lookUpMyInfo'
              className='initial-info-import-submit'
              type='submit'
              color='primary'
              variant='contained'
            >
              Continue
            </Button>

          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default InitialInfo;
