import React, { useContext, useEffect, useState } from 'react';
import Bugsnag from '@bugsnag/js';
import './Welcome.scss';
import NumberFormat from 'react-number-format';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { Button, CircularProgress, Grid, TextField } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { Helmet } from 'react-helmet';

import { ReactComponent as AppStore } from '../images/app-store-icon.svg';
import { ReactComponent as PlayStore } from '../images/play-store-icon.svg';
import { ReactComponent as ToolTipIcon } from '../images/tool-tip.svg';
import PageHeader from '../components/PageHeader';
import { Driver, UserAccount } from '../models/motion';
import { CreateUserAccount } from '../utils/mutations';
import MotionToast from '../components/MotionToast';
import { BrandContext, getPhoneNumber, getPrimaryColor } from '../utils/style';
import { GetPaymentInfo } from '../utils/queries';

export interface WelcomeProps {

}

const Welcome: React.FC<WelcomeProps> = () => {

  useEffect(() => {
    (window as any).nid('applicationSubmit');
  }, []);
  const { brand } = useContext(BrandContext);
  const phoneNumber = getPhoneNumber();
  const [createAccountMessage, setCreateAccountMessage] = useState('');
  const [createAccountToast, setCreateAccountToast] = useState(false);
  const [createAccountSeverity, setCreateAccountSeverity] = useState('success' as 'success' | 'error' | 'info' | 'warning');
  const [createdAccount, setCreatedAccount] = useState(window.sessionStorage.getItem('accountCreated'));
  const driversJson = window.sessionStorage.getItem('drivers');
  const drivers = JSON.parse(driversJson!) as Driver[];
  const paymentResponseJson = window.sessionStorage.getItem('paymentResponse');
  const paymentResponse = JSON.parse(paymentResponseJson!);
  const makePaymentInputJson = window.sessionStorage.getItem('makePaymentInput');
  const makePaymentInput = JSON.parse(makePaymentInputJson!);
  const wasPaidInFull = makePaymentInput.paidInFull;
  const motionTransactionId = window.sessionStorage.getItem('motionTransactionId');
  const selectedTier = window.sessionStorage.getItem('selectedTier');
  const appStoreUrl = brand === 'novo' ? 'https://apps.apple.com/us/app/novo-insurance/id1560121519' : 'https://apps.apple.com/us/app/motion-auto-insurance/id1476595129';
  const googlePlayUrl = brand === 'novo' ? 'https://play.google.com/store/apps/details?id=com.novo.insurance.client&hl=en_US&gl=US' : 'https://play.google.com/store/apps/details?id=com.motionautomobile&hl=en_US&gl=US';
  const { data: paymentInfoData, } = useQuery('paymentInfo', () => GetPaymentInfo({ paidInFull: wasPaidInFull, selection: selectedTier!, rateId: motionTransactionId! }), {
    onError: (error) => {
      Bugsnag.notify({ name: 'Error fetching payment info', message: error as string });
    }
  });
  if (paymentResponseJson) {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }
  const primaryDriver = drivers.filter(d => d.isPrimaryInsured)[0];
  const schema = yup.object().shape({
    email: yup.string()
      .email('Please enter a valid email')
      .required('Please enter an email used to login on the app')
      .trim(),
    password: yup.string()
      .required('Please enter a valid password')
      .matches(/^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
        { message: 'Please match the password requirements' })
      .trim(),
    confirmPassword: yup.string()
      .oneOf([yup.ref('password')], 'Passwords must match')
      .trim(),
  });
  const { control, errors, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  const handleDisplayToast = (severity: 'success' | 'error' | 'info' | 'warning', message: string) => {
    setCreateAccountSeverity(severity);
    setCreateAccountToast(true);
    setCreateAccountMessage(message);
    setTimeout(() => {
      setCreateAccountToast(false);
    }, 6000);
  };

  const [mutateCreateUser, { isLoading: isCreateUserLoading }] =
    useMutation(CreateUserAccount, {
      onSuccess: (data) => {
        if (data.error) {
          handleDisplayToast('error', data.message ? data.message : data.errorMessage);
        } else {
          setCreatedAccount('true');
          window.sessionStorage.setItem('accountCreated', 'true');
          handleDisplayToast('success', 'Account created!');
        }
      },
      onError: (error) => {
        Bugsnag.notify({ name: 'Create Account', message: 'Request failed to sign up user' });
      }
    });

  const onSubmit = (data: any) => {
    const payload = {
      username: data.email,
      password: data.confirmPassword,
      email: data.email,
      policy_id: paymentResponse.policyId,
      drivers_license: primaryDriver.licenseNumber,
    } as UserAccount;

    mutateCreateUser(payload);
  };

  return (
    <>
      <Helmet>
        <title>{`Welcome - ${brand === 'novo' ? 'Novo Insurance' : 'Motion Insurance'}`}</title>
        {
          brand === 'novo' ?
            <link rel='icon' href='/novo-fav.ico' />
            :
            <link rel='icon' href='/favicon.ico' />
        }
      </Helmet>
      <PageHeader
        icon='carCheck'
        header={`Congratulations, ${primaryDriver.firstName}! You are insured.`}
        description='Your Motion Insurance Policy Confirmation'
      />

      <div className='account-signup-wrapper'>
        <div>
          To access your policy later, enter login information below.
        </div>
        <form
          id='accountSignupForm'
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className='account-signup-form-wrapper'>
            <Grid container spacing={2} justify='flex-start'>
              <Grid item lg={8} md={8} sm={12} xs={12}>
                <Controller
                  as={
                    <TextField
                      id='email'
                      label='Email'
                      variant='outlined'
                      fullWidth
                      error={
                        !!(
                          errors &&
                          errors.email
                        )
                      }
                      helperText={
                        errors &&
                          errors.email
                          ? errors.email.message
                          : ''
                      }
                    />
                  }
                  name='email'
                  control={control}
                  defaultValue={primaryDriver.email || ''}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Grid container spacing={2} justify='flex-start'>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Controller
                      as={
                        <TextField
                          id='password'
                          label='Password'
                          variant='outlined'
                          type='password'
                          fullWidth
                          error={
                            !!(
                              errors &&
                              errors.password
                            )
                          }
                          helperText={
                            errors &&
                              errors.password
                              ? errors.password.message
                              : ''
                          }
                        />
                      }
                      name='password'
                      control={control}
                      defaultValue=''
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Controller
                      as={
                        <TextField
                          id='confirmPassword'
                          label='Confirm password'
                          variant='outlined'
                          type='password'
                          fullWidth
                          error={
                            !!(
                              errors &&
                              errors.confirmPassword
                            )
                          }
                          helperText={
                            errors &&
                              errors.confirmPassword
                              ? errors.confirmPassword.message
                              : ''
                          }
                        />
                      }
                      name='confirmPassword'
                      control={control}
                      defaultValue=''
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item lg={6} md={6}>
                <div className='password-information'>
                  <div>
                    <ToolTipIcon />
                  </div>
                  <div className='password-prompt-wrapper'>
                    <div>
                      Password must:
                    </div>
                    <div className='password-prompt-body'>
                      Be at least 8 characters in length,
                      have numbers and letters, and
                      have at least one special character
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className='create-account-button'>
            {
              isCreateUserLoading ?
                <div className='create-account-spinny-wrapper'>
                  <div>
                    <CircularProgress />
                  </div>
                  <div className='submitting-account-body'>
                    Creating account...
                  </div>
                </div>
                :
                <Button
                  disabled={createdAccount !== null}
                  type='submit'
                  id='createAccountButton'
                  color='primary'
                  variant='contained'
                >
                  Create account
                </Button>
            }
            <MotionToast
              severity={createAccountSeverity as 'success' | 'error' | 'info' | 'warning'}
              message={createAccountMessage}
              open={createAccountToast}
            />
          </div>
        </form>
      </div>

      <div className='payment-confirmation-wrapper'>
        <div className='welcome-bold'>
          Payment confirmation:
        </div>
        {
          paymentInfoData &&
          <div>
            <NumberFormat
              value={paymentInfoData.initialPayment ?? paymentInfoData.planTotal}
              displayType='text'
              prefix='Your first payment of $'
              suffix=' was successfully processed'
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </div>
        }
        <div>
          {`Your transaction ID for the payment is: ${paymentResponse.transactionId}`}
        </div>
      </div>
      <div className='welcome-wrapper'>
        <div>
          Welcome to&nbsp;
          {
            brand === 'novo' ?
              'Novo Insurance!'
              :
              'Motion Insurance!'
          }
          &nbsp;We are excited to have you along for the ride.&nbsp;Check your email for the following items:
        </div>
        <div className='email-list-wrapper'>
          <ul>
            <li>ID cards</li>
            <li>Auto insurance coverage summary / Declarations page</li>
            <li>Personal auto application</li>
            <li>Policy documents</li>
          </ul>
        </div>
        <div className='welcome-bold'>
          <div>
            {`Policy number: ${paymentResponse.policyId}`}
          </div>
          <div>
            {`Effective date: ${new Date(paymentResponse.dateEffective).toLocaleString('en-us', { timeZoneName: 'short' })}`}
          </div>
        </div>
      </div>
      <div className='mobile-app-wrapper'>
        <div className='mobile-app-left'>
          <div className='app-prompt-header'>
            It is all in the App.
          </div>
          <div className='app-prompt-header'>
            Download the&nbsp;
            {
              brand === 'novo' ?
                'Novo Insurance App.'
                :
                'Motion Insurance App.'
            }
          </div>
          <div className='app-prompt-body'>
            Download&nbsp;
            {
              brand === 'novo' ?
                'Novo Insurance'
                :
                'Motion Insurance'
            }
            &nbsp;for iOS or Android using the buttons
            below and save yourself some money.
          </div>
          <div className='mobile-app-button-wrapper'>
            <a
              href={appStoreUrl}
              target='_blank'
              rel='noopener noreferrer'
              style={{ color: getPrimaryColor() }}
            >
              <AppStore />
            </a>
            <a
              href={googlePlayUrl}
              target='_blank'
              rel='noopener noreferrer'
              style={{ color: getPrimaryColor() }}
            >
              <PlayStore />
            </a>
          </div>
        </div>
      </div>
      <div>
        Thank you for choosing&nbsp;
        {
          brand === 'novo' ?
            'Novo insurance'
            :
            'Motion insurance'
        }. We appreciate your business and look forward to driving with you.
      </div>
      <div className='questions-wrapper'>
        <div className='questions-header'>
          Got Questions?
        </div>
        <div className='questions-body'>
          If you still have questions don't hesitate to reach out to us. We are always here to help!
        </div>
        <div className='questions-body'>
          Call&nbsp;
          <a
            href={`tel:${phoneNumber}`}
            style={{ color: getPrimaryColor() }}
          >
            {phoneNumber}
          </a>
          &nbsp;for support from 9am to 5pm MT or chat with an agent now.
        </div>
      </div>

    </>
  );
};

export default Welcome;