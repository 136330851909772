import React, { useContext } from 'react';
import { BrandContext, } from '../utils/style';
import './RightHeader.scss';

const RightHeader: React.FC = () => {
  const { brand } = useContext(BrandContext);
  return (
    <div className='right-header'>
      {
        window.localStorage.getItem('agentData') !== null &&
        <a
          href={`${window.location.origin}/agent-dash`}
          target='_blank'
          rel='noopener noreferrer'
          className='header-link'
        >
          Agent Dashboard
        </a>
      }
      <a
        href={brand === 'motion' ? 'https://motioninsurance.com/contact.html' : 'https://www.novo.us/contact'}
        target='_blank'
        rel='noopener noreferrer'
        className='header-link'
      >
        Contact us
      </a>
    </div>
  );
};

export default RightHeader;
