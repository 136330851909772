import { FormControl, FormHelperText, FormLabel, Grid, Input, InputLabel, Select } from '@material-ui/core';
import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { getCurrentBiLimitsMenuList, getCurrentProviderMenuList, getYesNotMenuList, getOneFiveMonthMenuList, selectionMonthList, selectionYearList } from '../utils/selectList';

import './PriorCoverage.scss';

export interface PriorCoverageProps {
  index: number;
}

const PriorCoverage: React.FC<PriorCoverageProps> = ({ index }) => {
  const priorCoverageJson = window.sessionStorage.getItem('priorCoverage');
  const priorCoverage = JSON.parse(priorCoverageJson!) || {};
  const methods = useFormContext();
  const { errors, control } = methods;
  const carInsuranceForSixMonths = useWatch({
    control,
    name: 'carInsuranceForSixMonths',
    defaultValue: priorCoverage.carInsuranceForSixMonths
  });

  const dueToAMilitaryDeployment = useWatch({
    control,
    name: 'dueToAMilitaryDeployment',
    defaultValue: priorCoverage.dueToAMilitaryDeployment
  })

  const currentlyInsured = useWatch({
    control,
    name: 'currentlyInsured',
    defaultValue: priorCoverage.currentlyInsured
  })

  const monthContinuouslyInsured = useWatch({
    control,
    name: 'monthContinuouslyInsured',
    defaultValue: priorCoverage.monthContinuouslyInsured
  })

  const yearContinuouslyInsured = useWatch({
    control,
    name: 'yearContinuouslyInsured',
    defaultValue: priorCoverage.yearContinuouslyInsured
  })

  const currentCoverageCarrier = useWatch({
    control,
    name: 'currentCoverageCarrier',
    defaultValue: priorCoverage.currentCoverageCarrier
  })

  const currentCoverageBiLimit = useWatch({
    control,
    name: 'currentCoverageBiLimit',
    defaultValue: priorCoverage.currentCoverageBiLimit
  })

  const startMonthDeployed = useWatch({
    control,
    name: 'startMonthDeployed',
    defaultValue: priorCoverage.startMonthDeployed
  })

  const startYearDeployed = useWatch({
    control,
    name: 'startYearDeployed',
    defaultValue: priorCoverage.startYearDeployed
  })

  const endMonthDeployed = useWatch({
    control,
    name: 'endMonthDeployed',
    defaultValue: priorCoverage.endMonthDeployed
  })

  const endYearDeployed = useWatch({
    control,
    name: 'endYearDeployed',
    defaultValue: priorCoverage.endYearDeployed
  })

  const monthInsuredPrior = useWatch({
    control,
    name: 'monthInsuredPrior',
    defaultValue: priorCoverage.monthInsuredPrior
  })

  const yearInsuredPrior = useWatch({
    control,
    name: 'yearInsuredPrior',
    defaultValue: priorCoverage.yearInsuredPrior
  })

  const currentCoverageContinuouslyInsured = useWatch({
    control,
    name: 'currentCoverageContinuouslyInsured',
    defaultValue: priorCoverage.currentCoverageContinuouslyInsured
  })

  const recentInsuranceProvider = useWatch({
    control,
    name: 'recentInsuranceProvider',
    defaultValue: priorCoverage.recentInsuranceProvider
  })

  const priorBodilyLimit = useWatch({
    control,
    name: 'priorBodilyLimit',
    defaultValue: priorCoverage.priorBodilyLimit
  })

  const liabilityPerPerson = currentCoverageBiLimit && currentCoverageBiLimit !== 'I don\'t know' ? parseInt(currentCoverageBiLimit.split('/')[0].match(/\d+/)[0]) : priorBodilyLimit && priorBodilyLimit !== 'I don\'t know' ? parseInt(priorBodilyLimit.split('/')[0].match(/\d+/)[0]) : undefined
  const liabilityPerAccident = currentCoverageBiLimit && currentCoverageBiLimit !== 'I don\'t know' ? parseInt(currentCoverageBiLimit.split('/')[1].match(/\d+/)[0]) : priorBodilyLimit && priorBodilyLimit !== 'I don\'t know' ? parseInt(priorBodilyLimit.split('/')[1].match(/\d+/)[0]) : undefined

  const withoutCarInsuranceForThirtyOneDays = useWatch({
    control,
    name: 'withoutCarInsuranceForThirtyOneDays',
    defaultValue: priorCoverage.withoutCarInsuranceForThirtyOneDays
  })

  window.sessionStorage.setItem('priorCoverage', JSON.stringify({
    ...priorCoverage,
    carInsuranceForSixMonths,
    dueToAMilitaryDeployment,
    currentlyInsured,
    monthContinuouslyInsured,
    yearContinuouslyInsured,
    currentCoverageCarrier,
    currentCoverageBiLimit,
    liabilityPerPerson,
    liabilityPerAccident,
    startMonthDeployed,
    startYearDeployed,
    endMonthDeployed,
    endYearDeployed,
    monthInsuredPrior,
    yearInsuredPrior,
    currentCoverageContinuouslyInsured,
    recentInsuranceProvider,
    priorBodilyLimit,
    withoutCarInsuranceForThirtyOneDays
  }))

  return (
    <div className='prior-coverage-wrapper'>
      <Grid container spacing={2} justify='flex-start'>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Controller
            control={control}
            name='carInsuranceForSixMonths'
            defaultValue={carInsuranceForSixMonths || ''}
            render={({ onChange, name, onBlur }) => (
              <FormControl
                variant='outlined'
                fullWidth
                error={
                  !!(
                    errors &&
                    errors.carInsuranceForSixMonths
                  )
                }
              >
                <InputLabel>Have you had car insurance for the past 6 months?</InputLabel>
                <Select
                  id='carInsuranceForSixMonthsSelect'
                  label='Have you had car insurance for the past 6 months?'
                  value={carInsuranceForSixMonths}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                >
                  {getYesNotMenuList()}
                </Select>
                <FormHelperText>
                  {errors &&
                    errors.carInsuranceForSixMonths
                    ? errors.carInsuranceForSixMonths.message
                    : ''}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        {
          carInsuranceForSixMonths === 'Yes' ?
            <>
              <Controller
                control={control}
                name='currentlyInsured'
                defaultValue={'Yes'}
                render={() => (
                  <FormControl
                    variant='outlined'
                    error={
                      !!(
                        errors &&
                        errors.currentlyInsured
                      )
                    }
                  >
                    <Input type='hidden' defaultValue="Yes" id="currentlyInsured" value='Yes'></Input>
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                name='currentCoverageContinuouslyInsured'
                defaultValue={'1 month'}
                render={() => (
                  <FormControl
                    variant='outlined'
                    error={
                      !!(
                        errors &&
                        errors.currentCoverageContinuouslyInsured
                      )
                    }
                  >
                    <Input type='hidden' defaultValue="1 month" id="currentCoverageContinuouslyInsured" value='1 month'></Input>
                  </FormControl>
                )}
              />
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <FormLabel style={{color: 'black'}}>When did your insurance coverage start?</FormLabel>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Controller
                  control={control}
                  name='monthContinuouslyInsured'
                  defaultValue={monthContinuouslyInsured || ''}
                  render={({ onChange, name, onBlur }) => (
                    <FormControl
                      variant='outlined'
                      fullWidth
                      error={
                        !!(
                          errors &&
                          errors.monthContinuouslyInsured
                        )
                      }
                    >
                      <InputLabel>Month</InputLabel>
                      <Select
                        id='monthContinuouslyInsuredSelect'
                        label='Month'
                        value={monthContinuouslyInsured}
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                      >
                        {selectionMonthList()}
                      </Select>
                      <FormHelperText>
                        {errors &&
                          errors.monthContinuouslyInsured
                          ? errors.monthContinuouslyInsured.message
                          : ''}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Controller
                  control={control}
                  name='yearContinuouslyInsured'
                  defaultValue={yearContinuouslyInsured || ''}
                  render={({ onChange, name, onBlur }) => (
                    <FormControl
                      variant='outlined'
                      fullWidth
                      error={
                        !!(
                          errors &&
                          errors.yearContinuouslyInsured
                        )
                      }
                    >
                      <InputLabel>Year</InputLabel>
                      <Select
                        id='yearContinuouslyInsuredSelect'
                        label='Year'
                        value={yearContinuouslyInsured}
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                      >
                        {selectionYearList()}
                      </Select>
                      <FormHelperText>
                        {errors &&
                          errors.yearContinuouslyInsured
                          ? errors.yearContinuouslyInsured.message
                          : ''}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>  
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Controller
                  control={control}
                  name='currentCoverageCarrier'
                  defaultValue={currentCoverageCarrier || ''}
                  render={({ onChange, name, onBlur }) => (
                    <FormControl
                      variant='outlined'
                      fullWidth
                      error={
                        !!(
                          errors &&
                          errors.currentCoverageCarrier
                        )
                      }
                    >
                      <InputLabel>Who is your current provider?</InputLabel>
                      <Select
                        id='currentCoverageCarrierSelect'
                        label='Who is your current provider?'
                        value={currentCoverageCarrier}
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                      >
                        {getCurrentProviderMenuList()}
                      </Select>
                      <FormHelperText>
                        {errors &&
                          errors.currentCoverageCarrier
                          ? errors.currentCoverageCarrier.message
                          : ''}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Controller
                  control={control}
                  name='currentCoverageBiLimit'
                  defaultValue={currentCoverageBiLimit || ''}
                  render={({ onChange, name, onBlur }) => (
                    <FormControl
                      variant='outlined'
                      fullWidth
                      error={
                        !!(
                          errors &&
                          errors.currentCoverageBiLimit
                        )
                      }
                    >
                      <InputLabel>What are the bodily injury limits on your current policy?</InputLabel>
                      <Select
                        id='currentCoverageBiLimitSelect'
                        label='What are the bodily injury limits on your current policy?'
                        value={currentCoverageBiLimit}
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                      >
                        {getCurrentBiLimitsMenuList()}
                      </Select>
                      <FormHelperText>
                        {errors &&
                          errors.currentCoverageBiLimit
                          ? errors.currentCoverageBiLimit.message
                          : ''}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
            </>
            : null
        }

        {
          carInsuranceForSixMonths === 'No' ?
            <>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Controller
                control={control}
                name='dueToAMilitaryDeployment'
                defaultValue={dueToAMilitaryDeployment || ''}
                render={({ onChange, name, onBlur }) => (
                  <FormControl
                    variant='outlined'
                    fullWidth
                    error={
                      !!(
                        errors &&
                        errors.dueToAMilitaryDeployment
                      )
                    }
                  >
                    <InputLabel>Was this due to a military deployment where insurance was not required?</InputLabel>
                    <Select
                      id='dueToAMilitaryDeploymentSelect'
                      label='Was this due to a military deployment where insurance was not required?'
                      value={dueToAMilitaryDeployment}
                      name={name}
                      onChange={onChange}
                      onBlur={onBlur}
                    >
                      {getYesNotMenuList()}
                    </Select>
                    <FormHelperText>
                      {errors &&
                        errors.dueToAMilitaryDeployment
                        ? errors.dueToAMilitaryDeployment.message
                        : ''}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            {
              dueToAMilitaryDeployment === 'Yes' ?
              <>
               <Grid item lg={4} md={4} sm={4} xs={4}>
                <FormLabel style={{color: 'black'}}>When did your military deployment start?</FormLabel>
               </Grid>
               <Grid item lg={4} md={4} sm={4} xs={4}>
                <Controller
                  control={control}
                  name='startMonthDeployed'
                  defaultValue={startMonthDeployed || ''}
                  render={({ onChange, name, onBlur }) => (
                    <FormControl
                      variant='outlined'
                      fullWidth
                      error={
                        !!(
                          errors &&
                          errors.startMonthDeployed
                        )
                      }
                    >
                      <InputLabel>Month</InputLabel>
                      <Select
                        id='startMonthDeployedSelect'
                        label='Month'
                        value={startMonthDeployed}
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                      >
                        {selectionMonthList()}
                      </Select>
                      <FormHelperText>
                        {errors &&
                          errors.startMonthDeployed
                          ? errors.startMonthDeployed.message
                          : ''}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Controller
                  control={control}
                  name='startYearDeployed'
                  defaultValue={startYearDeployed || ''}
                  render={({ onChange, name, onBlur }) => (
                    <FormControl
                      variant='outlined'
                      fullWidth
                      error={
                        !!(
                          errors &&
                          errors.startYearDeployed
                        )
                      }
                    >
                      <InputLabel>Year</InputLabel>
                      <Select
                        id='startYearDeployedSelect'
                        label='Year'
                        value={startYearDeployed}
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                      >
                        {selectionYearList()}
                      </Select>
                      <FormHelperText>
                        {errors &&
                          errors.startYearDeployed
                          ? errors.startYearDeployed.message
                          : ''}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <FormLabel style={{color: 'black'}}>When did your military deployment end?</FormLabel>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Controller
                  control={control}
                  name='endMonthDeployed'
                  defaultValue={endMonthDeployed || ''}
                  render={({ onChange, name, onBlur }) => (
                    <FormControl
                      variant='outlined'
                      fullWidth
                      error={
                        !!(
                          errors &&
                          errors.endMonthDeployed
                        )
                      }
                    >
                      <InputLabel>Month</InputLabel>
                      <Select
                        id='endMonthDeployedSelect'
                        label='Month'
                        value={endMonthDeployed}
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                      >
                        {selectionMonthList()}
                      </Select>
                      <FormHelperText>
                        {errors &&
                          errors.endMonthDeployed
                          ? errors.endMonthDeployed.message
                          : ''}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Controller
                  control={control}
                  name='endYearDeployed'
                  defaultValue={endYearDeployed || ''}
                  render={({ onChange, name, onBlur }) => (
                    <FormControl
                      variant='outlined'
                      fullWidth
                      error={
                        !!(
                          errors &&
                          errors.endYearDeployed
                        )
                      }
                    >
                      <InputLabel>Year</InputLabel>
                      <Select
                        id='endYearDeployedSelect'
                        label='Year'
                        value={endYearDeployed}
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                      >
                        {selectionYearList()}
                      </Select>
                      <FormHelperText>
                        {errors &&
                          errors.endYearDeployed
                          ? errors.endYearDeployed.message
                          : ''}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <FormLabel style={{color: 'black'}}>When did your insurance coverage start before your deployment?</FormLabel>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Controller
                  control={control}
                  name='monthInsuredPrior'
                  defaultValue={monthInsuredPrior || ''}
                  render={({ onChange, name, onBlur }) => (
                    <FormControl
                      variant='outlined'
                      fullWidth
                      error={
                        !!(
                          errors &&
                          errors.monthInsuredPrior
                        )
                      }
                    >
                      <InputLabel>Month</InputLabel>
                      <Select
                        id='monthInsuredPriorSelect'
                        label='Month'
                        value={monthInsuredPrior}
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                      >
                        {selectionMonthList()}
                      </Select>
                      <FormHelperText>
                        {errors &&
                          errors.monthInsuredPrior
                          ? errors.monthInsuredPrior.message
                          : ''}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Controller
                  control={control}
                  name='yearInsuredPrior'
                  defaultValue={yearInsuredPrior || ''}
                  render={({ onChange, name, onBlur }) => (
                    <FormControl
                      variant='outlined'
                      fullWidth
                      error={
                        !!(
                          errors &&
                          errors.yearInsuredPrior
                        )
                      }
                    >
                      <InputLabel>Year</InputLabel>
                      <Select
                        id='yearInsuredPriorSelect'
                        label='Year'
                        value={yearInsuredPrior}
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                      >
                        {selectionYearList()}
                      </Select>
                      <FormHelperText>
                        {errors &&
                          errors.yearInsuredPrior
                          ? errors.yearInsuredPrior.message
                          : ''}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Controller
                  control={control}
                  name='currentlyInsured'
                  defaultValue={currentlyInsured || ''}
                  render={({ onChange, name, onBlur }) => (
                    <FormControl
                      variant='outlined'
                      fullWidth
                      error={
                        !!(
                          errors &&
                          errors.currentlyInsured
                        )
                      }
                    >
                      <InputLabel>Are you currently insured?</InputLabel>
                      <Select
                        id='currentlyInsuredSelect'
                        label='Are you currently insured?'
                        value={currentlyInsured}
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                      >
                        {getYesNotMenuList()}
                      </Select>
                      <FormHelperText>
                        {errors &&
                          errors.currentlyInsured
                          ? errors.currentlyInsured.message
                          : ''}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              {
                currentlyInsured === 'Yes' ?
                <>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Controller
                      control={control}
                      name='currentCoverageContinuouslyInsured'
                      defaultValue={currentCoverageContinuouslyInsured || ''}
                      render={({ onChange, name, onBlur }) => (
                        <FormControl
                          variant='outlined'
                          fullWidth
                          error={
                            !!(
                              errors &&
                              errors.currentCoverageContinuouslyInsured
                            )
                          }
                        >
                          <InputLabel>How long have you been continuously insured?</InputLabel>
                          <Select
                            id='currentCoverageContinuouslyInsuredSelect'
                            label='How long have you been continuously insured?'
                            value={currentCoverageContinuouslyInsured}
                            name={name}
                            onChange={onChange}
                            onBlur={onBlur}
                          >
                            {getOneFiveMonthMenuList()}
                          </Select>
                          <FormHelperText>
                            {errors &&
                              errors.currentCoverageContinuouslyInsured
                              ? errors.currentCoverageContinuouslyInsured.message
                              : ''}
                          </FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Controller
                      control={control}
                      name='currentCoverageCarrier'
                      defaultValue={currentCoverageCarrier || ''}
                      render={({ onChange, name, onBlur }) => (
                        <FormControl
                          variant='outlined'
                          fullWidth
                          error={
                            !!(
                              errors &&
                              errors.currentCoverageCarrier
                            )
                          }
                        >
                          <InputLabel>Who is your current provider?</InputLabel>
                          <Select
                            id='currentCoverageCarrierSelect'
                            label='Who is your current provider?'
                            value={currentCoverageCarrier}
                            name={name}
                            onChange={onChange}
                            onBlur={onBlur}
                          >
                            {getCurrentProviderMenuList()}
                          </Select>
                          <FormHelperText>
                            {errors &&
                              errors.currentCoverageCarrier
                              ? errors.currentCoverageCarrier.message
                              : ''}
                          </FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Controller
                      control={control}
                      name='currentCoverageBiLimit'
                      defaultValue={currentCoverageBiLimit || ''}
                      render={({ onChange, name, onBlur }) => (
                        <FormControl
                          variant='outlined'
                          fullWidth
                          error={
                            !!(
                              errors &&
                              errors.currentCoverageBiLimit
                            )
                          }
                        >
                          <InputLabel>What are the bodily injury limits on your current policy?</InputLabel>
                          <Select
                            id='currentCoverageBiLimitSelect'
                            label='What are the bodily injury limits on your current policy?'
                            value={currentCoverageBiLimit}
                            name={name}
                            onChange={onChange}
                            onBlur={onBlur}
                          >
                            {getCurrentBiLimitsMenuList()}
                          </Select>
                          <FormHelperText>
                            {errors &&
                              errors.currentCoverageBiLimit
                              ? errors.currentCoverageBiLimit.message
                              : ''}
                          </FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Grid>
                </>
                : null
              }
              {
                currentlyInsured === 'No' ?
                <>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Controller
                      control={control}
                      name='recentInsuranceProvider'
                      defaultValue={recentInsuranceProvider || ''}
                      render={({ onChange, name, onBlur }) => (
                        <FormControl
                          variant='outlined'
                          fullWidth
                          error={
                            !!(
                              errors &&
                              errors.recentInsuranceProvider
                            )
                          }
                        >
                          <InputLabel>Who was your most recent insurance provider?</InputLabel>
                          <Select
                            id='recentInsuranceProviderSelect'
                            label='Who was your most recent insurance provider?'
                            value={recentInsuranceProvider}
                            name={name}
                            onChange={onChange}
                            onBlur={onBlur}
                          >
                            {getCurrentProviderMenuList()}
                          </Select>
                          <FormHelperText>
                            {errors &&
                              errors.recentInsuranceProvider
                              ? errors.recentInsuranceProvider.message
                              : ''}
                          </FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Controller
                      control={control}
                      name='priorBodilyLimit'
                      defaultValue={priorBodilyLimit || ''}
                      render={({ onChange, name, onBlur }) => (
                        <FormControl
                          variant='outlined'
                          fullWidth
                          error={
                            !!(
                              errors &&
                              errors.priorBodilyLimit
                            )
                          }
                        >
                          <InputLabel>What were your Bodily Injury Limits?</InputLabel>
                          <Select
                            id='priorBodilyLimitSelect'
                            label='What were your Bodily Injury Limits?'
                            value={priorBodilyLimit}
                            name={name}
                            onChange={onChange}
                            onBlur={onBlur}
                          >
                            {getCurrentBiLimitsMenuList()}
                          </Select>
                          <FormHelperText>
                            {errors &&
                              errors.priorBodilyLimit
                              ? errors.priorBodilyLimit.message
                              : ''}
                          </FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Grid>
                </>
                : null
              }
              </>
              : null
            }

            {
              dueToAMilitaryDeployment === 'No' ?
              <>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Controller
                    control={control}
                    name='withoutCarInsuranceForThirtyOneDays'
                    defaultValue={withoutCarInsuranceForThirtyOneDays || ''}
                    render={({ onChange, name, onBlur }) => (
                      <FormControl
                        variant='outlined'
                        fullWidth
                        error={
                          !!(
                            errors &&
                            errors.withoutCarInsuranceForThirtyOneDays
                          )
                        }
                      >
                        <InputLabel>Were you without car insurance for more than 31 days in the past 6 months?</InputLabel>
                        <Select
                          id='withoutCarInsuranceForThirtyOneDaysSelect'
                          label='Were you without car insurance for more than 31 days in the past 6 months?'
                          value={withoutCarInsuranceForThirtyOneDays}
                          name={name}
                          onChange={onChange}
                          onBlur={onBlur}
                        >
                          {getYesNotMenuList()}
                        </Select>
                        <FormHelperText>
                          {errors &&
                            errors.withoutCarInsuranceForThirtyOneDays
                            ? errors.withoutCarInsuranceForThirtyOneDays.message
                            : ''}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Controller
                    control={control}
                    name='currentlyInsured'
                    defaultValue={currentlyInsured || ''}
                    render={({ onChange, name, onBlur }) => (
                      <FormControl
                        variant='outlined'
                        fullWidth
                        error={
                          !!(
                            errors &&
                            errors.currentlyInsured
                          )
                        }
                      >
                        <InputLabel>Are you currently insured?</InputLabel>
                        <Select
                          id='currentlyInsuredSelect'
                          label='Are you currently insured?'
                          value={currentlyInsured}
                          name={name}
                          onChange={onChange}
                          onBlur={onBlur}
                        >
                          {getYesNotMenuList()}
                        </Select>
                        <FormHelperText>
                          {errors &&
                            errors.currentlyInsured
                            ? errors.currentlyInsured.message
                            : ''}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </Grid>
                {
                  currentlyInsured === 'Yes' ?
                  <>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Controller
                        control={control}
                        name='currentCoverageContinuouslyInsured'
                        defaultValue={currentCoverageContinuouslyInsured || ''}
                        render={({ onChange, name, onBlur }) => (
                          <FormControl
                            variant='outlined'
                            fullWidth
                            error={
                              !!(
                                errors &&
                                errors.currentCoverageContinuouslyInsured
                              )
                            }
                          >
                            <InputLabel>How long have you been continuously insured?</InputLabel>
                            <Select
                              id='currentCoverageContinuouslyInsuredSelect'
                              label='How long have you been continuously insured?'
                              value={currentCoverageContinuouslyInsured}
                              name={name}
                              onChange={onChange}
                              onBlur={onBlur}
                            >
                              {getOneFiveMonthMenuList()}
                            </Select>
                            <FormHelperText>
                              {errors &&
                                errors.currentCoverageContinuouslyInsured
                                ? errors.currentCoverageContinuouslyInsured.message
                                : ''}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Controller
                        control={control}
                        name='currentCoverageCarrier'
                        defaultValue={currentCoverageCarrier || ''}
                        render={({ onChange, name, onBlur }) => (
                          <FormControl
                            variant='outlined'
                            fullWidth
                            error={
                              !!(
                                errors &&
                                errors.currentCoverageCarrier
                              )
                            }
                          >
                            <InputLabel>Who is your current provider?</InputLabel>
                            <Select
                              id='currentCoverageCarrierSelect'
                              label='Who is your current provider?'
                              value={currentCoverageCarrier}
                              name={name}
                              onChange={onChange}
                              onBlur={onBlur}
                            >
                              {getCurrentProviderMenuList()}
                            </Select>
                            <FormHelperText>
                              {errors &&
                                errors.currentCoverageCarrier
                                ? errors.currentCoverageCarrier.message
                                : ''}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Controller
                        control={control}
                        name='currentCoverageBiLimit'
                        defaultValue={currentCoverageBiLimit || ''}
                        render={({ onChange, name, onBlur }) => (
                          <FormControl
                            variant='outlined'
                            fullWidth
                            error={
                              !!(
                                errors &&
                                errors.currentCoverageBiLimit
                              )
                            }
                          >
                            <InputLabel>What are the bodily injury limits on your current policy?</InputLabel>
                            <Select
                              id='currentCoverageBiLimitSelect'
                              label='What are the bodily injury limits on your current policy?'
                              value={currentCoverageBiLimit}
                              name={name}
                              onChange={onChange}
                              onBlur={onBlur}
                            >
                              {getCurrentBiLimitsMenuList()}
                            </Select>
                            <FormHelperText>
                              {errors &&
                                errors.currentCoverageBiLimit
                                ? errors.currentCoverageBiLimit.message
                                : ''}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </>
                  : null
                }
            
                {
                  currentlyInsured === 'No' ?
                  <>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Controller
                        control={control}
                        name='recentInsuranceProvider'
                        defaultValue={recentInsuranceProvider || ''}
                        render={({ onChange, name, onBlur }) => (
                          <FormControl
                            variant='outlined'
                            fullWidth
                            error={
                              !!(
                                errors &&
                                errors.recentInsuranceProvider
                              )
                            }
                          >
                            <InputLabel>Who was your most recent insurance provider?</InputLabel>
                            <Select
                              id='recentInsuranceProviderSelect'
                              label='Who was your most recent insurance provider?'
                              value={recentInsuranceProvider}
                              name={name}
                              onChange={onChange}
                              onBlur={onBlur}
                            >
                              {getCurrentProviderMenuList()}
                            </Select>
                            <FormHelperText>
                              {errors &&
                                errors.recentInsuranceProvider
                                ? errors.recentInsuranceProvider.message
                                : ''}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Controller
                        control={control}
                        name='priorBodilyLimit'
                        defaultValue={priorBodilyLimit || ''}
                        render={({ onChange, name, onBlur }) => (
                          <FormControl
                            variant='outlined'
                            fullWidth
                            error={
                              !!(
                                errors &&
                                errors.priorBodilyLimit
                              )
                            }
                          >
                            <InputLabel>What were your Bodily Injury Limits?</InputLabel>
                            <Select
                              id='priorBodilyLimitSelect'
                              label='What were your Bodily Injury Limits?'
                              value={priorBodilyLimit}
                              name={name}
                              onChange={onChange}
                              onBlur={onBlur}
                            >
                              {getCurrentBiLimitsMenuList()}
                            </Select>
                            <FormHelperText>
                              {errors &&
                                errors.priorBodilyLimit
                                ? errors.priorBodilyLimit.message
                                : ''}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </>
                  : null
                }
              </>
              : null
            }
            </>
            : null
          }
      </Grid>
    </div>
  );
};

export default PriorCoverage;