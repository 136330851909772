import React, { useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles, Theme } from '@material-ui/core/styles';

export interface MotionToastProps {
  severity: 'error' | 'warning' | 'info' | 'success';
  message: string;
  open: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const MotionToast: React.FC<MotionToastProps> =
  ({ message, severity, open: openState }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(openState);

    useEffect(() => {
      setOpen(openState);
    }, [setOpen, openState]);

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }

      setOpen(false);
    };

    return (
      <div className={classes.root}>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <MuiAlert variant='filled' onClose={handleClose} severity={severity}>
            {message}
          </MuiAlert>
        </Snackbar>
      </div>
    );
  };

export default MotionToast;
