import React from 'react';
import { titleCaseWords } from '../utils/string';
import { getPrimaryColor, getSecondaryInitialColor } from '../utils/style';
import './InitialsBubble.scss';

export interface InitialsBubbleProps {
  color: string;
  firstName: string;
  lastName: string;
  page?: string;
}

const InitialsBubble: React.FC<InitialsBubbleProps> = ({
  color,
  firstName,
  lastName,
  page
}) => {
  const initials = (firstName.charAt(0) + lastName.charAt(0)).toUpperCase();
  return (
    <div className='initials-bubble-wrapper'>
      <div>
        <div
          className={`initials-bubble-${color}`}
          style={{ background: color === 'primary' ? getPrimaryColor() : getSecondaryInitialColor() }}
        >
          {initials}
        </div>
      </div>
      <div className='initials-bubble-full-name'>
        {page !== 'driver' ? `${titleCaseWords(firstName)} ${titleCaseWords(lastName)}` : `${firstName} ${lastName}`}
      </div>
    </div>
  );
};

export default InitialsBubble;
