import React from 'react';
import { Driver } from '../models/motion';
import InitialsBubble from './InitialsBubble';
import './DriverSimple.scss';

export interface DriverSimpleProps {
  driver: Driver;
}

const DriverSimple: React.FC<DriverSimpleProps> = ({ driver }) => {
  const parsedDate = new Date();
  parsedDate.setFullYear(parseInt(driver.birthDate!.split('-')[0], 10));
  parsedDate.setMonth(parseInt(driver.birthDate!.split('-')[1], 10) - 1);
  parsedDate.setDate(parseInt(driver.birthDate!.split('-')[2], 10));
  parsedDate.setHours(0, 0, 0);

  return (
    <div className='driver-wrapper'>
      <InitialsBubble
        color={driver.isPrimaryInsured ? 'primary' : 'secondary'}
        firstName={driver.firstName!}
        lastName={driver.lastName!}
        page=''
      />
      <div className='driver-info-wrapper'>
        <div className='driver-info-left'>
          <div className='driver-left-row'>
            Date of birth
          </div>
          {driver.driverType === ('rated' || 'permit') ? (
            <div>
              <div className='driver-left-row'>
                Driver's license, State
            </div>
              <div className='driver-left-row'>
                Driver status
              </div>
            </div>
          ) : null}
        </div>
        <div className='driver-info-right'>
          <div className='driver-right-row'>
            {
              parsedDate.toLocaleString(
                'en-us',
                {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                }
              )
            }
          </div>
          {driver.driverType === ('rated' || 'permit') ? (
            <div>
              <div className='driver-right-row'>
                {`${driver.licenseNumber}, ${driver.stateLicensed}`}
              </div>
              <div className='driver-right-row'>
                {driver.licenseStatus}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default DriverSimple;