import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { usStateList } from '../models/stateCodes';
import { ReactComponent as NovoLogo } from '../images/partnerImages/novo-logo.svg';
import './Auth.scss';
import { AuthModel } from '../models/motion';
import { LIVE_STATES } from '../utils/state';
import MotionModal from '../components/MotionModal';

const cognitoAuthCognito = () => {
  Auth.federatedSignIn({
    provider: CognitoHostedUIIdentityProvider.Cognito
  });
};

const AuthDetails: React.FC<AuthModel> = () => {
  const methods = useForm<AuthModel>({
    defaultValues: {
      state: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },
  });

  const { handleSubmit, control, watch } = methods;
  const [state, setState] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const watchState = watch('state');
  const watchFirstName = watch('firstName');
  const watchLastName = watch('lastName');
  const watchEmail = watch('email');
  const watchPhone = watch('phone');

  const [openModal, setOpenModal] = useState(false);

  const onSubmit = (data: AuthModel) => {
    if (!LIVE_STATES.includes(watchState!)) {
      setOpenModal(true);
    } else {
      window.scrollTo(0, 0);
      setState(state);
      window.open(`${window.location.origin}/primary-info?d_state=${state}`, '_self');
    }
  };

  const [stateMenuList] = React.useState(() => {
    return usStateList.map((s) => {
      return (
        <MenuItem key={s.value} value={s.value}>
          {s.display}
        </MenuItem>
      );
    });
  });

  const handleModalClose = () => {
    setOpenModal(false);
  };


  useEffect(() => {
    const stateCode = control.getValues('state');
    const first = control.getValues('firstName');
    const last = control.getValues('lastName');
    const eMail = control.getValues('email');
    const phoneNumber = control.getValues('phone');

    if (!LIVE_STATES.includes(watchState!)) {
      setOpenModal(true);
    }
    if (stateCode && stateCode.length > 0) {
      localStorage.setItem('state', stateCode);
      setState(stateCode);
    }

    if (first && first.length > 0) {
      setState(first);
    }
    if (last && last.length > 0) {
      setState(last);
    }
    if (eMail && eMail.length > 0) {
      setState(eMail);
    }
    if (phoneNumber && phoneNumber.length > 0) {
      setState(phoneNumber);
    }

    const notActiveStateData = {
      'state': stateCode,
      'firstName': first,
      'lastName': last,
      'email': eMail,
      'phone': phoneNumber
    };

    window.sessionStorage.setItem('notActiveStateInfo', JSON.stringify(notActiveStateData));

  }, [watchState, watchFirstName, watchLastName, watchEmail, watchPhone, control, firstName, lastName, email, phone]);
  return (
    <>
      <Helmet>
        <title>Initial Info - Novo Insurance</title>
        <link rel='icon' href='/novo-fav.ico' />
        <meta name='description' content='Get a Novo Auto Insurance quote and buy a policy' />
      </Helmet>

      {
        (process.env.REACT_APP_ENV === 'production' && !window.location.hostname.includes('stage')) ?
          <MotionModal
            title='Oops...'
            open={openModal}
            handleClose={handleModalClose}
            closeText='Ok, got it!'
            body={<>
              It looks like we are not yet live in your state.
              We are adding new states rapidly.
              Thank you for your interest in Novo Insurance,
              please let us know if you'd like to be notified when we are available in your state by providing to us your name, email address, and phone number

              <FormProvider {...methods}>
                <form
                  id='authModel'
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Controller
                    as={
                      <TextField
                        id='firstName'
                        label='First name'
                        variant='outlined'
                        fullWidth
                        margin='normal'
                        value={firstName}
                      />
                    }
                    name='firstName'
                    control={control}
                    defaultValue=''
                  />

                  <Controller
                    as={
                      <TextField
                        id='lastName'
                        label='Last name'
                        variant='outlined'
                        fullWidth
                        margin='normal'
                        value={lastName}
                      />
                    }
                    name='lastName'
                    control={control}
                    defaultValue=''
                  />

                  <Controller
                    control={control}
                    name='email'
                    defaultValue=''
                    as={
                      <TextField
                        id='email'
                        label='Email'
                        variant='outlined'
                        type='email'
                        fullWidth
                        margin='normal'
                        value={email}
                      />
                    }
                  />

                  <Controller
                    control={control}
                    name='phone'
                    defaultValue=''
                    as={
                      <TextField
                        id='phone'
                        label='Phone Number'
                        variant='outlined'
                        type='tel'
                        fullWidth
                        margin='normal'
                        value={phone}
                        inputProps={{ maxLength: 10 }}
                      />
                    }
                  />
                </form>
              </FormProvider>
            </>}
          /> : undefined
      }
      <Grid className='main' style={{ padding: '24px' }}>
        <div className='import-message-wrapper-scan'>
          <a href='https://www.novo.us' target='_blank'>
            <NovoLogo />
          </a>
        </div>
        <div className='import-message-wrapper-scan'>
          <span className='import-title-primary-sub'>Get a Quote</span>
        </div>
        <div className='import-message-wrapper-scan'>
          <span className='import-title-content-scan'>
            Novo is redefining, reimagining, and reinventing the car insurance industry by shifting the power back to the driver. <br /> Join the ride today.
          </span>
        </div>

        <div className='import-message-wrapper-scan'>
          <span className='import-title-sub'>Log in for the fastest quote</span>
        </div>
        <div className='import-message-wrapper-scan'>
          <span className='import-title-content-scan'>
            Get started ASAP by sharing your social accounts and authorizing us to prefill your personal info.
          </span>
        </div>

        <div className='import-message-wrapper-scan'>
          <span className='import-title-content-scan'>
            <Button
              id='authButton'
              className='initial-info-import-submit'
              type='submit'
              color='primary'
              variant='contained'
              onClick={cognitoAuthCognito}
            >
              Continue with social media
            </Button>
          </span>
        </div>
        <div className='import-message-wrapper-scan-line'>
          <span className='import-title-sub-line'>
            <Grid container spacing={3}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div style={{
                  display: 'inline-block',
                  borderBottom: '1px solid',
                  width: '100px',
                  margin: '5px 15px'
                }}
                />
                <div style={{ display: 'inline-block', background: '#fff' }}>OR</div>
                <div style={{
                  display: 'inline-block',
                  borderBottom: '1px solid',
                  width: '100px',
                  margin: '5px 15px'
                }}
                />
              </Grid>
            </Grid>
          </span>
        </div>
        <div className='import-message-wrapper-scan'>
          <span className='import-title-sub' style={{ margin: '.5em 0' }}>
            Choose your state to start your manual quote
          </span>
        </div>
        <div className='import-message-wrapper-scan' style={{ marginRight: '-5em' }}>
          <FormProvider {...methods}>
            <form
              id='authForm'
              className='import-form'
              onSubmit={handleSubmit(onSubmit)}
            >
              <Controller
                control={control}
                name='state'
                defaultValue=''
                render={({ onChange, value, name, onBlur }) => (
                  <FormControl
                    variant='outlined'
                    fullWidth
                  >
                    <InputLabel>State</InputLabel>
                    <Select
                      id='state'
                      label='State'
                      value={value}
                      name={name}
                      onChange={onChange}
                      onBlur={onBlur}
                      style={{ width: '80%' }}
                    >
                      {stateMenuList}
                    </Select>
                  </FormControl>
                )}
              />
              <span className='import-title-content-scan' style={{ margin: '0.5em 0' }}>
                <Button
                  id='authButton'
                  className='initial-info-import-submit'
                  type='submit'
                  color='primary'
                  variant='contained'
                  style={{ width: '80%' }}
                >
                  Get my quote
                </Button>
              </span>
            </form>
          </FormProvider>
        </div>
      </Grid>
    </>
  );
};

export default AuthDetails;

