/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { getLightBackgroundColor, getPrimaryColor } from '../utils/style';
import './QuoteTier.scss';
import TierBox from './TierBox';

export interface QuoteTierProps {
  monthlyFee: number;
  totalFee: number;
  policyTerm: number;
  tierName: string;
  tierDescription: string;
  isSelected: boolean;
  setSelection(): void;
}

const QuoteTier: React.FC<QuoteTierProps> =
  ({ monthlyFee, totalFee, policyTerm, tierName, tierDescription, isSelected, setSelection }) => {
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        onClick={() => { setSelection(); }}
        className={isSelected ? 'tier-card-selected' : 'tier-card'}
        style={
          {
            color: isSelected ? getPrimaryColor() : 'inherit',
            background: isSelected ? getLightBackgroundColor() : 'inherit'
          }
        }
      >
        <div className='tier-title-wrapper'>
          <div className='tier-title'>
            {tierName}
          </div>
          <span className='tier-message'>{tierDescription}</span>
        </div>
        <div
          className={isSelected ? 'tier-box-selected' : 'tier-box'}
          style={
            {
              border: isSelected ? `.4em solid ${getPrimaryColor()}` : '',
            }
          }
        >
          <TierBox
            monthlyFee={monthlyFee}
            totalFee={totalFee}
            policyTerm={policyTerm}
            isSelected={isSelected}
          />
        </div>
        <div className='tier-footer' />
      </div>
    );
  };

export default QuoteTier;