/* eslint-disable func-names */
/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup';
import { getAge } from './date';
import { LIVE_STATES } from '../utils/state';
import { monthList } from './selectList';

const agentDataString = window.localStorage.getItem('agentData');
const agentData = JSON.parse(agentDataString!) || [];

const ssnRegex = /^([0-9])(?!\1{2}-\1{2}-\1{4})[0-9]{2}-[0-9]{2}-[0-9]{4}/;
const addressRegex =
  /^(?!(.*(P.O. Box|PO Box|P O Box|P.O Box|Post Office Box).*))/i;
const violationRegex = /^\d+$/;
const minAdditionalDriverAge = 14;
const minDateAdditionalDriver =
  new Date(new Date().setFullYear(new Date().getFullYear() - minAdditionalDriverAge));
const minPrimaryDriverAge = 18;
const minDatePrimaryDriver =
  new Date(new Date().setFullYear(new Date().getFullYear() - minPrimaryDriverAge));

const currentYear = new Date().getFullYear()
const currentMonth = new Date().getMonth()
const accidentsInfoSchema = yup.object().shape({
  year: yup.string().required("Please select a year").trim(),
  month: yup.string().required("Please select a month").trim()
    .test(
      'comp-dedt',
      'Date cannot be in the future',
      function (data) {
        if (this.parent.year > currentYear) {
          return false;
        }
        const index = monthList.findIndex((month: any) => month.value === data);
        if (this.parent.year == currentYear && index > currentMonth) {
          return false;
        }
        return true;
      },
    )
  ,
  faultAccident: yup.boolean(),
  accidentType: yup.string().required("Please select a accident type").trim()
})
const violationInfoSchema = yup.object().shape({
  year: yup.string().required("Please select a year").trim(),
  month: yup.string().required("Please select a month").trim()
    .test(
      'comp-dedt',
      'Date cannot be in the future',
      function (data) {
        if (this.parent.year > currentYear) {
          return false;
        }
        const index = monthList.findIndex((month: any) => month.value === data);
        if (this.parent.year == currentYear && index > currentMonth) {
          return false;
        }
        return true;
      },
    )
  ,
  violationType: yup.string().required("Please select a violation type").trim(),
})

const driverSchema = yup.object().shape({
  firstName: yup.string().required("Please enter driver's first name").trim(),
  lastName: yup.string().required("Please enter driver's last name").trim(),
  gender: yup.string().when('driverType', { is: 'rated' || 'permit', then: yup.string().required("Please select driver's gender").trim() }),
  address: yup
    .string()
    .when('relationshipToPrimaryDriver', {
      is: 'SELF',
      then: yup.string().matches(addressRegex, {
        message: 'Please enter your garaging address',
        excludeEmptyString: true,
      }).required('Please enter your address').trim(),
      otherwise: yup.string()
    }),
  state: yup
    .string()
    .when('relationshipToPrimaryDriver', {
      is: 'SELF',
      then: yup.string().oneOf(LIVE_STATES, 'We aren\'t live in your state yet. Please check back soon!').required('Please select a state').trim(),
      otherwise: yup.string()
    }),
  birthDate: yup.date()
    .when('relationshipToPrimaryDriver', {
      is: 'SELF',
      then: yup.date()
        .max(minDatePrimaryDriver, `Policy holder must be at least ${minPrimaryDriverAge} years or older`)
        .typeError('Please enter a valid birth date'),
      otherwise: yup.date()
        .max(minDateAdditionalDriver, `Driver must be at least ${minAdditionalDriverAge} years or older to be included on the policy`)
        .typeError('Please enter a valid birth date'),
    }),
  relationshipStatus: yup
    .string()
    .required("Please select driver's marital status")
    .trim(),
  relationshipToPrimaryDriver: yup
    .string()
    .required("Please select driver's relation to you")
    .trim(),
  education:
    yup.string().when('driverType', {
      is: 'rated' || 'permit',
      then: yup
        .string().when('relationshipToPrimaryDriver', {
          is: 'Spouse',
          then: yup
            .string()
            .required("Please select the level of education for the driver"
            )
            .trim()
        }),
    }),
  occupation:
    yup.string().when('driverType', {
      is: 'rated' || 'permit',
      then: yup
        .string().when('relationshipToPrimaryDriver', {
          is: 'Spouse',
          then: yup
            .string()
            .required("Please select the occupation for this driver")
            .trim()
        })
    }),
  licenseNumber: yup
    .string().when('driverType', {
      is: 'rated' || 'permit',
      then: yup
        .string()
        .required("Please enter driver's driver license number")
        .trim()
    }),
  stateLicensed: yup
    .string().when('driverType', {
      is: 'rated' || 'permit',
      then: yup
        .string()
        .required("Please select the state that issued the driver's license")
        .trim()
    }),
  licenseStatus: yup
    .string()
    .when('relationshipToPrimaryDriver', {
      is: 'SELF',
      then: yup.string()
        .oneOf(['Active / Valid'], "Because you do not have a valid driver's license, we cannot insure you. Please work with your local Department of Motor Vehicles to ensure that you have a valid driver's license. If you have a learner's permit you can be added to an existing policy as an additional driver.")
        .required('Please select the status of the driver\'s license')
        .trim(),
      otherwise: yup.string().when('driverType', {
        is: 'rated' || 'permit',
        then: yup.string()
          .oneOf(['Active / Valid'], "Because the driver does not have a valid drivers license or a learner's permit, we cannot insure this driver. Please work with your local Department of Motor Vehicles to ensure that the driver has a valid driver's license or a learner's permit.")
          .required('Please select the status of the driver\'s license')
          .trim()
      })
    }),
  requireSr22: yup
    .string().when('driverType', {
      is: 'rated' || 'permit',
      then: yup
        .string()
        .required(
          'Please select if the driver has an sr22, hover over icon for more information'
        )
        .trim()
    }),
  ageLicensed: yup
    .string().when('driverType', {
      is: 'rated' || 'permit',
      then: yup
        .string()
        .required('Please select when the driver\'s licensed was obtained')
        .test(
          'is-younger',
          'Driver is too young to have been licensed at ${value}',
          function (ageLicensed) {
            const age = getAge(new Date(this.parent.birthDate), new Date());
            const ageLicensedAsInt = parseInt(ageLicensed!, 10);
            return age >= ageLicensedAsInt;
          },
        )
    }),
  email: yup
    .string().when('driverType', {
      is: 'rated' || 'permit',
      then: yup
        .string()
        .email('Please enter a valid email address')
        .required("Please enter the driver's email address")
        .trim()
    }),
  confirmEmail: yup
    .string()
    .oneOf([yup.ref('email'), ''], 'Email does not match')
    .required('Please verify your email address')
    .trim(),
  SSN: yup
    .string()
    .nullable()
    .matches(ssnRegex, {
      message: 'Please enter a valid social security number',
      excludeEmptyString: true,
    }),
  phone: yup.string().when('relationshipToPrimaryDriver', {
    is: 'SELF',
    then: yup.string()
      .matches(/(^\d{10}$)|(^\d{3}-\d{3}-\d{4}$)/, {
        message: 'Please submit a valid phone number 555-555-5555',
        excludeEmptyString: true,
      })
      .required('Please enter your phone number')
      .trim(),
    otherwise: yup
      .string().trim(),
  }),
  hasAccidents: yup
    .string().when('driverType', {
      is: 'rated' || 'permit',
      then: yup
        .string().required('Please select an option'),
    }),
  accidentsInfo: yup
    .array().when('hasAccidents', {
      is: 'yes',
      then: yup
        .array()
        .of(accidentsInfoSchema)
    }),

  hasViolations: yup
    .string().when('driverType', {
      is: 'rated' || 'permit',
      then: yup
        .string().required('Please select an option'),
    }),
  violationInfo: yup
    .array().when('hasViolations', {
      is: 'yes',
      then: yup
        .array()
        .of(violationInfoSchema)
      // .min(1, 'You must include at least one incident on the policy')
    }),
});
const vehicleSchema = yup.object().shape(
  {
    vin: yup
      .string()
      .matches(/^[A-HJ-NPR-Za-hj-npr-z\d]{17}$/gm,{
        message: 'Please enter a valid VIN',
        excludeEmptyString: true
      })
      .when(['state', 'licensePlateNumber'], {
        is: (state, licensePlateNumber) =>
          !state ||
          state.length === 0 ||
          !licensePlateNumber ||
          licensePlateNumber.length === 0,
        then: yup
          .string()
          .required(
            'Please include your VIN or State & License Plate to look up your vehicle'
          ),
        otherwise: yup.string().nullable(),
      })
      .trim(),
    state: yup
      .string()
      .when('vin', {
        is: (vin) => !vin || vin.length === 0,
        then: yup
          .string()
          .required(
            'Please include your VIN or State & License Plate to look up your vehicle'
          ),
        otherwise: yup.string().nullable(),
      })
      .trim(),
    licensePlateNumber: yup
      .string()
      .when('vin', {
        is: (vin) => !vin || vin.length === 0,
        then: yup
          .string()
          .required(
            'Please include your VIN or State & License Plate to look up your vehicle'
          ),
        otherwise: yup.string().nullable(),
      })
      .trim(),
    primaryUse: yup
      .string()
      .required('Please select the primary use for this vehicle')
      .trim(),
    primaryZipCode: yup
      .string()
      .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, {
        message: 'Please submit a valid zip code',
        excludeEmptyString: true,
      })
      .required('Please enter the Garaging ZIP Code for this vehicle')
      .trim(),
    ownershipType: yup
      .string()
      .required('Please select your ownership for this vehicle')
      .trim(),
    year: yup.number(),
    collDeductible: yup.string()
      .test(
        'coll-dedt',
        'Please select an option for the Comprehensive Deductible.  Comprehensive coverage and Collision coverage must be purchased together.',
        function (data) {
          if (this.parent.compDeductible !== 'DECLINED') {
            if (data !== 'DECLINED') {
              const coll = data?.split(' ')[0].replace(',', '');
              const comp = this.parent.compDeductible?.split(' ')[0].replace(',', '');
              if (coll && comp) return parseInt(coll) > parseInt(comp) - 1 ? true : Promise.resolve(this.createError({ message: 'Collision deductible cannot be lower than Compreshensive deductible.' }));
            }
            return true;
          }
          if (this.parent.compDeductible === 'DECLINED' && data === 'DECLINED') {
            return true;
          }
          return false;
        },
      ),
    compDeductible: yup.string()
      .test(
        'comp-dedt',
        'Please select an option for the Collision Deductible.  Collision coverage and Comprehensive coverage must be purchased together.',
        function (data) {
          if (this.parent.collDeductible !== 'DECLINED') {
            return true;
          }
          if (this.parent.collDeductible === 'DECLINED' && data === 'DECLINED') {
            return true;
          }
          return false;
        },
      ),
    rental: yup.string().test(
      'ren-tal',
      'In order to select this coverage you must also select Comprehensive coverage.',
      function (rental) {
        if ((rental === 'NONE' || rental === 'DECLINED') && this.parent.compDeductible === 'DECLINED') {
          return true;
        }
        if (this.parent.compDeductible === 'DECLINED' && (rental !== 'NONE' && rental !== 'DECLINED')) {
          return false;
        }
        if (this.parent.compDeductible !== 'DECLINED' && (rental === 'NONE' || rental === 'DECLINED')) {
          return true;
        }
        return true;
      },
    ),
    loan: yup.string().test(
      'lo-an',
      'In order to select this coverage you must also select Comprehensive coverage.',
      function (loan) {
        if ((loan === 'NONE' || loan === 'DECLINED') && this.parent.compDeductible === 'DECLINED') {
          return true;
        }
        if (this.parent.compDeductible === 'DECLINED' && (loan !== 'NONE' && loan !== 'DECLINED')) {
          return false;
        }
        if (this.parent.compDeductible !== 'DECLINED' && (loan === 'NONE' || loan === 'DECLINED')) {
          return true;
        }
        return true;
      },
    ),
  },
  [
    ['vin', 'state'],
    ['vin', 'licensePlateNumber'],
  ]
);


export const getReviewInfoSchema = () => {
  const reviewSchema = {
    drivers: yup
      .array()
      .of(driverSchema)
      .min(1, 'You must include at least one driver on the policy'),
    currentCoveragePolicyStartDate: yup
      .date()
      .max(new Date(), 'Please enter a valid policy start date')
      .typeError('Please enter a valid policy start date'),
    carInsuranceForSixMonths: yup
      .string()
      .trim()
      .required('Please select an option'),
    monthContinuouslyInsured: yup
      .string()
      .when('carInsuranceForSixMonths', {
        is: 'Yes',
        then: yup
          .string()
          .required('Please select a month')
          .trim(),
        otherwise: yup.string().trim()
      }),
    yearContinuouslyInsured: yup
      .string()
      .when('carInsuranceForSixMonths', {
        is: 'Yes',
        then: yup
          .string()
          .required('Please select a year')
          .trim(),
        otherwise: yup.string().trim()
      }),
    dueToAMilitaryDeployment: yup
      .string()
      .trim()
      .when('carInsuranceForSixMonths', {
        is: 'No',
        then: yup
          .string()
          .required('Please select an option')
          .trim(),
        otherwise: yup.string().trim()
      }),
    startMonthDeployed: yup
      .string()
      .when('dueToAMilitaryDeployment', {
        is: 'Yes',
        then: yup
          .string()
          .required('Please select a month')
          .trim(),
        otherwise: yup.string().trim()
      }),
    startYearDeployed: yup
      .string()
      .when('dueToAMilitaryDeployment', {
        is: 'Yes',
        then: yup
          .string()
          .required('Please select a year')
          .trim(),
        otherwise: yup.string().trim()
      }),
    endMonthDeployed: yup
      .string()
      .when('dueToAMilitaryDeployment', {
        is: 'Yes',
        then: yup
          .string()
          .required('Please select a month')
          .trim(),
        otherwise: yup.string().trim()
      }),
    endYearDeployed: yup
      .string()
      .when('dueToAMilitaryDeployment', {
        is: 'Yes',
        then: yup
          .string()
          .required('Please select a year')
          .trim(),
        otherwise: yup.string().trim()
      }),
    monthInsuredPrior: yup
      .string()
      .when('dueToAMilitaryDeployment', {
        is: 'Yes',
        then: yup
          .string()
          .required('Please select a month')
          .trim(),
        otherwise: yup.string().trim()
      }),
    yearInsuredPrior: yup
      .string()
      .when('dueToAMilitaryDeployment', {
        is: 'Yes',
        then: yup
          .string()
          .required('Please select a year')
          .trim(),
        otherwise: yup.string().trim()
      }),
    withoutCarInsuranceForThirtyOneDays: yup
      .string()
      .when('dueToAMilitaryDeployment', {
        is: 'No',
        then: yup
          .string()
          .required('Please select an option')
          .trim(),
        otherwise: yup.string().trim()
      }),
    currentCoverageContinuouslyInsured: yup
      .string()
      .when('currentlyInsured', {
        is: 'Yes',
        then: yup
          .string()
          .required('Please select an option')
          .trim(),
        otherwise: yup.string().trim()
      }),
    currentCoverageBiLimit : yup
      .string()
      .when('currentlyInsured', {
        is: 'Yes',
        then: yup
          .string()
          .required('Please select an option')
          .trim(),
        otherwise: yup.string().trim()
      }),
    currentlyInsured: yup
      .string()
      .trim()
      .required('Please select an option'),
    recentInsuranceProvider : yup
      .string()
      .trim()
      .when('currentlyInsured', {
      is: 'No',
      then: yup
        .string()
        .required('Please select an option')
        .trim(),
      otherwise: yup.string().trim()
    }),
    priorBodilyLimit : yup
      .string()
      .trim()
      .when('currentlyInsured', {
      is: 'No',
      then: yup
        .string()
        .required('Please select an option')
        .trim(),
      otherwise: yup.string().trim()
    }),
    currentCoverageCarrier: yup
      .string()
      .when('currentlyInsured', {
        is: 'Yes',
        then: yup
          .string()
          .required('Please select your current provider')
          .trim(),
        otherwise: yup.string().trim()
      }),
    vehicles: yup
      .array()
      .of(vehicleSchema)
      .min(1, 'You must include at least one vehicle on your policy'),
  };

  return reviewSchema;
};

const effectiveDateMin = new Date(new Date().setHours(0, 0, 0, 0));
const effectiveDateMax = agentData['agentId'] ?
  new Date(new Date().setDate(new Date().getDate() + 30)) :
  new Date(new Date().setDate(new Date().getDate() + 14));

const effectiveDaysMax = agentData['agentId'] ? '30' : '14'

export const getQuoteSchema = (stateCode: string | undefined) => {
  const quoteSchema: any = {
    effectiveDate: yup.date()
      .min(effectiveDateMin, 'Effective date can not be in the past')
      .max(effectiveDateMax, `You cannot request a quote with an effective date more than ${effectiveDaysMax} days in the future`),
    drivers: yup
      .array()
      .of(driverSchema)
      .min(1, 'You must include at least one driver on the policy'),
    currentCoveragePolicyStartDate: yup
      .date()
      .max(new Date(), 'Please enter a valid policy start date')
      .typeError('Please enter a valid policy start date'),
    currentCoverageBiLimit : yup
      .string()
      .when('currentlyInsured', {
        is: 'Yes',
        then: yup
          .string()
          .required('Please select an option')
          .trim(),
        otherwise: yup.string().trim()
      }),
    currentlyInsured: yup
      .string()
      .trim()
      .required('Please select an option'),
    currentCoverageCarrier: yup
      .string()
      .when('currentlyInsured', {
        is: 'Yes',
        then: yup
          .string()
          .required('Please select your current provider')
          .trim(),
        otherwise: yup.string().trim()
      }),
    carInsuranceForSixMonths: yup
      .string()
      .trim()
      .required('Please select an option'),
    monthContinuouslyInsured: yup
      .string()
      .when('carInsuranceForSixMonths', {
        is: 'Yes',
        then: yup
          .string()
          .required('Please select a month')
          .trim(),
        otherwise: yup.string().trim()
      }),
    yearContinuouslyInsured: yup
      .string()
      .when('carInsuranceForSixMonths', {
        is: 'Yes',
        then: yup
          .string()
          .required('Please select a year')
          .trim(),
        otherwise: yup.string().trim()
      }),
    dueToAMilitaryDeployment: yup
      .string()
      .trim()
      .when('carInsuranceForSixMonths', {
        is: 'No',
        then: yup
          .string()
          .required('Please select an option')
          .trim(),
        otherwise: yup.string().trim()
      }),
    startMonthDeployed: yup
      .string()
      .when('dueToAMilitaryDeployment', {
        is: 'Yes',
        then: yup
          .string()
          .required('Please select a month')
          .trim(),
        otherwise: yup.string().trim()
      }),
    startYearDeployed: yup
      .string()
      .when('dueToAMilitaryDeployment', {
        is: 'Yes',
        then: yup
          .string()
          .required('Please select a year')
          .trim(),
        otherwise: yup.string().trim()
      }),
    endMonthDeployed: yup
      .string()
      .when('dueToAMilitaryDeployment', {
        is: 'Yes',
        then: yup
          .string()
          .required('Please select a month')
          .trim(),
        otherwise: yup.string().trim()
      }),
    endYearDeployed: yup
      .string()
      .when('dueToAMilitaryDeployment', {
        is: 'Yes',
        then: yup
          .string()
          .required('Please select a year')
          .trim(),
        otherwise: yup.string().trim()
      }),
    monthInsuredPrior: yup
      .string()
      .when('dueToAMilitaryDeployment', {
        is: 'Yes',
        then: yup
          .string()
          .required('Please select a month')
          .trim(),
        otherwise: yup.string().trim()
      }),
    yearInsuredPrior: yup
      .string()
      .when('dueToAMilitaryDeployment', {
        is: 'Yes',
        then: yup
          .string()
          .required('Please select a year')
          .trim(),
        otherwise: yup.string().trim()
      }),
    withoutCarInsuranceForThirtyOneDays: yup
      .string()
      .when('dueToAMilitaryDeployment', {
        is: 'No',
        then: yup
          .string()
          .required('Please select an option')
          .trim(),
        otherwise: yup.string().trim()
      }),
    currentCoverageContinuouslyInsured: yup
      .string()
      .when('currentlyInsured', {
        is: 'Yes',
        then: yup
          .string()
          .required('Please select an option')
          .trim(),
        otherwise: yup.string().trim()
      }),
    recentInsuranceProvider : yup
      .string()
      .trim()
      .when('currentlyInsured', {
      is: 'No',
      then: yup
        .string()
        .required('Please select an option')
        .trim(),
      otherwise: yup.string().trim()
    }),
    priorBodilyLimit : yup
      .string()
      .trim()
      .when('currentlyInsured', {
      is: 'No',
      then: yup
        .string()
        .required('Please select an option')
        .trim(),
      otherwise: yup.string().trim()
    }),
    vehicles: yup
      .array()
      .of(vehicleSchema)
      .min(1, 'You must include at least one vehicle on your policy'),
    biLimit: yup.string().required('Please select an option').trim(),
    medLimit: yup.string().required('Please select an option').trim(),
    pdLimit: yup.string()
      .required('Please select an option')
      .trim()
      .test(
        'pd-lte',
        'Your Property Damage coverage must be equal to or less than the Bodily Injury per person selection.',
        function (pdLimit) {
          if (stateCode?.toLowerCase() === 'wi') {
            const { biLimit } = this.parent;
            const biPerPerson = biLimit ? biLimit.split('/')[0] : ''
            const pdLimitString = pdLimit ? String(pdLimit) : ''
            if (parseInt(biPerPerson) < parseInt(pdLimitString)) {
              return false
            }
          }
          return true;
        },
      ),
    umLimit: yup.string()
      .required('Please select an option')
      .trim()
      .test(
        'um-lte',
        'Please select an option less than or equal to the Bodily Injury selected above',
        function (umLimit) {
          if (!umLimit || !this.parent) {
            return true;
          }
          const { biLimit } = this.parent;
          if ((umLimit && umLimit.toLowerCase() === 'none')
            || (umLimit && umLimit.toLowerCase() === 'declined')) {
            return true;
          }

          return parseInt(umLimit!.split('/')[0], 10) <= parseInt(biLimit.split('/')[0], 10);
        },
      ),
    umpdLimit: yup.string()
      .trim()
      .test(
        'umpd-lte',
        'Please select an option less than or equal to the property damage selected above',
        function (umpdLimit) {
          if (stateCode && stateCode.toLowerCase() === 'tn') {
            if (parseInt(umpdLimit!?.split(' ')[0], 10) > parseInt(this.parent.pdLimit)) {
              return false
            }
            if (this.parent.umLimit === 'DECLINED' && umpdLimit === 'DECLINED') {
              return true
            } else if (this.parent.umLimit) return this.parent.umLimit !== 'DECLINED' ? true : Promise.resolve(this.createError({ message: 'Uninsured Motorist Property Damage cannot be purchased without uninsured motorist bodily injury coverage.' }));
          }
          return true
        },
      ),
  };

  quoteSchema.uimLimit = yup.string()
    .required('Please select an option')
    .trim()
    .test(
      'uim-lte',
      'Please select an option less than or equal to the Bodily Injury selected above',
      function (uimLimit) {
        if (!uimLimit || !this.parent) {
          return true;
        }

        const { biLimit } = this.parent;

        if ((uimLimit && uimLimit.toLowerCase() === 'none')
          || (uimLimit && uimLimit.toLowerCase() === 'declined')) {
          return true;
        }

        return parseInt(uimLimit!.split('/')[0], 10) <= parseInt(biLimit.split('/')[0], 10);
      },
    );


  return quoteSchema;
};