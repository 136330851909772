import React from 'react';
import PageHeader from '../components/PageHeader';
import { setQuoteInfo } from '../utils/queryParams';

export interface PartnerQuoteProps {

}

const PartnerQuote: React.FC<PartnerQuoteProps> = () => {
  // get previous quote from get-partner-summary and persist to local storage
  setQuoteInfo(window.location.href);

  return (
    <div>
      <PageHeader
        icon='circularProgress'
        header='Loading your information...'
        description='Give us a few seconds while we load your info.'
      />
      <div style={{ marginBottom: '50em' }} />
    </div>
  );
}

export default PartnerQuote;