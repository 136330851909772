import { State } from "./stateCodes";

export const violationList: State[] = [
  { display: 'Careless or improper operation', value: 'MAJ' },
  { display: 'Defective equipment', value: 'IND' },
  { display: 'Driver under suspension', value: 'MIN' },
  { display: 'Driving While Intoxicated/Under Influence', value: 'DWI' },
  { display: 'Failure to report accident', value: 'MIN' },
  { display: 'Failure to yield', value: 'MIN' },
  { display: 'Felony of Motor Vehicle /Vehicle Theft', value: 'MAJ' },
  { display: 'Fleeing from policy officer', value: 'MAJ' },
  { display: 'Following vehicle too closely', value: 'MIN' },
  { display: 'Improper backing', value: 'MIN' },
  { display: 'Improper lane change', value: 'MIN' },
  { display: 'Improper turning', value: 'MIN' },
  { display: 'Leaving the scene of an accident', value: 'MAJ' },
  { display: 'License or credentials violations', value: 'MIN' },
  { display: 'License violation (serious)', value: 'MIN' },
  { display: 'Minor moving violation', value: 'MIN' },
  { display: 'Other serious violation', value: 'MAJ' },
  { display: 'Other Violations:', value: 'MIN' },
  { display: 'Passing a school bus', value: 'MIN' },
  { display: 'Racing', value: 'MAJ' },
  { display: 'Reckless', value: 'MAJ' },
  { display: 'Refusal to test', value: 'DWI' },
  { display: 'Safety violation', value: 'MIN' },
  { display: 'Speeding', value: 'SPD' },
  { display: 'Stop Sign/Stop Light/Traffic Device', value: 'MIN' },
  { display: 'Vehicle Use without Owner’s consent', value: 'MAJ' },
  { display: 'Vehicular Homicide', value: 'MAJ' },
  { display: 'Wrong Way on a one way street', value: 'MIN' },
];

export const accidentList: State[] = [
  { display: 'Collision with another vehicle', value: 'a' },
  { display: 'Hit object/property', value: 'b' },
  { display: 'Swerved/lost control', value: 'c' },
  { display: 'Hit an animal', value: 'd' },
  { display: 'Victim of hit and run', value: 'e' },
  { display: 'Acts of nature/weather', value: 'f' },
  { display: 'Object fell on vehicle (non-weather)', value: 'g' },
  { display: 'Theft/Vandalism/Fire', value: 'h' },
  { display: 'Windshield/Glass Only', value: 'i' },
  { display: 'Damage from pothole', value: 'j' },
  { display: 'Hit Pedestrian', value: 'k' }
];