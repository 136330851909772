export const occupations = [
  {
    title: 'Homemaker (full-time)',
    valueName: 'homemaker',
    options: ['Homemaker (full-time)'],
    iconName: 'home-lg-alt',
  },
  {
    title: 'Retired (full-time)',
    valueName: 'retired',
    options: ['Retired (full-time)'],
    iconName: 'user-secret',
  },
  {
    title: 'Unemployed',
    valueName: 'unemployed',
    options: ['Unemployed'],
    iconName: 'search-dollar',
  },
  {
    title: 'Student (full-time)',
    valueName: 'student',
    options: ['Student (full-time)'],
    iconName: 'graduation-cap',
  },
  {
    title: 'Agriculture/Forestry/Fishing',
    valueName: 'agriculture',
    options: [
      'Farm/Ranch Owner',
      'Inspector/Quality Control',
      'Laborer/Worker',
      'Supervisor',
      'Other',
    ],
    iconName: 'trees',
  },
  {
    title: 'Art/Design/Media',
    valueName: 'media',
    options: [
      'Artist/Writer',
      'Composer / Director / Producer',
      'Designer / Photographer',
      'Editor',
      'Performer',
      'Supervisor / Manager',
      'Other',
    ],
    iconName: 'pencil-paintbrush',
  },
  {
    title: 'Banking/Finance/Real Estate',
    valueName: 'finance',
    options: [
      'Broker',
      'Accountant',
      'Analyst',
      'Appraiser - Real Estate',
      'Billing & Collections',
      'Finance / Insurance / Real Estate Rep',
      'Financial Advisor / Investor',
      'Loan Officer',
      'Manager - Property',
      'Sales / Purchasing Rep',
      'Supervisor / Manager',
      'CSR/Teller',
      'Other',
    ],
    iconName: 'cash-register',
  },
  {
    title: 'Business/Sales/Office',
    valueName: 'business',
    options: [
      'Account Executive',
      'Accountant / Auditor',
      'Administrative Assistant',
      'Agent/Broker',
      'Analyst - Business or Finance',
      'Analyst - Other than Business or Finance',
      'Bookkeeper',
      'Cashier/Checker',
      'Consultant',
      'Customer Service Representative',
      'General Office Support',
      'Human Resource (HR) Specialist',
      'Manager - Other than Executive / GM',
      'Manufacturing Rep',
      'Marketing Specialist',
      'Order Fulfillment Support',
      'Payroll Support',
      'Public Relations (PR) Specialist',
      'Receptionist',
      'Retail Salesperson',
      'Secretary (except legal)',
      'Supervisor / Manager (Non-Retail)',
      'Supervisor / Manager (Retail)',
      'Vehicle / Equip Rental Rep',
      'Wholesale Rep',
      'Other - Business / Office',
      'Other - Sales',
    ],
    iconName: 'user-tie',
  },
  {
    title: 'Construction/Energy/Mining',
    valueName: 'construction',
    options: [
      'Electrician/Linesman',
      'Field Service Technician',
      'Foreman/Manager/Inspector',
      'Heat/Air Technician',
      'Laborer/Worker',
      'Equipment Operator',
      'Maintenance / Custodian',
      'Plumber',
      'Other',
    ],
    iconName: 'user-hard-hat',
  },
  {
    title: 'Education/Library',
    valueName: 'education',
    options: [
      'Assistant/ Aide',
      'College Professor',
      'Counselor',
      'Librarian/Curator',
      'Principal',
      'Teacher - Special Education',
      'Teacher -Vocational',
      'Teacher, K-12',
      'Other',
    ],
    iconName: 'user-graduate',
  },
  {
    title: 'Engineer/Architect/Science/Math',
    valueName: 'stem',
    options: [
      'Analyst - Engineering',
      'Analyst - Science',
      'Architect / Drafter',
      'Engineer',
      'Scientist / Researcher',
      'Supervisor / Manager',
      'Technician',
      'Other',
    ],
    iconName: 'abacus',
  },
  {
    title: 'Food Service/Hotel Services',
    valueName: 'service',
    options: [
      'Bartender',
      'Caterer',
      'Chef',
      'Cook/Worker',
      'Desk Clerk',
      'Dishwasher',
      'Food Preparer / Server',
      'Housekeeper/Maid',
      'Manager - Food Service',
      'Manager - Hotel Service',
      'Supervisor - Food Service',
      'Supervisor - Hotel Service',
      'Other - Food Services',
      'Other - Hotel Services',
    ],
    iconName: 'concierge-bell',
  },
  {
    title: 'Government/Military',
    valueName: 'gov',
    options: [
      'Auditor / Analyst / Examiner',
      'Enlisted Military Personnel (E1-E4)',
      'Military - Officer (E5-O10)',
      'Officer - Warrant',
      'Supervisor / Manager',
      'US Postal Worker',
      'Other',
    ],
    iconName: 'landmark-alt',
  },
  {
    title: 'Information Technology',
    valueName: 'it',
    options: [
      'Director/Administrator',
      'Analyst',
      'Programmer',
      'Supervisor / Manager',
      'Technician',
      'Other',
    ],
    iconName: 'laptop-code',
  },
  {
    title: 'Insurance',
    valueName: 'insurance',
    options: [
      'Agent/Broker',
      'Claims Adjuster',
      'Supervisor / Manager',
      'Underwriter / Actuary',
      'Other',
    ],
    iconName: 'umbrella',
  },
  {
    title: 'Legal/Law Enforcement/Security',
    valueName: 'law',
    options: [
      'Corrections Officer',
      'Detective / Investigator',
      'Fire Fighter',
      'Lawyer / Judge / Mediator',
      'Paralegal/Law Clerk',
      'Police / Highway Patrol / Sheriff',
      'Security Guard',
      'Supervisor / Manager',
      'Other',
    ],
    iconName: 'shield-alt',
  },
  {
    title: 'Medical/Social Services/Religion',
    valueName: 'medical',
    options: [
      'Aide / Attendant',
      'Assistant / Technician / Therapist',
      'Chiropractor',
      'Clergy',
      'Counselor',
      'Dentist / Orthodontist',
      'Doctor / Surgeon',
      'Nurse - LPN',
      'Nurse - RN',
      'Paramedic/E.M. Technician',
      'Pharmacist',
      'Psychologist / Therapist',
      'Social Worker',
      'Supervisor / Manager - Social Service / Religion',
      'Supervisor / Manager / Administrator - Medical',
      'Veterinarian',
      'Other - Medical',
      'Other - Social Services / Religion',
    ],
    iconName: 'hospital',
  },
  {
    title: 'Personal Care/Service',
    valueName: 'personalCare',
    options: [
      'Caregiver',
      'Housekeeper',
      'Dry Cleaner/Laundry',
      'Hair Stylist/Barber',
      'Pet Services',
      'Supervisor / Manager',
      'Other',
    ],
    iconName: 'hand-heart',
  },
  {
    title: 'Production/Manufacturing',
    valueName: 'manufacturing',
    options: [
      'Assembler / Fabricator',
      'Inspector / Quality Control',
      'Laborer / Helper',
      'Machine Operator',
      'Machinist / Woodworker',
      'Production Worker',
      'Supervisor / Manager',
      'Other',
    ],
    iconName: 'industry-alt',
  },
  {
    title: 'Repair/Maintenance/Grounds',
    valueName: 'repair',
    options: [
      'Custodian/Janitor',
      'Laborer / Helper',
      'Landscape/Grounds Maintenance',
      'Repair / Mechanic',
      'Supervisor / Manager',
      'Other',
    ],
    iconName: 'tools',
  },
  {
    title: 'Sports/Recreation',
    valueName: 'sport',
    options: [
      'Admin / Activity Assistant',
      'Athlete',
      'Fitness Trainer / Instructor',
      'Supervisor / Manager',
      'Other',
    ],
    iconName: 'football-helmet',
  },
  {
    title: 'Travel/Transportation/Storage',
    valueName: 'travel',
    options: [
      'Driver (All Vehicles)',
      'Packer / Mover',
      'Supervisor / Manager',
      'Other',
    ],
    iconName: 'plane-departure',
  },
];
