import React, { useContext } from 'react';
import { BrandContext, getPhoneNumber, getPrimaryColor } from '../utils/style';
import './LeftFooter.scss';

const LeftFooter: React.FC = () => {
  const { brand } = useContext(BrandContext);
  const phoneNumber = getPhoneNumber();
  return (
    <div className='left-footer-wrapper'>
      <div className='left-footer-item-wrapper'>
        <span className='left-footer-bold'>Need help?</span>
        <span>
          Call: <a href={`tel:${phoneNumber}`} style={{ color: getPrimaryColor() }}>{phoneNumber}</a>
        </span>
        {
          brand === 'novo' ?
            <a href='mailto:support@novo.us' style={{ color: getPrimaryColor() }}>support@novo.us</a>
            :
            <a
              href='mailto:support@motionauto.io'
              style={{ color: getPrimaryColor() }}
            >
              support@motionauto.io
            </a>
        }
        <span>Available from 9am to 5pm MST</span>
      </div>
    </div>
  );
};

export default LeftFooter;
