import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { ReactComponent as HorizontalLine } from '../images/rectangle-line.svg';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup
} from '@material-ui/core';
import './IncidentReport.scss';
import { AuthModel } from '../models/motion';
import { BrandContext, getNovoIconColor } from '../utils/style';
import { useHistory } from 'react-router';
import { incidentMvr } from '../utils/mutations';
import { accidentList } from '../models/incidents';


const IncidentReport: React.FC = () => {
  const { brand } = useContext(BrandContext);
  const history = useHistory()
  const queryParams = window.sessionStorage.getItem('queryParams') || '';
  const methods = useForm({
    // default values 
  });
  const { handleSubmit, errors, control, watch } = methods;

  const onSubmit = (data: AuthModel) => {
    // API call
  };
  const nextClicked = () => {
   history.push(`/incident-report-mvr${queryParams}`)
   window.scrollTo(0, 0);
  };


  return (
    <div>
      <Helmet>
        <title>{`Incident Report - ${brand === 'motion' ? 'Motion Insurance' : 'Novo Insurance'}`}</title>
        {
          brand === 'motion' ?
            <link rel='icon' href='/favicon.ico' />
            :
            <link rel='icon' href='/novo-fav.ico' />
        }
        <meta name='description' content={`Get a ${brand === 'motion' ? 'Motion' : 'Novo'} Auto Insurance quote and buy a policy`} />
      </Helmet>
      <div> <div className='import-message-wrapper-scan-report'  >
        <span className='import-title-sub-report' style={{ color: getNovoIconColor() }}>Look What We Found</span>
      </div>
        <div className='import-message-wrapper-scan-report'  >
          <span className='import-title-content-scan-report' style={{ fontSize: '20px' }}>
            Review MVR driver history results.
          </span>
        </div>
        <Grid className="main">
          <div className='import-message-wrapper-scan-report' style={{ marginBottom: '0em' }}>
            <span className='import-title-content-scan-report import-title-head' >
              <h2 style={{ color: getNovoIconColor() }}>Accidents (Past 5 years)</h2>
            </span>
          </div>
          <div className='import-message-wrapper-scan-report-text'  >
            <span className='import-title-content-scan-report import-title-head-sub'>
              YOU REPORTED
            </span>
          </div>
          <div className='import-message-wrapper-scan-report-text'  >
            <span className='import-title-content-scan-report import-title-date'>
              {new Date(incidentMvr[1].find(ele => ele.group.toLowerCase() === 'accident')?.date || '').toLocaleString('default', { month: 'long' }) + ' ' + new Date(incidentMvr[1].find(ele => ele.group.toLowerCase()  === 'accident')?.date || '').getFullYear()}
            </span>
          </div>
          <div className='import-message-wrapper-scan-report-text'  >
            <span className='import-title-content-scan-report import-title-type'>
              {accidentList.find(accident => accident.value === incidentMvr[1].find(ele => ele.group.toLowerCase() === 'accident')?.code)?.display}
            </span>
          </div>
          <br />
          <div className='import-message-wrapper-scan-report-text'  >
            <span className='import-title-content-scan-report import-title-mvr'>
              MVR RESULTS
            </span>
          </div>
          <div className='import-message-wrapper-scan-report-text'  >
            <span className='import-title-content-scan-report import-title-match'>
              Select the best match
            </span>
          </div>

          <FormProvider {...methods}>
            <form
              id='authForm'
              className='import-form'
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid container spacing={3}>
                <Grid item lg={3} md={3} ></Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className='import-message-wrapper-scan-report'>
                    <span className='import-title-content-scan-report-dyn'>
                      <Controller
                        control={control}
                        name={''}
                        defaultValue={''}
                        render={({ onChange, value, name, onBlur }) => (
                          <FormControl
                            component="fieldset"
                            fullWidth
                          >
                            <Grid container spacing={2} justify='flex-start'>
                              <RadioGroup value={value} onChange={onChange} onBlur={onBlur} row aria-label='hasViolations' name={name}>
                                <span style={{ marginTop: '-9px' }}>
                                  {incidentMvr[0].filter(incident => incident.group === 'ACCIDENT').map(incident => {
                                    return (<div>
                                      <Grid item lg={12} md={12} sm={12} xs={12} className='mvr-date'>
                                        <FormControlLabel value={new Date(incident.date).toLocaleString('default', { month: 'long' }) + ' ' + new Date(incident.date).getFullYear()} control={<Radio
                                          color="primary" />} label={new Date(incident.date).toLocaleString('default', { month: 'long' }) + ' ' + new Date(incident.date).getFullYear()} />
                                      </Grid>
                                      <span className='mvr-type'>{accidentList.find(accident => accident.value === incident.code)?.display}</span>
                                    </div>)
                                  })}
                                </span>
                              </RadioGroup>
                              <FormHelperText>
                              </FormHelperText>
                            </Grid>
                          </FormControl>
                        )}
                      />
                    </span>
                  </div>
                </Grid>
                <Grid item lg={3} md={3} ></Grid>
              </Grid>
              <Grid container style={{ marginTop: '4em' }}>
                <Grid container style={{}}>
                  <Grid item lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'center' }}>
                    <HorizontalLine style={{ width: '10em' }} />
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Grid>
        <Grid className="main" >
          <div className='import-message-wrapper-scan-report' >
            <span className='import-title-content-scan-report import-title-head'>
              <h2 style={{ color: getNovoIconColor() }}>Traffic violations (past 5 years)</h2>
            </span>
          </div>
          <div className='import-message-wrapper-scan-report-text'  >
            <span className='import-title-content-scan-report import-title-head-sub'>
              YOU REPORTED
            </span>
          </div>
          <div className='import-message-wrapper-scan-report-text'  >
            <span className='import-title-content-scan-report import-title-date'>
              {new Date(incidentMvr[1].find(ele => ele.group.toLowerCase() === 'violation')?.date || '').toLocaleString('default', { month: 'long' }) + ' ' + new Date(incidentMvr[1].find(ele => ele.group.toLowerCase() === 'violation')?.date || '').getFullYear()}
            </span>
          </div>
          <div className='import-message-wrapper-scan-report-text'  >
            <span className='import-title-content-scan-report import-title-type'>
              {accidentList.find(accident => accident.value === incidentMvr[1].find(ele => ele.group.toLowerCase() === 'violation')?.code)?.display}
            </span>
          </div>
          <br />
          <div className='import-message-wrapper-scan-report-text'  >
            <span className='import-title-content-scan-report import-title-mvr'>
              MVR RESULTS
            </span>
          </div>

          <div className='import-message-wrapper-scan-report-text'  >
            <span className='import-title-content-scan-report import-title-match'>
              Select the best match
            </span>
          </div>

          <FormProvider {...methods}>
            <form
              id='authForm'
              className='import-form'
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid container spacing={3}>
                <Grid item lg={3} md={3} ></Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className='import-message-wrapper-scan-report'>
                    <span className='import-title-content-scan-report'>
                      <Controller
                        control={control}
                        name={''}
                        defaultValue={''}
                        render={({ onChange, value, name, onBlur }) => (
                          <FormControl
                            component="fieldset"
                            fullWidth
                          >
                            <Grid container spacing={2} justify='flex-start'>
                            <RadioGroup value={value} onChange={onChange} onBlur={onBlur} row aria-label='hasViolations' name={name}>
                                <span style={{ marginTop: '-9px' }}>
                                  {incidentMvr[0].filter(incident => incident.group.toLowerCase() === 'violation').map(incident => {
                                    return (<div>
                                      <Grid item lg={12} md={12} sm={12} xs={12} className='mvr-date'>
                                        <FormControlLabel value={new Date(incident.date).toLocaleString('default', { month: 'long' }) + ' ' + new Date(incident.date).getFullYear()} control={<Radio
                                          color="primary" />} label={new Date(incident.date).toLocaleString('default', { month: 'long' }) + ' ' + new Date(incident.date).getFullYear()} />
                                      </Grid>
                                      <span className='mvr-type'>{accidentList.find(accident => accident.value === incident.code)?.display}</span>
                                    </div>)
                                  })}
                                </span>
                              </RadioGroup>
                              <FormHelperText>
                              </FormHelperText>
                            </Grid>
                          </FormControl>
                        )}
                      />
                    </span>
                  </div>
                </Grid>
                <Grid item lg={3} md={3} ></Grid>
              </Grid>
              <Grid container style={{ marginTop: '4em' }}>
                <Grid item lg={3} md={3} ></Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} >
                  <div style={{ textAlign: 'center' }}  >
                    <span className='import-title-content-scan-report' style={{ width: '45%' }}>
                      <Button
                        id='authButtonReport'
                        className='initial-info-import-submit'
                        type='submit'
                        color='primary'
                        variant='contained'
                        onClick={nextClicked}
                        style={{ width: '41%' }}
                      >
                        Next
                      </Button>
                    </span>
                  </div>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Grid>
      </div>
    </div>
  );
};

export default IncidentReport;