import { Accordion, AccordionDetails, AccordionSummary, createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import './QuoteAccordion.scss';
import { ReactComponent as ExpandIcon } from '../images/expand-icon.svg';
import Coverages from './Coverages';
import { Offer } from '../models/motion';

export interface QuoteAccordionProps {
  tier: string;
  offer: Offer;
  inEditMode: boolean;
  changeEditMode(): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
  }),
);

const QuoteAccordion: React.FC<QuoteAccordionProps> =
  ({ tier, offer, inEditMode, changeEditMode }) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState<string | false>('panel');

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

    return (
      <div className={classes.root}>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandIcon />}
            aria-controls='panel1bh-content'
            id='quoteAccordion'
          >
            <div className='accordion-heading'>
              <div>
                What's covered
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Coverages
              tier={tier}
              offer={offer}
              inEditMode={inEditMode}
              changeEditMode={changeEditMode}
            />
          </AccordionDetails>
        </Accordion>
      </div>
    );
  };

export default QuoteAccordion;