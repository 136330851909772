export const getAge = (birthDate: Date, dateToCompare: Date) => {
  const dateToCompareMonth = dateToCompare.getMonth() + 1;
  const dateToCompareDay = dateToCompare.getDate();
  const birthMonth = birthDate.getMonth() + 1;
  const birthDay = birthDate.getDate();
  let age = dateToCompare.getFullYear() - birthDate.getFullYear();

  if (dateToCompareMonth < birthMonth) {
    age -= 1;
  }

  if ((dateToCompareMonth === birthMonth) && (dateToCompareDay < birthDay)) {
    age -= 1;
  }

  return age;
};