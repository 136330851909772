import React, { useContext, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';

import { useFieldArray, useFormContext } from 'react-hook-form';
import { Button, Checkbox, FormControlLabel, withStyles } from '@material-ui/core';
import { Offer } from '../models/motion';
// import { ReactComponent as TowTruckIcon } from '../images/tow-truck-icon.svg';
// import { ReactComponent as AccidentIcon } from '../images/accident-icon.svg';
// import { ReactComponent as SeatBeltIcon } from '../images/seat-belt-icon.svg';
// import { ReactComponent as BatteryIcon } from '../images/battery-icon.svg';
import { ReactComponent as CarSmall } from '../images/car-front-small.svg';
import { ReactComponent as CarSmallNovo } from '../images/car-front-small-novo.svg';
import { ReactComponent as AvatarSmall } from '../images/avatar-small.svg';
import { ReactComponent as AvatarSmallNovo } from '../images/avatar-small-novo.svg';
import './Coverages.scss';
import PolicyCoverages from './PolicyCoverages';
import VehicleCoverages from './VehicleCoverages';
import PolicyCoveragesEdit from './PolicyCoveragesEdit';
import Vehicle from './Vehicle';
import { GetLimits } from '../utils/queries';
import Driver from './Driver';
import { BrandContext, getPrimaryColor } from '../utils/style';

export interface CoveragesProps {
  tier: string;
  offer: Offer;
  inEditMode: boolean;
  changeEditMode(): void;
}

const CustomFormControlLabel = withStyles({
  label: {
    fontSize: '0.875em',
  }
})(FormControlLabel);

const Coverages: React.FC<CoveragesProps> =
  ({ tier, offer, inEditMode, changeEditMode }) => {
    let factors = offer[`${tier}_deductible`];
    if (Array.isArray(factors)) {
      [factors] = factors;
    }

    // fix issue of none in factors WILL NEED TO ADDRESS FOR PROD
    if (process.env.REACT_APP_ENV === 'dev' && Object.values(factors).some(v => v && v.toLowerCase() === 'none')) {
      Object.keys(factors).forEach(k => {
        if ((factors as any)[k] && (factors as any)[k].toLowerCase() === 'none') {
          // eslint-disable-next-line no-param-reassign
          (factors as any)[k] = 'DECLINED';
        }
      });
    }
    const { brand } = useContext(BrandContext);
    const paperlessAPIVal: any = offer.paperless;
    const telematicsAPIVal: any = offer.telematics;
    const driversJson = window.sessionStorage.getItem('drivers');
    const drivers = JSON.parse(driversJson!) || [];
    const [section, setSection] = useState('policy');
    const [expandAll, setExpandAll] = useState(false);
    const [paperlessBilling, setPaperlessBilling] = useState(paperlessAPIVal === 'Y');
    const [telematicsDiscount, setTelematicsDiscount] = useState(telematicsAPIVal === 'Y');
    const handelEditButtonClick = (currSection: string) => {
      changeEditMode();
      setSection(currSection);
    };
    const handleExpandAllClick = () => {
      setExpandAll(!expandAll);
    };
    const handleCheckPaperlessBilling =
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setPaperlessBilling(event.target.checked);
      };
    const handleTelematicsDiscount =
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setTelematicsDiscount(event.target.checked);
      };
    const methods = useFormContext();
    const { control, register } = methods;
    const {
      fields: driverFields,
      append: driverAppend,
      remove: driverRemove,
    } = useFieldArray({
      control,
      name: 'drivers',
    });
    const {
      fields: vehicleFields,
      append: vehicleAppend,
      remove: vehicleRemove,
    } = useFieldArray({
      control,
      name: 'vehicles',
    });

    const primaryDriverState = useRef(drivers[0].state);
    const watchPrimaryDriverState = methods.watch('drivers[0].state');
    const { data: dataLimits, refetch } = useQuery('limitsAndDeductibles', () => GetLimits(primaryDriverState.current));
    useEffect(() => {
      primaryDriverState.current = watchPrimaryDriverState;
      refetch();
    }, [watchPrimaryDriverState, refetch]);
    return (
      <div className='coverages-wrapper'>
        <div className='coverages-header'>
          <div>
            <span>Policy Coverages</span>
          </div>
          {
            !inEditMode ?
              <div>
                <Button
                  id='policyCoveragesEditButton'
                  onClick={() => handelEditButtonClick('policy')}
                  color='secondary'
                  variant='contained'
                >
                  Edit
                </Button>
              </div>
              : null
          }
          {
            inEditMode ?
              <button
                id='expandAllSections'
                className='expand-all-button'
                type='button'
                onClick={handleExpandAllClick}
                style={{ color: getPrimaryColor() }}
              >
                Expand all
              </button>
              : null
          }
        </div>
        {
          inEditMode ?
            <PolicyCoveragesEdit
              tier={tier}
              offer={offer}
              isAccordionOpen={section === 'policy'}
              clickedExpandAll={expandAll}
              limits={dataLimits}
            />
            : <PolicyCoverages factors={factors} limits={dataLimits} />
        }
        <div className='coverages-header'>
          <div>
            <span>Vehicle Coverages</span>
          </div>
          {
            !inEditMode ?
              <div>
                <Button
                  id='vehicleCoveragesEditButton'
                  onClick={() => handelEditButtonClick('vehicle')}
                  color='secondary'
                  variant='contained'
                >
                  Edit
                </Button>
              </div>
              : null
          }
        </div>
        {
          inEditMode ?
            vehicleFields.map((item, index) => {
              return (
                <Vehicle
                  key={item.id}
                  vehicle={item}
                  index={index}
                  showCoverages={true}
                  offer={offer}
                  tier={tier}
                  state={dataLimits.state}
                  limits={dataLimits.limits_deduct}
                  isAccordionOpen={false}
                  clickedExpandAll={expandAll}
                  remove={() => {
                    vehicleRemove(index);
                  }}
                  validateEve={() => { }}
                />
              );
            })
            : <VehicleCoverages tier={tier} offer={offer} limits={dataLimits} />
        }
        {
          inEditMode ?
            <div className='add-vehicle-button'>
              <Button
                id='coveragesAddVehicleButton'
                type='button'
                startIcon={brand === 'motion' ? <CarSmall /> : <CarSmallNovo />}
                color='secondary'
                variant='contained'
                onClick={() => {
                  vehicleAppend({
                    primaryUse: 'Commute',
                    ownershipType: 'Own',
                    ownershipLength: '',
                    primaryZipCode: drivers[0].zip || '',
                    state: drivers[0].state || '',
                  }, true);
                }}
              >
                Add another vehicle
              </Button>
            </div>
            : null
        }
        {
          inEditMode ?
            <>
              <div className='coverages-header'>
                <div>
                  <span>Drivers</span>
                </div>
              </div>
              {
                driverFields.map((item, index) => {
                  return (
                    <Driver
                      key={item.id}
                      driver={item}
                      index={index}
                      inEditMode={inEditMode}
                      isAccordionOpen={false}
                      clickedExpandAll={expandAll}
                      remove={() => {
                        driverRemove(index);
                      }}
                      maritalStatus={() => {}}
                    />
                  );
                })
              }
            </>
            : null
        }
        {
          inEditMode ?
            <div className='add-driver-button'>
              <Button
                id='coveragesAddDriverButton'
                type='button'
                startIcon={brand === 'motion' ? <AvatarSmall /> : <AvatarSmallNovo />}
                color='secondary'
                variant='contained'
                onClick={() => {
                  driverAppend({
                    firstName: '',
                    lastName: '',
                    canBeRemoved: true,
                    driverType: 'rated',
                  }, true);
                }}
              >
                Add another driver
              </Button>
            </div>
            : null
        }
        {
          inEditMode && (drivers[0].state === 'TN' || drivers[0].state === 'WI') ?
            <div className='paperless-check-wrapper'>
              <CustomFormControlLabel
                control={
                  <Checkbox
                    id='telematicsParticipationDiscount'
                    color='primary'
                    checked={telematicsDiscount}
                    onChange={handleTelematicsDiscount}
                    name='telematicsDiscount'
                    inputRef={register}
                  />
                }
                label='MAP Telematics participation discount is applied.'
              />
              <div className='paperless-billing-disclosure'>
                (Uncheck this box to remove the discount)
              </div>
            </div>
            : null
        }
        {inEditMode ?
          <div className='paperless-check-wrapper'>
            <CustomFormControlLabel
              control={
                <Checkbox
                  id='coveragesPaperlessDiscountCheckbox'
                  color='primary'
                  checked={paperlessBilling}
                  onChange={handleCheckPaperlessBilling}
                  name='paperless'
                  inputRef={register}
                />
              }
              label='Paperless discount is applied. Thanks for saving the trees!'
            />
            <div className='paperless-billing-disclosure'>
              (Uncheck this box to have a printed copy of the
              policy mailed to you and remove the discount)
            </div>
          </div>
          : null}
        {/* {
          !inEditMode ?
            <div className='included-features-wrapper'>
              <div className='included-features-header'>
                Included with your Motion policy
              </div>
              <div className='icon-row'>
                <div className='feature-item'>
                  <div>
                    <TowTruckIcon />
                  </div>
                  <div>Roadside</div>
                  <div>Assistance</div>
                </div>
                <div className='feature-item'>
                  <div>
                    <AccidentIcon />
                  </div>
                  <div>Accident</div>
                  <div>Forgiveness</div>
                </div>
                <div className='feature-item'>
                  <div>
                    <SeatBeltIcon />
                  </div>
                  <div>Safe</div>
                  <div>Driving</div>
                </div>
                <div className='feature-item'>
                  <div>
                    <BatteryIcon />
                  </div>
                  <div>Battery</div>
                  <div>Replacement</div>
                </div>
              </div>
            </div>
            : null
        } */}
      </div>
    );
  };

export default Coverages;