import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import Bugsnag from '@bugsnag/js';
import NumberFormat from 'react-number-format';
import { Button, Checkbox, CircularProgress, FormControlLabel, Grid, Tooltip, withStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PageHeader from '../components/PageHeader';
import { GetDisclosures, GetOffer, GetPaymentInfo } from '../utils/queries';
import './ReviewPolicy.scss';
import DriverSimple from '../components/DriverSimple';
import MotionToast from '../components/MotionToast';
import { Driver, Vehicle } from '../models/motion';
import VehicleSimple from '../components/VehicleSimple';
import DisclosureAccordion from '../components/DisclosureAccordion';
import { BrandContext, getBrand, getPrimaryColor, getSecondaryColor, shouldCoBrand, shouldDisplayMotionModal } from '../utils/style';
import { ReactComponent as ToolTipIcon } from '../images/tool-tip.svg';

export interface ReviewPolicyProps {

}
const CustomFormControlLabel = withStyles({
  label: {
    fontSize: '0.875em',
  }
})(FormControlLabel);

const ReviewPolicy: React.FC<ReviewPolicyProps> = () => {

  useEffect(() => {
    (window as any).nid('start', window.location.pathname);
  }, []);
  const { brand, setBrand, setPrimaryColor, setSecondaryColor, setCoBrand } = useContext(BrandContext);
  useEffect(() => {
    setBrand(getBrand());
    setPrimaryColor && setPrimaryColor(getPrimaryColor());
    setSecondaryColor && setSecondaryColor(getSecondaryColor());
    setCoBrand && setCoBrand(shouldCoBrand());
  });
  const history = useHistory();
  const motionTransactionId = window.sessionStorage.getItem('motionTransactionId');
  const driversJson = window.sessionStorage.getItem('drivers');
  const drivers = JSON.parse(driversJson!) as Driver[];
  const primaryDriver = drivers.filter(d => d.isPrimaryInsured)[0];
  const vehiclesJson = window.sessionStorage.getItem('vehicles');
  const vehicles = JSON.parse(vehiclesJson!) as Vehicle[];
  const selectedTier = window.sessionStorage.getItem('selectedTier');
  const queryParams = window.sessionStorage.getItem('queryParams') || '';
  const [expandAll, setExpandAll] = useState(false);
  const [checkedTermsAgreement, setCheckedTermsAgreement] = useState(false);

  const { data: offerData } = useQuery(
    `offer-${motionTransactionId}`,
    () => GetOffer(motionTransactionId!),
    {
      onError: (error) => {
        Bugsnag.notify({ name: 'Error fetching offer', message: error as string });
      }
    }
  );
  const { data: paymentInfoData, } = useQuery('paymentInfo', () => GetPaymentInfo({ paidInFull: false, selection: selectedTier!, rateId: motionTransactionId! }), {
    onError: (error) => {
      Bugsnag.notify({ name: 'Error fetching payment info', message: error as string });
    }
  });
  const { data: disclosureData } = useQuery(
    `disclosure-${motionTransactionId}`,
    () => GetDisclosures(motionTransactionId!, 'N', primaryDriver.non_drinker ? 'Y' : 'N', vehicles.map(v => v.vin), selectedTier!, primaryDriver.state!),
    {
      onSuccess: (data) => {
        if (data && data.errorMessage) {
          Bugsnag.notify({ name: 'Error fetching disclosures', message: data.errorMessage });
        }
      },
      onError: (error) => {
        Bugsnag.notify({ name: 'Error fetching disclosures', message: error as string });
      }
    }
  );
  const handleExpandAllClick = () => {
    setExpandAll(!expandAll);
  };
  const handleCheckTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedTermsAgreement(event.target.checked);
  };
  const handleSaveAndContinue = () => {
    const eSignature = window.sessionStorage.getItem('iAgreeESignature');
    const signature = eSignature ? JSON.parse(eSignature) : false;
    window.scrollTo(0, 0);
    if (signature) {
    history.push(`/payment${queryParams}`);
    } else {
      handleDisplayToast('warning', "Electronic Signature must to be agreed upon before proceeding, click the 'I Agree' button below the 'Electronic Signature'.");
    }
  };
  const handleEditCoverage = () => {
    history.push(`/quote${queryParams}`);
  };

  const [quoteSubmitMessage, setQuoteSubmitMessage] = useState('');
  const [quoteSubmitToast, setQuoteSubmitToast] = useState(false);
  const [createAccountSeverity, setCreateAccountSeverity] = useState('success' as 'success' | 'error' | 'info' | 'warning');
  const handleDisplayToast = (severity: 'success' | 'error' | 'info' | 'warning', message: string) => {
    setCreateAccountSeverity(severity);
    setQuoteSubmitToast(true);
    setQuoteSubmitMessage(message);
    setTimeout(() => {
      setQuoteSubmitToast(false);
    }, 6000);
  };

  if (!offerData) {
    return (
      <>
        <Helmet>
          <title>Review Policy - Motion Insurance</title>
        </Helmet>
        <PageHeader
          icon='circularProgress'
          header='Preparing for review...'
          description=''
        />
        <div style={{ marginBottom: '50em' }} />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{`Review Policy - ${brand === 'novo' ? 'Novo Insurance' : 'Motion Insurance'}`}</title>
        {
          brand === 'novo' ?
            <link rel='icon' href='/novo-fav.ico' />
            :
            <link rel='icon' href='/favicon.ico' />
        }
      </Helmet>
      <MotionToast
        severity={createAccountSeverity as 'success' | 'error' | 'info' | 'warning'}
        message={quoteSubmitMessage}
        open={quoteSubmitToast}
      />
      <PageHeader
        icon='motionShield'
        header='Review your policy'
        description='Please review the policy purchase summary below.'
      />
      <div className='payment-disclosure-wrapper'>
        <div className='payment-row'>
          <Grid container spacing={1}>
            <Grid container item xs={12} lg={12} sm={12} md={6} spacing={3}>
              <div className='payment-today' style={{ marginLeft: '9px' }}>
                Price includes all applicable fees&nbsp;<Tooltip
                  enterTouchDelay={0}
                  leaveTouchDelay={3500}
                  style={{ marginTop: '7px' }}
                  className='tooltip'
                  title='Applicable fees could include installment fees, state mandated fees, and SR22 filing fees.'
                  placement='top-start'
                >
                  <ToolTipIcon />
                </Tooltip>&nbsp;:
              </div>
            </Grid>
          </Grid>
          <div className='payment-today-amount'>
            <NumberFormat
              value={paymentInfoData?.initialPayment}
              displayType='text'
              prefix='$'
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </div>
        </div>
        <div className='payment-row'>
          <div className='payment-info'>
            Next payment due
          </div>
          <div className='payment-info'>
            <NumberFormat
              value={paymentInfoData?.monthlyPayment}
              displayType='text'
              prefix='$'
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </div>
        </div>
        <div className='payment-row'>
          <div className='payment-info'>
            <NumberFormat
              value={offerData.offer.policy_term}
              displayType='text'
              suffix=' month total'
            />
          </div>
          <div className='payment-info'>
            <NumberFormat
              value={paymentInfoData?.planTotal}
              displayType='text'
              prefix='$'
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </div>
        </div>
        <div className='payment-row'>
          <div className='payment-info' />
          <div className='payment-info'>
            Price includes all fees
          </div>
        </div>
        <div className='payment-row'>
          <div className='payment-info' />
          <div className='edit-coverage-wrapper'>
            <Button
              id='editCoverageButton'
              onClick={handleEditCoverage}
              color='secondary'
              variant='contained'
            >
              Edit Coverage
            </Button>
          </div>
        </div>
      </div>
      <div className='application-review-wrapper'>
        <div className='application-header'>
          Application for insurance below
        </div>
        <div className='application-section-header'>
          Drivers
        </div>
        <div className='application-section-contents'>
          {
            drivers.map(driver => <DriverSimple key={driver.birthDate?.toString()} driver={driver} />)
          }
        </div>
        <div className='application-section-header'>
          Vehicles
        </div>
        <div className='application-section-contents'>
          {
            vehicles.map(vehicle => <VehicleSimple key={vehicle.vin} vehicle={vehicle} />)
          }
        </div>
      </div>
      <div className='disclosures-wrapper'>
        <div className='disclosures-header'>
          <div className='disclosures-header-left'>
            <div className='disclosures-spacer'>
              Disclosures
            </div>
            {
              !disclosureData &&
              <CircularProgress size={30} />
            }
          </div>
          <div>
            <button
              id='expandAllSections'
              className='expand-all-button'
              type='button'
              onClick={handleExpandAllClick}
              style={{ color: getPrimaryColor() }}
            >
              {!expandAll ? 'Expand all' : 'Collapse all'}
            </button>
          </div>
        </div>
        <div>
          {
            disclosureData && disclosureData.disclosures && disclosureData.disclosures.map(
              (d: { disclosureTitle: string, disclosureText: string[] }, index: number) =>
                <DisclosureAccordion
                  key={d.disclosureTitle}
                  disclosure={d}
                  isExpanded={expandAll}
                  index={index}
                  primaryDriver={primaryDriver}
                />
            )
          }
        </div>
      </div>
      <div className='terms-agreement-wrapper'>
        <CustomFormControlLabel
          control={
            <Checkbox
              id='disclosureCheckBox'
              color='primary'
              onChange={handleCheckTerms}
              checked={checkedTermsAgreement}
            />
          }
          label={
            <div className='terms-statement'>
              I understand the disclosures above and agree to all of the
              terms therein. I understand and agree that selection of
              any of the above options shall be binding on all persons
              insured under the policy, and that this selection shall
              apply to this policy and any renewal, reinstatement,
              substitute, amended, altered, modified, or replacement
              policy with this company or any affiliated company,
              unless a named insured revokes this selection or selects
              a different option.
            </div>
          }
        />
      </div>
      <div className='button-wrapper'>
        <Button
          id='reviewPolicyBackButton'
          onClick={handleEditCoverage}
          color='secondary'
          variant='contained'
        >
          Back
        </Button>
        <div className='save-and-continue'>
          {
            checkedTermsAgreement && disclosureData ?
              <Button
                id='reviewPolicyContinueButton'
                onClick={handleSaveAndContinue}
                color='primary'
                variant='contained'
              >
                Save & continue
              </Button>
              :
              <Button
                id='reviewPolicyContinueButton'
                disabled
                color='primary'
                variant='contained'
              >
                Save & continue
              </Button>
          }
        </div>
      </div>
    </>
  );
};

export default ReviewPolicy;