import { MenuItem } from '@material-ui/core';
import React from 'react';
import NumberFormat from 'react-number-format';
import { occupations } from '../models/occupations';
import { State, usStateList } from '../models/stateCodes';


export const monthList: State[] = [
  { display: 'January', value: '01' },
  { display: 'February', value: '02' },
  { display: 'March', value: '03' },
  { display: 'April', value: '04' },
  { display: 'May', value: '05' },
  { display: 'June', value: '06' },
  { display: 'July', value: '07' },
  { display: 'August', value: '08' },
  { display: 'September', value: '09' },
  { display: 'October', value: '10' },
  { display: 'November', value: '11' },
  { display: 'December', value: '12' },
];

export const getFormattedLimitWithRange = (limit: string) => {
  const limitSplit = limit.split('/');
  return `$${limitSplit[0]}k/$${limitSplit[1]}k`;
};

export const getFormattedRentalLimit = (limit: string) => {
  const limitSplit = limit.split(' ') || [];
  const numbers = limitSplit.filter(ls => !isNaN(parseInt(ls, 10)));
  return (
    limit.toLowerCase() === 'none' || limit.toLowerCase() === 'declined' ?
      <MenuItem key={limit} value={limit}>
        Declined
      </MenuItem>
      :
      <MenuItem key={limit} value={limit}>
        <NumberFormat
          value={numbers[0]}
          displayType='text'
          prefix='$'
          suffix=' per day'
          thousandSeparator={true}
        />
        &nbsp;
        <NumberFormat
          value={numbers[1]}
          displayType='text'
          prefix='$'
          suffix=' max'
          thousandSeparator={true}
        />
      </MenuItem>
  );
};

export const getMenuListItemWithRange = (limit: string) => {
  const formattedLimit = getFormattedLimitWithRange(limit);
  return (
    limit.toLowerCase() === 'declined' || limit.toLowerCase() === 'none' ?
      <MenuItem key={limit} value={limit}>
        Declined
      </MenuItem>
      :
      <MenuItem key={limit} value={limit}>
        {formattedLimit}
      </MenuItem>
  );
};

export const getMenuListItemWithDeductible =
  (limit: string, prefix?: string, suffix?: string) => {
    const limitSplit = limit.split(' ');
    const hasConditionalGlass = limitSplit.some(ls => ls.toLowerCase() === 'glass');

    if (hasConditionalGlass) {
      return (
        <MenuItem key={limit} value={limit}>
          <NumberFormat
            value={limitSplit[0]}
            displayType='text'
            prefix='$'
            suffix=' w/ $0 glass'
            thousandSeparator={true}
          />
        </MenuItem>
      );
    }
    return (
      limit.toLowerCase() === 'declined' || limit.toLowerCase() === 'none' ?
        <MenuItem key={limit} value={limit}>
          Declined
        </MenuItem>
        :
        <MenuItem key={limit} value={limit}>
          <NumberFormat
            value={limitSplit[0]}
            displayType='text'
            prefix={prefix || ''}
            suffix={suffix || ''}
            thousandSeparator={true}
          />
        </MenuItem>
    );
  };

export const getMenuListItem =
  (limit: string, prefix: string, suffix: string) => {
    return (
      limit.toLowerCase() === 'declined' || limit.toLowerCase() === 'none' ?
        <MenuItem key={limit} value={limit}>
          Declined
        </MenuItem>
        :
        <MenuItem key={limit} value={limit}>
          <NumberFormat
            value={limit}
            displayType='text'
            prefix={prefix}
            suffix={suffix}
            thousandSeparator={true}
          />
        </MenuItem>
    );
  };

export const getPrimaryUseMenuList = () => {
  return ['Business', 'Commute', 'Delivery', 'Farm', 'Pleasure'].map((g) => {
    return (
      <MenuItem key={g} value={g}>
        {g}
      </MenuItem>
    );
  });
};

export const getStateMenuList = () => {
  return usStateList.map((s) => {
    return (
      <MenuItem key={s.value} value={s.value}>
        {s.display}
      </MenuItem>
    );
  });
};

export const getMenuListWithoutFormatting = (limit: string) => {
  return (
    <MenuItem key={limit} value={limit}>
      {limit}
    </MenuItem>
  );
};

export const getMenuListWithDisplayAndValue =
  (item: { display: string, value: string }) => {
    return (
      <MenuItem key={JSON.stringify(item)} value={item.value}>
        {item.display}
      </MenuItem>
    );
  };

export const educationOptions = [
  'No high school diploma or GED',
  'High school diploma or GED',
  'Vocational/trade degree/ military training',
  'Some college',
  'Currently in college',
  'College degree',
  'Graduate work or graduate degree',
  'Unknown',
];
export const getEductionMenuList = () => {
  return educationOptions.map((g) => {
    return (
      <MenuItem key={g} value={g}>
        {g}
      </MenuItem>
    );
  });
};

export const occupationOptions = occupations;
export const getOccupationMenuList = () => {
  return occupationOptions.map((o) => {
    return (
      <optgroup key={o.title} label={o.title}>
        {o.options.map((op) => {
          return (
            <option key={`${o.title} ${op}`} value={`${o.title} ${op}`}>
              {op}
            </option>
          );
        })}
      </optgroup>
    );
  });
};

export const getYesNotMenuList = () => {
  return [
    'Yes', 'No'
  ].map((g) => {
    return (
      <MenuItem key={g} value={g}>
        {g}
      </MenuItem>
    );
  });
};

export const getCurrentBiLimitsMenuList = () => {
  return [
    '15k/30k',
    '25k/50k',
    '50k/100k',
    '100k/300k',
    '250k/500k',
    '500k/500k',
    'I don\'t know',
  ].map((g) => {
    return (
      <MenuItem key={g} value={g}>
        {g}
      </MenuItem>
    );
  });
};

export const priorCoverageBiLimits: any = { 15: 30, 25: 50, 50: 100, 100: 300, 250: 500, 500: 500 }

export const getCurrentProviderMenuList = () => {
  return [
    '21st Century',
    'AAA',
    'Allstate',
    'American Family',
    'Bear River',
    'Clearcover',
    'Elephant',
    'Erie Insurance',
    'Esurance',
    'Farmers',
    'Geico',
    'Liberty Mutual',
    'Mercury',
    'Metlife',
    'Nationwide',
    'Progressive',
    'Root',
    'Safe Auto',
    'Safeco',
    'State Farm',
    'The General',
    'Travelers',
    'USAA',
    'Other',
  ].map((g) => {
    return (
      <MenuItem key={g} value={g}>
        {g}
      </MenuItem>
    );
  });
};

export const getSixSixtyMonthMenuList = () => {
  return [
    '6 months', '7 months', '8 months', '9 months', '10 months', 
    '11 months', '12 months', '13 months', '14 months', '15 months', 
    '16 months', '17 months', '18 months', '19 months', '20 months', 
    '21 months', '22 months', '23 months', '24 months', '25 months', 
    '26 months', '27 months', '28 months', '29 months', '30 months', 
    '31 months', '32 months', '33 months', '34 months', '35 months', 
    '36 months', '37 months', '38 months', '39 months', '40 months', 
    '41 months', '42 months', '43 months', '44 months', '45 months', 
    '46 months', '47 months', '48 months', '49 months', '50 months', 
    '51 months', '52 months', '53 months', '54 months', '55 months', 
    '56 months', '57 months', '58 months', '59 months', '60 months'
  ].map((g) => {
    return (
      <MenuItem key={g} value={g}>
        {g}
      </MenuItem>
    );
  });
};

export const getOneFiveMonthMenuList = () => {
  return [
    '1 month', 
    '2 months', 
    '3 months', 
    '4 months', 
    '5 months'
  ].map((g) => {
    return (
      <MenuItem key={g} value={g}>
        {g}
      </MenuItem>
    );
  });
}

export const getdeployedMonthMenuList = () => {
  return [
    '1 month', 
    '2 months', 
    '3 months', 
    '4 months', 
    '5 months',
    '6 or more months'
  ].map((g) => {
    return (
      <MenuItem key={g} value={g}>
        {g}
      </MenuItem>
    );
  });
}

export const selectionMonthList = () => {
  return monthList.map((s: any) => {
    return (
      <MenuItem key={s.value} value={s.value}>
        {s.display}
      </MenuItem>
    );
  });
}

export const selectionYearList = () => {
  const currentYear = new Date().getFullYear()
  const years = []
  for (let i = currentYear; i > (currentYear - 6); i--) {
    if (i === (currentYear - 5)) {
      years.push(i + ' or earlier')
    } else {
      years.push(i)
    }
  }
  return years.map((s: any) => {
    return (
      <MenuItem key={s} value={s}>
        {s}
      </MenuItem>
    );
  });
}

export const relationshipStatusOptions = [
  'Single',
  'Married',
  'Widowed',
  'Divorced'
];
export const getRelationshipStatusMenuList = () => {
  return relationshipStatusOptions.map((g) => {
    return (
      <MenuItem key={g} value={g}>
        {g}
      </MenuItem>
    );
  });
};

export const residenceTypeOptions = [
  'Own home',
  'Own manufactured/ mobile home',
  'Rent',
  'Other'
];
export const getResidenceTypeMenuList = () => {
  return residenceTypeOptions.map((g) => {
    return (
      <MenuItem key={g} value={g}>
        {g}
      </MenuItem>
    );
  });
};

export const lengthOfResidencyOptions = [
  '0-11 months',
  '12 or more months'
];
export const getLengthOfResidencyMenuList = () => {
  return lengthOfResidencyOptions.map((g) => {
    return (
      <MenuItem key={g} value={g}>
        {g}
      </MenuItem>
    );
  });
};

export const genderOptions = [
  'Female',
  'Male'
];
export const getGenderMenuList = () => {
  return genderOptions.map((g) => {
    return (
      <MenuItem key={g} value={g}>
        {g}
      </MenuItem>
    );
  });
};