import { Driver } from '../models/motion';

export const identifyPotentialPrimaryInsured = (primaryInsured: Driver, prefillDrivers: Driver[]) => {
  const result: Driver[] = [];

  if (!prefillDrivers || prefillDrivers.length === 0) {
    return result;
  }

  prefillDrivers.forEach((prefillDriver: Driver) => {
    let points = 0;
    if (prefillDriver.firstName?.toLocaleLowerCase() === primaryInsured?.firstName?.toLowerCase()) {
      points += 2;
    }
    if (prefillDriver.lastName?.toLocaleLowerCase() === primaryInsured?.lastName?.toLowerCase()) {
      points += 2;
    }
    if (prefillDriver.address?.toLocaleLowerCase() === primaryInsured?.address?.toLowerCase()) {
      points += 1;
    }
    if (prefillDriver.city?.toLocaleLowerCase() === primaryInsured?.city?.toLowerCase()) {
      points += 1;
    }
    if (prefillDriver.state?.toLocaleLowerCase() === primaryInsured?.state?.toLowerCase()) {
      points += 1;
    }
    if (prefillDriver.zip?.toLocaleLowerCase() === primaryInsured?.zip?.toLowerCase()) {
      points += 1;
    }

    result.push({
      ...prefillDriver,
      prefillPoints: points,
    });
  });

  return result.sort((first, second) => { return second.prefillPoints! - first.prefillPoints!; });
};

export const mergeDriverPrefill = (prefillDrivers: any[], indexOfPni: number, primaryInsured: Driver) => {
  const mergedDrivers = [];

  // merge primary driver and add to list
  mergedDrivers.push({
    ...prefillDrivers[indexOfPni],
    ...primaryInsured,
  });

  // add remaining prefill drivers
  prefillDrivers.forEach((p, index) => {
    if (index !== indexOfPni) {
      mergedDrivers.push({
        ...p,
        canBeRemoved: true,
        address: primaryInsured.address,
        apartmentNumber: primaryInsured.apartmentNumber,
        city: primaryInsured.city,
        state: primaryInsured.state,
        zip: primaryInsured.zip,
        gender: p.gender?.toLowerCase() === 'male' ? 'Male' : 'Female',
        stateLicensed: p.licenseState?.toUpperCase(),
      });
    }
  });

  window.sessionStorage.setItem('drivers', JSON.stringify(mergedDrivers));

  return mergedDrivers;
};