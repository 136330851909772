import { TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import PlacesAutocomplete, {
  geocodeByAddress
} from 'react-places-autocomplete';
import { getLightBackgroundColor } from '../utils/style';
import './PlacesAutoComplete.scss';

export interface PlacesAutoCompleteProps {
  name: string;
  id: string;
  label: string;
  defaultValue: string;
  error: boolean;
  helperText: string;
  setStreetNumber: React.Dispatch<React.SetStateAction<string>>;
  setStreetName: React.Dispatch<React.SetStateAction<string>>;
  setCity: React.Dispatch<React.SetStateAction<string>>;
  setState: React.Dispatch<React.SetStateAction<string>>;
  setZipCode: React.Dispatch<React.SetStateAction<string>>;
}

const PlacesAutoComplete: React.FC<PlacesAutoCompleteProps> = ({
  name,
  id,
  label,
  defaultValue,
  error,
  helperText,

  setStreetNumber,
  setStreetName,
  setCity,
  setState,
  setZipCode,
}) => {
  const [address, setAddress] = useState('');
  const methods = useFormContext();
  const { control } = methods;

  const handleSelect = async (value: string) => {
    const results = await geocodeByAddress(value);
    let streetNumber = '';
    let streetName = '';
    let city = '';
    let state = '';
    let zipCode = '';
    results[0].address_components.forEach((component) => {
      if (component.types.some((c: string) => c.includes('street_number'))) {
        streetNumber = component.long_name;
      }
      if (component.types.some((c: string) => c.includes('route'))) {
        streetName = component.long_name;
      }
      if (component.types.some((c: string) => c.includes('locality'))) {
        city = component.long_name;
      }
      if (component.types.some((c: string) => c.includes('administrative_area_level_1'))) {
        state = component.short_name;
      }
      if (component.types.some((c: string) => c.includes('postal_code') && !c.includes('suffix'))) {
        zipCode = component.long_name;
      }
    });

    setStreetNumber(streetNumber);
    setStreetName(streetName);
    setCity(city);
    setState(state);
    setZipCode(zipCode);
  };

  return (
    <div>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        as={(
          <PlacesAutocomplete
            value={address}
            onChange={setAddress}
            onSelect={handleSelect}
            searchOptions={{ types: ['address'] }}
            debounce={300}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }: any) => (
              <div>
                <TextField
                  id={id}
                  error={error}
                  helperText={helperText}
                  label={label}
                  type='text'
                  fullWidth
                  variant='outlined'
                  {...getInputProps()}
                />


                {
                  suggestions.length > 0 ?
                    <div className='places-auto-complete-dropdown-wrapper'>
                      {loading ? <div>loading...</div> : null}
                      <div>
                        {suggestions.map((suggestion: any) =>
                          <div
                            className={suggestion.active ? 'active-option google-option' : 'google-option'}
                            {...getSuggestionItemProps(suggestion)}
                            key={suggestion.description}
                            style={{ background: suggestion.active ? getLightBackgroundColor() : '' }}
                          >
                            {suggestion.description}
                          </div>
                        )}
                      </div>
                    </div>
                    : null
                }
              </div>
            )}
          </PlacesAutocomplete>
        )}
      />
    </div>
  );
};

export default PlacesAutoComplete;