import { MakePaymentInput } from '../models/motion';
import { getCardConnectToken } from './cardConnect';
import { paymentBind } from './mutations';

export interface PaymentRequestItem {
  label: string;
  amount: string;
  currency: 'USD';
}

export interface PaymentRequestPayload {
  totalLabel: string;
  totalAmount: string;
  currency: 'USD';
  items: PaymentRequestItem[];
}


export const initPaymentRequest =
  ({ currency, totalAmount, totalLabel, items }: PaymentRequestPayload) => {
    const networks = ['amex', 'discover', 'mastercard', 'visa'];
    const types = ['debit', 'credit', 'prepaid'];
    const supportedInstruments = [{
      supportedMethods: 'basic-card',
      data: { supportedNetworks: networks, supportedTypes: types },
    }];

    const details = {
      total: { label: totalLabel, amount: { currency, value: totalAmount } },
      displayItems: items.map(i => {
        return {
          label: i.label,
          amount: { currency: i.currency, value: i.amount }
        };
      }),
    };

    const options = {
      requestPayerName: false,
      requestPayerPhone: false,
      requestPayerEmail: false,
    };

    return new PaymentRequest(supportedInstruments, details, options);
  };

export interface PaymentRequestPolicyInfo {
  selection: string;
  paidInFull: boolean;
  rateId: string;
}

export const paymentRequestClicked = (request: PaymentRequest, policyInfo: PaymentRequestPolicyInfo, setPaymentRequestSuccess: React.Dispatch<React.SetStateAction<boolean>>) => {
  request.show()
    .then(async (instrumentResponse) => {
      const expiry = `${instrumentResponse.details.expiryMonth}${instrumentResponse.details.expiryYear.slice(-2)}`;
      // get card token from card pointe 
      const tokenResponse = await getCardConnectToken({
        account: instrumentResponse.details.cardNumber,
        cvv: instrumentResponse.details.cardSecurityCode,
        expiry,
      });
      // generate payload for payment call
      const payload = {
        selection: policyInfo.selection,
        paidInFull: policyInfo.paidInFull,
        payment: {
          token: tokenResponse.token,
          expiry,
          name: instrumentResponse.details.cardholderName,
          address: instrumentResponse.details.billingAddress.addressLine[0],
          city: instrumentResponse.details.billingAddress.city,
          postalCode: instrumentResponse.details.billingAddress.postalCode,
        },
        rateId: policyInfo.rateId,
      } as MakePaymentInput;
      window.sessionStorage.setItem('makePaymentInput', JSON.stringify(payload));
      // process payment call
      const paymentResponseData = await paymentBind(payload);
      if ((paymentResponseData && paymentResponseData.success)
        || (paymentResponseData && !paymentResponseData.success && paymentResponseData.errorType === 'bind')) {
        window.sessionStorage.setItem('paymentResponse', JSON.stringify(paymentResponseData));
        setPaymentRequestSuccess(true);
        return instrumentResponse.complete('success');
      }

      if (paymentResponseData
        && !paymentResponseData.success
        && paymentResponseData.errorType === 'payment') {
        return instrumentResponse.complete('fail');
      }
    })
    .catch((err) => {
      // user closed 
      console.error(err);
    });
};

export const canMakePayment = async () => {
  const payload = {
    currency: 'USD',
    totalAmount: '0.00',
    totalLabel: 'verify can make payment',
    items: [
      {
        currency: 'USD',
        amount: '0.00',
        label: 'verification item',
      }
    ]
  } as PaymentRequestPayload;
  const request = initPaymentRequest(payload);

  try {
    if ((window as any).PaymentRequest !== undefined) {
      const result = await request.canMakePayment();
      return result;
    }
  } catch (error) {
    // do nothing
  }
  return false;
};