import React, { useContext } from 'react';
import { CircularProgress } from '@material-ui/core';
import { ReactComponent as Shield } from '../images/shield-check.svg';
import { ReactComponent as ShieldNovo } from '../images/shield-check-novo.svg';
import { ReactComponent as MotionShield } from '../images/shield-motion-icon.svg';
import { ReactComponent as AvatarSuccess } from '../images/avatar-success.svg';
import { ReactComponent as AvatarSuccessNovo } from '../images/avatar-success-novo.svg';
import { ReactComponent as CreditCard } from '../images/credit-card-icon-purple.svg';
import { ReactComponent as CreditCardNovo } from '../images/credit-card-icon-novo.svg';
import { ReactComponent as CarCheck } from '../images/car-check-icon.svg';
import { ReactComponent as CarCheckNovo } from '../images/car-check-icon-novo.svg';
import './PageHeader.scss';
import { BrandContext } from '../utils/style';

export interface PageHeaderProps {
  icon: string;
  header: string;
  description: string | any;
}

const PageHeader: React.FC<PageHeaderProps> =
  ({ icon, header, description }) => {
    const { brand } = useContext(BrandContext);
    return (
      <div className='header-wrapper'>
        <div className='title-wrapper'>
          <div>
            {icon === 'motionShield' && brand === 'novo' ? <ShieldNovo /> : null}
            {icon === 'motionShield' && brand === 'motion' ? <MotionShield /> : null}
            {icon === 'shield' && brand === 'novo' ? <ShieldNovo /> : null}
            {icon === 'shield' && brand === 'motion' ? <Shield /> : null}
            {icon === 'avatarSuccess' && brand === 'novo' ? <AvatarSuccessNovo /> : null}
            {icon === 'avatarSuccess' && brand === 'motion' ? <AvatarSuccess /> : null}
            {icon === 'circularProgress' ? <CircularProgress /> : null}
            {icon === 'creditCard' && brand === 'novo' ? <CreditCardNovo /> : null}
            {icon === 'creditCard' && brand === 'motion' ? <CreditCard /> : null}
            {icon === 'carCheck' && brand === 'novo' ? <CarCheckNovo /> : null}
            {icon === 'carCheck' && brand === 'motion' ? <CarCheck /> : null}
          </div>
          <span className='header-title'>
            {header}
          </span>
        </div>
        <span className='header-description'>{description}</span>
      </div>);
  };

export default PageHeader;