import React from 'react';
import { Offer, Vehicle } from '../models/motion';
import './VehicleCoverages.scss';
import VehicleCoverage from './VehilceCoverage';

export interface VehicleCoveragesProps {
  tier: string;
  offer: Offer;
  limits: any;
}

const VehicleCoverages: React.FC<VehicleCoveragesProps> =
  ({ tier, offer, limits }) => {
    const vehiclesJson = window.sessionStorage.getItem('vehicles');
    const vehicles = JSON.parse(vehiclesJson!) as Vehicle[];
    const factors = offer[`${tier}_deductible`];

    return (
      <div className='vehicle-coverages-wrapper'>
        {vehicles.map((vehicle, index: number) => {
          let currFactors;
          if (Array.isArray(factors)) {
            [currFactors] = factors.filter(f => f.vin === vehicle.vin);
          } else {
            currFactors = factors;
          }
          return (
            <VehicleCoverage
              key={vehicle.vin}
              vehicle={vehicle}
              factors={currFactors}
              tier={tier}
              index={index}
              limits={limits}
            />);
        })}
      </div>
    );
  };

export default VehicleCoverages;