import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button, CircularProgress, createStyles, IconButton, makeStyles, Modal, Theme, Tooltip, withStyles } from '@material-ui/core';
import { PhotoCamera } from '@material-ui/icons';
import { isDesktop } from 'react-device-detect';

import './IdScan.scss';
import { ScanId } from '../utils/mutations';
import { populatePniFromDriverLicense } from '../utils/driverLicense';
import MotionToast from './MotionToast';
import { ReactComponent as ToolTipIcon } from '../images/tool-tip.svg';

export interface IdScanProps {

}

const MotionModal = withStyles({
  root: {
    position: 'absolute',
    width: 400,
    backgroundColor: 'white',
    border: '2px solid #000',
    padding: '2em',
  },
})(Modal);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        // margin: theme.spacing(1),
      },
    },
    input: {
      display: 'none',
    },
  }),
);

const IdScan: React.FC<IdScanProps> = () => {
  const classes = useStyles();
  const [autoImport, setAutoImport] = useState(false);
  const [pictureData, setPictureData] = useState<string>();
  const [base64Encoding, setBase64Encoding] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { setValue } = useFormContext();

  const handleImageSubmit = async () => {
    if (!pictureData || !base64Encoding) {
      handleDisplayToast('error', 'Oops, it doesn\'t look like you have taken a picture yet.');
      return;
    }
    setIsSubmitting(true);
    let idData;
    try {
      idData = await ScanId(base64Encoding);
    } catch (error) {
      setIsSubmitting(false);
      handleDisplayToast('error', 'Oops, it looks something went wrong. Please make sure the image isn\'t blurry and the ID\'s barcode is clearly visible.');
    }
    if (idData) {
      window.sessionStorage.setItem('idScan', JSON.stringify(idData));

      // auto fill pni driver 
      const primaryDriver = populatePniFromDriverLicense(idData);
      setValue('firstName', primaryDriver.firstName, { shouldDirty: true });
      setValue('lastName', primaryDriver.lastName, { shouldDirty: true });
      setValue('address', primaryDriver.address, { shouldDirty: true });
      setValue('apartmentNumber', primaryDriver.apartmentNumber, { shouldDirty: true });
      setValue('city', primaryDriver.city, { shouldDirty: true });
      setValue('state', primaryDriver.state, { shouldDirty: true });
      setValue('zip', primaryDriver.zip, { shouldDirty: true });

      setIsSubmitting(false);
      setAutoImport(false);
      handleDisplayToast('success', 'Successfully imported info from your driver\'s license!', 3000);
    }
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);

      reader.addEventListener('load', () => {
        if (reader.result) {
          setPictureData(reader.result.toString());
          setBase64Encoding(reader.result.toString().split('base64,')[1]);
        }
      });
    }
  };

  const [idScanErrorMessage, setIdScanErrorMessage] = useState('');
  const [idScanErrorToast, setIdScanErrorToast] = useState(false);
  const [createAccountSeverity, setCreateAccountSeverity] = useState('success' as 'success' | 'error' | 'info' | 'warning');

  const handleDisplayToast = (severity: 'success' | 'error' | 'info' | 'warning', message: string, ttl = 6000) => {
    setCreateAccountSeverity(severity);
    setIdScanErrorToast(true);
    setIdScanErrorMessage(message);
    setTimeout(() => {
      setIdScanErrorToast(false);
    }, ttl);
  };

  return (
    <>
      <MotionToast
        severity={createAccountSeverity as 'success' | 'error' | 'info' | 'warning'}
        message={idScanErrorMessage}
        open={idScanErrorToast}
      />
      <MotionModal
        open={autoImport}
        onClose={() => { }}
      >
        <>
          <div className='id-scan-modal-wrapper'>
            {
              autoImport && !pictureData &&
              <div>
                <div className='id-scan-image-row id-scan-message'>
                  Take a picture of the back of your driver's license. For best results image must be clear without any glares. If your image is too blurry autofill will fail.
                </div>
              </div>
            }
            {
              pictureData &&
              <div className='id-scan-image-wrapper'>
                <div className='id-scan-image-column'>
                  <div className='id-scan-image-wrapper'>
                    <img
                      src={pictureData}
                      alt='idPicture'
                      className='id-scan-image'
                    />
                  </div>
                  <div className='id-scan-image-row'>
                    Submit a clear image of the back of your Driver License for best results
                  </div>
                </div>
              </div>
            }
            <div className='id-scan-button-wrapper'>
              <Button
                id='idScanCancel'
                color='secondary'
                variant='contained'
                onClick={() => setAutoImport(false)}
              >
                Cancel
              </Button>
              <input
                accept='image/*'
                className={classes.input}
                id='id-image-upload'
                type='file'
                onChange={(e) => handleImageUpload(e)}
              />
              {
                !isSubmitting && !pictureData &&
                <div className='id-scan-spacer'>
                  <div className={classes.root}>
                    <label htmlFor='id-image-upload'>
                      <IconButton
                        color='primary'
                        aria-label='upload picture'
                        component='span'
                        id='idScanTakePhoto'
                      >
                        <PhotoCamera />
                      </IconButton>
                    </label>
                  </div>
                </div>
              }

              {
                pictureData && !isSubmitting &&
                <div className='id-scan-spacer'>
                  <label htmlFor='id-image-upload'>
                    <IconButton
                      color='primary'
                      aria-label='upload picture'
                      component='span'
                      id='idScanRetakePhoto'
                    >
                      <PhotoCamera />
                    </IconButton>
                  </label>
                </div>
              }


              <div className='id-scan-spacer'>
                <Button
                  id='idScanSubmit'
                  color='primary'
                  variant='contained'
                  onClick={handleImageSubmit}
                  disabled={!pictureData || isSubmitting}
                >
                  Submit
                </Button>
              </div>
              {
                isSubmitting &&
                <div className='id-scan-spacer'>
                  <CircularProgress />
                </div>
              }
            </div>
          </div>
        </>
      </MotionModal>
      {
        (!isDesktop) &&
        <div className='id-scan-wrapper'>
          <div>
            <Button id='idScanButton' color='primary' variant='contained' onClick={() => setAutoImport(true)}>Autofill with ID</Button>
          </div>
          <div>
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={3500}
              className='tooltip'
              title="Use the barcode on the back of your driver's license to auto-fill some of the form fields needed to provide you an insurance quote"
              placement='top-start'
            >
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ marginLeft: '0.5em', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <ToolTipIcon />
                </div>
              </div>
            </Tooltip>
          </div>
        </div>
      }
    </>
  );
};

export default IdScan;