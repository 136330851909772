import axios from 'axios';

export interface CardConnectTokenVariables {
  account: string; // 
  cvv: string; // 3 to 4 chars
  expiry: string; // MMYY
}

export const getCardConnectToken =
  async (payload: CardConnectTokenVariables) => {
    const response = await axios.post(`${process.env.REACT_APP_CARD_POINTE_HOST}/cardsecure/api/v1/ccn/tokenize`, payload);

    return response.data;
  };
