import React, { useContext } from 'react';

import './ErrorPage.scss';
import { ReactComponent as CarFlatTire } from '../images/car-flat-tire-icon.svg';
import { ReactComponent as CarFlatTireNovo } from '../images/car-flat-tire-novo-icon.svg';
import { BrandContext, getPhoneNumber } from '../utils/style';

export interface ErrorInternalProps {
  errorCode?: number;
}

const ErrorInternal: React.FC<ErrorInternalProps> = ({ errorCode }) => {
  const { brand, primaryColor } = useContext(BrandContext);
  const phoneNumber = getPhoneNumber();

  return (
    <div className='error-row-wrapper'>
      <div className='error-column-wrapper'>
        <div>
          {
            brand === 'motion' ?
              <CarFlatTire />
              :
              <CarFlatTireNovo />
          }
        </div>
        <div className='error-message'>
          Oops... This page doesn't seem to be working.
          We apologize for any inconvenience.
        </div>
        <div className='customer-service-prompt'>
          <div className='customer-service-question'>
            Need to talk with someone now?
          </div>
          <div>
            Call customer service <a style={{ color: primaryColor }} href={`tel:${phoneNumber}`}>{phoneNumber}</a>
          </div>
        </div>
        <div className='error-filler' />
      </div>
    </div>
  );
};

export default ErrorInternal;
