/* eslint-disable no-underscore-dangle */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Bugsnag from '@bugsnag/js';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import './Payment.scss';
import { Button, CircularProgress } from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ReactComponent as LockIcon } from '../images/lock.svg';
import PageHeader from '../components/PageHeader';
import { Driver, MakePaymentInput } from '../models/motion';
import { GetOffer, GetPaymentInfo } from '../utils/queries';
import { paymentBind } from '../utils/mutations';
import { sendActiveCampaignEvent } from '../utils/activeCampaign';
import MotionToast from '../components/MotionToast';
import { BrandContext, getPhoneNumber } from '../utils/style';
import PaymentOptions from '../components/PaymentOptions';
import BillingInformation from '../components/BillingInformation';
import CreditCardDetails from '../components/CreditCardDetails';
import PaymentRequestApiButton from '../components/PaymentRequestApiButton';

export interface PaymentProps {
  setNavigationProgress(): void;
}


const schema = yup.object().shape({
  firstName: yup.string().required('Please enter the first name as is appears on the card').trim(),
  lastName: yup.string().required('Please enter the last name as is appears on the card').trim(),
  address: yup.string().required('Please enter the billing address').trim(),
  city: yup.string().required('Please enter the billing city').trim(),
  state: yup.string().required('Please enter the billing state').trim(),
  zip: yup.string()
    .min(5, 'Please enter a valid zip code')
    .required('Please enter the billing zip')
    .trim(),
  creditCardNumber: yup.string()
    .min(18, 'Please enter a valid credit card number')
    .required('Please enter a valid credit card number')
    .trim(),
  creditCardExpiration: yup.string()
    .min(5, 'Please enter the expiration date for the card')
    .required('Please enter the expiration date for the card')
    .trim(),
  cvv: yup.string()
    .min(3, 'Please enter the CCV for the card')
    .required('Please enter the CCV for the card')
    .trim(),
  cardConnectToken: yup.string().trim(),
  paymentAmount: yup.string().trim(),
});

const Payment: React.FC<PaymentProps> = ({ setNavigationProgress }) => {
  useEffect(() => {
    (window as any).nid('start', window.location.pathname);
  }, []);
  const history = useHistory();
  const driversJson = window.sessionStorage.getItem('drivers');
  const primaryDriver = JSON.parse(driversJson!)
    .filter((d: Driver) => d.isPrimaryInsured)[0] as Driver;
  const { brand } = useContext(BrandContext);
  const methods = useForm({ resolver: yupResolver(schema) });
  const { watch, handleSubmit } = methods;
  const watchPaymentType = watch('paymentType', 'monthly');
  const isPaidInFull = watchPaymentType === 'payInFull';
  const motionTransactionId = window.sessionStorage.getItem('motionTransactionId');
  const selectedTier = window.sessionStorage.getItem('selectedTier');
  const agentData = window.localStorage.getItem('agentData');
  const startDate = window.sessionStorage.getItem('startDate');
  const queryParams = window.sessionStorage.getItem('queryParams') || '';
  const [paymentRequestSuccess, setPaymentRequestSuccess] = useState(false);
  const [balanceRemaining, setBalanceRemaining] = useState(0);
  const [paymentMessage, setPaymentMessage] = useState('');
  const [paymentToast, setPaymentToast] = useState(false);
  const [paymentSeverity, setPaymentSeverity] = useState('success' as 'success' | 'error' | 'info' | 'warning');
  const handlePaymentToast = (status: 'success' | 'error' | 'warning' | 'info', message: string, ttl: number) => {
    setPaymentSeverity(status);
    setPaymentMessage(message);
    setPaymentToast(true);
    setTimeout(() => {
      setPaymentToast(false);
    }, ttl);
  };
  const { data: offerData } = useQuery(`offer-${motionTransactionId}`, () => GetOffer(motionTransactionId!), {
    onError: (error) => {
      Bugsnag.notify({ name: 'Error fetching offer', message: error as string });
    }
  });
  const { data: paymentInfoData, } = useQuery('paymentInfo', () => GetPaymentInfo({ paidInFull: isPaidInFull, selection: selectedTier!, rateId: motionTransactionId! }), {
    onError: (error) => {
      Bugsnag.notify({ name: 'Error fetching payment info', message: error as string });
    }
  });

  useEffect(() => {
    if (paymentInfoData) {
      const paymentMade = paymentInfoData.initialPayment ?? paymentInfoData.monthlyPayment;

      setBalanceRemaining(paymentInfoData.planTotal - paymentMade);
    }
  }, [paymentInfoData, setBalanceRemaining]);

  const [mutatePaymentBind, { isLoading: isMutatePaymentBinding }] = useMutation(paymentBind, {
    onSettled: (data) => {
      // advance user through the experience if 100% success
      // advance user through the experience if payment success and bind failure
      if ((data && data.success) || (data && !data.success && data.errorType === 'bind')) {
        window.sessionStorage.setItem('paymentResponse', JSON.stringify(data));
        setPaymentRequestSuccess(true);
      }

      // notify user of payment failure
      if (data && !data.success && data.errorType === 'payment') {
        handlePaymentToast('error', 'Could not process payment with the card information provided', 9000);
      }

      // notify user of duplicate attempt
      if (data && !data.success && data.errorType === 'invalid') {
        handlePaymentToast('info', `${data.message} If you have any additional questions please call customer support ${getPhoneNumber()}`, 9000);
      }
    }
  });

  const handleSuccessfulPaymentEvents = useCallback(() => {

    // update lead in Active Campaign
    if (agentData === null) {
      sendActiveCampaignEvent({
        email: primaryDriver.email,
        'field[%CUSTOMER%]': '1'
      });
    }

    // update navigation
    window.sessionStorage.setItem('navigationProgress',
      JSON.stringify({ 1: 'complete', 2: 'complete', 3: 'complete' }));
    setNavigationProgress();

    // advance user to next page
    history.push(`/welcome${queryParams}`);
    window.scrollTo(0, 0);
  }, [agentData, balanceRemaining, history, offerData, paymentInfoData, primaryDriver.email, setNavigationProgress, startDate, watchPaymentType]);

  useEffect(() => {
    if (paymentRequestSuccess) {
      handleSuccessfulPaymentEvents();
    }
  }, [paymentRequestSuccess, handleSuccessfulPaymentEvents]);




  if (!offerData) {
    return (
      <>
        <Helmet>
          <title>Payment - Motion Insurance</title>
        </Helmet>
        <PageHeader
          icon='circularProgress'
          header='Payment...'
          description=''
        />
        <div style={{ marginBottom: '50em' }} />
      </>
    );
  }


  const submitPayment = async (data: any) => {
    const payload = {
      selection: selectedTier,
      paidInFull: watchPaymentType === 'payInFull',
      payment: {
        token: data.cardConnectToken,
        expiry: data.creditCardExpiration.replace('/', ''),
        name: `${data.firstName} ${data.lastName}`,
        address: data.address,
        city: data.city,
        postalCode: data.zip,
      },
      rateId: offerData.transaction_id,
    } as MakePaymentInput;

    window.sessionStorage.setItem('makePaymentInput', JSON.stringify(payload));

    try {
      await mutatePaymentBind(payload);
    } catch (error) {
      Bugsnag.notify({ name: 'Error making initial payment', message: error as string });
    }
  };

  const handleBackClick = () => {
    history.push(`/review-policy${queryParams}`);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Helmet>
        <title>{`Payment - ${brand === 'novo' ? 'Novo Insurance' : 'Motion Insurance'}`}</title>
        {
          brand === 'novo' ?
            <link rel='icon' href='/novo-fav.ico' />
            :
            <link rel='icon' href='/favicon.ico' />
        }
      </Helmet>
      <MotionToast
        severity={paymentSeverity as 'success' | 'error' | 'info' | 'warning'}
        message={paymentMessage}
        open={paymentToast}
      />
      <div>
        <PageHeader
          icon='creditCard'
          header='Payment'
          description=''
        />
        <FormProvider {...methods}>
          <form
            id='paymentForm'
            onSubmit={handleSubmit(submitPayment)}
          >
            <div>
              <PaymentOptions />
              <PaymentRequestApiButton setPaymentRequestSuccess={setPaymentRequestSuccess} />
              <CreditCardDetails />
              <BillingInformation />
              <div className='payment-line-separator' />
              <div className='payment-button-wrapper'>
                <div className='payment-button-group'>
                  {
                    isMutatePaymentBinding ?
                      <div className='submitting-payment-wrapper'>
                        <div>
                          <CircularProgress />
                        </div>
                        <div className='submitting-payment-body'>
                          Submitting payment and activating new policy...
                        </div>
                      </div>
                      :
                      <>
                        <div>
                          <Button onClick={handleBackClick} color='secondary' variant='contained'>Back</Button>
                        </div>
                        <div id='submitPaymentButton' className='submit-payment-button'>
                          <Button
                            type='submit'
                            color='primary'
                            variant='contained'
                          >
                            Make payment
                          </Button>
                        </div>
                      </>
                  }
                </div>
                <div className='secure-payment-wrapper'>
                  <div>
                    <LockIcon />
                  </div>
                  <div className='secure-payment-statement'>
                    Secure checkout
                  </div>
                </div>
              </div>
              <div className='payment-footnotes-wrapper'>
                <div className='installment-plan-disclosure'>
                  {`*Total Premium includes future payments and applicable
              fees, including a $${offerData.offer.installment_plan / 5 || 5} installment fee per payment
              automatically withdrawn from your account or card.`}
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default Payment;