import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import './index.scss';

import App from './components/App';

import Amplify from 'aws-amplify';

const awsConfig = {
  Auth: {
    region: 'us-east-1',
    userPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: process.env.REACT_APP_DOMAIN,
      responseType: 'code'
    },
    federationTarget : "COGNITO_USER_POOLS"
  }
}

Amplify.configure(awsConfig);

Bugsnag.start({
  apiKey: 'a3b53c7916c37d08aa097befa76ce144',
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.NODE_ENV,
})

// GTM config
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER!
}

TagManager.initialize(tagManagerArgs);


ReactDOM.render(
  <App />
  , document.getElementById('root'));


