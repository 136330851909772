import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import Bugsnag from '@bugsnag/js';
import NumberFormat from 'react-number-format';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Button, Grid } from '@material-ui/core';
import { GetOffer, GetStepFunctionStatus } from '../utils/queries';
import './Quote.scss';

import PageHeader from '../components/PageHeader';
import QuoteTier from '../components/QuoteTier';
import QuoteAccordion from '../components/QuoteAccordion';
import { initiateQuote } from '../utils/mutations';
import ErrorInternal from './ErrorInternal';
import { Partner } from '../models/partner';
import { getQuoteSchema } from '../utils/formValidation';
import MotionToast from '../components/MotionToast';
import MotionModal from '../components/MotionModal';
import { BrandContext, getBrand, getPrimaryColor, getSecondaryColor } from '../utils/style';

import {
  VehicleFromExistingPolicyRule,
  DriverFromExistingPolicyRule,
  DuplicateVinRule,
  HighRiskQuoteRule,
  HasDriver,
  HasVehicle,
  MarriedSpouseRule,
  VehicleCountRule,
  OddNumberSpouseRule,
  PniAddressMatchesGaragingAddress,
  RideshareRule,
  GrossVehicleWeightRule,
  IRule,
  RateCallEvent, 
  RateCallEventController, 
  Driver,
  Vehicle,
  ValidationError,
  TooManyIncidentsRule,
  PropertyDamageLessThanLiabilityPerPersonRule,
  UninsuredMotoristBodilyInjuryEqualityRule,
  UninsuredMotoristPropertyDamageBodilyInjuryEqualityRule,
}  from '@novo/rate-call-event-validation'


export interface QuoteProps {
  navigationProgress: { 1: string, 2: string, 3: string };
  setNavigationProgress(): void;
}
const Quote: React.FC<QuoteProps> = ({ navigationProgress, setNavigationProgress }) => {

  useEffect(() => {
    (window as any).nid('start', window.location.pathname);
  }, []);
  const { brand, setBrand, setPrimaryColor, setSecondaryColor } = useContext(BrandContext);
  useEffect(() => {
    setBrand(getBrand());
    setPrimaryColor && setPrimaryColor(getPrimaryColor());
    setSecondaryColor && setSecondaryColor(getSecondaryColor());
  });
  const driversJson = window.sessionStorage.getItem('drivers');
  const drivers = JSON.parse(driversJson!) as Driver[];
  const primaryDriver = drivers.filter(d => d.isPrimaryInsured)[0];
  const vehiclesJson = window.sessionStorage.getItem('vehicles');
  const vehicles = JSON.parse(vehiclesJson!) as Vehicle[];
  const queryParams = window.sessionStorage.getItem('queryParams') || '';
  
  const agentJson = window.localStorage.getItem('agentData');
  const agent = JSON.parse(agentJson!) || null;
  const isMotionAgent = agent && agent.channel === 'Motion';

  const [tierOneSelected, setTierOneSelected] = useState(false);
  const [tierTwoSelected, setTierTwoSelected] = useState(true);
  const [tierThreeSelected, setTierThreeSelected] = useState(false);
  const [motionTransactionId, setMotionTransactionId] = useState('');
  const [inEditMode, setInEditMode] = useState(false);
  const [executionArn] = useState(window.sessionStorage.getItem('executionArn'));
  const [submittedForRequote] = useState(window.sessionStorage.getItem('requote'));
  const [rateCall] = useState(window.sessionStorage.getItem('rateCall'));
  const [selectedTier, setSelectedTier] = useState((submittedForRequote === 'true' || rateCall === 'true') ? 'requested' : 'better');
  
  const date = window.sessionStorage.getItem('startDate') || '';
  const [startDate, setStartDate] = useState<any>(date ? (new Date(date) < new Date() ? new Date() : new Date(date)) : new Date());

  const history = useHistory();

  if (new Date(date) < new Date()) {
    window.sessionStorage.setItem('startDate', new Date().toString());
  }

  const { data: offerData, refetch } = useQuery(
    `offer-${motionTransactionId}`,
    () => GetOffer(motionTransactionId),
    {
      enabled: false,
      onError: (error) => {
        Bugsnag.notify({ name: 'Error fetching offer', message: error as string });
      },
    }
  );

  const { data: statusData, refetch: refetchStatus } =
    useQuery(
      `stepFunctionStatus-${executionArn}`, () => GetStepFunctionStatus(executionArn!),
      {
        onSettled: (data) => {
          if (data && data.status && data.status.toLowerCase() === 'running') {
            setTimeout(() => {
              refetchStatus();
            }, 500);
          }

          if (data && data.status && data.status.toLowerCase() === 'failed') {
            Bugsnag.notify({ name: 'Quote', message: `Quote step function failed, execution name ${executionArn}` });
          }
        },
        onError: (error) => {
          Bugsnag.notify({ name: 'Error fetching step function status', message: error as string });
        }
      }
    );

  const handleChangeEditMode = () => {
    setInEditMode(!inEditMode);
  };

  useEffect(() => {
    if (statusData && statusData.status && statusData.status.toLowerCase() === 'succeeded') {
      let output = JSON.parse(statusData.output);
      if (Array.isArray(output)) {
        output = output[0];
      }
      if (output.premium_response) {
        setMotionTransactionId(output.premium_response.motionauto_transaction_id);
      }
      if (output.rerating) {
        setMotionTransactionId(output.rerating.externalQuoteId);
      }
    }
  }, [statusData, setMotionTransactionId]);

  useEffect(() => {
    if (motionTransactionId.length > 0) {
      refetch();
    }
  }, [motionTransactionId, refetch]);

  const handleTierClick = (tier: string) => {
    switch (tier) {
      case 'tierOne':
        setTierOneSelected(true);
        setTierTwoSelected(false);
        setTierThreeSelected(false);
        setSelectedTier('basic');
        break;
      case 'tierTwo':
        setTierOneSelected(false);
        setTierTwoSelected(true);
        setTierThreeSelected(false);
        setSelectedTier((submittedForRequote === 'true' || rateCall === 'true') ? 'requested' : 'better');
        break;
      case 'tierThree':
        setTierOneSelected(false);
        setTierTwoSelected(false);
        setTierThreeSelected(true);
        setSelectedTier('best');
        break;
      default:
        break;
    }
  };

  const handleCancelEdit = () => {
    setStartDate(new Date(date));
    setInEditMode(false);
  };

  const schema = yup.object().shape(getQuoteSchema(primaryDriver.state));
  const methods = useForm({
    defaultValues: {
      effectiveDate: new Date(),
      vehicles,
      drivers
    },
    resolver: yupResolver(schema),
  });
  const { handleSubmit, errors } = methods;

  const [stopQuoteModal, setStopQuoteModal] = useState(false);
  const [stopQuoteBody, setStopQuoteBody] = useState('');
  const handleStopQuoteModal = (body: string) => {
    setStopQuoteBody(body);
    setStopQuoteModal(true);
  };
  const confirmStopQuote = () => {
    window.location.href = `tel://${18444250049}`;
    setStopQuoteModal(false);
  };
  const cancelStopQuote = () => {
    setStopQuoteModal(false);
  };

  const [mutate, info] = useMutation(initiateQuote, {
    onError: (error) => {
      Bugsnag.notify({ name: 'Trigger RC3 quote', message: error as string });
    }
  });
  const onSubmitRequote = async (data: any) => {

    const rateCallEventController = new RateCallEventController()
    window.sessionStorage.setItem('requote', 'true');
    window.sessionStorage.setItem('rateCall', 'true');
    // save drivers
    const updatedDrivers = await rateCallEventController.prepareDrivers(data.drivers)
    window.sessionStorage.setItem('drivers', JSON.stringify(updatedDrivers));

    // save vehicles
    const updatedVehicles = await rateCallEventController.prepareVehicles(data.vehicles);
    window.sessionStorage.setItem('vehicles', JSON.stringify(updatedVehicles));

    // prepare prior coverage
    const currentCoverage = await rateCallEventController.prepareCurrentCoverage(data)
    window.sessionStorage.setItem('priorCoverage', JSON.stringify(currentCoverage));

    // Policy coverage validationn for rate call
    const policyCoverageJson = window.sessionStorage.getItem('policyCoverage');
    const existingPolicyCoverage = JSON.parse(policyCoverageJson!) || {};
    let policyCoverage = await rateCallEventController.preparePolicyCoverage(existingPolicyCoverage)
    const isTestLead = await rateCallEventController.prepareTestLead(drivers)
    const trackingData = window.sessionStorage.getItem('tracking')
    const trackingDataJson = JSON.parse(trackingData!);
    const tracking = await rateCallEventController.prepareTracking(trackingDataJson)

    // in the event agentData exists in local storage overwrite agentId and channel (partner)
    if (agent !== null && agent.agentId && agent.channel) {
      rateCallEventController.agent_id = agent.agentId
      rateCallEventController.channel = agent.channel
    }

    const utcDateString = startDate.toISOString();
    const utcDate = utcDateString.split('T')[0];

    const rateCallEvent:RateCallEvent = await rateCallEventController.prepareRateCallEvent({
      effectiveAt: utcDate,
      doAsync: true,
      bind: true,
      brand: brand === 'novo' ? 'novo' : '',
    })
    const canSkipValidation = !isMotionAgent && process.env.REACT_APP_ENV === 'production'
    const validateRule = async (rule:IRule, rateCallEvent:RateCallEvent):Promise<boolean> => {
      const response = await rule(rateCallEvent).catch(() => {
        return false
      })
      if (!response) {
        return false
      }
      return true
    }
    let valid = await validateRule(DuplicateVinRule, rateCallEvent)
    
    if (!valid) {
      handleDisplayToast('error', 'Oops, it looks like you already added this vehicle. Either add a different vehicle or delete the duplicate.')
      return
    }
    if (await !validateRule(HasDriver, rateCallEvent)) {
      handleDisplayToast('error', 'Oops, add at least one driver to get a quote.')
      return
    }

    if (await !validateRule(HasVehicle, rateCallEvent)) {
      handleDisplayToast('error', 'Oops, add at least one vehicle to get a quote.')
      return
    }

    if (await !validateRule(HighRiskQuoteRule, rateCallEvent)) {
      handleStopQuoteModal('Please call our customer service team for a quote.')
      return
    }

    if (await !validateRule(VehicleCountRule, rateCallEvent)) {
      handleStopQuoteModal('Please add all driver-age people in the household.')
      return
    }


    if (await !validateRule(MarriedSpouseRule, rateCallEvent)) {
      handleStopQuoteModal('Please add your spouse for an accurate quote')
      return
    }

    if (await !validateRule(OddNumberSpouseRule, rateCallEvent)) {
      handleStopQuoteModal("You've indicated that one or more drivers are married, please enter their spouse.")
      return
    }

    
    if (!canSkipValidation) {
      if (await !validateRule(DriverFromExistingPolicyRule,rateCallEvent)) {
        handleStopQuoteModal('Oops, one of the included drivers already exists on an current policy. Please call customer support for assistance with this quote.')
        return
      }
      
      if (await !validateRule(VehicleFromExistingPolicyRule,rateCallEvent)) {
        handleStopQuoteModal('Oops, one of the included vehicles already exists on an current policy. Please call customer support for assistance with this quote.')
        return
      }

      if (!await validateRule(PniAddressMatchesGaragingAddress, rateCallEvent)) {
        handleStopQuoteModal('Oops, one of your vehicles zip code does not match your address\'s zip code. Please call customer support for assistance with this quote.')
        return
      }
    }

    try {
      await mutate({ input: rateCallEvent });
    } catch (error) {
      Bugsnag.notify({ name: 'Trigger RC3 quote', message: error as string });
    }
    history.push(`/review-info${queryParams}`);
    history.replace(`/quote${queryParams}`);
  };

  if (info.data) {
    window.sessionStorage.setItem('executionArn', info.data.executionArn);
    window.sessionStorage.setItem('requote', 'true');
  }

  const onSubmitContinue = (data: any) => {
    // set selected tier
    window.sessionStorage.setItem('selectedTier', selectedTier);
    window.sessionStorage.setItem('motionTransactionId', motionTransactionId);
    // update navigation
    window.sessionStorage.setItem('navigationProgress',
      JSON.stringify({ 1: 'complete', 2: 'complete', 3: 'active' }));
    setNavigationProgress();
    // route to review page
    history.push(`/review-policy${queryParams}`);
    window.scrollTo(0, 0);
  };

  const onSubmitError = (formErrors: any) => {
    if (formErrors.vehicles
      && formErrors.vehicles.some((v: any) => {
        return v.ownershipLength
          && v.ownershipType
          && v.primaryUse
          && v.primaryZipCode
          && v.year;
      })) {
      handleDisplayToast('error', 'Please delete any unused add vehicle forms or click "Find my car".');
    }

    console.log(formErrors);
  };

  const [quoteSubmitMessage, setQuoteSubmitMessage] = useState('');
  const [quoteSubmitToast, setQuoteSubmitToast] = useState(false);
  const [createAccountSeverity, setCreateAccountSeverity] = useState('success' as 'success' | 'error' | 'info' | 'warning');

  const handleDisplayToast = (severity: 'success' | 'error' | 'info' | 'warning', message: string) => {
    setCreateAccountSeverity(severity);
    setQuoteSubmitToast(true);
    setQuoteSubmitMessage(message);
    setTimeout(() => {
      setQuoteSubmitToast(false);
    }, 6000);
  };

  if (statusData && statusData.status && statusData.status.toLowerCase() === 'failed') {
    return (
      <ErrorInternal />
    );
  }

  if (!offerData) {
    window.scrollTo(0, 0);
    return (
      <>
        <Helmet>
          <title>Quote - Motion Insurance</title>
        </Helmet>
        <PageHeader
          icon='circularProgress'
          header='Generating your quote...'
          description='Give us a few seconds while we generate your personalized quote.'
        />
        <div style={{ marginBottom: '50em' }} />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{`Quote - ${brand === 'motion' ? 'Motion Insurance' : 'Novo Insurance'}`}</title>
        {
          brand === 'motion' ?
            <link rel='icon' href='/favicon.ico' />
            :
            <link rel='icon' href='/novo-fav.ico' />
        }
      </Helmet>
      <MotionToast
        severity={createAccountSeverity as 'success' | 'error' | 'info' | 'warning'}
        message={quoteSubmitMessage}
        open={quoteSubmitToast}
      />
      <MotionModal
        title='Get a quote?'
        body={stopQuoteBody}
        open={stopQuoteModal}
        confirmText='Call 1 (844) 425-0049'
        handleConfirm={confirmStopQuote}
        closeText='Cancel'
        handleClose={cancelStopQuote}
      />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitRequote, onSubmitError)}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <PageHeader
                icon='shield'
                header={'Here\'s your quote!'}
                description={
                  <NumberFormat
                    value={offerData.offer.initial_payment_basic_fee}
                    displayType='text'
                    thousandSeparator={true}
                    prefix='Start today for as low as $'
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                }
              />
            </Grid>
            <Grid item style={{ margin: 'auto auto' }} lg={6} md={6} sm={12} xs={12}>
              <Controller
                render={({ value, onChange, onBlur }) => (
                  <KeyboardDatePicker
                    margin='none'
                    id='startDate'
                    label='Policy start date'
                    format='MM/dd/yyyy'
                    fullWidth
                    value={startDate}
                    onChange={(e: any) => {
                      onChange(e);
                      setInEditMode(true);
                      setStartDate(new Date(e));
                      return new Date(e);
                    }}
                    onBlur={onBlur}
                    inputVariant='outlined'
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    variant='dialog'
                    error={
                      !!(
                        methods.errors &&
                          methods.errors.effectiveDate
                          ? methods.errors.effectiveDate.message
                          : ''
                      )
                    }
                    helperText={
                      methods.errors &&
                        methods.errors.effectiveDate
                        ? methods.errors.effectiveDate.message
                        : ''
                    }
                  />
                )}
                name='effectiveDate'
                control={methods.control}
                defaultValue={startDate}
              />
            </Grid>
          </Grid>
          <div className='tier-wrapper'>
            <QuoteTier
              tierName='Economy'
              tierDescription='Covers the minimums and a little more.'
              monthlyFee={offerData.offer.initial_payment_basic_fee}
              totalFee={offerData.offer.basic_fee}
              policyTerm={offerData.offer.policy_term}
              isSelected={tierOneSelected}
              setSelection={() => { handleTierClick('tierOne'); }}
            />{
              (submittedForRequote || rateCall) ?
                <QuoteTier
                  tierName='Custom'
                  tierDescription='Your custom coverage with your selections.'
                  monthlyFee={offerData.offer.initial_payment_requested_fee}
                  totalFee={offerData.offer.requested_fee}
                  policyTerm={offerData.offer.policy_term}
                  isSelected={tierTwoSelected}
                  setSelection={() => { handleTierClick('tierTwo'); }}
                />
                : <QuoteTier
                  tierName='Comfort'
                  tierDescription='A good middle ground of coverage.'
                  monthlyFee={offerData.offer.initial_payment_better_fee}
                  totalFee={offerData.offer.better_fee}
                  policyTerm={offerData.offer.policy_term}
                  isSelected={tierTwoSelected}
                  setSelection={() => { handleTierClick('tierTwo'); }}
                />
            }

            <QuoteTier
              tierName='Turbo'
              tierDescription='Extensive coverage for additional protection.'
              monthlyFee={offerData.offer.initial_payment_best_fee}
              totalFee={offerData.offer.best_fee}
              policyTerm={offerData.offer.policy_term}
              isSelected={tierThreeSelected}
              setSelection={() => { handleTierClick('tierThree'); }}
            />
          </div>
          <QuoteAccordion
            tier={selectedTier}
            offer={offerData.offer}
            inEditMode={inEditMode}
            changeEditMode={handleChangeEditMode}
          />

          <div className='button-wrapper'>
            {
              inEditMode ?
                (
                  <div className='requote-wrapper'>
                    <Button
                      id='quoteRequoteCancel'
                      onClick={handleCancelEdit}
                      color='secondary'
                      variant='contained'
                    >Cancel
                    </Button>
                    <div className='requote-button'>
                      <Button
                        id='quoteRequoteButton'
                        type='submit'
                        color='primary'
                        variant='contained'
                      >Update and requote
                      </Button>
                    </div>
                    {
                      Object.keys(errors).length > 0 &&
                      <div className='form-error-message'>
                        See error(s) above
                      </div>

                    }
                  </div>
                )
                :
                (
                  <Button
                    id='quoteContinueButton'
                    onClick={onSubmitContinue}
                    color='primary'
                    variant='contained'
                  >
                    Review & purchase policy
                  </Button>
                )
            }
          </div>
        </form>
      </FormProvider>

    </>
  );
};

export default Quote;
