import React, { useContext, SyntheticEvent, useEffect, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import './Driver.scss';
import { ArrayField, Controller, useFormContext, useWatch } from 'react-hook-form';
import InputMask from 'react-input-mask';

import { KeyboardDatePicker } from '@material-ui/pickers';
import InitialsBubble from './InitialsBubble';
import { ReactComponent as ToolTipIcon } from '../images/tool-tip.svg';
import { ReactComponent as TrashCanIcon } from '../images/trash-can.svg';
import { ReactComponent as ExpandIcon } from '../images/expand-icon.svg';
import { ReactComponent as LockIcon } from '../images/lock.svg';
import { usStateList } from '../models/stateCodes';
import { getEductionMenuList, getOccupationMenuList } from '../utils/selectList';
import MotionModal from './MotionModal';
import { getAge } from '../utils/date';
import PriorCoverage from './PriorCoverage';
import AccidentViolation from './AccidentViolation';
import { LIVE_STATES } from '../utils/state';
import PlacesAutoComplete from './PlacesAutoComplete';
import { BrandContext } from '../utils/style';

export interface DriverProps {
  index: number;
  driver: Partial<ArrayField<Record<string, any>, 'id'>>;
  inEditMode: boolean;
  isAccordionOpen: boolean;
  clickedExpandAll?: boolean;
  remove(): void;
  maritalStatus(data: any): void;
}

const Accordion = withStyles({
  root: {
    width: '100%',
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    lineHeight: 'normal',
    borderRadius: 'none',
    color: 'black',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
    fontFamily: 'AcherusGrotesqueBold',
    fontSize: '0.875em',
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    fontSize: '0.875em',
  },
}))(MuiAccordionDetails);

const CustomFormControlLabel = withStyles({
  label: {
    fontSize: '0.875em',
  }
})(FormControlLabel);

const Driver: React.FC<DriverProps> =
  ({
    driver,
    index,
    remove,
    inEditMode,
    isAccordionOpen,
    clickedExpandAll,
    maritalStatus
  }) => {
    const { brand } = useContext(BrandContext);
    const [educationMenuList] = useState(getEductionMenuList());
    const [occupationMenuList] = useState(getOccupationMenuList());
    const { isPrimaryInsured } = driver;
    const methods = useFormContext();
    const { setValue, control } = methods;
    const watchDriverType = useWatch({ control, name: `drivers[${index}].driverType`, defaultValue: '' });
    const watchFirstName = useWatch({ control, name: `drivers[${index}].firstName`, defaultValue: '' });
    const watchRelationshipToPrimary = useWatch({ control, name: `drivers[${index}].relationshipToPrimaryDriver`, defaultValue: '' });
    const watchState = useWatch({ control, name: `drivers[${index}].state`, defaultValue: '' });
    const pniState = useWatch({ control, name: 'drivers[0].state', defaultValue: '' });
    const watchBirthDate = useWatch({ control, name: `drivers[${index}].birthDate`, defaultValue: '' });
    const [driverAge, setDriverAge] =
      useState(getAge(new Date(watchBirthDate), new Date()));
    useEffect(() => {
      setDriverAge(getAge(new Date(watchBirthDate), new Date()));
    }, [setDriverAge, watchBirthDate]);
    const [expanded, setExpanded] = React.useState<string | false>(isAccordionOpen ? 'panel1' : 'panel');
    const handleChange =
      (panel: string) => (
        event: React.ChangeEvent<{}>,
        isExpanded: boolean
      ) => {
        setExpanded(isExpanded ? panel : false);
      };

    useEffect(() => {
      if (clickedExpandAll) {
        setExpanded('panel1');
      }

      if (watchFirstName === 'New') {
        setExpanded('panel1');
      }
    }, [clickedExpandAll, setExpanded, watchFirstName]);

    const [stateMenuList] = React.useState(() => {
      return usStateList.map((s) => {
        return (
          <MenuItem key={s.value} value={s.value}>
            {s.display}
          </MenuItem>
        );
      });
    });

    const [licenseStatusMenuList] = React.useState(() => {
      return [
        'Active / Valid',
        'Suspended / Denied',
        'Revoked',
        'Expired',
      ].map((g) => {
        return (
          <MenuItem key={g} value={g}>
            {g}
          </MenuItem>
        );
      });
    });

    const [relationshipStatusMenuList] = React.useState(() => {
      return ['Single', 'Married', 'Widowed', 'Divorced'].map((g) => {
        return (
          <MenuItem key={g} value={g}>
            {g}
          </MenuItem>
        );
      });
    });

    const [genderMenuList] = React.useState(() => {
      return ['Female', 'Male'].map((g) => {
        return (
          <MenuItem key={g} value={g}>
            {g}
          </MenuItem>
        );
      });
    });

    const [ageLicensedMenuList] = React.useState(() => {
      return ['14', '15', '16', '17', '18', '19', '20', '21 or older'].map(
        (g) => {
          return (
            <MenuItem key={g} value={g}>
              {g}
            </MenuItem>
          );
        }
      );
    });
    const [relationshipMenuList] = React.useState(() => {
      return [
        { display: 'Parent', value: 'Parent' },
        { display: 'Spouse', value: 'Spouse' },
        { display: 'Child', value: 'Child' },
        { display: 'Other Related', value: 'Other_Related' },
        { display: 'Other Unrelated', value: 'Other_Unrelated' },
      ].map((g) => {
        return (
          <MenuItem key={g.value} value={g.value}>
            {g.display}
          </MenuItem>
        );
      });
    });
    const driverType = () => {
      return [
        { display: 'Included', value: 'rated' },
        { display: 'Permit', value: 'permit' },
        { display: 'Excluded', value: 'excluded' },
        { display: 'List Only', value: 'list-only' },
      ].map((g) => {
        return (
          <MenuItem key={g.value} value={g.value}>
            {g.display}
          </MenuItem>
        );
      });
    };

    const [requireSr22MenuList] = React.useState(() => {
      return ['Yes', 'No'].map((g) => {
        return (
          <MenuItem key={g} value={g}>
            {g}
          </MenuItem>
        );
      });
    });

    let birthDateDefaultValue = null;

    const expectedBirthDateFormat = /^\d{4}-\d{2}-\d{2}$/; // yyyy-mm-dd
    if (driver.birthDate && expectedBirthDateFormat.test(driver.birthDate)) {
      birthDateDefaultValue = new Date(
        parseInt(driver.birthDate.split('-')[0], 10),
        parseInt(driver.birthDate.split('-')[1], 10) - 1,
        parseInt(driver.birthDate.split('-')[2], 10),
        0,
        0,
        0
      );
    }
    const handleClick = (e: any) => {
      maritalStatus(e.target.value)
    }
    const [driverRemoveModal, setDriverRemoveModal] = useState(false);
    const handleDriverRemove = (e: SyntheticEvent) => {
      e.stopPropagation();
      setDriverRemoveModal(true);
    };
    const confirmRemoveDriver = () => {
      remove();
      setDriverRemoveModal(false);
    };
    const cancelRemoveDriver = () => {
      setDriverRemoveModal(false);
    };

    const [liveStateModal, setLiveStateModal] = useState(false);
    const handleOnLiveStateClose = () => {
      setLiveStateModal(!liveStateModal);
    };
    useEffect(() => {
      if (watchState && !LIVE_STATES.includes(watchState!)) {
        setLiveStateModal(true);
      }
    }, [watchState]);

    // places autocomplete 
    const [streetNumber, setStreetNumber] = useState('');
    const [streetName, setStreetName] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');

    useEffect(() => {
      if (streetNumber !== '' && streetName !== '') {
        setValue(`drivers[${index}].address`, `${streetNumber} ${streetName}`);
      }
      if (city) {
        setValue(`drivers[${index}].city`, `${city}`);
      }
      if (state) {
        setValue(`drivers[${index}].state`, `${state}`);
      }
      if (zipCode) {
        setValue(`drivers[${index}].zip`, `${zipCode}`);
      }
    }, [setValue, streetNumber, streetName, city, state, zipCode, index]);

    // the date picker only accepts specific formats as an input
    // to guard against bad formats from partners we verify the date format
    // and default to today if the stored birth date isn't in the right format

    return (
      <>
        <MotionModal
          title='Are you sure?'
          body='You will not be able to retrieve this driver once deleted.'
          open={driverRemoveModal}
          handleClose={cancelRemoveDriver}
          closeText='Cancel'
          confirmText='Delete'
          handleConfirm={confirmRemoveDriver}
        />
        <MotionModal
          title='Oops...'
          body={
            `It looks like we are not yet live in your state. 
          We are adding new states rapidly. 
          Thank you for your interest in ${brand === 'motion' ? 'Motion Insurance' : 'Novo Insurance'}
          and we look forward to serving you in the near future.`
          }
          open={liveStateModal}
          handleClose={handleOnLiveStateClose}
          closeText='Ok, got it!'
        />
        <div className='accordion-wrapper'>
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary
              expandIcon={<ExpandIcon />}
              aria-controls='panel1bh-content'
              id={`driverAccordion-${index}`}
            >
              <div className='driver-accordion-wrapper'>
                {
                  driver.firstName !== undefined
                    && driver.lastName !== undefined ?
                    (
                      <InitialsBubble
                        color={isPrimaryInsured ? 'primary' : 'secondary'}
                        firstName={driver.firstName ? driver.firstName : 'New'}
                        lastName={driver.lastName ? driver.lastName : 'Driver'}
                        page='driver'
                      />
                    ) : null
                }
                {driver.canBeRemoved === 'true' || driver.canBeRemoved === true ? (
                  <IconButton onClick={handleDriverRemove}>
                    <TrashCanIcon />
                  </IconButton>
                ) : null}
                <input
                  type='hidden'
                  name={`drivers[${index}].canBeRemoved`}
                  defaultValue={driver.canBeRemoved}
                  ref={methods.register()}
                />
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {
                !inEditMode ?
                  <div className='additional-driver-info-wrapper'>
                    <div className='additional-driver-info-left'>
                      {driver.address && isPrimaryInsured ? (
                        <span className='primary-insured-info-item'>Garaging Address</span>
                      ) : null}
                      {driver.apartmentNumber && isPrimaryInsured ? (
                        <span>Apartment number</span>
                      ) : null}
                      {driver.city && isPrimaryInsured ? (
                        <span>City, state & zip</span>
                      ) : null}
                      {driver.birthDate ? <span>Date of birth</span> : null}
                      {driver.gender ? <span>Gender</span> : null}
                      {driver.relationshipStatus ? <span>Marital status</span> : null}
                      {!isPrimaryInsured && driver.relationshipToPrimaryDriver ? (
                        <span>Relation to you</span>
                      ) : null}
                      {driver.driverType ? <span>Driver Type</span> : null}
                      {driver.SSN !== undefined &&
                        driver.SSN !== null ?
                        (
                          <span>Social security</span>
                        ) : null}
                      {driver.phone ? <span>Phone number</span> : null}
                      {driver.licenseNumber ? <span>DL number</span> : null}
                      {driver.stateLicensed ? <span>DL state</span> : null}
                      {driver.licenseStatus ? <span>DL status</span> : null}
                      {driver.requireSr22 ? <span>Driver has sr22</span> : null}
                      {driver.ageLicensed ? <span>Age license obtained</span> : null}
                      {driver.email ? <span>Email address</span> : null}
                    </div>
                    <div className='additional-driver-info-right'>
                      {driver.address && isPrimaryInsured ? (
                        <span>{driver.address}</span>
                      ) : null}
                      {driver.apartmentNumber && isPrimaryInsured ? (
                        <span>{driver.apartmentNumber}</span>
                      ) : null}
                      {driver.city && driver.state && driver.zip && isPrimaryInsured ? (
                        <span>{`${driver.city}, ${driver.state} ${driver.zip}`}</span>
                      ) : null}
                      {driver.birthDate ? (
                        <span>
                          {new Date(`${driver.birthDate!} 00:00:00`).toLocaleString(
                            'en-us',
                            {
                              month: 'long',
                              day: 'numeric',
                              year: 'numeric',
                            }
                          )}
                        </span>
                      ) : null}
                      {driver.gender ? <span>{driver.gender}</span> : null}
                      {driver.relationshipStatus ? (
                        <span>{driver.relationshipStatus}</span>
                      ) : null}
                      {!isPrimaryInsured && driver.relationshipToPrimaryDriver ? (
                        <span>{driver.relationshipToPrimaryDriver}</span>
                      ) : null}
                      {driver.driverType ? <span>Driver Type</span> : null}
                      {driver.SSN ? (
                        <span>
                          &bull;&bull;&bull;&bull;&bull; {driver.SSN?.slice(5, 9)}
                        </span>
                      ) : null}
                      {driver.phone ? <span>{driver.phone}</span> : null}
                      {driver.licenseNumber ? <span>{driver.licenseNumber}</span> : null}
                      {driver.stateLicensed ? <span>{driver.stateLicensed}</span> : null}
                      {driver.licenseStatus ? <span>{driver.licenseStatus}</span> : null}
                      {driver.requireSr22 ? <span>{driver.requireSr22}</span> : null}
                      {driver.ageLicensed ? <span>{driver.ageLicensed}</span> : null}
                      {driver.email ? <span>{driver.email}</span> : null}
                    </div>
                  </div>
                  : null
              }
              <div id={`driverForm-${index}`} className='additional-driver-dl-form'>
                <Grid container spacing={2} justify='flex-start'>
                  {driver.firstName === 'New' || !driver.firstName || inEditMode ? (
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Controller
                        as={
                          <TextField
                            id={`driverFirstName-${index}`}
                            label='First Name'
                            variant='outlined'
                            fullWidth
                            error={
                              !!(
                                methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index].firstName
                              )
                            }
                            helperText={
                              methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index].firstName
                                ? methods.errors.drivers[index].firstName.message
                                : ''
                            }
                          />
                        }
                        name={`drivers[${index}].firstName`}
                        control={methods.control}
                        defaultValue={driver.firstName || ''}
                      />
                    </Grid>
                  ) :
                    (
                      <input
                        type='hidden'
                        name={`drivers[${index}].firstName`}
                        defaultValue={driver.firstName}
                        ref={methods.register()}
                      />
                    )}
                  {driver.lastName === 'Driver' || !driver.lastName || inEditMode ? (
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Controller
                        as={
                          <TextField
                            id={`driverLastName-${index}`}
                            label='Last Name'
                            variant='outlined'
                            fullWidth
                            error={
                              !!(
                                methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index].lastName
                              )
                            }
                            helperText={
                              methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index].lastName
                                ? methods.errors.drivers[index].lastName.message
                                : ''
                            }
                          />
                        }
                        name={`drivers[${index}].lastName`}
                        control={methods.control}
                        defaultValue={driver.lastName || ''}
                      />
                    </Grid>
                  ) :
                    (
                      <input
                        type='hidden'
                        name={`drivers[${index}].lastName`}
                        defaultValue={driver.lastName}
                        ref={methods.register()}
                      />
                    )}
                  {isPrimaryInsured && inEditMode ?
                    (
                      <>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <PlacesAutoComplete
                            id={`driverAddress-${index}`}
                            name={`drivers[${index}].address`}
                            defaultValue={driver.address || ''}
                            label='Garaging Address'
                            error={
                              !!(
                                methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index].address
                              )
                            }
                            helperText={
                              methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index].address
                                ? methods.errors.drivers[index].address.message
                                : ''
                            }
                            setStreetNumber={setStreetNumber}
                            setStreetName={setStreetName}
                            setCity={setCity}
                            setState={setState}
                            setZipCode={setZipCode}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Controller
                            as={
                              <TextField
                                id={`driverApartmentNumber-${index}`}
                                label='Apartment number'
                                variant='outlined'
                                fullWidth
                                error={
                                  !!(
                                    methods.errors &&
                                    methods.errors.drivers &&
                                    methods.errors.drivers[index] &&
                                    methods.errors.drivers[index].apartmentNumber
                                  )
                                }
                                helperText={
                                  methods.errors &&
                                    methods.errors.drivers &&
                                    methods.errors.drivers[index] &&
                                    methods.errors.drivers[index].apartmentNumber
                                    ? methods.errors.drivers[index].apartmentNumber.message
                                    : ''
                                }
                              />
                            }
                            name={`drivers[${index}].apartmentNumber`}
                            control={methods.control}
                            defaultValue={driver.apartmentNumber || ''}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Controller
                            as={
                              <TextField
                                id={`driverCity-${index}`}
                                label='City'
                                variant='outlined'
                                fullWidth
                                error={
                                  !!(
                                    methods.errors &&
                                    methods.errors.drivers &&
                                    methods.errors.drivers[index] &&
                                    methods.errors.drivers[index].city
                                  )
                                }
                                helperText={
                                  methods.errors &&
                                    methods.errors.drivers &&
                                    methods.errors.drivers[index] &&
                                    methods.errors.drivers[index].city
                                    ? methods.errors.drivers[index].city.message
                                    : ''
                                }
                              />
                            }
                            name={`drivers[${index}].city`}
                            control={methods.control}
                            defaultValue={driver.city || ''}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Controller
                            control={methods.control}
                            name={`drivers[${index}].state`}
                            defaultValue={driver.state || ''}
                            render={({ onChange, value, name, onBlur }) => (
                              <FormControl
                                variant='outlined'
                                fullWidth
                                error={
                                  !!(
                                    methods.errors &&
                                    methods.errors.drivers &&
                                    methods.errors.drivers[index] &&
                                    methods.errors.drivers[index].state
                                  )
                                }
                              >
                                <InputLabel>State</InputLabel>
                                <Select
                                  id={`driverState-${index}`}
                                  label='State'
                                  value={value}
                                  name={name}
                                  onChange={onChange}
                                  onBlur={onBlur}
                                >
                                  {stateMenuList}
                                </Select>
                                <FormHelperText>
                                  {methods.errors &&
                                    methods.errors.drivers &&
                                    methods.errors.drivers[index] &&
                                    methods.errors.drivers[index].state
                                    ? methods.errors.drivers[index].state.message
                                    : ''}
                                </FormHelperText>
                              </FormControl>
                            )}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Controller
                            as={
                              <TextField
                                id={`driverZip-${index}`}
                                label='Zip code'
                                variant='outlined'
                                fullWidth
                                inputProps={{ maxLength: 5 }}
                                error={
                                  !!(
                                    methods.errors &&
                                    methods.errors.drivers &&
                                    methods.errors.drivers[index] &&
                                    methods.errors.drivers[index].zip
                                  )
                                }
                                helperText={
                                  methods.errors &&
                                    methods.errors.drivers &&
                                    methods.errors.drivers[index] &&
                                    methods.errors.drivers[index].zip
                                    ? methods.errors.drivers[index].zip.message
                                    : ''
                                }
                              />
                            }
                            name={`drivers[${index}].zip`}
                            control={methods.control}
                            defaultValue={driver.zip || ''}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Controller
                            control={methods.control}
                            alwaysShowMask={false}
                            name={`drivers[${index}].phone`}
                            defaultValue={driver.phone || ''}
                            maskChar=''
                            as={
                              <InputMask mask='999-999-9999'>
                                {() => {
                                  return (
                                    <TextField
                                      id='phoneNumber'
                                      error={
                                        !!(
                                          methods.errors &&
                                          methods.errors.drivers &&
                                          methods.errors.drivers[index] &&
                                          methods.errors.drivers[index].phone
                                        )
                                      }
                                      helperText={
                                        methods.errors &&
                                          methods.errors.drivers &&
                                          methods.errors.drivers[index] &&
                                          methods.errors.drivers[index].phone
                                          ? methods.errors.drivers[index].phone.message
                                          : ''
                                      }
                                      label='Phone Number'
                                      variant='outlined'
                                      type='text'
                                      fullWidth
                                    />
                                  );
                                }}
                              </InputMask>
                            }
                          />
                        </Grid>
                      </>
                    ) :
                    (null)}
                  {driver.gender || (watchDriverType === 'rated' || watchDriverType === 'permit') ? (
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Controller
                        control={methods.control}
                        name={`drivers[${index}].gender`}
                        defaultValue={driver.gender || ''}
                        render={({ onChange, value, name, onBlur }) => (
                          <FormControl
                            variant='outlined'
                            fullWidth
                            error={
                              !!(
                                methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index].gender
                              )
                            }
                          >
                            <InputLabel>Gender</InputLabel>
                            <Select
                              id={`driverGender-${index}`}
                              label='gender'
                              value={value}
                              name={name}
                              onChange={onChange}
                              onBlur={onBlur}
                            >
                              {genderMenuList}
                            </Select>
                            <FormHelperText>
                              {methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index].gender
                                ? methods.errors.drivers[index].gender.message
                                : ''}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>
                  ) :
                    (
                      <input
                        type='hidden'
                        name={`drivers[${index}].gender`}
                        defaultValue={driver.gender}
                        ref={methods.register()}
                      />
                    )}
                  {!driver.birthDate || inEditMode ? (
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Controller
                        render={({ value, onChange, onBlur }) => (
                          <KeyboardDatePicker
                            margin='none'
                            id={`driverDateOfBirth-${index}`}
                            label='Birth date'
                            format='MM/dd/yyyy'
                            fullWidth
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            inputVariant='outlined'
                            KeyboardButtonProps={{
                              'aria-label': 'change date'
                            }}
                            variant='dialog'
                            error={
                              !!(
                                methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index].birthDate
                              )
                            }
                            helperText={
                              methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index].birthDate
                                ? methods.errors.drivers[index].birthDate.message
                                : ''
                            }
                          />
                        )}
                        name={`drivers[${index}].birthDate`}
                        control={methods.control}
                        defaultValue={birthDateDefaultValue}
                      />
                    </Grid>
                  ) :
                    (
                      <input
                        type='hidden'
                        name={`drivers[${index}].birthDate`}
                        defaultValue={
                          new Date(driver.birthDate).toISOString().split('T')[0]
                        }
                        ref={methods.register()}
                      />
                    )}
                  {!driver.relationshipStatus || inEditMode ? ( 
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Controller
                        control={methods.control}
                        name={`drivers[${index}].relationshipStatus`}
                        defaultValue={driver.relationshipStatus || ''}
                        render={({ onChange, value, name, onBlur }) => (
                          <FormControl
                            variant='outlined'
                            fullWidth
                            error={
                              !!(
                                methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index].relationshipStatus
                              )
                            }
                          >
                            <InputLabel>Marital Status</InputLabel>
                            <Select
                              id={`driverMaritalStatus-${index}`}
                              label='Marital status'
                              value={value}
                              name={name}
                              onChange={onChange}
                              onClick={handleClick}
                              onBlur={onBlur}
                            >
                              {relationshipStatusMenuList}
                            </Select>
                            <FormHelperText>
                              {methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index].relationshipStatus
                                ? methods.errors.drivers[index].relationshipStatus
                                  .message
                                : ''}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>
                  ) :
                    (
                      <input
                        type='hidden'
                        name={`drivers[${index}].relationshipStatus`}
                        defaultValue={driver.relationshipStatus}
                        ref={methods.register()}
                      />
                    )}
                  {(!driver.relationshipToPrimaryDriver && !isPrimaryInsured) || (!isPrimaryInsured && inEditMode) ? (
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Controller
                        control={methods.control}
                        name={`drivers[${index}].relationshipToPrimaryDriver`}
                        defaultValue={driver.relationshipToPrimaryDriver || ''}
                        render={({ onChange, value, name, onBlur }) => (
                          <FormControl
                            variant='outlined'
                            fullWidth
                            error={
                              !!(
                                methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index]
                                  .relationshipToPrimaryDriver
                              )
                            }
                          >
                            <InputLabel>Relation to you</InputLabel>
                            <Select
                              id={`relationshipToPrimaryDriver-${index}`}
                              label='relation to you'
                              value={value}
                              name={name}
                              onChange={onChange}
                              onBlur={onBlur}
                            >
                              {relationshipMenuList}
                            </Select>
                            <FormHelperText>
                              {methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index]
                                  .relationshipToPrimaryDriver
                                ? methods.errors.drivers[index]
                                  .relationshipToPrimaryDriver.message
                                : ''}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>
                  ) :
                    (
                      <input
                        type='hidden'
                        name={`drivers[${index}].relationshipToPrimaryDriver`}
                        defaultValue={
                          isPrimaryInsured ? 'SELF' : driver.relationshipToPrimaryDriver
                        }
                        ref={methods.register()}
                      />
                    )}
                  {(!isPrimaryInsured && !driver.driverType) || (inEditMode && !isPrimaryInsured) ? (
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Controller
                        control={methods.control}
                        name={`drivers[${index}].driverType`}
                        defaultValue={driver.driverType || 'Active'}
                        render={({ onChange, value, name, onBlur }) => (
                          <FormControl
                            variant='outlined'
                            fullWidth
                            error={
                              !!(
                                methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index].driverType
                              )
                            }
                          >
                            <InputLabel>
                              Driver Type
                            </InputLabel>
                            <Select
                              id={`drivertype-${index}`}
                              label='Driver Type'
                              value={value}
                              name={name}
                              onChange={onChange}
                              onBlur={onBlur}
                              startAdornment={
                                <InputAdornment position='start'>
                                  <Tooltip
                                    enterTouchDelay={0}
                                    leaveTouchDelay={3500}
                                    style={{ marginRight: '1em' }}
                                    className='tooltip'
                                    title={'An excluded driver is someone who is part of your household who will not be covered on the policy. Someone who is "list only" is someone who is part of your household and does not drive.'}
                                    placement='top-start'
                                  >
                                    <ToolTipIcon />
                                  </Tooltip>
                                </InputAdornment>
                              }
                            >
                              {driverType()}
                            </Select>
                            <FormHelperText>
                              {methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index].driverType
                                ? methods.errors.drivers[index].driverType.message
                                : ''}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>
                  ) :
                    (
                      <input
                        type='hidden'
                        name={`drivers[${0}].driverType`}
                        defaultValue='rated'
                        ref={methods.register()}
                      />
                    )}
                  {isPrimaryInsured || (watchRelationshipToPrimary === 'Spouse' && (watchDriverType === 'rated' || watchDriverType === 'permit')) ? (
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Controller
                        control={methods.control}
                        name={`drivers[${index}].education`}
                        defaultValue={driver.education || ''}
                        render={({ onChange, value, name, onBlur }) => (
                          <FormControl
                            variant='outlined'
                            fullWidth
                            error={
                              !!(
                                methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index]
                                  .education
                              )
                            }
                          >
                            <InputLabel>Education</InputLabel>
                            <Select
                              id={`education-${index}`}
                              label='Education'
                              value={value}
                              name={name}
                              onChange={onChange}
                              onBlur={onBlur}
                            >
                              {educationMenuList}
                            </Select>
                            <FormHelperText>
                              {methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index]
                                  .education
                                ? methods.errors.drivers[index]
                                  .education.message
                                : ''}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>
                  ) :
                    (
                      <input
                        type='hidden'
                        name={`drivers[${index}].education`}
                        defaultValue={
                          isPrimaryInsured ? 'SELF' : driver.education
                        }
                        ref={methods.register()}
                      />
                    )}

                  {isPrimaryInsured || (watchRelationshipToPrimary === 'Spouse' && (watchDriverType === 'rated' || watchDriverType === 'permit')) ? (
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Controller
                        control={methods.control}
                        name={`drivers[${index}].occupation`}
                        defaultValue={driver.occupation || ''}
                        render={({ onChange, value, name, onBlur }) => (
                          <FormControl
                            variant='outlined'
                            fullWidth
                            error={
                              !!(
                                methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index]
                                  .occupation
                              )
                            }
                          >
                            <InputLabel>Occupation</InputLabel>
                            <Select
                              id={`occupation-${index}`}
                              label='Occupation'
                              value={value}
                              name={name}
                              onChange={onChange}
                              onBlur={onBlur}
                              native
                            >
                              <option aria-label='None' value='' />
                              {occupationMenuList}
                            </Select>
                            <FormHelperText>
                              {methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index]
                                  .occupation
                                ? methods.errors.drivers[index]
                                  .occupation.message
                                : ''}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>
                  ) :
                    (
                      <input
                        type='hidden'
                        name={`drivers[${index}].occupation`}
                        defaultValue={
                          isPrimaryInsured ? 'SELF' : driver.occupation
                        }
                        ref={methods.register()}
                      />
                    )}
                  {isPrimaryInsured || (watchRelationshipToPrimary === 'Spouse' && (watchDriverType === 'rated' || watchDriverType === 'permit')) ? (
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Controller
                        control={methods.control}
                        name={`drivers[${index}].SSN`}
                        defaultValue={driver.SSN || ''}
                        as={
                          <InputMask maskChar='' mask='999-99-9999'>
                            {() => {
                              return (
                                <TextField
                                  id={`driverSSN1-${index}`}
                                  error={
                                    !!(
                                      methods.errors &&
                                      methods.errors.drivers &&
                                      methods.errors.drivers[index] &&
                                      methods.errors.drivers[index].SSN
                                    )
                                  }
                                  helperText={
                                    methods.errors &&
                                      methods.errors.drivers &&
                                      methods.errors.drivers[index] &&
                                      methods.errors.drivers[index].SSN
                                      ? methods.errors.drivers[index].SSN.message
                                      : ''
                                  }
                                  label='Social security number'
                                  variant='outlined'
                                  type='text'
                                  fullWidth
                                  InputProps={
                                    {
                                      endAdornment:
                                        <InputAdornment position='start'>
                                          <Tooltip
                                            enterTouchDelay={0}
                                            leaveTouchDelay={3500}
                                            className='tooltip'
                                            title='Providing a social security number allows us to provide the most accurate quote and is kept secure'
                                            placement='top-start'
                                          >
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                              <LockIcon />
                                              <div style={{ marginLeft: '0.5em', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                <ToolTipIcon />
                                              </div>
                                            </div>
                                          </Tooltip>
                                        </InputAdornment>
                                    }
                                  }
                                />
                              );
                            }}
                          </InputMask>
                        }
                      />
                    </Grid>
                  ) :
                    (
                      <input
                        type='hidden'
                        name={`drivers[${index}].SSN`}
                        defaultValue={driver.SSN || ''}
                        ref={methods.register()}
                      />
                    )}
                  {isPrimaryInsured || (watchDriverType === 'rated' || watchDriverType === 'permit') ? (
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Controller
                        as={
                          <TextField
                            id={`licenseNumber-${index}`}
                            label={"Driver's license number"}
                            variant='outlined'
                            fullWidth
                            error={
                              !!(
                                methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index].licenseNumber
                              )
                            }
                            helperText={
                              methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index].licenseNumber
                                ? methods.errors.drivers[index].licenseNumber.message
                                : ''
                            }
                          />
                        }
                        name={`drivers[${index}].licenseNumber`}
                        control={methods.control}
                        defaultValue={driver.licenseNumber || ''}
                      />
                    </Grid>
                  ) :
                    (
                      <input
                        type='hidden'
                        name={`drivers[${index}].licenseNumber`}
                        defaultValue={driver.licenseNumber}
                        ref={methods.register()}
                      />
                    )}
                  {isPrimaryInsured || (watchDriverType === 'rated' || watchDriverType === 'permit') ? (
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Controller
                        control={methods.control}
                        name={`drivers[${index}].stateLicensed`}
                        defaultValue={driver.stateLicensed || driver.state || ''}
                        render={({ onChange, value, name, onBlur }) => (
                          <FormControl
                            variant='outlined'
                            fullWidth
                            error={
                              !!(
                                methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index].stateLicensed
                              )
                            }
                          >
                            <InputLabel>Driver's license state</InputLabel>
                            <Select
                              id={`stateLicensed-${index}`}
                              label={"Driver's license state"}
                              value={value}
                              name={name}
                              onChange={onChange}
                              onBlur={onBlur}
                            >
                              {stateMenuList}
                            </Select>
                            <FormHelperText>
                              {methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index].stateLicensed
                                ? methods.errors.drivers[index].stateLicensed.message
                                : ''}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>
                  ) :
                    (
                      <input
                        type='hidden'
                        name={`drivers[${index}].stateLicensed`}
                        defaultValue={driver.stateLicensed}
                        ref={methods.register()}
                      />
                    )}
                  {isPrimaryInsured || (watchDriverType === 'rated' || watchDriverType === 'permit') ? (
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Controller
                        control={methods.control}
                        name={`drivers[${index}].licenseStatus`}
                        defaultValue={driver.licenseStatus || 'Active / Valid'}
                        render={({ onChange, value, name, onBlur }) => (
                          <FormControl
                            variant='outlined'
                            fullWidth
                            error={
                              !!(
                                methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index].licenseStatus
                              )
                            }
                          >
                            <InputLabel>Driver's license status</InputLabel>
                            <Select
                              id={`licenseStatus-${index}`}
                              label={"driver's license status"}
                              value={value}
                              name={name}
                              onChange={onChange}
                              onBlur={onBlur}
                            >
                              {licenseStatusMenuList}
                            </Select>
                            <FormHelperText>
                              {methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index].licenseStatus
                                ? methods.errors.drivers[index].licenseStatus.message
                                : ''}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>
                  ) :
                    (
                      <input
                        type='hidden'
                        name={`drivers[${index}].licenseStatus`}
                        defaultValue={driver.licenseStatus}
                        ref={methods.register()}
                      />
                    )}
                  {driver.requireSr22 || (watchDriverType === 'rated' || watchDriverType === 'permit') ? (
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Controller
                        control={methods.control}
                        name={`drivers[${index}].requireSr22`}
                        defaultValue={driver.requireSr22 || 'No'}
                        render={({ onChange, value, name, onBlur }) => (
                          <FormControl
                            variant='outlined'
                            fullWidth
                            error={
                              !!(
                                methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index].requireSr22
                              )
                            }
                          >
                            <InputLabel>SR22</InputLabel>
                            <Select
                              id={`requireSr22-${index}`}
                              label='SR22'
                              value={value}
                              name={name}
                              onChange={onChange}
                              onBlur={onBlur}
                              startAdornment={
                                <InputAdornment position='start'>
                                  <Tooltip
                                    className='tooltip'
                                    title={
                                      'An SR22 is a statement of financial responsibility that ' +
                                      'certain people are required to provide. An SR22 is proof that ' +
                                      'you have the proper amount of insurance that your state has ' +
                                      'required you to have.  The most common reasons people need ' +
                                      'an SR22 are because of a DUI or DWI, driving without insurance, ' +
                                      'or driving with a suspended license. The filing will be issued ' +
                                      "to the state selected once you've completed purchase."
                                    }
                                    placement='top-start'
                                  >
                                    <ToolTipIcon />
                                  </Tooltip>
                                </InputAdornment>
                              }
                            >
                              {requireSr22MenuList}
                            </Select>
                            <FormHelperText>
                              {methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index].requireSr22
                                ? methods.errors.drivers[index].requireSr22.message
                                : ''}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>
                  ) :
                    (
                      <input
                        type='hidden'
                        name={`drivers[${index}].requireSr22`}
                        defaultValue={driver.requireSr22}
                        ref={methods.register()}
                      />
                    )}
                  {isPrimaryInsured || (watchDriverType === 'rated' || watchDriverType === 'permit') ? (
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Controller
                        control={methods.control}
                        name={`drivers[${index}].ageLicensed`}
                        defaultValue={driver.ageLicensed || '16'}
                        render={({ onChange, value, name, onBlur }) => (
                          <FormControl
                            variant='outlined'
                            fullWidth
                            error={
                              !!(
                                methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index].ageLicensed
                              )
                            }
                          >
                            <InputLabel>Age license obtained</InputLabel>
                            <Select
                              id={`driverAgeLicensed-${index}`}
                              label='Age license obtained'
                              value={value}
                              name={name}
                              onChange={onChange}
                              onBlur={onBlur}
                            >
                              {ageLicensedMenuList}
                            </Select>
                            <FormHelperText>
                              {methods.errors &&
                                methods.errors.drivers &&
                                methods.errors.drivers[index] &&
                                methods.errors.drivers[index].ageLicensed
                                ? methods.errors.drivers[index].ageLicensed.message
                                : ''}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>
                  ) :
                    (
                      <input
                        type='hidden'
                        name={`drivers[${index}].ageLicensed`}
                        defaultValue={driver.ageLicensed}
                        ref={methods.register()}
                      />
                    )}
                  {isPrimaryInsured || (watchDriverType === 'rated' || watchDriverType === 'permit') ?
                    (
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Controller
                          as={
                            <TextField
                              id={`driverEmail-${index}`}
                              label='Email'
                              variant='outlined'
                              fullWidth
                              error={
                                !!(
                                  methods.errors &&
                                  methods.errors.drivers &&
                                  methods.errors.drivers[index] &&
                                  methods.errors.drivers[index].email
                                )
                              }
                              helperText={
                                methods.errors &&
                                  methods.errors.drivers &&
                                  methods.errors.drivers[index] &&
                                  methods.errors.drivers[index].email
                                  ? methods.errors.drivers[index].email.message
                                  : ''
                              }
                            />
                          }
                          name={`drivers[${index}].email`}
                          control={methods.control}
                          defaultValue={driver.email || ''}
                        />
                      </Grid>
                    ) :
                    (
                      <input
                        type='hidden'
                        name={`drivers[${index}].email`}
                        defaultValue={driver.email}
                        ref={methods.register()}
                      />
                    )}
                </Grid>
                {
                  driverAge >= 55 && (pniState === 'UT' || pniState === 'TN') && (watchDriverType === 'rated' || watchDriverType === 'permit') ?
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <div className='checkbox-wrapper'>
                        <Controller
                          name={`drivers[${index}].hadDriverTraining`}
                          defaultValue={!!(driver.hadDriverTraining && driver.hadDriverTraining === 'Y')}
                          control={methods.control}
                          render={(props) => (
                            <CustomFormControlLabel
                              control={
                                <Checkbox
                                  color='primary'
                                  onChange={(e) => {
                                    props.onChange(e.target.checked);
                                  }}
                                  checked={props.value}
                                />
                              }
                              label={
                                <div className='checkbox-label-wrapper'>
                                  <div className='checkbox-label'>
                                    Have you completed any voluntary,
                                    state-recognized driver training classes in the past 3 years?
                                  </div>
                                  <div className='checkbox-footnote'>
                                    (We may ask you to provide a certificate of completion)
                                  </div>
                                </div>
                              }
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    : null
                }
                {
                  isPrimaryInsured && watchState === 'UT' ?
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <div className='checkbox-wrapper'>
                        <Controller
                          name={`drivers[${index}].non_drinker`}
                          defaultValue={driver.non_drinker || false}
                          control={methods.control}
                          render={(props) => (
                            <CustomFormControlLabel
                              control={
                                <Checkbox
                                  id={`driverNonDrinker-${index}`}
                                  color='primary'
                                  onChange={(e) => {
                                    props.onChange(e.target.checked);
                                  }}
                                  checked={props.value}
                                />
                              }
                              label={
                                <div className='non-drinker-checkbox-wrapper'>
                                  <div className='checkbox-label'>
                                    Non-drinker discount
                                  </div>
                                  <div className='non-drinker-tooltip-wrapper'>
                                    <Tooltip
                                      enterTouchDelay={0}
                                      leaveTouchDelay={3500}
                                      className='tooltip'
                                      title={`
                                  You certify that you and your household do not consume alcohol.  
                                  See the full disclosure on the Review Your Quote page.
                                  `}
                                      placement='top-start'
                                    >
                                      <ToolTipIcon />
                                    </Tooltip>
                                  </div>
                                </div>
                              }
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    : null
                }
                {
                  isPrimaryInsured ?
                    <PriorCoverage index={index} />
                    : null
                }
                {
                  isPrimaryInsured || (watchDriverType === 'rated' || watchDriverType === 'permit') ?
                    <AccidentViolation index={index} />
                    : null
                }
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </>
    );
  };

export default Driver;
