import React, { useContext } from 'react';
import './RightFooter.scss';
import { ReactComponent as Bbb } from '../images/bbb.svg';
import { BrandContext, getPrimaryColor } from '../utils/style';

const RightFooter: React.FC = () => {
  const { brand } = useContext(BrandContext);
  return (
    <div className='right-footer-wrapper'>
      {
        brand === 'motion' &&
        <div className='bbb-svg'>
          <a
            href='https://www.bbb.org/us/ut/saratoga-springs/profile/auto-insurance/motion-auto-llc-1166-90025701'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Bbb />
          </a>
        </div>
      }
      <div className='right-footer-copyright'>
        <span>
          {`© ${brand === 'novo' ? 'Novo Insurance LLC' : 'Motion Auto LLC'}, ${new Date().getFullYear()}. All rights reserved.`}
        </span>
        <span>
          Please see our &nbsp;
          <a
            href={brand === 'novo' ? 'https://www.novo.us/privacy-policy' : 'https://www.motioninsurance.com/privacy-policy.html'}
            target='_blank'
            rel='noopener noreferrer'
            style={{ color: getPrimaryColor() }}
          >
            Privacy Policy
          </a>
          , &nbsp;

          <>
            <a
              href={brand === 'novo' ? 'https://www.novo.us/telematics-policy' : 'https://motioninsurance.com/telematics-privacy-policy.html'}
              target='_blank'
              rel='noopener noreferrer'
              style={{ color: getPrimaryColor() }}
            >
              Telematics Privacy Policy
            </a>
            , and &nbsp;
          </>

          <a
            href={brand === 'novo' ? 'https://www.novo.us/terms-of-use' : 'https://motioninsurance.com/terms-of-use.html'}
            target='_blank'
            rel='noopener noreferrer'
            style={{ color: getPrimaryColor() }}
          >
            Legal Notices
          </a>
          .
        </span>
      </div>
    </div>
  );
};

export default RightFooter;
