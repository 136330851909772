import React, { useContext } from 'react';
import { ReactComponent as LogoFull } from '../images/logo-full.svg';
import { ReactComponent as LogoSmall } from '../images/logo-small.svg';
import { ReactComponent as CoBrand } from '../images/partnerImages/motion-novo-full.svg';
import { ReactComponent as CoBrandSmall } from '../images/partnerImages/motion-novo-mobile.svg';
import { ReactComponent as NovoLogo } from '../images/partnerImages/novo-logo.svg';
import { BrandContext } from '../utils/style';
import './LeftHeader.scss';

const LeftHeader: React.FC = () => {
  const { brand, coBrand } = useContext(BrandContext);


  return (
    <>
      {
        brand === 'novo' &&
        !coBrand &&
        <div>
          <div className='logo-full'>
            <a href='https://www.novo.us' target='_blank'>
              <NovoLogo />
            </a>
          </div>
          <div className='logo-small'>
            <a href='https://www.novo.us' target='_blank'>
              <NovoLogo />
            </a>
          </div>
        </div>
      }
      {
        brand === 'motion' &&
        !coBrand &&
        <div>
          <div className='logo-full'>
            <LogoFull />
          </div>
          <div className='logo-small'>
            <LogoSmall />
          </div>
        </div>
      }
      {
        coBrand &&
        <div>
          <div className='logo-full'>
            <CoBrand />
          </div>
          <div className='logo-small'>
            <CoBrandSmall />
          </div>
        </div>
      }
    </>
  );
};

export default LeftHeader;
