import Bugsnag from '@bugsnag/js';
import { Partner } from '../models/partner';

export const sendGtmEvent = () => {
  const partnerInfoJson = window.sessionStorage.getItem('partner');
  const partnerInfo = JSON.parse(partnerInfoJson!) as Partner;
  const paymentResponseJson = window.sessionStorage.getItem('paymentResponse');
  const paymentResponse = JSON.parse(paymentResponseJson!);
  const sessionId = window.sessionStorage.getItem('sessionId');


  const eventName = 'MotionActivity';

  const eventProps = {
    GA_CLIENT: process.env.REACT_APP_ENV === 'production' ? 'G-7EG67Z4X36' : 'G-3JPMH3TE9M',
    motionUserId: partnerInfo && partnerInfo.visitorId ? partnerInfo.visitorId : 'not provided',
    sessionID: sessionId || 'not provided',
    pageUrl: window.location.pathname,
    partner: partnerInfo && partnerInfo.partner ? partnerInfo.partner : 'not provided',
    publisher: partnerInfo && partnerInfo.publisher ? partnerInfo.publisher : 'not provided',
    campaign: partnerInfo && partnerInfo.campaign ? partnerInfo.campaign : 'not provided',
    state: partnerInfo && partnerInfo.state ? partnerInfo.state : 'not provided',
    trackingId: partnerInfo && partnerInfo.trackingId ? partnerInfo.trackingId : 'not provided',
    referrerUrl: partnerInfo && partnerInfo.url ? partnerInfo.url : 'not provided',
    policyId: 'not provided',
    partnerInfo: {
      partner: partnerInfo && partnerInfo.partner ? partnerInfo.partner : 'not provided',
      publisher: partnerInfo && partnerInfo.publisher ? partnerInfo.publisher : 'not provided',
      campaign: partnerInfo && partnerInfo.campaign ? partnerInfo.campaign : 'not provided',
      state: partnerInfo && partnerInfo.state ? partnerInfo.state : 'not provided',
      trackingId: partnerInfo && partnerInfo.trackingId ? partnerInfo.trackingId : 'not provided',
      url: partnerInfo && partnerInfo.url ? partnerInfo.url : 'not provided',
      policyId: 'not provided'
    }
  };

  if (paymentResponse) {
    eventProps.policyId = paymentResponse && paymentResponse._bind.policy_id ? paymentResponse._bind.policy_id : 'not provided';
    eventProps.partnerInfo.policyId = paymentResponse && paymentResponse._bind.policy_id ? paymentResponse._bind.policy_id : 'not provided';
  }

  if ((window as any).dataLayer && (window as any).dataLayer.push) {
    (window as any).dataLayer.push({
      event: eventName,
      eventProps,
    });
  } else {
    Bugsnag.notify({ name: 'GTM Not Loaded', message: `Failed to send ${eventName} event because GTM was not loaded` });
  }

};

export const agentLoginInfoCapture = (agentId: string) => {
  const eventName = 'AgentLoginInfo';
  const eventProps = {
    agentId,
  };
  if ((window as any).dataLayer && (window as any).dataLayer.push) {
    (window as any).dataLayer.push({
      event: eventName,
      eventProps,
    });
  } else {
    Bugsnag.notify({ name: 'GTM Not Loaded', message: `Failed to send ${eventName} event because GTM was not loaded` });
  }
};
