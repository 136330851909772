import React from 'react';
import './VehicleSimple.scss';
import { Vehicle } from '../models/motion';

export interface VehicleSimpleProps {
  vehicle: Vehicle;
}

const VehicleSimple: React.FC<VehicleSimpleProps> = ({ vehicle }) => {
  return (
    <div className='vehicle-simple-wrapper'>
      <img src={vehicle.imageUrl} className='vehicle-simple-image' alt={`${vehicle.year}-${vehicle.model}-${vehicle.model}`} />
      <div className='vehicle-simple-description-wrapper'>
        <div>
          {`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
        </div>
        <div className='vehicle-simple-vin-wrapper'>
          <div>
            VIN:
          </div>
          <div className='vehicle-simple-vin'>
            {vehicle.vin}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleSimple;