import React from 'react';
import NumberFormat from 'react-number-format';
import { getPrimaryColor } from '../utils/style';
import './TierBox.scss';


export interface TierBoxProps {
  monthlyFee: number;
  totalFee: number;
  policyTerm: number;
  isSelected: boolean;
}

const TierBox: React.FC<TierBoxProps> =
  ({ monthlyFee, totalFee, policyTerm, isSelected }) => {
    return (
      <>
        <div
          className={isSelected ? 'start-wrapper-selected' : 'start-wrapper'}
          style={{ color: isSelected ? getPrimaryColor() : '' }}
        >
          <span>Start today at</span>
        </div>
        <div>
          <span
            className={isSelected ? 'tier-price-selected' : 'tier-price'}
            style={{ color: isSelected ? getPrimaryColor() : '' }}
          >
            <NumberFormat
              value={monthlyFee}
              displayType='text'
              thousandSeparator={true}
              prefix='$'
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </span>
        </div>
        <div
          className={isSelected ? 'down-payment-selected' : 'down-payment'}
          style={{ color: isSelected ? getPrimaryColor() : '' }}
        >
          <span>
            first payment
          </span>
        </div>
        <div className='total-wrapper'>
          <div>
            <span>
              <NumberFormat
                value={totalFee}
                displayType='text'
                thousandSeparator={true}
                prefix='Total of $'
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </span>
          </div>
          <div>
            <span>
              {`for ${policyTerm} months`}
            </span>
          </div>
        </div>
      </>
    );
  };

export default TierBox;