import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  Select,
  Tooltip
} from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Offer } from '../models/motion';
import './PolicyCoveragesEdit.scss';
import { ReactComponent as ExpandIcon } from '../images/expand-icon.svg';
import { ReactComponent as ShieldMotion } from '../images/shield-motion-icon.svg';
import { ReactComponent as ShieldNovo } from '../images/shield-check-novo.svg';
import { ReactComponent as ToolTipIcon } from '../images/tool-tip.svg';
import { getMenuListWithDisplayAndValue } from '../utils/selectList';
import { BrandContext, } from '../utils/style';

export interface PolicyCoveragesEditProps {
  tier: string;
  offer: Offer;
  isAccordionOpen: boolean;
  clickedExpandAll: boolean;
  limits: any;
}

const PolicyCoveragesEdit: React.FC<PolicyCoveragesEditProps> =
  ({ tier, offer, isAccordionOpen, clickedExpandAll, limits }) => {
    const { brand } = useContext(BrandContext);
    const methods = useFormContext();
    const { setValue, watch } = methods;
    const watchPrimaryDriverState = watch('drivers[0].state');
    const [expanded, setExpanded] = React.useState<string | false>(isAccordionOpen ? 'panel1' : 'panel');
    const handleChange =
      (panel: string) => (
        event: React.ChangeEvent<{}>,
        isExpanded: boolean
      ) => {
        setExpanded(isExpanded ? panel : false);
      };
    const [biMenuList, setBiMenuList] = React.useState([]);
    const [medMenuList, setMedMenuList] = React.useState([]);
    const [pdMenuList, setPdMenuList] = React.useState([]);
    const [uimMenuList, setUimMenuList] = React.useState([]);
    const [umMenuList, setUmMenuList] = React.useState([]);
    const [umpdMenuList, setUmpdMenuList] = React.useState([]);
    const [roadsideMenuList, setRoadsideMenuList] = React.useState([]);

    const showUmpd = watchPrimaryDriverState
      && watchPrimaryDriverState.toLowerCase() === 'tn';

    const medLabel = limits && limits.limits_deduct.med_limit ? 'Medical' : 'Personal injury protection';
    const umLabel = limits && limits.limits_deduct.um_limit ? 'Uninsured motorist' : 'Uninsured motorist bodily injury';
    let uninsuredMotoristToolTip = 'Information is currently unavailable';
    if (limits && limits.limits_deduct.um_limit) {
      uninsuredMotoristToolTip = limits.limits_deduct.um_limit.description;
    } else if (limits && limits.limits_deduct.umbi_limit) {
      uninsuredMotoristToolTip = limits.limits_deduct.umbi_limit.description;
    }
    let medicalToolTip = 'Information is currently unavailable';
    if (limits && limits.limits_deduct.med_limit) {
      medicalToolTip = limits.limits_deduct.med_limit.description;
    } else if (limits && limits.limits_deduct.personalInjuryLimit) {
      medicalToolTip = limits.limits_deduct.personalInjuryLimit.description;
    }

    useEffect(() => {
      if (limits && limits.limits_deduct) {
        if (limits.limits_deduct.bi_limit) {
          const { options: biOptions } = limits.limits_deduct.bi_limit;
          setBiMenuList(
            biOptions.map((limit: { display: string, value: string }) =>
              getMenuListWithDisplayAndValue(limit))
          );
        }
        if (limits.limits_deduct.med_limit
          || limits.limits_deduct.personalInjuryLimit) {
          const { options: medOptions } = limits.limits_deduct.med_limit ?
            limits.limits_deduct.med_limit
            : limits.limits_deduct.personalInjuryLimit;
          setMedMenuList(
            medOptions.map((limit: { display: string, value: string }) =>
              getMenuListWithDisplayAndValue(limit))
          );
        }
        if (limits.limits_deduct.pd_limit) {
          const { options: pdOptions } = limits.limits_deduct.pd_limit;
          setPdMenuList(
            pdOptions.map((limit: { display: string, value: string }) =>
              getMenuListWithDisplayAndValue(limit))
          );
        }
        if (limits.limits_deduct.uim_limit) {
          const { options: uimOptions } = limits.limits_deduct.uim_limit;
          setUimMenuList(
            uimOptions.map((limit: { display: string, value: string }) =>
              getMenuListWithDisplayAndValue(limit))
          );
        }
        if (limits.limits_deduct.um_limit || limits.limits_deduct.umbi_limit) {
          const { options: umOptions } = limits.limits_deduct.um_limit ?
            limits.limits_deduct.um_limit
            : limits.limits_deduct.umbi_limit;
          setUmMenuList(
            umOptions.map((limit: { display: string, value: string }) =>
              getMenuListWithDisplayAndValue(limit))
          );
        }
        if (limits.limits_deduct.umpd_limit || limits.limits_deduct.umpd_limit) {
          const { options: umpdOptions } = limits.limits_deduct.umpd_limit ?
            limits.limits_deduct.umpd_limit
            : limits.limits_deduct.umpd_limit;
          setUmpdMenuList(
            umpdOptions.map((limit: { display: string, value: string }) =>
              getMenuListWithDisplayAndValue(limit))
          );
        }
        if (limits.limits_deduct.roadside_limit || limits.limits_deduct.roadside_limit) {
          const { options: roadsideOptions } = limits.limits_deduct.roadside_limit ?
            limits.limits_deduct.roadside_limit
            : limits.limits_deduct.roadside_limit;
          setRoadsideMenuList(
            roadsideOptions.map((limit: { display: string, value: string }) =>
              getMenuListWithDisplayAndValue(limit))
          );
        }
      }
    }, [limits,
      setBiMenuList,
      setMedMenuList,
      setPdMenuList,
      setUimMenuList,
      setUmMenuList.toString,
      setUmpdMenuList,
      setRoadsideMenuList,
    ]);

    useEffect(() => {
      if (clickedExpandAll) {
        setExpanded('panel1');
      }
    }, [clickedExpandAll, setExpanded]);

    const getDeclineValueForCoverage =
      (options: [{ display: string, value: string }]) => {
        const result = options.filter(o =>
          o.value.toLowerCase() === 'none'
          || o.value.toLowerCase() === 'declined')[0].value;
        return result;
      };

    useEffect(() => {
      if (biMenuList.length > 0 && limits) {
        let factors = offer[`${tier}_deductible`];
        if (Array.isArray(factors)) {
          [factors] = factors;
        }
        const offerState = watchPrimaryDriverState
        const limitState = limits.state;

        if (offerState.toLowerCase() === limitState.toLowerCase()) {
          setValue('biLimit', factors.bi_limit.toLowerCase() === 'none'
            || factors.bi_limit.toLowerCase() === 'declined'
            ? getDeclineValueForCoverage(limits.limits_deduct.bi_limit.options)
            : factors.bi_limit);
          if (factors.med_limit && limits && limits.limits_deduct.med_limit) {
            setValue('medLimit', factors.med_limit.toLowerCase() === 'none'
              || factors.med_limit.toLowerCase() === 'declined'
              ? getDeclineValueForCoverage(limits.limits_deduct.med_limit.options)
              : factors.med_limit);
          }
          if (factors.pip_limit && limits && limits.limits_deduct.personalInjuryLimit) {
            setValue('medLimit', factors.pip_limit.toLowerCase() === 'none'
              || factors.pip_limit.toLowerCase() === 'declined'
              ? getDeclineValueForCoverage(limits.limits_deduct.personalInjuryLimit.options)
              : factors.pip_limit);
          }
          setValue('pdLimit', factors.pd_limit.toLowerCase() === 'none'
            || factors.pd_limit.toLowerCase() === 'declined'
            ? getDeclineValueForCoverage(limits.limits_deduct.pd_limit.options)
            : factors.pd_limit);
          if (factors.uim_limit && limits && limits.limits_deduct.uim_limit) {
            setValue('uimLimit', factors.uim_limit.toLowerCase() === 'none'
              || factors.uim_limit.toLowerCase() === 'declined'
              ? getDeclineValueForCoverage(limits.limits_deduct.uim_limit.options)
              : factors.uim_limit);
          }
          if (factors.um_limit && limits && limits.limits_deduct.um_limit) {
            setValue('umLimit', factors.um_limit.toLowerCase() === 'none'
              || factors.um_limit.toLowerCase() === 'declined'
              ? getDeclineValueForCoverage(limits.limits_deduct.um_limit.options)
              : factors.um_limit);
          }
          if (factors.umbi_limit && limits && limits.limits_deduct.umbi_limit) {
            setValue('umLimit', factors.umbi_limit.toLowerCase() === 'none'
              || factors.umbi_limit.toLowerCase() === 'declined'
              ? getDeclineValueForCoverage(limits.limits_deduct.umbi_limit.options)
              : factors.umbi_limit);
          }
          if (factors.umpd_limit && limits && limits.limits_deduct.umpd_limit) {
            setValue('umpdLimit', factors.umpd_limit.toLowerCase() === 'none'
              || factors.umpd_limit.toLowerCase() === 'declined'
              ? getDeclineValueForCoverage(limits.limits_deduct.umbi_limit.options)
              : factors.umpd_limit);
          }
          if (factors.tow_limit && limits && limits.limits_deduct.roadside_limit) {
            setValue('towingLimit', factors.tow_limit.toLowerCase() === 'none'
              || factors.tow_limit.toLowerCase() === 'declined'
              ? getDeclineValueForCoverage(limits.limits_deduct.roadside_limit.options)
              : factors.tow_limit);
          }
        } else {
          setValue('biLimit', '');
          setValue('medLimit', '');
          setValue('pdLimit', '');
          setValue('uimLimit', '');
          setValue('umLimit', '');
          setValue('umpdLimit', '');
          setValue('towingLimit', '');
        }
      }
    }, [tier, setValue, offer, biMenuList, limits]);

    const watchUmLimit = methods.watch('umLimit');
    const watchUimLimit = methods.watch('uimLimit');
    const limitState = limits.state;

    useEffect(() => {
      if ((limitState === 'WI') && (watchUimLimit !== 'DECLINED')) {
        setValue('uimLimit', watchUmLimit);
      }
      if ((limitState === 'WI') && (watchUmLimit === '25/50')) {
        setValue('uimLimit', 'DECLINED');
      }
    }, [watchUmLimit, watchUimLimit, limitState, setValue]);

    return (
      <div>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandIcon />}
            aria-controls='panel1bh-content'
            id='policyCoveragesAccordion'
          >
            <div className='policy-edit-heading-wrapper'>
              <div>
                {
                  brand === 'motion' ?
                    <ShieldMotion />
                    :
                    <ShieldNovo />
                }
              </div>
              <div className='policy-edit-heading'>
                Adjust policy level coverages that best suit you.
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className='policy-coverages-form-wrapper'>
              <Grid container spacing={2} justify='flex-start'>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Controller
                    control={methods.control}
                    name='biLimit'
                    defaultValue=''
                    render={({ onChange, value, name, onBlur }) => (
                      <FormControl
                        variant='outlined'
                        fullWidth
                        error={
                          !!(
                            methods.errors &&
                            methods.errors.biLimit
                          )
                        }
                      >
                        <InputLabel>Bodily injury</InputLabel>
                        <Select
                          id='biLimit'
                          label='Bodily injury'
                          value={value}
                          name={name}
                          onChange={onChange}
                          onBlur={onBlur}
                          startAdornment={
                            <InputAdornment position='start'>
                              <Tooltip
                                enterTouchDelay={0}
                                leaveTouchDelay={3500}
                                style={{ marginRight: '1em' }}
                                className='tooltip'
                                title={
                                  limits
                                    && limits.limits_deduct
                                    && limits.limits_deduct.bi_limit
                                    && limits.limits_deduct.bi_limit.description ?
                                    limits.limits_deduct.bi_limit.description
                                    : 'Info is currently unavailable'
                                }
                                placement='top-start'
                              >
                                <ToolTipIcon />
                              </Tooltip>
                            </InputAdornment>
                          }
                        >
                          {biMenuList}
                        </Select>
                        <FormHelperText id='biLimitErrorText'>
                          {methods.errors &&
                            methods.errors.biLimit
                            ? methods.errors.biLimit.message
                            : ''}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Controller
                    control={methods.control}
                    name='medLimit'
                    defaultValue=''
                    render={({ onChange, value, name, onBlur }) => (
                      <FormControl
                        variant='outlined'
                        fullWidth
                        error={
                          !!(
                            methods.errors &&
                            methods.errors.medLimit
                          )
                        }
                      >
                        <InputLabel>{medLabel}</InputLabel>
                        <Select
                          id='medLimit'
                          label={medLabel}
                          value={value}
                          name={name}
                          onChange={onChange}
                          onBlur={onBlur}
                          startAdornment={
                            <InputAdornment position='start'>
                              <Tooltip
                                enterTouchDelay={0}
                                leaveTouchDelay={3500}
                                style={{ marginRight: '1em' }}
                                className='tooltip'
                                title={
                                  medicalToolTip
                                }
                                placement='top-start'
                              >
                                <ToolTipIcon />
                              </Tooltip>
                            </InputAdornment>
                          }
                        >
                          {medMenuList}
                        </Select>
                        <FormHelperText id='medLimitErrorText'>
                          {methods.errors &&
                            methods.errors.medLimit
                            ? methods.errors.medLimit.message
                            : ''}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Controller
                    control={methods.control}
                    name='pdLimit'
                    defaultValue=''
                    render={({ onChange, value, name, onBlur }) => (
                      <FormControl
                        variant='outlined'
                        fullWidth
                        error={
                          !!(
                            methods.errors &&
                            methods.errors.pdLimit
                          )
                        }
                      >
                        <InputLabel>Property damage</InputLabel>
                        <Select
                          id='pdLimit'
                          label='Property damage'
                          value={value}
                          name={name}
                          onChange={onChange}
                          onBlur={onBlur}
                          startAdornment={
                            <InputAdornment position='start'>
                              <Tooltip
                                enterTouchDelay={0}
                                leaveTouchDelay={3500}
                                style={{ marginRight: '1em' }}
                                className='tooltip'
                                title={
                                  limits
                                    && limits.limits_deduct
                                    && limits.limits_deduct.pd_limit
                                    && limits.limits_deduct.pd_limit.description ?
                                    limits.limits_deduct.pd_limit.description
                                    : 'Info is currently unavailable'
                                }
                                placement='top-start'
                              >
                                <ToolTipIcon />
                              </Tooltip>
                            </InputAdornment>
                          }
                        >
                          {pdMenuList}
                        </Select>
                        <FormHelperText id='pdLimitErrorText'>
                          {methods.errors &&
                            methods.errors.pdLimit
                            ? methods.errors.pdLimit.message
                            : ''}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </Grid>
                {
                  limits && limits.limits_deduct.uim_limit &&
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Controller
                      control={methods.control}
                      name='uimLimit'
                      defaultValue=''
                      render={({ onChange, value, name, onBlur }) => (
                        <FormControl
                          variant='outlined'
                          fullWidth
                          error={
                            !!(
                              methods.errors &&
                              methods.errors.uimLimit
                            )
                          }
                        >
                          <InputLabel>Underinsured motorist</InputLabel>
                          <Select
                            id='uimLimit'
                            label='Underinsured motorist'
                            value={value}
                            name={name}
                            onChange={onChange}
                            onBlur={onBlur}
                            startAdornment={
                              <InputAdornment position='start'>
                                <Tooltip
                                  enterTouchDelay={0}
                                  leaveTouchDelay={3500}
                                  style={{ marginRight: '1em' }}
                                  className='tooltip'
                                  title={
                                    limits
                                      && limits.limits_deduct
                                      && limits.limits_deduct.uim_limit
                                      && limits.limits_deduct.uim_limit.description ?
                                      limits.limits_deduct.uim_limit.description
                                      : 'Info is currently unavailable'
                                  }
                                  placement='top-start'
                                >
                                  <ToolTipIcon />
                                </Tooltip>
                              </InputAdornment>
                            }
                          >
                            {uimMenuList}
                          </Select>
                          <FormHelperText id='uimLimitErrorText'>
                            {methods.errors &&
                              methods.errors.uimLimit
                              ? methods.errors.uimLimit.message
                              : ''}
                          </FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Grid>
                }
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Controller
                    control={methods.control}
                    name='umLimit'
                    defaultValue=''
                    render={({ onChange, value, name, onBlur }) => (
                      <FormControl
                        variant='outlined'
                        fullWidth
                        error={
                          !!(
                            methods.errors &&
                            methods.errors.umLimit
                          )
                        }
                      >
                        <InputLabel>{umLabel}</InputLabel>
                        <Select
                          id='umLimit'
                          label={umLabel}
                          value={value}
                          name={name}
                          onChange={onChange}
                          onBlur={onBlur}
                          startAdornment={
                            <InputAdornment position='start'>
                              <Tooltip
                                enterTouchDelay={0}
                                leaveTouchDelay={3500}
                                style={{ marginRight: '1em' }}
                                className='tooltip'
                                title={
                                  uninsuredMotoristToolTip
                                }
                                placement='top-start'
                              >
                                <ToolTipIcon />
                              </Tooltip>
                            </InputAdornment>
                          }
                        >
                          {umMenuList}
                        </Select>
                        <FormHelperText id='umLimitErrorText'>
                          {methods.errors &&
                            methods.errors.umLimit
                            ? methods.errors.umLimit.message
                            : ''}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </Grid>
                {
                  showUmpd ?
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Controller
                        control={methods.control}
                        name='umpdLimit'
                        defaultValue=''
                        render={({ onChange, value, name, onBlur }) => (
                          <FormControl
                            variant='outlined'
                            fullWidth
                            error={
                              !!(
                                methods.errors &&
                                methods.errors.umpdLimit
                              )
                            }
                          >
                            <InputLabel>Uninsured motorist Property Damage</InputLabel>
                            <Select
                              id='umpdLimit'
                              label='Uninsured motorist Property Damage'
                              value={value}
                              name={name}
                              onChange={onChange}
                              onBlur={onBlur}
                              startAdornment={
                                <InputAdornment position='start'>
                                  <Tooltip
                                    enterTouchDelay={0}
                                    leaveTouchDelay={3500}
                                    style={{ marginRight: '1em' }}
                                    className='tooltip'
                                    title={
                                      limits
                                        && limits.limits_deduct
                                        && limits.limits_deduct.umpd_limit
                                        && limits.limits_deduct.umpd_limit.description ?
                                        limits.limits_deduct.umpd_limit.description
                                        : 'Info is currently unavailable'
                                    }
                                    placement='top-start'
                                  >
                                    <ToolTipIcon />
                                  </Tooltip>
                                </InputAdornment>
                              }
                            >
                              {umpdMenuList}
                            </Select>
                            <FormHelperText id='umpdLimitErrorText'>
                              {methods.errors &&
                                methods.errors.umpdLimit
                                ? methods.errors.umpdLimit.message
                                : ''}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>
                    : null
                }
              </Grid>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  };

export default PolicyCoveragesEdit;