import { Driver } from '../models/motion';

export enum DocumentType {
  IdentityCard = 'ID',
  DriverLicense = 'DL',
  BankCard = 'CC',
  HealthCard = 'HC',
  MachineReadableTravelDoc = 'TD',
  DODAndCommonAccessCard = 'CAC'
}

export enum ComplianceType {
  MateriallyCompliant = 'M',
  FullyCompliant = 'F',
  NonCompliant = 'N'
}

export interface DriversLicense {
  address1: string,
  address2: string,
  birthdate: string,
  cardRevisionDate: string,
  city: string,
  classificationCode: string,
  complianceType: ComplianceType,
  country: string,
  countryCode: string,
  documentType: DocumentType,
  endorsementCodeDescription: string,
  /** Any endorsements on a driver's license which authorize the operation of specified types of vehicles or the operation of vehicles carrying specified loads. Endorsements shall be specific to classification of a driver's license. */
  endorsementsCode: string,
  expirationDate: string,
  eyeColor: string,
  firstName: string,
  fullName: string,
  gender: string,
  /** Date on which the hazardous material endorsement granted by the document is no longer valid. (MMDDCCYY for U.S., CCYYMMDD for Canada) */
  hazmatExpDate?: string,
  hairColor: string,
  height: string,
  iin: string,
  issueDate: string,
  issuedBy: string,
  jurisdictionCode: string,
  lastName: string,
  licenseNumber: string,
  /** DHS required field that indicates that the cardholder has temporary lawful status = “1” */
  limitedDurationDocument?: string,
  middleName: string,
  namePrefix: string,
  nameSuffix: string,
  organDonor: string,
  postalBox: string,
  postalCode: string,
  race: string,
  /** Jurisdiction-specific codes that represent restrictions to driving privileges (such as airbrakes, automatic transmission, daylight only,etc.) */
  restrictionCode: string,
  restrictionCodeDescription: string,
  vehicleClassCode: string,
  vehicleClassCodeDescription: string,
  veteran?: string,
  weightKG: string,
  weightLBS: string,
}


export const populatePniFromDriverLicense = (driverLicense: DriversLicense, setValue: void) => {
  const driversJSON = window.sessionStorage.getItem('drivers');
  let drivers = JSON.parse(driversJSON ?? '{}');
  let primaryDriver: Driver = {};

  drivers = drivers.map((d: Driver) => {
    if (!d.isPrimaryInsured) return d;

    primaryDriver = {
      ...d,
      address: driverLicense.address1,
      apartmentNumber: driverLicense.address2,
      birthDate: driverLicense.birthdate,
      city: driverLicense.city,
      firstName: driverLicense.firstName,
      gender: driverLicense.gender,
      lastName: driverLicense.lastName,
      stateLicensed: driverLicense.issuedBy,
      licenseNumber: driverLicense.licenseNumber,
      zip: driverLicense.postalCode.substr(0, 5),
      state: driverLicense.issuedBy,
    } as Driver;

    return primaryDriver;
  });

  if (drivers.length > 0) {
    window.sessionStorage.setItem('drivers', JSON.stringify(drivers));
  }

  return primaryDriver;
};