import React, { useState } from 'react';
import Bugsnag from '@bugsnag/js';
import { useHistory } from 'react-router';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme } from 'pretty-format';
import { Grid, TextField, Paper, IconButton, InputAdornment, Dialog, DialogTitle, DialogActions, CircularProgress, Button } from '@material-ui/core';
import './AgentLogin.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import WarningIcon from '@material-ui/icons/Warning';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Helmet } from 'react-helmet';
import { ReactComponent as LogoFull } from '../images/motion-agent.svg';
import { Agentuser } from '../models/motion';
import { GetConfirmation, GetForgotPassword } from '../utils/queries';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      textAlign: 'center',
      padding: '1.5em ',
      paddingBottom: '6em'
    },
  }),
);


const AgentForgotPassword: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const [page, setPage] = useState('forgotPass');
  const [username, setUsername] = useState('');
  const [open, setOpen] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [message, setMessage] = React.useState('');
  let schemaObj = {};
  if (page === 'forgotPass') {
    schemaObj = {
      email: yup.string().email().required('Please enter your email').trim(),
    };
  } else {
    schemaObj = {
      code: yup.string().required('Please enter your code').trim(),
      password: yup.string().required('Please enter a valid password')
        .matches(/^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
          { message: 'Please match the password requirements' }).trim(),
      confirmPassword: yup.string()
        .oneOf([yup.ref('password')], 'Passwords must match')
        .trim(),
    };
  }

  const schema = yup.object().shape(schemaObj);
  const { register, handleSubmit, errors } = useForm<Agentuser>({
    defaultValues: {
      email: '',
      code: '',
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(schema),
  });
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const onSubmit = async (data: Agentuser) => {
    setOpen(true);
    setIsError(false);
    setMessage('');
    try {
      if (page === 'forgotPass') {
        const resp = await GetForgotPassword(data.email);
        if (resp.status === 'success') {
          setOpen(false);
          setUsername(data.email);
          setPage('createPass');
        } else {
          setIsError(true);
          setOpen(true);
          setMessage('Sorry for the Inconvenience please contact customer service 844-425-0049');
        }
      } else {
        data.email = username;
        const resp = await GetConfirmation(data);
        if (resp.status === 'success') {
          setOpen(false);
          history.push('/agent');
        } else {
          setIsError(true);
          setOpen(true);
          setMessage('Sorry for the Inconvenience please contact customer service 844-425-0049');
        }
      }
    } catch (e) {
      setIsError(true);
      setOpen(true);
      setMessage('Sorry for the Inconvenience please contact customer service 844-425-0049');
      Bugsnag.notify(e);
    }
  };
  const handleForgotPassword = () => {
    history.push('/agent');
  };
  const handleResend = async () => {
    setOpen(true);
    setIsError(false);
    setMessage('');
    try {
      const resp = await GetForgotPassword(username);
      if (resp.status === 'success') {
        setOpen(false);
      } else {
        setOpen(false);
        setOpen(true);
        setMessage('Sorry for the Inconvenience please contact customer service 844-425-0049');
      }
    } catch (e) {
      Bugsnag.notify(e);
    }
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  return (
    <>
      <Helmet>
        <title>Agent Forgot Password - Motion Insurance</title>
      </Helmet>
      <div className={classes.root}>
        <Dialog
          open={open}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{!isError && <CircularProgress />} {isError && message}</DialogTitle>
          {isError && <DialogActions>
            <Button onClick={() => setOpen(false)} color='primary' autoFocus>
              Ok
            </Button>
          </DialogActions>}

        </Dialog>
        <Grid container spacing={3}>
          <Grid item lg={12} component={Paper} elevation={1} className='logo-paper'>
            <div className='logo-full'>
              <LogoFull />
            </div>
          </Grid>
          <Grid container spacing={3} className='agent-login'>
            <Grid item lg={4} />
            <Grid item lg={4}>
              {page === 'forgotPass' && <Paper className={classes.paper}>
                <h3>Need help with your password ?</h3>
                <p>Enter the email you use for Motion, and we’ll help you create a new password.</p>
                <form
                  id='initialForm'
                  className='import-form'
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Grid container spacing={3}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <TextField
                        id='email'
                        error={errors.email?.message !== undefined}
                        helperText={
                          errors.email?.message !== undefined
                            ? errors.email?.message
                            : ''
                        }
                        label='Email'
                        variant='outlined'
                        type='text'
                        name='email'
                        inputRef={register}
                        InputProps={{
                          endAdornment:
                            <InputAdornment position='end'>
                              {errors.email?.message !== undefined && <WarningIcon color='error' />}
                            </InputAdornment>
                          ,
                        }}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Button
                      id='lookUpMyInfo'
                      className='import-submit'
                      type='submit'
                      color='primary'
                      variant='contained'
                    >
                      Next
                    </Button>
                  </Grid>
                </form>
                <h4 className='return-login' onClick={handleForgotPassword}>Return to the log in</h4>
              </Paper>}
              {page === 'createPass' && <Paper className={classes.paper}>
                <h3>Create a password</h3>
                <span>Choose a password that is hard to guess and</span>
                <br />
                <span>unique to this account.</span>
                <br />
                <form
                  id='initialForm'
                  className='import-form'
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Grid container spacing={3}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <TextField
                        id='code'
                        error={errors.code?.message !== undefined}
                        helperText={
                          errors.code?.message !== undefined
                            ? errors.code?.message
                            : ''
                        }
                        label='Security code'
                        variant='outlined'
                        type='text'
                        name='code'
                        inputRef={register}
                        InputProps={{
                          endAdornment:
                            <InputAdornment position='end'>
                              <h4 className='forgot-email' onClick={handleResend}>Resend</h4>
                            </InputAdornment>
                          ,
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <TextField
                        error={errors.password?.message !== undefined}
                        helperText={
                          errors.password?.message !== undefined
                            ? errors.password?.message
                            : ''
                        }
                        label='Password'
                        variant='outlined'
                        type={showPassword ? 'text' : 'password'}
                        name='password'
                        inputRef={register}
                        InputProps={{
                          endAdornment:
                            <InputAdornment position='end'>
                              <IconButton
                                onClick={handleClickShowPassword}
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ,
                        }}
                        fullWidth

                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <TextField
                        id='confirmPassword'
                        error={errors.confirmPassword?.message !== undefined}
                        helperText={
                          errors.confirmPassword?.message !== undefined
                            ? errors.confirmPassword?.message
                            : ''
                        }
                        label='Confirm Password'
                        variant='outlined'
                        type={showConfirmPassword ? 'text' : 'password'}
                        name='confirmPassword'
                        inputRef={register}
                        InputProps={{
                          endAdornment:
                            <InputAdornment position='end'>
                              <IconButton
                                onClick={handleClickShowConfirmPassword}
                              >
                                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ,
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <p className='pass-instruction'>Password must be at least 8 characters in length have numbers and letters have at least one special character.
                      </p>
                    </Grid>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Button
                      id='lookUpMyInfo'
                      className='import-submit'
                      type='submit'
                      color='primary'
                      variant='contained'
                    >
                      Save
                    </Button>
                  </Grid>
                </form>
                <h4 className='return-login' onClick={handleForgotPassword}>Return to the log in</h4>
              </Paper>}
            </Grid>
            <Grid item lg={4} />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default AgentForgotPassword;