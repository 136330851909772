import React from 'react';
import NavItem from './SidebarNavItem';
import './Sidebar.scss';
import { ReactComponent as VerticalLine } from '../images/sidebar-nav-line.svg';

interface SidebarProps {
  navigationProgress: { 1: string, 2: string, 3: string }
}


const Sidebar: React.FC<SidebarProps> = ({ navigationProgress }) => {
  return (
    <div className='sidebar-wrapper' id='sidebar'>
      <div>
        <div>
          <NavItem status={navigationProgress[1]} index={1} text='Lookup your information' />
          <VerticalLine className='sidebar-item-separator' />
        </div>
        <div>
          <NavItem status={navigationProgress[2]} index={2} text='Get a quote' />
          <VerticalLine className='sidebar-item-separator' />
        </div>
        <div>
          <NavItem status={navigationProgress[3]} index={3} text='Get your insurance' />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
