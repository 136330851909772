import { Button, Grid, Modal, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import './MotionModal.scss';
import { ReactComponent as XClose } from '../images/x-close-icon.svg';
import { sendActiveCampaignEvent } from '../utils/activeCampaign';
import { getBrand } from '../utils/style';

export interface MotionModalProps {
  title: string;
  body: string | React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
  open: boolean;
  handleClose(): void;
  handleConfirm?(): void;
  closeText: string;
  confirmText?: string;
  canClose?: boolean;
  disclosureText?: boolean;
}

const MotionMod = withStyles({
  root: {
    position: 'absolute',
    width: 400,
    backgroundColor: 'white',
    border: '2px solid #000',
    padding: '2em',
  },
})(Modal);

const MotionModal: React.FC<MotionModalProps> =
  ({ title, body, open, closeText, confirmText, canClose = true, disclosureText = false, handleClose = ()=>{}, handleConfirm = ()=>{} }) => {
    const driversJson = window.sessionStorage.getItem('drivers');
    const drivers = JSON.parse(driversJson!);
    const notActiveStateInfoJson = window.sessionStorage.getItem('notActiveStateInfo');
    const notActiveStateInfo = JSON.parse(notActiveStateInfoJson!);
    const [selectedOption, setSelectedOption] = useState(false);
    const [selectionResponse, setSelectionResponse] = useState('');
    const handelButtonClick = (option: boolean) => {
      sendActiveCampaignEvent({
        email: notActiveStateInfo ? notActiveStateInfo.email : drivers[0].email!,
        first_name: notActiveStateInfo ? notActiveStateInfo.firstName : drivers[0].firstName!,
        last_name: notActiveStateInfo ? notActiveStateInfo.lastName : drivers[0].lastName!,
        phone: notActiveStateInfo ? notActiveStateInfo.phone : drivers[0].phone!,
        'field[%STATE%]': notActiveStateInfo ? notActiveStateInfo.state : drivers[0].state!,
        'field[%ZIP%]': notActiveStateInfo ? 'not provided' : drivers[0].zip!,
        'field[%ADDRESS%]': notActiveStateInfo ? 'not provided' : drivers[0].address!,
        'field[%INTERESTED%]': notActiveStateInfo ? 'not provided' : option,
        'field[%MEDIAPARTNER%]': notActiveStateInfo ? 'not provided' : 'not provided',
        'field[%UTMMEDIUM%]': notActiveStateInfo ? 'not provided' : 'not provided',
        'field[%UNIQUEID%]': notActiveStateInfo ? 'not provided' : 'not provided',
        'field[%CUSTOMER%]': notActiveStateInfo ? 'not provided' : '0',
        'field[%FULLQUOTEURL%]': notActiveStateInfo ? 'not provided' : window.origin,
        'field[%BRAND%]': notActiveStateInfo ? 'not provided' : getBrand(),
      });
      setSelectedOption(true);
      if (option) {
        handleConfirm()
      } else {
        handleClose()
      }
    };

    return (
      <Grid container justify='flex-start'>
        <MotionMod open={open} onClose={handleClose}>
          {!selectedOption ?
            <div className={disclosureText ? 'motion-modal-wrapper motion-modal-wrapper-disclosure' : 'motion-modal-wrapper'}>
              <div className='motion-modal-close-wrapper'>
                {
                  canClose &&
                  <button onClick={handleClose} type='button' className='motion-modal-close-x'>
                    <XClose />
                  </button>
                }
              </div>
              <div className='motion-modal-body-wrapper'>
                <div className='motion-modal-title'>
                  {title}
                </div>
                <div className='motion-modal-body'>
                  {body}
                </div>
                {
                  !confirmText && !handleConfirm && process.env.REACT_APP_ENV === 'production' ?
                    <div>
                      <Button
                        id='motionModalCloseButton'
                        onClick={() => handelButtonClick(true)}
                        color='primary'
                        variant='contained'
                        className='motion-modal-button-yes'
                      >
                        Yes, please notify me
                      </Button>
                      &nbsp;&nbsp;&nbsp;
                      <Button
                        id='motionModalCloseButton'
                        onClick={() => handelButtonClick(false)}
                        color='secondary'
                        variant='contained'
                        className='motion-modal-button-no'
                      >
                        No, don't notify me
                      </Button>
                    </div>
                    :
                    <div className='button-group-wrapper'>
                      {closeText ?
                        <Button
                          id='motionModalCloseButton'
                          onClick={handleClose}
                          color='secondary'
                          variant='contained'
                        >
                          {closeText}
                        </Button> 
                        : null
                      }
                      {confirmText ?
                        <div className='confirm-button'>
                          <Button
                            id='motionModalConfirmButton'
                            onClick={handleConfirm}
                            color='primary'
                            variant='contained'
                          >
                            {confirmText}
                          </Button>
                        </div>
                        : null
                      }
                    </div>
                }
              </div>
            </div> :
              <></>
          }
        </MotionMod>
      </Grid>
    );
  };

export default MotionModal;