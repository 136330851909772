import Bugsnag from '@bugsnag/js';
import ActiveCampaign from 'activecampaign';

const ac = new ActiveCampaign(
  `${process.env.REACT_APP_ACTIVE_CAMPAIGN_HOST}`,
  `${process.env.REACT_APP_ACTIVE_CAMPAIGN_KEY}`
);

export const sendActiveCampaignEvent = async (payload: any) => {
  try {
    await ac.api('contact/sync', payload);
  } catch (error) {
    Bugsnag.notify({ name: 'Active Campaign', message: error as string });
  }
};