import React, { Dispatch, SetStateAction } from 'react';

export const getPrimaryColor = () => {
  return getBrand() === 'motion' ? 'rgba(127,37,217,1)' : '#6968FF';
};

export const getSecondaryColor = () => {
  return getBrand() === 'motion' ? 'rgba(127,37,217,0.4)' : '#A6A8DD';
};

export const getNovoIconColor = () => {
  return getBrand() === 'motion' ? 'rgba(127,37,217,0.4)' : '#2E3192';
};

export const getSecondaryInitialColor = () => {
  return '#f56875';
};

export const getLightBackgroundColor = () => {
  return getBrand() === 'motion' ? 'rgba(127, 37, 217, .15)' : 'rgba(166, 168, 221, 0.15)';
};

export const getBrand = () => {
  return 'novo';
};

export const shouldDisplayMotionModal = () => {
  return window.location.origin.includes('novo');
};

export const getPhoneNumber = () => {
  const brand = getBrand();

  return brand === 'motion' ? '844-425-0049' : '888-606-0328';
};

export const shouldCoBrand = () => {
  const isPartner = false;
  const isCoBrandedPage = window.location.pathname.includes('review-policy')
    || window.location.pathname.includes('payment')
    || window.location.pathname.includes('welcome');

  return isPartner && isCoBrandedPage;
};

interface Context {
  brand: string;
  setBrand: Dispatch<SetStateAction<string>>;
  primaryColor?: string;
  setPrimaryColor?: Dispatch<SetStateAction<string>>;
  primaryHoverColor?: string;
  setPrimaryHoverColor?: Dispatch<SetStateAction<string>>;
  primaryDisabledColor?: string;
  setPrimaryDisabledColor?: Dispatch<SetStateAction<string>>;
  secondaryColor?: string;
  setSecondaryColor?: Dispatch<SetStateAction<string>>;
  secondaryInitialColor?: string;
  setSecondaryInitialColor?: Dispatch<SetStateAction<string>>;
  lightBackground?: string;
  setLightBackgroundColor?: Dispatch<SetStateAction<string>>;
  coBrand?: boolean;
  setCoBrand?: Dispatch<SetStateAction<boolean>>;
}
export const BrandContext = React.createContext({} as Context);

export const BrandProvider = BrandContext.Provider;