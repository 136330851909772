import { Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, Select, TextField, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Driver } from '../models/motion';
import { getStateMenuList } from '../utils/selectList';
import PlacesAutoComplete from './PlacesAutoComplete';

export interface BillingInformationProps {

}

const CustomFormControlLabel = withStyles({
  label: {
    fontSize: '0.875em',
  }
})(FormControlLabel);

const BillingInformation: React.FC<BillingInformationProps> = () => {
  const { control, errors, setValue } = useFormContext();
  const [stateMenuList] = useState(getStateMenuList());
  const [sameAddressInfo, setSameAddressInfo] = useState(false);
  const driversJson = window.sessionStorage.getItem('drivers');
  const primaryDriver =
    JSON.parse(driversJson!)
      .filter((d: Driver) => d.isPrimaryInsured)[0] as Driver;

  const handleCheckSameAddress =
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSameAddressInfo(event.target.checked);
      if (event.target.checked) {
        setValue('firstName', primaryDriver.firstName, { shouldValidate: true });
        setValue('lastName', primaryDriver.lastName, { shouldValidate: true });
        setValue('address', primaryDriver.address, { shouldValidate: true });
        setValue('city', primaryDriver.city, { shouldValidate: true });
        setValue('state', primaryDriver.state, { shouldValidate: true });
        setValue('zip', primaryDriver.zip, { shouldValidate: true });
      } else {
        setValue('firstName', '');
        setValue('lastName', '');
        setValue('address', '');
        setValue('city', '');
        setValue('state', '');
        setValue('zip', '');
      }
    };

  // places autocomplete
  const [streetNumber, setStreetNumber] = useState('');
  const [streetName, setStreetName] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');

  useEffect(() => {
    if (streetNumber !== '' && streetName !== '') {
      setValue('address', `${streetNumber} ${streetName}`);
    }
    if (city) {
      setValue('city', `${city}`);
    }
    if (state) {
      setValue('state', `${state}`);
    }
    if (zipCode) {
      setValue('zip', `${zipCode}`);
    }
  }, [setValue, streetNumber, streetName, city, state, zipCode]);

  return (
    <>
      <div className='payment-information-header'>
        Billing Information
      </div>
      <CustomFormControlLabel
        control={
          <Checkbox
            id='sameAddressInfoCheckbox'
            color='primary'
            checked={sameAddressInfo}
            onChange={handleCheckSameAddress}
          />
        }
        label='Same address as previously provided'
      />
      <Grid container spacing={2} justify='flex-start'>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Controller
            as={
              <TextField
                id='firstName'
                label='First name'
                variant='outlined'
                fullWidth
                error={
                  !!(
                    errors &&
                    errors.firstName
                  )
                }
                helperText={
                  errors &&
                    errors.firstName
                    ? errors.firstName.message
                    : ''
                }
              />
            }
            name='firstName'
            control={control}
            defaultValue=''
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Controller
            as={
              <TextField
                id='lastName'
                label='Last name'
                variant='outlined'
                fullWidth
                error={
                  !!(
                    errors &&
                    errors.lastName
                  )
                }
                helperText={
                  errors &&
                    errors.lastName
                    ? errors.lastName.message
                    : ''
                }
              />
            }
            name='lastName'
            control={control}
            defaultValue=''
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <PlacesAutoComplete
            id='address'
            name='address'
            defaultValue=''
            label='Address'
            error={
              !!(
                errors &&
                errors.address
              )
            }
            helperText={
              errors &&
                errors.address &&
                errors.address.message
                ? errors.address.message
                : ''
            }
            setStreetNumber={setStreetNumber}
            setStreetName={setStreetName}
            setCity={setCity}
            setState={setState}
            setZipCode={setZipCode}
          />
        </Grid>

        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Controller
            as={
              <TextField
                id='city'
                label='City'
                variant='outlined'
                fullWidth
                error={
                  !!(
                    errors &&
                    errors.city
                  )
                }
                helperText={
                  errors &&
                    errors.city
                    ? errors.city.message
                    : ''
                }
              />
            }
            name='city'
            control={control}
            defaultValue=''
          />
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Controller
            control={control}
            name='state'
            defaultValue=''
            render={({ onChange, value, name, onBlur }) => (
              <FormControl
                variant='outlined'
                fullWidth
                error={
                  !!(
                    errors &&
                    errors.state
                  )
                }
              >
                <InputLabel>State</InputLabel>
                <Select
                  id='state'
                  label='State'
                  value={value}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                >
                  {stateMenuList}
                </Select>
                <FormHelperText>
                  {
                    errors &&
                      errors.state
                      ? errors.state.message
                      : ''
                  }
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Controller
            as={
              <TextField
                id='zip'
                label='Zip'
                type='tel'
                variant='outlined'
                fullWidth
                error={
                  !!(
                    errors &&
                    errors.zip
                  )
                }
                helperText={
                  errors &&
                    errors.zip
                    ? errors.zip.message
                    : ''
                }
              />
            }
            name='zip'
            control={control}
            defaultValue=''
          />
        </Grid>
      </Grid>
    </>
  );
};

export default BillingInformation;