import React, { useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { ReactComponent as NovoLogo } from '../images/partnerImages/novo-logo.svg';
import { ReactComponent as LogoFull } from '../images/logo-full.svg';
import { ReactComponent as VerticalDash } from '../images/verticalDash.svg';
import { ReactComponent as XClose } from '../images/x-close-icon.svg';
import CommonModal from './Modal';
import './MotionNovoModal.scss';

const MotionNovoModal: React.FC<any> = ({ content, buttonContent, motionNovoOpen, updateMotionNovoOpen, isAgent }: any) => {

  const [open, setOpen] = useState(motionNovoOpen);
  const handleClose = () => {
    setOpen(false);
    updateMotionNovoOpen(false)
  };
  const resolution = window.screen.width === 280 || window.screen.width === 320 ? '13px' : '15px';
  const modalBody = (
    <div>
      <Grid
        container
        direction='row'
        justify='center'
        alignItems='center'
        className='grid-centre'
      >
        <div className='modal-style mn-modal'>
          <div id='simple-modal-title' className='modal-images'>
            <button type='button' className='motion-modal-close-x' onClick={handleClose}>
              <XClose />
            </button>
            <Grid
              container
              direction='row'
              justify='center'
              alignItems='center'
              className="mn-image-grid"
            >
              <Grid item md={2} sm={2} lg={2} xl={2} xs={2} className='grid-centre' />
              <Grid
                item
                md={4}
                sm={4}
                lg={4}
                xl={4}
                xs={4}
                className='grid-centre'
              >
                <LogoFull className='logo-motion mn-motionimg' />
              </Grid>
              <VerticalDash />
              <Grid
                item
                md={4}
                sm={4}
                lg={4}
                xl={4}
                xs={4}
                className='grid-centre'
              >
                <NovoLogo className="mn-novoimg" />
              </Grid>
              <Grid item md={2} sm={2} lg={2} xl={2} xs={2} className='grid-centre' >
              </Grid>
            </Grid>
          </div>
          <Grid
            container
            direction='column'
            justify='center'
            alignItems='center'
          >
            <div className='modal-text' style={{ fontFamily: 'raleway' }}>
              <h1 className='mn-heading'>Motion has joined Novo!</h1>
              {isAgent ? <p className='mn-content' style={{ fontSize: resolution }}>
                {content}
              </p> : <p className='mn-content' >
                {content}
              </p>}
              <Grid
                container
                direction='row'
                justify='center'
                alignItems='center'
              >
                <Grid
                  item
                  className='grid-centre mn-button'
                >
                  <Button
                    id='disclosuresModalSubmit'
                    type='submit'
                    onClick={handleClose}
                    color='primary'
                    variant='contained'
                  >
                    {buttonContent}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </div>
      </Grid>
    </div >
  );
  return <CommonModal modalBody={modalBody} open={open} />;
};

export default MotionNovoModal;
