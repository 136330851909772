import React, { useContext, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { ReactComponent as MotionLogo } from '../images/partnerImages/motionlogo.svg';
import { ReactComponent as NovoLogo } from '../images/partnerImages/novo-logo.svg';
import { ReactComponent as Plus } from '../images/partnerImages/plusicon.svg';
import { ReactComponent as ZebraLogo } from '../images/partnerImages/thezebra.svg';
import { ReactComponent as CompareLogo } from '../images/partnerImages/compare.svg';
import { ReactComponent as InsurifyLogo } from '../images/partnerImages/insurify.svg';
import { ReactComponent as MagicquoteLogo } from '../images/partnerImages/magicquote.svg';
import { ReactComponent as ItcLogo } from '../images/partnerImages/Itc.svg';
import CommonModal from './Modal';
import { Partner } from '../models/partner';
import { titleCaseWords } from '../utils/string';
import { BrandContext, getPrimaryColor } from '../utils/style';

const DisclosuresModal: React.FC = () => {
  const { brand } = useContext(BrandContext);
  const partnerInfoJson = window.sessionStorage.getItem('partner');
  const partnerInfo = JSON.parse(partnerInfoJson!) as Partner;
  const showPartnerInDisclosure =
    (partnerInfo && partnerInfo.partner && partnerInfo.partner.toLowerCase() === 'zebra')
    || (partnerInfo && partnerInfo.partner && partnerInfo.partner.toLowerCase() === 'the zebra')
    || (partnerInfo && partnerInfo.partner && partnerInfo.partner.toLowerCase() === 'compare.com')
    || (partnerInfo && partnerInfo.partner && partnerInfo.partner.toLowerCase() === 'compare')
    || (partnerInfo && partnerInfo.partner && partnerInfo.partner.toLowerCase() === 'insurify')
    || (partnerInfo && partnerInfo.partner && partnerInfo.partner.toLowerCase() === 'magicquote')
    || (partnerInfo && partnerInfo.partner && partnerInfo.partner.toLowerCase() === 'itc');
  const acceptedDisclosureOn = window.sessionStorage.getItem(
    'acceptedDisclosureOn'
  );
  const [open, setOpen] = useState(acceptedDisclosureOn === null);
  const handleClose = () => {
    window.sessionStorage.setItem(
      'acceptedDisclosureOn',
      JSON.stringify(new Date().toLocaleDateString('en-us'))
    );
    setOpen(false);
  };
  const modalBody = (
    <div>
      <Grid
        container
        direction='row'
        justify='center'
        alignItems='center'
        className='grid-centre'
      >
        <div className='modal-style'>
          <div id='simple-modal-title' className='modal-images'>
            <Grid
              container
              direction='row'
              justify='center'
              alignItems='center'
            >
              {
                partnerInfo && showPartnerInDisclosure ?
                  <Grid item md={1} sm={1} lg={1} xl={1} className='grid-centre' />
                  : null
              }
              <Grid
                item
                md={3}
                sm={3}
                lg={3}
                xl={3}
                xs={12}
                className='grid-centre'
              >
                {
                  brand === 'motion' ?
                    <MotionLogo className='logo-motion' />
                    :
                    <div>
                      <a href='https://www.novo.us' target='_blank'>
                        <NovoLogo />
                      </a>
                    </div>
                }
              </Grid>
              {
                partnerInfo && showPartnerInDisclosure ?
                  (
                    <>
                      <Grid
                        item
                        md={3}
                        sm={3}
                        lg={3}
                        xl={3}
                        xs={12}
                        className='grid-centre'
                      >
                        <Plus className='logo-plus' />
                      </Grid>
                      <Grid
                        item
                        md={3}
                        sm={3}
                        lg={3}
                        xl={3}
                        xs={12}
                        className='grid-centre'
                      >
                        {partnerInfo && partnerInfo.partner && partnerInfo.partner.toLowerCase() === 'zebra'
                          && <ZebraLogo className='logo-partner' />}
                        {partnerInfo && partnerInfo.partner && partnerInfo.partner.toLowerCase() === 'the zebra'
                          && <ZebraLogo className='logo-partner' />}
                        {partnerInfo && partnerInfo.partner && partnerInfo.partner.toLowerCase() === 'compare.com'
                          && <CompareLogo className='logo-partner' />}
                        {partnerInfo && partnerInfo.partner && partnerInfo.partner.toLowerCase() === 'compare'
                          && <CompareLogo className='logo-partner' />}
                        {partnerInfo && partnerInfo.partner && partnerInfo.partner.toLowerCase() === 'insurify'
                          && <InsurifyLogo className='logo-partner' />}
                        {partnerInfo && partnerInfo.partner && partnerInfo.partner.toLowerCase() === 'magicquote'
                          && <MagicquoteLogo className='logo-partner' />}
                        {partnerInfo && partnerInfo.partner && partnerInfo.partner.toLowerCase() === 'itc'
                          && <ItcLogo className='logo-partner' />}

                      </Grid>
                      <Grid item md={1} sm={1} lg={1} xl={1} className='grid-centre' />
                    </>
                  )
                  : null
              }
            </Grid>
          </div>
          <Grid
            container
            direction='column'
            justify='flex-start'
            alignItems='flex-start'
          >
            <div className='modal-text'>
              {
                partnerInfo && partnerInfo.partner && showPartnerInDisclosure ?
                  <p id='simple-modal-description' className='bold-content'>
                    {
                      `Welcome and thank you for visiting us from our trusted partner,
                ${titleCaseWords(partnerInfo.partner)}. For your convenience, we’ve imported your info from
                their site. Please read the disclosure below.`
                    }
                  </p>
                  : null
              }
              <p id='simple-modal-title'><b>To offer you the best quote...</b></p>
              <p id='simple-modal-description'>
                The more accurate your response, the better the quote we can provide. Accidents and violations discovered only by consumer reports cause rates to be higher than if they were self-disclosed.
              </p>
              <p>
                So let’s keep it simple and find you the best premiums possible.
              </p>
              <p>
                Understand how your private info is used even more by checking out our{' '}
                <a
                  href={brand === 'motion' ? 'https://motionauto.io/telematics-privacy-policy.html' : 'https://www.novo.us/telematics-policy'}
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{ color: getPrimaryColor() }}
                >
                  Telematics Privacy Policy
                </a> {' '}
                 and {' '}
                <a
                  href={brand === 'motion' ? 'https://www.motionauto.io/privacy-policy.html' : 'https://www.novo.us/privacy-policy'}
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{ color: getPrimaryColor() }}
                >
                  Privacy Policy
                </a>
                .
              </p>
              <Grid
                container
                direction='row'
                justify='flex-start'
                alignItems='flex-start'
              >
                <Grid
                  item
                  md={4}
                  sm={4}
                  lg={4}
                  xl={4}
                  className='grid-centre'
                />
                <Grid
                  item
                  md={4}
                  sm={4}
                  lg={4}
                  xl={4}
                  className='grid-centre'
                />
                <Grid
                  item
                  md={4}
                  sm={4}
                  lg={4}
                  xl={4}
                  xs={12}
                  className='grid-centre'
                >
                  <Button
                    id='disclosuresModalSubmit'
                    type='submit'
                    onClick={handleClose}
                    color='primary'
                    variant='contained'
                  >
                    Continue
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </div>
      </Grid>
    </div>
  );
  return <CommonModal modalBody={modalBody} open={open} />;
};

export default DisclosuresModal;
