import { Button, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useQuery } from 'react-query';
import { canMakePayment, initPaymentRequest, paymentRequestClicked, PaymentRequestPayload, PaymentRequestPolicyInfo } from '../utils/paymentRequest';
import { ReactComponent as ToolTipIcon } from '../images/tool-tip.svg';
import { GetOffer } from '../utils/queries';

import './PaymentRequestApiButton.scss';

export interface PaymentRequestApiButtonProps {
  setPaymentRequestSuccess: React.Dispatch<React.SetStateAction<boolean>>;
}

const PaymentRequestApiButton: React.FC<PaymentRequestApiButtonProps> = ({ setPaymentRequestSuccess }) => {
  const [useDigitalWallet, setUseDigitalWallet] = useState<boolean>(false);
  useEffect(() => {
    setCanMakePayment();
  }, []);
  const setCanMakePayment = async () => {
    const result = await canMakePayment();
    setUseDigitalWallet(result);
  };
  const { control } = useFormContext();
  const watchPaymentAmount = useWatch({
    control,
    name: 'paymentAmount',
    defaultValue: ''
  });
  const watchPaymentType = useWatch({
    control,
    name: 'paymentType',
    defaultValue: ''
  });
  const motionTransactionId = window.sessionStorage.getItem('motionTransactionId');
  const selectedTier = window.sessionStorage.getItem('selectedTier');
  const { data: offerData } = useQuery(`offer-${motionTransactionId}`, () => GetOffer(motionTransactionId!));


  const handlePaymentClick = () => {
    const policyInfo = {
      selection: selectedTier,
      paidInFull: watchPaymentType === 'payInFull',
      policyId: offerData.offer_id,
      rateId: motionTransactionId,
    } as PaymentRequestPolicyInfo;
    const payload = {
      currency: 'USD',
      totalAmount: watchPaymentAmount,
      totalLabel: 'Total',
      items: [
        {
          currency: 'USD',
          amount: watchPaymentAmount,
          label: `${watchPaymentType === 'payInFull' ? 'Full premium' : 'Initial payment'}`,
        }
      ]
    } as PaymentRequestPayload;
    const request = initPaymentRequest(payload);

    paymentRequestClicked(request, policyInfo, setPaymentRequestSuccess);
  };


  return (
    <>
      {
        useDigitalWallet && offerData &&
        <>
          <div className='payment-request-button-wrapper'>
            <Button id='mobileWalletButton' color='primary' variant='contained' onClick={handlePaymentClick}>Make payment with mobile wallet</Button>
            <div className='payment-request-button-spacer payment-request-button-message'>
              or enter payment information below
            </div>
            <div className='payment-request-button-spacer'>
              <Tooltip
                enterTouchDelay={0}
                leaveTouchDelay={4000}
                className='tooltip'
                title='Paying with your wallet will use any payment methods you have stored in your browser or digital wallet. We currently accept all major credit cards.'
                placement='right'
              >
                <ToolTipIcon />
              </Tooltip>
            </div>
          </div>
          <div className='payment-line-separator' />
        </>
      }
    </>
  );
};

export default PaymentRequestApiButton;
