import { yupResolver } from '@hookform/resolvers';
import Bugsnag, { NotifiableError } from '@bugsnag/js';
import React, { useContext, useEffect, useState } from 'react';
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import { Helmet } from 'react-helmet';
import { Button } from '@material-ui/core';
import DriverComponent from '../components/Driver';
import VehicleComponent from '../components/Vehicle';
import { ReactComponent as AvatarSmall } from '../images/avatar-small.svg';
import { ReactComponent as AvatarSmallNovo } from '../images/avatar-small-novo.svg';
import { ReactComponent as CarSmall } from '../images/car-front-small.svg';
import { ReactComponent as CarSmallNovo } from '../images/car-front-small-novo.svg';
import './ReviewInfo.scss';
import { initiateQuote } from '../utils/mutations';
import PageHeader from '../components/PageHeader';
import { Partner } from '../models/partner';
import { getReviewInfoSchema } from '../utils/formValidation';
import MotionToast from '../components/MotionToast';
import MotionModal from '../components/MotionModal';
import { BrandContext, getPhoneNumber } from '../utils/style';

import {
  VehicleFromExistingPolicyRule,
  DriverFromExistingPolicyRule,
  DuplicateVinRule,
  HighRiskQuoteRule,
  HasDriver,
  HasVehicle,
  MarriedSpouseRule,
  VehicleCountRule,
  OddNumberSpouseRule,
  PniAddressMatchesGaragingAddress,
  RideshareRule,
  GrossVehicleWeightRule,
  IRule,
  RateCallEvent, 
  RateCallEventController, 
  Driver,
  Vehicle,
  ValidationError,
  TooManyIncidentsRule,
  VehicleTypeAndConstructionRule
}  from '@novo/rate-call-event-validation'

type FormValues = {
  drivers: Driver[];
  vehicles: Vehicle[];
  hasCurrentCoverage?: string;
  currentlyInsured?: string
  currentCoverageCarrier?: string;
  currentCoveragePolicyStartDate?: string;
  currentCoverageBiLimit?: string;
  isPolicyExpired?: string;
  carInsuranceForSixMonths?: string;
  dueToAMilitaryDeployment?: string;
  withoutCarInsuranceForThirtyOneDays?: string;
  monthContinuouslyInsured?: string;
  yearContinuouslyInsured?: any;
  totalMonthsContinuouslyInsured?: number;
  startMonthDeployed?: string;
  startYearDeployed?: string;
  endMonthDeployed?: string;
  endYearDeployed?: string;
  monthInsuredPrior?: string;
  yearInsuredPrior?: string;
  totalMonthsInsuredPrior?: number;
  currentCoverageContinuouslyInsured?: string;
  recentInsuranceProvider?: string;
  priorBodilyLimit?: string;
};

interface ReviewInfoProps {
  navigationProgress: { 1: string, 2: string, 3: string };
  setNavigationProgress(): void;
}

const ReviewInfo: React.FC<ReviewInfoProps> = ({ navigationProgress, setNavigationProgress }) => {
  const [quoteSubmitMessage, setQuoteSubmitMessage] = useState('');
  const [quoteSubmitToast, setQuoteSubmitToast] = useState(false);
  const [createAccountSeverity, setCreateAccountSeverity] = useState('success' as 'success' | 'error' | 'info' | 'warning');
  
  const handleDisplayToast = (severity: 'success' | 'error' | 'info' | 'warning', message: string) => {
    setCreateAccountSeverity(severity);
    setQuoteSubmitToast(true);
    setQuoteSubmitMessage(message);
    setTimeout(() => {
      setQuoteSubmitToast(false);
    }, 6000);
  };
  
  const [stopQuoteModal, setStopQuoteModal] = useState(false);
  const [stopQuoteBody, setStopQuoteBody] = useState('');
  const handleStopQuoteModal = (body: string) => {
    setStopQuoteBody(body);
    setStopQuoteModal(true);
  };

  useEffect(() => {
    (window as any).nid('start', window.location.pathname);
  }, []);  



  const [inEditMode, setInEditMode] = useState(true);
  const handleEditClick = () => {
    setInEditMode(!inEditMode);
  };
  const { brand } = useContext(BrandContext);
  const [mutate, info] = useMutation(initiateQuote);
  const history = useHistory();
  const driversJson = window.sessionStorage.getItem('drivers');
  const drivers = JSON.parse(driversJson!) as Driver[];
  const vehiclesJson = window.sessionStorage.getItem('vehicles');
  const vehicles = JSON.parse(vehiclesJson!) as Vehicle[];
  const partnerJson = window.sessionStorage.getItem('partner');
  const partner = JSON.parse(partnerJson!) as Partner;
  const sessionId = window.sessionStorage.getItem('sessionId');
  const motionauto_offer_id = window.sessionStorage.getItem('motionautoOfferId') || '';
  const queryParams = window.sessionStorage.getItem('queryParams') || '';
  // const household_id = window.sessionStorage.getItem('householdId');
  const date = window.sessionStorage.getItem('startDate') || '';
  
  const agentJson = window.localStorage.getItem('agentData');
  const agent = JSON.parse(agentJson!) || null;
  const isMotionAgent = agent && agent.channel === 'Motion';

  const schema = yup.object().shape(getReviewInfoSchema());
  const methods = useForm({
    defaultValues: {
      drivers: [...drivers],
      // if no vehicles exist stub a new vehicle
      vehicles: vehicles && vehicles.length > 0 ? [...vehicles] : [{
        primaryUse: 'Commute',
        ownershipType: 'Own',
        ownershipLength: '',
        primaryZipCode: drivers[0].zip || '',
        state: drivers[0].state || '',
        rideshare: false,
      }],
    },
    resolver: yupResolver(schema),
  });
  
  const { control, errors } = methods;
  const {
    fields: driverFields,
    append: driverAppend,
    remove: driverRemove,
  } = useFieldArray({
    control,
    name: 'drivers',
  });
  const {
    fields: vehicleFields,
    append: vehicleAppend,
    remove: vehicleRemove,
  } = useFieldArray({
    control,
    name: 'vehicles',
  });
  const { handleSubmit } = methods;

  const confirmStopQuote = () => {
    window.location.href = `${getPhoneNumber().replace(/-/g, '')}`;
    setStopQuoteModal(false);
  };
  const cancelStopQuote = () => {
    setStopQuoteModal(false);
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const rateCallEventController = new RateCallEventController()
    window.sessionStorage.setItem('requote', '');
    // save drivers
    const updatedDrivers = await rateCallEventController.prepareDrivers(data.drivers)
    window.sessionStorage.setItem('drivers', JSON.stringify(updatedDrivers));

    // save vehicles
    const updatedVehicles = await rateCallEventController.prepareVehicles(data.vehicles);
    window.sessionStorage.setItem('vehicles', JSON.stringify(updatedVehicles));

    // prepare prior coverage
    const currentCoverage = await rateCallEventController.prepareCurrentCoverage(data)
    // window.sessionStorage.setItem('priorCoverage', JSON.stringify(currentCoverage));

    // Policy coverage validationn for rate call
    const policyCoverageJson = window.sessionStorage.getItem('policyCoverage');
    const existingPolicyCoverage = JSON.parse(policyCoverageJson!) || {};

    let policyCoverage = await rateCallEventController.preparePolicyCoverage(existingPolicyCoverage)
    const isTestLead = await rateCallEventController.prepareTestLead(drivers)
    const trackingData = window.sessionStorage.getItem('tracking')
    const trackingDataJson = JSON.parse(trackingData!);
    const tracking = await rateCallEventController.prepareTracking(trackingDataJson)
    
    // in the event agentData exists in local storage overwrite agentId and channel (partner)
    if (agent !== null && agent.agentId && agent.channel) {
      rateCallEventController.agent_id = agent.agentId
      rateCallEventController.channel = agent.channel
    }

    const newDate = date ? (new Date(date) < new Date() ? new Date() : new Date(date)) : new Date()
    const utcDateString = newDate.toISOString();
    const utcDate = utcDateString.split('T')[0];

    const rateCallEvent:RateCallEvent = await rateCallEventController.prepareRateCallEvent({
      effectiveAt: utcDate,
      doAsync: true,
      bind: true,
      brand: brand === 'novo' ? 'novo' : '',
    })
    const canSkipValidation = isMotionAgent && process.env.REACT_APP_ENV === 'production'

    const validateRule = async (rule:IRule, rateCallEvent:RateCallEvent):Promise<boolean> => {
      return await rule(rateCallEvent).catch((validationError:ValidationError) => {
        console.log(validationError)
        return false
      }) as boolean
    }
    
    if (!await validateRule(DuplicateVinRule, rateCallEvent)) {
      handleDisplayToast('error', 'Oops, it looks like you already added this vehicle. Either add a different vehicle or delete the duplicate.')
      return
    }

    if (!await validateRule(HasDriver, rateCallEvent)) {
      handleDisplayToast('error', 'Oops, add at least one driver to get a quote.')
      return
    }

    if (!await validateRule(HasVehicle, rateCallEvent)) {
      handleDisplayToast('error', 'Oops, add at least one vehicle to get a quote.')
      return
    }

    if (!await validateRule(HighRiskQuoteRule, rateCallEvent)) {
      handleStopQuoteModal('Please call our customer service team for a quote.')
      return
    }

    
    if (!await validateRule(RideshareRule, rateCallEvent)) {
      handleStopQuoteModal("Please call customer support for assistance with this quote.")
      return
    }

    if (!await validateRule(PniAddressMatchesGaragingAddress, rateCallEvent)) {
      handleStopQuoteModal('Please call customer support for assistance with this quote.')
      return
    }

    if (!await validateRule(VehicleTypeAndConstructionRule, rateCallEvent)) {
      // TODO: Stop quote for now?
      handleStopQuoteModal('Please call customer support for assistance with this quote.')
      return
      console.log('truck/construction trigger')
    }

    if (!await validateRule(GrossVehicleWeightRule, rateCallEvent)) {
      handleStopQuoteModal('Please call customer support for assistance with this quote.')
      return
    }

    if (!await validateRule(TooManyIncidentsRule, rateCallEvent)) {
      handleStopQuoteModal('Please call customer support for assistance with this quote.')
      return
    }
    
    if (canSkipValidation) {
      if (!await validateRule(DriverFromExistingPolicyRule,rateCallEvent)) {
        handleStopQuoteModal('Oops, one of the included drivers already exists on an current policy. Please call customer support for assistance with this quote.')
        return
      }
      
      if (!await validateRule(VehicleFromExistingPolicyRule,rateCallEvent)) {
        handleStopQuoteModal('Oops, one of the included vehicles already exists on an current policy. Please call customer support for assistance with this quote.')
        return
      }

      if (!await validateRule(VehicleCountRule, rateCallEvent)) {
        handleStopQuoteModal('Please add all driver-age people in the household.')
        return
      }

      if (!await validateRule(MarriedSpouseRule, rateCallEvent)) {
        handleStopQuoteModal('Please add your spouse for an accurate quote')
        return
      }

      if (!await validateRule(OddNumberSpouseRule, rateCallEvent)) {
        handleStopQuoteModal("You've indicated that one or more drivers are married, please enter their spouse.")
        return
      }
    }

    try {
      await mutate({
        input: rateCallEvent
      });
    } catch (e) {
      Bugsnag.notify(e as NotifiableError);
    }

    // update navigation
    window.sessionStorage.setItem('navigationProgress',
      JSON.stringify({ 1: 'complete', 2: 'active', 3: 'inactive' }));
    setNavigationProgress();
    history.push(`/quote${queryParams}`);
  };

  if (info.data) {
    window.sessionStorage.setItem('executionArn', info.data.executionArn);
  }

  const onSubmitError = (formErrors: any) => {
    if (formErrors.vehicles
      && formErrors.vehicles.some((v: any) => {
        return v.ownershipLength
          && v.ownershipType
          && v.primaryUse
          && v.primaryZipCode
          && v.year;
      })) {
      handleDisplayToast('error', 'Please delete any unused add vehicle forms or click "Find my car".');
    }
  };
  return (
    <>
      <Helmet>
        <title>{`Review Info - ${brand === 'motion' ? 'Motion Insurance' : 'Novo Insurance'}`}</title>
        {
          brand === 'motion' ?
            <link rel='icon' href='/favicon.ico' />
            :
            <link rel='icon' href='/novo-fav.ico' />
        }
      </Helmet>
      <MotionToast
        severity={createAccountSeverity as 'success' | 'error' | 'info' | 'warning'}
        message={quoteSubmitMessage}
        open={quoteSubmitToast}
      />
      <MotionModal
        title='Get a quote?'
        body={stopQuoteBody}
        open={stopQuoteModal}
        confirmText={`Call ${getPhoneNumber()}`}
        handleConfirm={confirmStopQuote}
        closeText='Cancel'
        handleClose={cancelStopQuote}
      />
      <PageHeader
        icon='avatarSuccess'
        header='Let’s get to know each other...'
        description='Congrats! You’re minutes away from your quote. Verify we have the correct information below.'
      />
      <div className='drivers-header'>
        <div className='review-info-header'>
          Drivers:
        </div>
        {!inEditMode ?
          <Button
            onClick={handleEditClick}
            color='secondary'
            variant='contained'
          >
            Edit
          </Button>
          : null}
      </div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit, onSubmitError)}>
          <div className='drivers-wrapper'>
            {driverFields.map((item, index) => {
              return (
                <DriverComponent
                  key={item.id}
                  driver={item}
                  index={index}
                  inEditMode={inEditMode}
                  isAccordionOpen={true}
                  remove={() => {
                    driverRemove(index);
                  }}
                  maritalStatus={(data) => {
                    if (index === 0 && drivers[index].isPrimaryInsured && data === 'Married') {
                      driverAppend({
                        firstName: '',
                        lastName: '',
                        canBeRemoved: true,
                        driverType: 'rated',
                        relationshipToPrimaryDriver:'Spouse'
                      });
                    }
                  }}
                />
              );
            })}
            <p className='drivers-notice'>You, your spouse, and all driving age household members are required to be added to the policy as additional drivers or excluded. Please note, if you choose to exclude a driver, this policy will NOT include any coverage for that individual. If a claim arises and the excluded driver or a licensed household member not listed on the policy is the driver of the insured vehicle at the time of the accident the claim will be denied.</p>
            <div className='add-driver-wrapper'>
              <Button
                id='reviewInfoAddDriverButton'
                type='button'
                startIcon={brand === 'motion' ? <AvatarSmall /> : <AvatarSmallNovo />}
                onClick={() => {
                  driverAppend({
                    firstName: '',
                    lastName: '',
                    canBeRemoved: true,
                    driverType: 'rated',
                  });
                }}
                color='secondary'
                variant='contained'
              >
                Add another driver
              </Button>
            </div>
          </div>

          <div className='vehicle-info-header'>
            Vehicles:
          </div>
          <div className='review-vehicles-wrapper'>
            {vehicleFields.map((item, index) => {
              return (
                <VehicleComponent
                  key={item.id}
                  vehicle={item}
                  index={index}
                  showCoverages={false}
                  isAccordionOpen={true}
                  state={drivers[0].state}
                  validateEve={() => {
                    if (item.ownershipType && item.primaryUse && item.primaryZipCode)
                      control.updateFormState({ errors: { vehicles: [] } });
                  }}
                  remove={() => {
                    vehicleRemove(index);
                  }}
                />
              );
            })}
            <div className='add-driver-wrapper'>
              <Button
                id='reviewInfoAddVehicleButton'
                type='button'
                startIcon={brand === 'motion' ? <CarSmall /> : <CarSmallNovo />}
                onClick={() => {
                  vehicleAppend({
                    primaryUse: 'Commute',
                    ownershipType: 'Own',
                    ownershipLength: '',
                    primaryZipCode: drivers[0].zip || '',
                    state: drivers[0].state || '',
                  });
                }}
                color='secondary'
                variant='contained'
              >
                Add another vehicle
              </Button>
            </div>
          </div>
          <div className='review-info-submit-wrapper'>
            <Button
              id='reviewInfoGetQuoteButton'
              type='submit'
              color='primary'
              variant='contained'
              disabled={info.isLoading}
            >
              Get quote
            </Button>
            {
              Object.keys(errors).length > 0 &&
              (Object.values(errors).some(v => (v!.length > 0 || v!.hasOwnProperty('message')))) &&
              <div className='form-error-message'>
                See error(s) above
              </div>
            }
          </div>
        </form>
      </FormProvider>
    </>
  );
};
export default ReviewInfo;
