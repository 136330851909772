import { Deductible } from '../models/motion';

export const getVehicleCoveragesByTier =
  (tier: string, factors: Deductible) => {
    const result = {
      collisionDeductible: factors.coll_limit,
      comprehensiveDeductible: factors.comp_limit,
      rental: factors.rent_limit,
      towing: factors.tow_limit,
      loan: factors.loan_limit,
      uninsuredMotoristPropertyDamage: factors.umpd_limit,
    };
    return result;
  };