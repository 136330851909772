export interface State {
  display: string;
  value: string;
}

export const usStateList: State[] = [
  { display: 'Alabama', value: 'AL' },
  { display: 'Alaska', value: 'AK' },
  { display: 'Arizona', value: 'AZ' },
  { display: 'Arkansas', value: 'AR' },
  { display: 'California', value: 'CA' },
  { display: 'Colorado', value: 'CO' },
  { display: 'Connecticut', value: 'CT' },
  { display: 'Delaware', value: 'DE' },
  { display: 'Florida', value: 'FL' },
  { display: 'Georgia', value: 'GA' },
  { display: 'Hawaii', value: 'HI' },
  { display: 'Idaho', value: 'ID' },
  { display: 'Illinois', value: 'IL' },
  { display: 'Indiana', value: 'IN' },
  { display: 'Iowa', value: 'IA' },
  { display: 'Kansas', value: 'KS' },
  { display: 'Kentucky', value: 'KY' },
  { display: 'Louisiana', value: 'LA' },
  { display: 'Maine', value: 'ME' },
  { display: 'Maryland', value: 'MD' },
  { display: 'Massachusetts', value: 'MA' },
  { display: 'Michigan', value: 'MI' },
  { display: 'Minnesota', value: 'MN' },
  { display: 'Mississippi', value: 'MS' },
  { display: 'Missouri', value: 'MO' },
  { display: 'Montana', value: 'MT' },
  { display: 'Nebraska', value: 'NE' },
  { display: 'Nevada', value: 'NV' },
  { display: 'New Hampshire', value: 'NH' },
  { display: 'New Jersey', value: 'NJ' },
  { display: 'New Mexico', value: 'NM' },
  { display: 'New York', value: 'NY' },
  { display: 'North Carolina', value: 'NC' },
  { display: 'North Dakota', value: 'ND' },
  { display: 'Ohio', value: 'OH' },
  { display: 'Oklahoma', value: 'OK' },
  { display: 'Oregon', value: 'OR' },
  { display: 'Pennsylvania', value: 'PA' },
  { display: 'Rhode Island', value: 'RI' },
  { display: 'South Carolina', value: 'SC' },
  { display: 'South Dakota', value: 'SD' },
  { display: 'Tennessee', value: 'TN' },
  { display: 'Texas', value: 'TX' },
  { display: 'Utah', value: 'UT' },
  { display: 'Vermont', value: 'VT' },
  { display: 'Virginia', value: 'VA' },
  { display: 'Washington', value: 'WA' },
  { display: 'Washington, D.C.', value: 'DC' },
  { display: 'West Virginia', value: 'WV' },
  { display: 'Wisconsin', value: 'WI' },
  { display: 'Wyoming', value: 'WY' },
];


export const monthList: State[] = [
  { display: 'January', value: '01' },
  { display: 'February', value: '02' },
  { display: 'March', value: '03' },
  { display: 'April', value: '04' },
  { display: 'May', value: '05' },
  { display: 'June', value: '06' },
  { display: 'July', value: '07' },
  { display: 'August', value: '08' },
  { display: 'September', value: '09' },
  { display: 'October', value: '10' },
  { display: 'November', value: '11' },
  { display: 'December', value: '12' },
];
