
export const agentSearch = (initResults: any[], keyword: any, keys: any[]) => {
  const filteredResults = initResults.filter((row: any, index: any) => {
    if (keyword) {
      const isMatched = [];
      for (const key of keys) {
        isMatched.push(row[key]);
      }
      return isMatched.join(' ').toLowerCase().indexOf(keyword.toLowerCase()) >= 0;
    }
    return row;


  });
  return filteredResults;
};