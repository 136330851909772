import React from 'react';
import './PolicyCoverages.scss';
// import NumberFormat from 'react-number-format';
import { Tooltip } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';

import { ReactComponent as ToolTipIcon } from '../images/tool-tip.svg';
import { Deductible } from '../models/motion';

export interface PolicyCoveragesProps {
  factors: Deductible;
  limits: any;
}

const PolicyCoverages: React.FC<PolicyCoveragesProps> =
  ({ factors, limits }) => {

    const methods = useFormContext();
    const { watch } = methods;
    const watchPrimaryDriverState = watch('drivers[0].state');
    const showUmpd = watchPrimaryDriverState
      && watchPrimaryDriverState.toLowerCase() === 'tn'


    let medicalToolTip = 'Information is currently unavailable';
    if (limits && limits.limits_deduct.med_limit) {
      medicalToolTip = limits.limits_deduct.med_limit.description;
    } else if (limits && limits.limits_deduct.personalInjuryLimit) {
      medicalToolTip = limits.limits_deduct.personalInjuryLimit.description;
    }
    let uninsuredMotoristToolTip = 'Information is currently unavailable';
    if (limits && limits.limits_deduct.um_limit) {
      uninsuredMotoristToolTip = limits.limits_deduct.um_limit.description;
    } else if (limits && limits.limits_deduct.umbi_limit) {
      uninsuredMotoristToolTip = limits.limits_deduct.umbi_limit.description;
    }

    let uninsuredMotoristPDToolTip = 'Information is currently unavailable';
    if (limits && limits.limits_deduct.umpd_limit) {
      uninsuredMotoristPDToolTip = limits.limits_deduct.umpd_limit.description;
    }

    if (factors.uim_limit === '') {
      factors.uim_limit = 'DECLINED';
    }
    if (limits && limits.state && limits.state.toLowerCase() === 'ut') {
      factors.uim_limit = factors && factors.uim_limit && factors.uim_limit.toLowerCase() === 'none' ? 'DECLINED' : factors.uim_limit;
      factors.umbi_limit = factors && factors.umbi_limit && factors.umbi_limit.toLowerCase() === 'none' ? 'DECLINED' : factors.umbi_limit;
    }
    if (limits && limits.state && limits.state.toLowerCase() === 'az') {
      factors.med_limit = factors && factors.med_limit && factors.med_limit.toLowerCase() === 'none' ? 'DECLINED' : factors.med_limit;
    }

    return (
      <div className='policy-coverage-wrapper'>
        <div className='premium-factor-wrapper'>
          <div className='premium-factor-row'>
            <div>Bodily Injury</div>
            <div className='premium-factor-row-right'>
              {
                limits &&
                  limits
                    .limits_deduct
                    .bi_limit
                    .options
                    .filter((o: { display: string, value: string }) =>
                      o.value === factors.bi_limit).length > 0 ?
                  limits
                    .limits_deduct
                    .bi_limit
                    .options
                    .filter((o: { display: string, value: string }) =>
                      o.value === factors.bi_limit)[0]
                    .display
                  : ''
              }
              <div className='premium-factor-tooltip'>
                <Tooltip
                  enterTouchDelay={0}
                  leaveTouchDelay={3500}
                  className='tooltip'
                  title={
                    limits ?
                      limits.limits_deduct.bi_limit.description
                      : 'Information not currently available'
                  }
                  placement='top-start'
                >
                  <ToolTipIcon />
                </Tooltip>
              </div>
            </div>
          </div>
          <div className='premium-factor-row'>
            <div>{factors.med_limit ? 'Medical' : 'Personal injury protection'}</div>
            <div className='premium-factor-row-right'>
              {
                limits && limits.limits_deduct.med_limit &&
                  limits
                    .limits_deduct
                    .med_limit
                    .options
                    .filter((o: { display: string, value: string }) =>
                      o.value === factors.med_limit).length > 0 ?
                  limits
                    .limits_deduct
                    .med_limit
                    .options
                    .filter((o: { display: string, value: string }) =>
                      o.value === factors.med_limit)[0]
                    .display
                  : ''
              }
              {
                limits && limits.limits_deduct.personalInjuryLimit &&
                  limits
                    .limits_deduct
                    .personalInjuryLimit
                    .options
                    .filter((o: { display: string, value: string }) =>
                      o.value === factors.pip_limit).length > 0 ?
                  limits
                    .limits_deduct
                    .personalInjuryLimit
                    .options
                    .filter((o: { display: string, value: string }) =>
                      o.value === factors.pip_limit)[0]
                    .display
                  : ''
              }
              <div className='premium-factor-tooltip'>
                <Tooltip
                  enterTouchDelay={0}
                  leaveTouchDelay={3500}
                  className='tooltip'
                  title={
                    medicalToolTip
                  }
                  placement='top-start'
                >
                  <ToolTipIcon />
                </Tooltip>
              </div>
            </div>
          </div>
          <div className='premium-factor-row'>
            <div>Property damage</div>
            <div className='premium-factor-row-right'>
              {
                limits && limits.limits_deduct.pd_limit &&
                  limits
                    .limits_deduct
                    .pd_limit
                    .options
                    .filter((o: { display: string, value: string }) =>
                      o.value === factors.pd_limit).length > 0 ?
                  limits
                    .limits_deduct
                    .pd_limit
                    .options
                    .filter((o: { display: string, value: string }) =>
                      o.value === factors.pd_limit)[0]
                    .display
                  : ''
              }
              <div className='premium-factor-tooltip'>
                <Tooltip
                  enterTouchDelay={0}
                  leaveTouchDelay={3500}
                  className='tooltip'
                  title={
                    limits ?
                      limits.limits_deduct.pd_limit.description
                      : 'Information not currently available'
                  }
                  placement='top-start'
                >
                  <ToolTipIcon />
                </Tooltip>
              </div>
            </div>
          </div>
          {
            limits && limits.limits_deduct.uim_limit && factors.uim_limit.length > 0 &&
            <div className='premium-factor-row'>

              <div>Under insured motorist</div>
              <div className='premium-factor-row-right'>
                {
                  limits && limits.limits_deduct.uim_limit && factors.uim_limit.length > 0 &&
                    limits
                      .limits_deduct
                      .uim_limit
                      .options
                      .filter((o: { display: string, value: string }) =>
                        o.value === factors.uim_limit).length > 0 ?
                    limits
                      .limits_deduct
                      .uim_limit
                      .options
                      .filter((o: { display: string, value: string }) =>
                        o.value === factors.uim_limit)[0]
                      .display
                    : ''
                }
                {
                  limits && limits.limits_deduct.uim_limit && factors.uim_limit.length > 0 &&
                  <div className='premium-factor-tooltip'>
                    <Tooltip
                      enterTouchDelay={0}
                      leaveTouchDelay={3500}
                      className='tooltip'
                      title={
                        limits ?
                          limits.limits_deduct.uim_limit.description
                          : 'Information not currently available'
                      }
                      placement='top-start'
                    >
                      <ToolTipIcon />
                    </Tooltip>
                  </div>
                }
              </div>
            </div>
          }
          <div className='premium-factor-row'>
            <div>{factors.um_limit ? 'Uninsured motorist' : 'Uninsured motorist bodily injury'}</div>
            <div className='premium-factor-row-right'>
              {
                limits && limits.limits_deduct.um_limit &&
                  limits
                    .limits_deduct
                    .um_limit
                    .options
                    .filter((o: { display: string, value: string }) =>
                      o.value === factors.um_limit).length > 0 ?
                  limits
                    .limits_deduct
                    .um_limit
                    .options
                    .filter((o: { display: string, value: string }) =>
                      o.value === factors.um_limit)[0]
                    .display
                  : ''
              }
              {
                limits && limits.limits_deduct.umbi_limit &&
                  limits
                    .limits_deduct
                    .umbi_limit
                    .options
                    .filter((o: { display: string, value: string }) =>
                      o.value === factors.umbi_limit).length > 0 ?
                  limits
                    .limits_deduct
                    .umbi_limit
                    .options
                    .filter((o: { display: string, value: string }) =>
                      o.value === factors.umbi_limit)[0]
                    .display
                  : ''
              }
              <div className='premium-factor-tooltip'>
                <Tooltip
                  enterTouchDelay={0}
                  leaveTouchDelay={3500}
                  className='tooltip'
                  title={
                    uninsuredMotoristToolTip
                  }
                  placement='top-start'
                >
                  <ToolTipIcon />
                </Tooltip>
              </div>
            </div>
          </div>
          {
            showUmpd ?
              <div className='premium-factor-row'>
                <div>Uninsured motorist property damage</div>
                <div className='premium-factor-row-right'>
                  {
                    limits && limits.limits_deduct.umpd_limit &&
                      limits
                        .limits_deduct
                        .umpd_limit
                        .options
                        .filter((o: { display: string, value: string }) =>
                          o.value === factors.umpd_limit).length > 0 ?
                      limits
                        .limits_deduct
                        .umpd_limit
                        .options
                        .filter((o: { display: string, value: string }) =>
                          o.value === factors.umpd_limit)[0]
                        .display
                      : ''
                  }
                  <div className='premium-factor-tooltip'>
                    <Tooltip
                      enterTouchDelay={0}
                      leaveTouchDelay={3500}
                      className='tooltip'
                      title={
                        uninsuredMotoristPDToolTip
                      }
                      placement='top-start'
                    >
                      <ToolTipIcon />
                    </Tooltip>
                  </div>
                </div>
              </div> : null
          }
        </div>

      </div>
    );
  };

export default PolicyCoverages;