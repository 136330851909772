import React, { useContext } from 'react';
import { ReactComponent as Circle1 } from '../images/nav-circle-1.svg';
import { ReactComponent as Circle2 } from '../images/nav-circle-2.svg';
import { ReactComponent as Circle3 } from '../images/nav-circle-3.svg';
import { ReactComponent as Circle2Inactive } from '../images/nav-circle-2-inactive.svg';
import { ReactComponent as Circle3Inactive } from '../images/nav-circle-3-inactive.svg';
import { ReactComponent as CircleComplete } from '../images/nav-circle-complete.svg';
import { ReactComponent as CircleCompleteNovo } from '../images/nav-circle-complete-novo.svg';
import './SidebarNavItem.scss';
import { BrandContext, getPrimaryColor } from '../utils/style';

interface INavItem {
  status: string;
  index: number;
  text: string;
}

const NavItem: React.FC<INavItem> = ({ status, index, text }) => {
  const { brand } = useContext(BrandContext);
  let iconResult;

  if (index === 1) {
    if (status === 'active') {
      iconResult = <Circle1 />;
    }
    if (status === 'complete') {
      iconResult = brand === 'motion' ? <CircleComplete /> : <CircleCompleteNovo />;
    }
  }

  if (index === 2) {
    if (status === 'inactive') {
      iconResult = <Circle2Inactive />;
    }
    if (status === 'active') {
      iconResult = <Circle2 />;
    }
    if (status === 'complete') {
      iconResult = brand === 'motion' ? <CircleComplete /> : <CircleCompleteNovo />;
    }
  }

  if (index === 3) {
    if (status === 'inactive') {
      iconResult = <Circle3Inactive />;
    }
    if (status === 'active') {
      iconResult = <Circle3 />;
    }
    if (status === 'complete') {
      iconResult = brand === 'motion' ? <CircleComplete /> : <CircleCompleteNovo />;
    }
  }

  return (
    <div className='nav-item-wrapper'>
      {iconResult}
      <span
        className={`nav-item-text-${status}`}
        style={{ color: status === 'complete' ? getPrimaryColor() : 'inherit' }}
      >
        {text}
      </span>
    </div>
  );
};

export default NavItem;
