import React, { useEffect, useState } from 'react';
import './App.scss';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  BrowserRouter as Router,
} from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';

import { Grid, ThemeProvider } from '@material-ui/core';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Sidebar from './Sidebar';
import theme from '../theme';
import LeftHeader from './LeftHeader';
import RightHeader from './RightHeader';
import LeftFooter from './LeftFooter';
import RightFooter from './RightFooter';
import InitialInfo from '../pages/InitialInfo';
import AuthDetails from '../pages/Auth';
import IncidentReport from '../pages/IncidentReport';
import IncidentReportMvr from '../pages/IncidentReportMvr';
import SecondaryInfo from '../pages/SecondaryInfo';
import { getPrimaryInsuredParams } from '../utils/queryParams';
import ReviewInfo from '../pages/ReviewInfo';
import { Driver } from '../models/motion';
import Quote from '../pages/Quote';
import ReviewPolicy from '../pages/ReviewPolicy';
import Payment from '../pages/Payment';
import Welcome from '../pages/Welcome';
import PartnerQuote from '../pages/PartnerQuote';
import AgentLogin from '../pages/AgentLogin';
import AgentForgotPassword from '../pages/AgentForgotPassword';
import AgentDash from '../pages/AgentDash';
import { BrandProvider, getBrand, getPrimaryColor, getSecondaryColor, shouldCoBrand } from '../utils/style';

const queryCache = new QueryCache();

const App = () => {
  const drivers = window.sessionStorage.getItem('drivers');
  const navigationProgressJson = window.sessionStorage.getItem('navigationProgress');
  const navigationProgress = JSON.parse(navigationProgressJson!);
  const initialProgress = { 1: 'active', 2: 'inactive', 3: 'inactive' };
  const isAgentPortal = window.location.href.indexOf('agent') > -1
  const authPage = (window.location.href.indexOf('initial-info')) > -1 || !window.location.href.replace(`${window.location.origin}/`, '');
  const [brand, setBrand] = useState<string>(getBrand());
  const [queryParams, setQueryparams] = useState<string>(window.location.href.indexOf('?') >= 0 ? `?${window.location.href.split('/?')[1]}` : '');
  const [primaryColor, setPrimaryColor] = useState<string>(getPrimaryColor);
  const [secondaryColor, setSecondaryColor] = useState<string>(getSecondaryColor);
  const [coBrand, setCoBrand] = useState(shouldCoBrand);
  if (navigationProgress === null) {
    window.sessionStorage.setItem('navigationProgress', JSON.stringify(initialProgress));
  }
  const [navigationProgressState, setNavigationProgress] =
    useState(initialProgress);

  const handleNavigationUpdate = () => {
    const navProgressJson = window.sessionStorage.getItem('navigationProgress');
    const navProgress = JSON.parse(navProgressJson!) || initialProgress;
    setNavigationProgress(navProgress);
  };

  if (drivers === null && !authPage) {
    const newDrivers: Driver[] = [];
    newDrivers.push(getPrimaryInsuredParams(window.location.href));

    window.sessionStorage.setItem('drivers', JSON.stringify(newDrivers));
  }

  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <Router>
        <BrandProvider value={{
          brand, setBrand,
          primaryColor, setPrimaryColor,
          secondaryColor, setSecondaryColor,
          coBrand, setCoBrand,
        }}
        >
          <ThemeProvider theme={theme(primaryColor)}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container spacing={0}>
                {
                  !isAgentPortal && !authPage &&
                  <>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Grid container spacing={0}>
                        <Grid item lg={4} md={4} xs={6}>
                          <LeftHeader />
                        </Grid>
                        <Grid item lg={8} md={8} xs={6}>
                          <RightHeader />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                      <Sidebar navigationProgress={navigationProgressState} />
                    </Grid>
                  </>
                }
                <Grid item lg={isAgentPortal || authPage ? 12 : 8} md={isAgentPortal || authPage ? 12 : 8} sm={12} xs={12} className={!isAgentPortal && !authPage ? 'main-wrapper' : 'agent-main-wrapper'}>
                  <Switch>
                    <Route exact path='/'>
                      <Redirect to={`/initial-info${queryParams}`} />
                    </Route>
                    <Route path='/initial-info' component={AuthDetails} />
                    <Route path='/primary-info' component={InitialInfo} />
                    <Route path='/secondary-info' component={SecondaryInfo} />
                    <Route path='/review-info' component={() => <ReviewInfo navigationProgress={navigationProgressState} setNavigationProgress={() => handleNavigationUpdate()} />} />
                    <Route path='/quote' component={() => <Quote navigationProgress={navigationProgressState} setNavigationProgress={() => handleNavigationUpdate()} />} />
                    <Route path='/incident-report' component={IncidentReport} />
                    <Route path='/incident-report-mvr' component={IncidentReportMvr} />
                    <Route path='/review-policy' component={ReviewPolicy} />
                    <Route path='/payment' component={() => <Payment setNavigationProgress={() => handleNavigationUpdate()} />} />
                    <Route path='/welcome' component={Welcome} />
                    <Route path='/partner-quote' component={PartnerQuote} />
                    <Route path='/agent' component={AgentLogin} />
                    <Route path='/agent-forgot' component={AgentForgotPassword} />
                    <Route path='/agent-dash' component={AgentDash} />
                  </Switch>
                </Grid>
                {
                  !isAgentPortal && !authPage &&
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={0}>
                      <Grid item lg={4} md={4} sm={12} xs={12}>
                        <LeftFooter />
                      </Grid>
                      <Grid item lg={8} md={8} sm={12} xs={12}>
                        <RightFooter />
                      </Grid>
                    </Grid>
                  </Grid>
                }
                {
                  authPage &&
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={0}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <RightFooter />
                      </Grid>
                    </Grid>
                  </Grid>
                }
              </Grid>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </BrandProvider>
      </Router>
    </ReactQueryCacheProvider>
  );
};

export default App;
