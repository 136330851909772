import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Paper, InputAdornment, TextField, Button, MenuItem, Select } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Avatar from '@material-ui/core/Avatar';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import './AgentDash.scss';
import SearchIcon from '@material-ui/icons/Search';
import Pagination from '@material-ui/lab/Pagination';
import { ReactComponent as LeftArrowDark } from '../images/left-arrow-dark.svg';
import { ReactComponent as LeftArrowLight } from '../images/left-arrow-light.svg';
import { ReactComponent as RightArrowDark } from '../images/right-arrow-dark.svg';
import { ReactComponent as RightArrowLight } from '../images/right-arrow-light.svg';
import { ReactComponent as LogoFull } from '../images/motion-agent.svg';
import { ReactComponent as LeadsLogoDark } from '../images/leads-dark.svg';
import { ReactComponent as LeadsLogoLight } from '../images/leads-light.svg';
import { ReactComponent as CustomerLogoLight } from '../images/customer-light.svg';
import { ReactComponent as CustomerLogoDark } from '../images/customer-dark.svg';
import { ReactComponent as NovoLogo } from '../images/partnerImages/novo-logo.svg';
import { GetQuotes, GetPolicies } from '../utils/queries';
import PageHeader from '../components/PageHeader';
import { agentSearch } from '../utils/agentSearch';
import { Helmet } from 'react-helmet';
import { BrandContext } from '../utils/style';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    root: {
      flexGrow: 1
    }
  }));

const avatarColors = [{
  color: 'white',
  backgroundColor: '#F2D649',
}, {
  color: 'white',
  backgroundColor: '#E94D5C',
}, {
  color: 'white',
  backgroundColor: '#C1E571',
}, {
  color: 'white',
  backgroundColor: '#E7B6F8',
}, {
  color: 'white',
  backgroundColor: '#881AAC',
}, {
  color: 'white',
  backgroundColor: '#888888',
}, {
  color: 'white',
  backgroundColor: '#A2E1E9',
}, {
  color: 'white',
  backgroundColor: '#F09156',
}, {
  color: 'white',
  backgroundColor: '#3C6DF2',
}, {
  color: 'white',
  backgroundColor: '#E97883',
}, {
  color: 'white',
  backgroundColor: '#5DC9AE',
}, {
  color: 'white',
  backgroundColor: '#E7B6F8',
}, {
  color: 'white',
  backgroundColor: '#5CC369',
}];
const leadsColumns = ['Name', 'Vehicle', 'Quote', 'Coverages Quoted'];
const customerColumns = ['Name', 'Policy', 'Effective', 'Renewal', 'Premium (- fees)', 'Vehicles', 'Drivers', 'Status'];


const AgentDash: React.FC = () => {
  const history = useHistory();
  const [rows, setRows] = React.useState<any[]>([]);
  const [initRows, setInitRows] = React.useState<any[]>([]);
  const [cols, setCols] = React.useState<any[]>(customerColumns);
  const [isLead, setIsLead] = React.useState<boolean>(false);
  const [isLogout, setIsLogout] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [totalCount, setTotalCount] = React.useState(0);
  const [recPerPage, setRecPerPage] = React.useState<number>(20);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [pageMenuItems, setPageMenuItems] = React.useState<any[]>([]);
  const agentDataString = window.localStorage.getItem('agentData');
  const [agentData, setAgentData] = React.useState<any>();
  const [displayQuote, setDisplayQuote] = React.useState<boolean>(false);
  const { brand } = useContext(BrandContext);
  useEffect(() => {
    if (agentDataString) {
      const agentDataJson = JSON.parse(agentDataString || '')
      setAgentData(agentDataJson)
      if (agentDataJson['channel']?.toLowerCase() === 'motion' && window.location.hostname.includes("novo")) {
        setDisplayQuote(true)
        setCols(leadsColumns)
        setIsLead(true)
      }
    } else {
      history.push('/agent')
    }
  }, [agentDataString, history])

  const createRowData = (resp: any, type: any, recPerPageVal: any) => {
    setIsLoading(true)
    const data = [];
    setRows([]);
    setInitRows([]);
    recPerPageVal = recPerPageVal ? recPerPageVal : recPerPage
    if (resp) {
      if (type === 'quotes') {
        setTotalCount(resp.quote_count);
        let menuItemsArr = []
        for (let j = 1; j <= Math.ceil(resp.quote_count / recPerPageVal); j++) {
          menuItemsArr.push(<MenuItem key={j} value={j}>Page {j}</MenuItem>)
        }
        setPageMenuItems(menuItemsArr)
      } else {
        setTotalCount(resp.policy_count);
        let menuItemsArr = []
        for (let j = 1; j <= Math.ceil(resp.policy_count / recPerPageVal); j++) {
          menuItemsArr.push(<MenuItem key={j} value={j}>Page {j}</MenuItem>)
        }
        setPageMenuItems(menuItemsArr)
      }
      for (let i = 0; i < resp[type].length; i++) {
        const quote = resp[type][i];
        let vehicles = [];
        if (type === 'quotes') {
          vehicles = quote.vehicles.length ? quote.vehicles[0] : [];
        } else {
          vehicles = quote.vehicles ? JSON.parse(quote.vehicles)[0] : [];
        }
        data.push({
          firstname: quote.first_name,
          lastname: quote.last_name,
          phone: quote.cell_phone,
          email: quote.email_address,
          policyId: quote.policy_id,
          policyStatus: quote.policy_status,
          vehicle: vehicles,
          initialPayment: quote.initial_payment,
          createdDate: quote.created_date,
          coveragesQuoted: quote.rate_id,
          effectiveDate: quote.effective_date,
          effectiveTime: quote.effective_time,
          renewalDate: quote.renewal_date,
          driverCount: quote.driver_count,
          vehicleCount: quote.vehicle_count,
          premium: quote.premium,
          avatarColor: avatarColors[Math.floor(Math.random() * 10)]
        });
      }
      setRows(data);
      setInitRows(data);
    }
  };
  const initData = async (pageVal?: any, recPerPageVal?: any) => {
    if (agentData) {
      if (pageVal > Math.ceil(totalCount / (recPerPageVal || recPerPage))) {
        pageVal = 1
        setCurrentPage(1)
      }
      setIsLoading(true)
      let respQuote: any = '';
      if (displayQuote) {
        respQuote = await GetQuotes(agentData['channel'], agentData['agentId'], recPerPageVal || recPerPage, pageVal || currentPage);
        await createRowData(respQuote, 'quotes', recPerPageVal);
        setIsLoading(false)
      } else {
        respQuote = await policiesData(1)
      }
    }
  };
  const policiesData = async (pageVal?: any, recPerPageVal?: any) => {
    if (agentData) {
      if (pageVal > Math.ceil(totalCount / (recPerPageVal || recPerPage))) {
        pageVal = 1
        setCurrentPage(1)
      }
      setIsLoading(true)
      const respQuote = await GetPolicies(agentData['channel'], agentData['agentId'], recPerPageVal || recPerPage, pageVal || currentPage);
      await createRowData(respQuote, 'policies', recPerPageVal);
      setIsLoading(false)
    }
  };
  useEffect(() => {
    if (agentData && !agentData.hasOwnProperty('email')) {
      history.push('/agent')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentData, history]);

  useEffect(() => {
    if (agentData) {
      initData(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentData]);

  const handleRole = () => {
    setRows([]);
    setInitRows([]);
    if (isLead) {
      setIsLead(false);
      setCols(customerColumns);
      policiesData(1);
      setCurrentPage(1);
    } else {
      setIsLead(true);
      setCols(leadsColumns);
      initData(1);
      setCurrentPage(1);
    }
  };
  const paginate = (value: number) => {
    setRows([]);
    setInitRows([]);
    setCurrentPage(value);
    if (isLead) {
      initData(value);

    } else {
      policiesData(value);

    }
  }
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    paginate(value)
  };
  const handleArrows = (value: number) => {
    paginate(value)
  };

  const mouseHovered = (id: any) => {
    let obj = document as any
    obj.getElementById(id).style.color = 'rgba(92, 20, 166, 1)'
  };

  const mouseOut = (id: any) => {
    let obj = document as any
    obj.getElementById(id).style.color = 'black'
  };

  const nextScreen = () => {
    const agentDataStorage = window.sessionStorage.getItem('agentData');
    window.sessionStorage.clear();
    if (agentDataStorage) {
      window.sessionStorage.setItem('agentData', agentDataStorage);
    }
    window.open(`${window.location.origin}/initial-info`, '_blank');
  };
  const logoutClicked = () => {
    setIsLogout(!isLogout)
  };
  const handleRecordsPerPage = (event: any) => {
    setRows([]);
    setInitRows([]);
    setRecPerPage(event.target.value)
    if (isLead) {
      initData(currentPage, event.target.value);
    } else {
      policiesData(currentPage, event.target.value);
    }
  };
  const handlePage = (event: any) => {
    setRows([]);
    setInitRows([]);
    setCurrentPage(event.target.value)
    if (isLead) {
      initData(event.target.value, recPerPage);
    } else {
      policiesData(event.target.value, recPerPage);
    }
  };
  const searchAgestResults = async (event: any) => {
    let resp = await agentSearch(initRows, event.target.value.trim(), ['firstname', 'lastname'])
    setRows(resp)
  };
  const classes = useStyles();
  return (
    <>
      <Helmet>
        <title>{`Initial Info - ${brand === 'motion' ? 'Motion Insurance' : 'Novo Insurance'}`}</title>
        {
          brand === 'motion' ?
            <link rel='icon' href='/favicon.ico' />
            :
            <link rel='icon' href='/novo-fav.ico' />
        }
      </Helmet>
      <div className={classes.root}>
        <Grid container spacing={3} style={{ background: "white" }}>
          <Grid container spacing={1} component={Paper} className='header-grid'>
            <Grid item lg={4}>
              <div className='logo-full'>
                { brand === 'novo' &&
                  <a href='https://www.novo.us' target='_blank'>
                    <NovoLogo />
                  </a>
                }
                { brand === 'motion' &&
                  <LogoFull />
                }
              </div>
            </Grid>
            <Grid item lg={4}>
              <ToggleButtonGroup>
                <span className='toggle-paper'>
                  {displayQuote && <ToggleButton onClick={handleRole} value='Leads' className={isLead ? 'toggle-button toggle-style-active' : 'toggle-button toggle-style-inactive'}>
                    {isLead && <React.Fragment><LeadsLogoDark />&nbsp;&nbsp;<span style={{ textTransform: 'initial', fontWeight: 'bold' }}>Leads</span></React.Fragment>}
                    {!isLead && <React.Fragment><LeadsLogoLight /> &nbsp;&nbsp;<span style={{ textTransform: 'initial', fontWeight: 'bold', color: 'black' }}>Leads</span> </React.Fragment>}
                  </ToggleButton>}
                  <ToggleButton onClick={displayQuote ? handleRole : () => { }} value='Customers' className={!isLead ? 'toggle-button toggle-style-active' : 'toggle-button toggle-style-inactive'}>
                    {!isLead && <React.Fragment> <CustomerLogoDark /> &nbsp;&nbsp; <span style={{ textTransform: 'initial', fontWeight: 'bold' }}>Customers</span></React.Fragment>}
                    {isLead && <React.Fragment> <CustomerLogoLight />&nbsp; &nbsp; <span style={{ textTransform: 'initial', fontWeight: 'bold', color: 'black' }}>Customers</span></React.Fragment>}
                  </ToggleButton>

                </span>
              </ToggleButtonGroup>
            </Grid>
            <Grid item lg={3} style={{ marginTop: 'auto', textAlignLast: 'right', padding: '10px' }}>
              <span >  {agentData && agentData['email']}</span>
            </Grid>
            <Grid item lg={1} style={{ marginTop: 'auto' }}>

              <div style={{ display: 'flex' }}>
                {/*eslint-disable-next-line*/}
                <div> <a onClick={logoutClicked}> <Avatar
                  style={avatarColors[Math.floor(Math.random() * 10)]}>{agentData && agentData['email']?.charAt(0)}</Avatar></a></div>
                &nbsp;
                <div style={{ marginTop: '10px' }}>
                  {/*eslint-disable-next-line*/}
                  <a className="logout" onClick={() => {
                    window.sessionStorage.setItem('agentData', '{}');
                    history.push('/agent')
                  }}>Logout</a></div>

              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3} className='navbar-grid'>
            <Grid item lg={1} />
            <Grid item lg={5}>
              <span className='text-grid'>
                <TextField
                  id='search'
                  variant='outlined'
                  type='text'
                  name='search'
                  placeholder='search sales leads'
                  InputProps={{
                    startAdornment:
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    ,
                  }}
                  style={{ height: '2em' }}
                  onChange={searchAgestResults}
                />
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                { displayQuote &&
                  <Button
                    variant='outlined'
                    onClick={nextScreen}
                    className="start-quote-btn"
                    style={{
                      height: '2.3em',
                      borderRadius: '15px',
                      marginTop: '2px',
                      textTransform: 'initial'
                    }}
                  >
                    Start a new quote
                  </Button>
                }
              </span>
            </Grid>
            <Grid item lg={5} style={{ textAlignLast: 'end' }}>
              {rows.length > 0 &&
                <span className='header-page'> <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  value={recPerPage}
                  onChange={handleRecordsPerPage}
                >
                  <MenuItem value={20}>20 Policies per page</MenuItem>
                  <MenuItem value={40}>40 Policies per page</MenuItem>
                  <MenuItem value={60}>60 Policies per page</MenuItem>
                </Select>
                  &nbsp;
                  &nbsp; <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    value={currentPage}
                    onChange={handlePage}
                  >

                    {pageMenuItems}

                  </Select> &nbsp;
                  &nbsp; Of {Math.ceil(totalCount / recPerPage)}
                  {/* &nbsp;<i className='arrow left' />&nbsp;<i className='arrow right' /> */}
                  &nbsp;&nbsp;{currentPage <= 1 && <React.Fragment><LeftArrowLight style={{ cursor: 'pointer' }} />&nbsp;&nbsp;</React.Fragment>}
                  {currentPage > 1 && <React.Fragment><LeftArrowDark style={{ cursor: 'pointer' }} onClick={() => handleArrows(currentPage - 1)} />&nbsp;&nbsp;</React.Fragment>}
                  {currentPage < Math.ceil(totalCount / recPerPage) && <React.Fragment><RightArrowDark style={{ cursor: 'pointer' }} onClick={() => handleArrows(currentPage + 1)} /></React.Fragment>}
                  {currentPage >= Math.ceil(totalCount / recPerPage) && <React.Fragment><RightArrowLight style={{ cursor: 'pointer' }} /></React.Fragment>}
                </span>}
            </Grid>
            <Grid item lg={1} />
          </Grid>
          <Grid container spacing={3} className='table-grid'>
            <Grid item lg={1} />
            <Grid item lg={10}>
              {rows.length === 0 && isLoading && <>
                <PageHeader
                  icon='circularProgress'
                  header={isLead ? `Loading Quotes...` : `Loading Policies...`}
                  description=''
                />
                <div style={{ marginBottom: '50em' }} />
              </>}
              {rows.length === 0 && !isLoading && <div style={{ marginBottom: '50em' }} >{isLead ? 'There are no Quotes..!' : 'There are no Policies..!'}</div>}
              {rows.length > 0 &&
                <TableContainer>
                  <Table className={classes.table} aria-label='simple table'>
                    <TableHead style={{ borderTop: '1px solid lightgrey' }}>
                      <TableRow>
                        {cols.length > 0 && cols.map((col) => (
                          <TableCell key={col} align='left'><h4>{col}</h4></TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isLead && rows.length > 0 && rows.map((row, index) => (
                        <TableRow key={row.policyId}>
                          <TableCell component='th' scope='row' align='left'>
                            <Grid container spacing={2}>
                              <Grid item lg={2}><Avatar style={row?.avatarColor}>{row?.firstname?.charAt(0)}{row?.lastname?.charAt(0)}</Avatar></Grid>
                              <Grid item lg={10}>
                                {row?.firstname} {row?.lastname}
                                <br />
                                <span className='color-grey'>{row?.phone}, {row?.email}</span>
                              </Grid>
                            </Grid>
                          </TableCell>
                          {
                            row?.vehicle &&
                            <TableCell align='left'>  {row?.vehicle.model_year}, &nbsp; {row?.vehicle.make_full}, &nbsp; {row?.vehicle.model_full}
                              <br />
                              <span className='color-grey'>VIN :  {row?.vehicle?.vin}</span>
                            </TableCell>
                          }
                          <TableCell align='left'>${row?.initialPayment} <br />
                            <span className='color-grey'>{row?.createdDate}</span>
                          </TableCell>
                          <TableCell align='left' >
                            <a className={'view-quote'} style={{ color: 'black' }} id={`quote${index}`} onMouseOut={() => mouseOut(`quote${index}`)} onMouseOver={() => mouseHovered(`quote${index}`)} href={`${window.location.origin}/partner-quote?transactionId=${row.coveragesQuoted}`} rel="noopener noreferrer" target="_blank" > {'View Quote'}</a>
                          </TableCell>
                        </TableRow>
                      ))}
                      {!isLead && rows?.length > 0 && rows.map((row) => (
                        <TableRow key={row?.policyId}>
                          <TableCell component='th' scope='row' align='left'>
                            <Grid container spacing={2}>
                              <Grid item lg={2}><Avatar style={row?.avatarColor}>{row?.firstname?.charAt(0)}{row?.lastname?.charAt(0)}</Avatar></Grid>
                              <Grid item lg={10}>
                                {row?.firstname} {row?.lastname}
                                <br />
                                <span className='color-grey'>{row?.phone}, {row?.email}</span>
                              </Grid>
                            </Grid>
                          </TableCell>
                          <TableCell align='left'>{row?.policyId} </TableCell>
                          <TableCell align='left'>{row?.createdDate} </TableCell>
                          <TableCell align='left'> {row?.effectiveDate}
                            <br />
                            <span className='color-grey'>{row?.effectiveTime}</span>
                          </TableCell>
                          <TableCell align='left'>${row?.premium} <br />
                            <span className='color-grey'>{row?.createdDate}</span>
                          </TableCell>
                          <TableCell align='left'>{row?.vehicleCount} Vehicle</TableCell>
                          <TableCell align='left'>{row?.driverCount} Drivers </TableCell>
                          <TableCell align='left'>{row?.policyStatus}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>}
            </Grid>
            <Grid item lg={1} />
          </Grid>
          <Grid container spacing={3} className='footer-page-grid'>
            <Grid item lg={4} />
            <Grid item lg={4} style={{ textAlignLast: 'end' }}>
              {rows.length > 0 &&
                <span className='sub-page'>Showing {currentPage}-{Math.ceil(totalCount / recPerPage)} of {totalCount}</span>}
            </Grid>
            {rows.length > 0 &&
              <Grid item lg={3}>
                <span>
                  <Pagination page={currentPage} onChange={handleChange}
                    count={Math.ceil(totalCount / recPerPage)} className='footer-page' />
                </span>

              </Grid>}
          </Grid>
        </Grid>
      </div >
    </>
  );
};
export default AgentDash;