/* eslint-disable no-nested-ternary */
import React, { useCallback, SyntheticEvent, useContext, useEffect, useState } from 'react';
import Bugsnag from '@bugsnag/js';
import './Vehicle.scss';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  withStyles,
  Tooltip,
  Button,
} from '@material-ui/core';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { ArrayField, Controller, useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import { ReactComponent as NewCar } from '../images/car-front.svg';
import { ReactComponent as NewCarNovo } from '../images/car-front-novo.svg';
import { ReactComponent as ToolTipIcon } from '../images/tool-tip.svg';
import { ReactComponent as TrashCanIcon } from '../images/trash-can.svg';
import { ReactComponent as ExpandIcon } from '../images/expand-icon.svg';
import { usStateList } from '../models/stateCodes';
import { GetVehicleInfo } from '../utils/queries';
import VehicleCoverageEdit from './VehicleCoverageEdit';
import { Offer, Vehicle as VehicleModel } from '../models/motion';
import MotionModal from './MotionModal';
import MotionToast from './MotionToast';
import { BrandContext, getPhoneNumber } from '../utils/style';

const CustomFormControlLabel = withStyles({
  label: {
    fontSize: '0.875em',
  }
})(FormControlLabel);

const Accordion = withStyles({
  root: {
    width: '100%',
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    border: 'none',
    borderRadius: '0px',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    borderRadius: '0px',
    color: 'black',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
    fontFamily: 'AcherusGrotesqueBold',
    fontSize: '0.875em',
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    borderRadius: '0px',
    padding: theme.spacing(2),
    fontSize: '0.875em',
  },
}))(MuiAccordionDetails);

export interface VehicleProps {
  vehicle: Partial<ArrayField<Record<string, any>, 'id'>>;
  index: number;
  showCoverages: boolean;
  state?: string;
  offer?: Offer;
  limits?: any;
  tier?: string;
  isAccordionOpen: boolean;
  clickedExpandAll?: boolean;
  remove(): void;
  validateEve(): void;
}

const Vehicle: React.FC<VehicleProps> =
  ({
    vehicle,
    index,
    showCoverages,
    offer,
    limits,
    state: policyState,
    tier,
    isAccordionOpen,
    clickedExpandAll,
    remove,
    validateEve
  }) => {
    const {
      year,
      make,
      model,
      vin,
      imageUrl,
      primaryZipCode,
      ownershipLength,
      ownershipType,
      state,
      primaryUse,
      rideshare,
      titleBrands
    } = vehicle;
    const { brand } = useContext(BrandContext);
    const methods = useFormContext();
    const driversJson = window.sessionStorage.getItem('drivers');
    const drivers = JSON.parse(driversJson!);
    const [vinState, setVinState] = useState(vin);
    const [yearState, setYearState] = useState(year);
    const [makeState, setMakeState] = useState(make);
    const [modelState, setModelState] = useState(model);
    const [imageState, setImageState] = useState(imageUrl);
    const [titleBrandsState, setTitleBrandsState] = useState(titleBrands);
    const [grossVehicleWeight, setGrossVehicleWeight] = useState(0);
    const [vehicleType, setVehicleType] = useState(0);
    const [expanded, setExpanded] = React.useState<string | false>(isAccordionOpen ? 'panel1' : 'panel');
    const handleChange =
      (panel: string) => (
        event: React.ChangeEvent<{}>,
        isExpanded: boolean
      ) => {
        setExpanded(isExpanded ? panel : false);
      };

    const updateVINErrorsCallback = useCallback((msg: string) => {

      const arr = [];
      for (let i = 0; i <= index; i++) {
        arr.push({
          vin: {
            message: msg,
            type: 'required'
          }
        });
      }
      methods.control.updateFormState({
        errors: {
          vehicles: arr
        }
      });

    }, [index, methods]);

    const [rideShareModal, setRideShareModal] = useState(false);
    const handleRideshareModalClose = () => {
      setRideShareModal(false);
    };
    const watchVin = methods.watch(`vehicles[${index}].vin`, false);
    const watchVehicleState = methods.watch(`vehicles[${index}].state`);
    const watchLicensePlateNumber = methods.watch(
      `vehicles[${index}].licensePlateNumber`
    );

    const validVinCheck = /^[A-HJ-NPR-Za-hj-npr-z\d]{17}$/gm.test(vinState);
    useEffect(() => {
      if (!validVinCheck && vinState) {
        updateVINErrorsCallback('Please enter a valid VIN.');
      }
    }, [vinState, index, methods.control, validVinCheck, updateVINErrorsCallback]);

    let calltheapi = true;
    if (yearState && makeState && modelState && imageState) {
      calltheapi = false;
    }

    if ((!watchLicensePlateNumber || !watchVehicleState) && !watchVin) {
      calltheapi = false;
    }

    const { isLoading, refetch } = useQuery(
      [`${vinState}-${index}`],
      () =>
        GetVehicleInfo(
          watchVin || vinState,
          watchLicensePlateNumber || '',
          watchVehicleState || '',
          calltheapi),
      {
        staleTime: Infinity,
        enabled:
          !vinState || !yearState || !makeState || !modelState || !imageState,
        onSuccess: (successData: any) => {
          // prevent user from adding duplicate vehicles to quote
          // vin will be an empty string when user submits state and license plate number
          const formData = methods.getValues()
          let duplicateVinCount = 0
          formData.vehicles.forEach((v: VehicleModel) => {
            if (v.vin === successData.VIN
              || (v.vin === '' && formData.vehicles.some((v2: VehicleModel) => {
                return successData.hasOwnProperty('exception') ? false : (v2.vin === successData.VIN)
              }))) {
              duplicateVinCount += 1
            }
          });
          if (duplicateVinCount > 1) {
            handleDisplayToast('error', 'Oops, it looks like you already added this vehicle. Either add a different vehicle or delete the duplicate.')
            return
          }
          if (successData.hasOwnProperty('errorMessage')) {
            setImageState('')
          } else {
            setImageState(successData.image ? successData.image : '')
          }
          setYearState(successData.year)
          setMakeState(successData.make)
          setModelState(successData.model)
          setVinState(successData.VIN)
          setTitleBrandsState(successData.titleBrands)
          setGrossVehicleWeight(successData.vinMasterData?.grossVehicleWeight)
          setVehicleType(successData.vinMasterData?.type)

          if ((successData.exception && watchVin && watchVin.length > 0)
            || (successData.exception && watchLicensePlateNumber && watchLicensePlateNumber.length > 0)) {
            updateVINErrorsCallback("Oops, we couldn't find your vehicle. Please verify your information and try again. If error continues please contact customer support.");
          }
        },
        onError: (error) => {
          setImageState('');
          handleDisplayToast('error', 'Oops, we couldn\'t find your vehicle. Please verify your information and try again.');
          Bugsnag.notify(error as Error);
        }
      }
    );

    const validVinChar = /^[A-HJ-NPR-Za-hj-npr-z\d]{17}$/gm.test(watchVin);
    const handleFindMyCar = () => {
      if (!validVinChar && watchVin.length > 0) {
        updateVINErrorsCallback('You have entered an invalid VIN. The letters I, O, Q and special characters are not allowed.  Please review your VIN and try again.');
      } else if (watchVin.length === 0 && watchLicensePlateNumber.length === 0) {
        updateVINErrorsCallback('Please include your VIN or State & License Plate to look up your vehicle.');
      } else {
        refetch();
        validateEve();
      }
    };

    useEffect(() => {
      if (clickedExpandAll) {
        setExpanded('panel1');
      }

      if (!yearState && !makeState && !modelState) {
        setExpanded('panel1');
      }
    }, [clickedExpandAll, setExpanded, yearState, makeState, modelState]);

    useEffect(() => {
      // this prevents a recently deleted vehicle from reappearing 
      // when user adds a vehicle right after deleting a different vehicle
      if (!vehicle.vin) {
        setVinState(null);
      }
    }, [setVinState, vehicle]);

    const [ownershipTypeMenuList] = React.useState(() => {
      return ['Own', 'Lease', 'Financed'].map((g) => {
        return (
          <MenuItem key={g} value={g}>
            {g}
          </MenuItem>
        );
      });
    });
    const [ownershipLengthMenuList] = React.useState(() => {
      return ['Less than 1 year', '1-2 years', '3-4 years', '5 or more years'].map((g) => {
        return (
          <MenuItem key={g} value={g}>
            {g}
          </MenuItem>
        );
      });
    });
    const [primaryUseMenuList] = React.useState(() => {
      return ['Business', 'Commute', 'Delivery', 'Farm', 'Pleasure'].map((g) => {
        return (
          <MenuItem key={g} value={g}>
            {g}
          </MenuItem>
        );
      });
    });
    const [stateMenuList] = React.useState(() => {
      return usStateList.map((s) => {
        return (
          <MenuItem key={s.value} value={s.value}>
            {s.display}
          </MenuItem>
        );
      });
    });

    const [vehicleRemoveModal, setVehicleRemoveModal] = useState(false);
    const handleVehicleRemove = (e: SyntheticEvent) => {
      e.stopPropagation();
      setVehicleRemoveModal(true);
    };
    const confirmRemoveVehicle = () => {
      remove();
      setVehicleRemoveModal(false);
    };
    const cancelRemoveVehicle = () => {
      setVehicleRemoveModal(false);
    };

    const [vehicleErrorMessage, setVehicleErrorMessage] = useState('');
    const [vehicleErrorToast, setVehicleErrorToast] = useState(false);
    const [createAccountSeverity, setCreateAccountSeverity] = useState('success' as 'success' | 'error' | 'info' | 'warning');

    const handleDisplayToast = (severity: 'success' | 'error' | 'info' | 'warning', message: string) => {
      setCreateAccountSeverity(severity);
      setVehicleErrorToast(true);
      setVehicleErrorMessage(message);
      setTimeout(() => {
        setVehicleErrorToast(false);
      }, 6000);
    };


    return (
      <>
        <MotionToast
          severity={createAccountSeverity as 'success' | 'error' | 'info' | 'warning'}
          message={vehicleErrorMessage}
          open={vehicleErrorToast}
        />
        <MotionModal
          title='Are you sure?'
          body='You will not be able to retrieve this vehicle once it is deleted.'
          open={vehicleRemoveModal}
          handleClose={cancelRemoveVehicle}
          closeText='Cancel'
          confirmText='Delete'
          handleConfirm={confirmRemoveVehicle}
        />
        <MotionModal
          title='Notice'
          body={`Please call customer support for assistance with this quote.`}
          open={rideShareModal}
          handleClose={handleRideshareModalClose}
          closeText='cancel'
          confirmText={`call ${getPhoneNumber().replace(/-/g, '')}`}
          handleConfirm={() => {
              window.location.href = `${getPhoneNumber().replace(/-/g, '')}`
          }}
        />
        <div className='accordion-wrapper'>
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary
              expandIcon={<ExpandIcon />}
              aria-controls='panel1bh-content'
              id={`vehicleAccordion-${index}`}
            >
              <div className='vehicle-accordion-summary'>
                {yearState && makeState && modelState && validVinCheck ? (
                  <>
                    <div className='vehicle-header-left'>
                      {imageState ? (
                        <div>
                          <img
                            src={imageState}
                            className='vehicle-image'
                            alt={`${yearState} ${makeState} ${modelState}`}
                          />
                        </div>
                      ) : brand === 'motion' ? <NewCar /> : <NewCarNovo />}
                      <div className='vehicle-header-description'>
                        {yearState && makeState && modelState ? (
                          <div>
                            <div>{`${yearState} ${makeState} ${modelState}`}</div>
                            <div className='vehicle-header-description-vin'>
                              {`VIN: ${vinState}`}
                            </div>
                          </div>
                        ) :
                          (
                            <span>loading...</span>
                          )}
                      </div>
                    </div>
                    <div className='vehicle-header-right'>
                      <div>
                        <IconButton onClick={handleVehicleRemove}>
                          <TrashCanIcon />
                        </IconButton>
                      </div>
                    </div>
                  </>
                ) :
                  (
                    <>
                      <div className='vehicle-header-left'>
                        <div>
                          {
                            brand === 'motion' ?
                              <NewCar />
                              :
                              <NewCarNovo />
                          }
                        </div>
                        <div className='vehicle-header-description'>
                          <span className='new-vehicle-prompt'>
                            Tell us about your vehicle
                          </span>
                        </div>
                      </div>
                      <div className='vehicle-header-right'>
                        <div>
                          <IconButton onClick={handleVehicleRemove}>
                            <TrashCanIcon />
                          </IconButton>
                        </div>
                      </div>
                    </>
                  )}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className='additional-driver-dl-form'>
                <Grid container spacing={2} justify='center'>
                  <input
                    type='hidden'
                    name={`vehicles[${index}].titleBrands`}
                    defaultValue={JSON.stringify(titleBrandsState)}
                    ref={methods.register()}
                  />
                  <input
                    type='hidden'
                    name={`vehicles[${index}].year`}
                    defaultValue={yearState}
                    ref={methods.register()}
                  />
                  <input
                    type='hidden'
                    name={`vehicles[${index}].grossVehicleWeight`}
                    defaultValue={grossVehicleWeight}
                    ref={methods.register()}
                  />
                  <input
                    type='hidden'
                    name={`vehicles[${index}].type`}
                    defaultValue={vehicleType}
                    ref={methods.register()}
                  />
                  <input
                    type='hidden'
                    name={`vehicles[${index}].make`}
                    defaultValue={makeState}
                    ref={methods.register()}
                  />
                  <input
                    type='hidden'
                    name={`vehicles[${index}].model`}
                    defaultValue={modelState}
                    ref={methods.register()}
                  />
                  {!validVinCheck ? (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Controller
                        as={
                          <TextField
                            id={`vehicleVinNumber-${index}`}
                            label='Vehicle Identification Number (VIN)'
                            variant='outlined'
                            fullWidth
                            InputProps={{
                              endAdornment:
                                <InputAdornment position='start'>
                                  <Tooltip
                                    enterTouchDelay={0}
                                    leaveTouchDelay={3500}
                                    className='tooltip'
                                    title='The VIN can often be found on the lower-left corner of the dashboard, in front of the steering wheel. You can read the number by looking through the windshield on the driver’s side of the vehicle.  It can also be found on the inside of the driver-side doorjamb, and on the title for the vehicle.'
                                    placement='top-start'
                                  >
                                    <div style={{ marginLeft: '0.5em', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                      <ToolTipIcon />
                                    </div>
                                  </Tooltip>
                                </InputAdornment>

                            }}
                            error={
                              !!(
                                methods.errors &&
                                methods.errors.vehicles &&
                                methods.errors.vehicles[index] &&
                                methods.errors.vehicles[index].vin
                              )
                            }
                            helperText={
                              methods.errors &&
                                methods.errors.vehicles &&
                                methods.errors.vehicles[index] &&
                                methods.errors.vehicles[index].vin
                                ? methods.errors.vehicles[index].vin.message
                                : ''
                            }
                          />
                        }
                        name={`vehicles[${index}].vin`}
                        control={methods.control}
                        defaultValue={vinState || ''}
                      />
                    </Grid>
                  ) :
                    (
                      <input
                        type='hidden'
                        name={`vehicles[${index}].vin`}
                        defaultValue={vinState}
                        ref={methods.register()}
                      />
                    )}
                  {!validVinCheck ? (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <span>Or look up VIN by license plate</span>
                    </Grid>
                  ) : null}
                  {!validVinCheck ? (
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Controller
                        control={methods.control}
                        name={`vehicles[${index}].state`}
                        defaultValue={state || ''}
                        render={({ onChange, value, name, onBlur }) => (
                          <FormControl
                            variant='outlined'
                            fullWidth
                            error={
                              !!(
                                methods.errors &&
                                methods.errors.vehicles &&
                                methods.errors.vehicles[index] &&
                                methods.errors.vehicles[index].state
                              )
                            }
                          >
                            <InputLabel>State</InputLabel>
                            <Select
                              id={`vehicleState-${index}`}
                              label='state'
                              value={value}
                              name={name}
                              onChange={onChange}
                              onBlur={onBlur}
                            >
                              {stateMenuList}
                            </Select>
                            <FormHelperText>
                              {methods.errors &&
                                methods.errors.vehicles &&
                                methods.errors.vehicles[index] &&
                                methods.errors.vehicles[index].state
                                ? methods.errors.vehicles[index].state.message
                                : ''}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>
                  ) : null}
                  {!validVinCheck ? (
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Controller
                        as={
                          <TextField
                            id={`vehicleLicensePlateNumber-${index}`}
                            label='License plate number'
                            variant='outlined'
                            fullWidth
                            error={
                              !!(
                                methods.errors &&
                                methods.errors.vehicles &&
                                methods.errors.vehicles[index] &&
                                methods.errors.vehicles[index].licensePlateNumber
                              )
                            }
                            helperText={
                              methods.errors &&
                                methods.errors.vehicles &&
                                methods.errors.vehicles[index] &&
                                methods.errors.vehicles[index].licensePlateNumber
                                ? methods.errors.vehicles[index].licensePlateNumber
                                  .message
                                : ''
                            }
                          />
                        }
                        name={`vehicles[${index}].licensePlateNumber`}
                        control={methods.control}
                        defaultValue=''
                      />
                    </Grid>
                  ) : null}
                  {
                    showCoverages && vinState ?
                      <VehicleCoverageEdit
                        tier={tier!}
                        offer={offer!}
                        limits={limits}
                        index={index}
                        vehicle={vehicle}
                      />
                      : null
                  }
                  {(vinState && validVinCheck) ? (
                    <>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Controller
                          control={methods.control}
                          name={`vehicles[${index}].primaryUse`}
                          defaultValue={primaryUse || ''}
                          render={({ onChange, value, name, onBlur }) => (
                            <FormControl
                              variant='outlined'
                              fullWidth
                              error={
                                !!(
                                  methods.errors &&
                                  methods.errors.vehicles &&
                                  methods.errors.vehicles[index] &&
                                  methods.errors.vehicles[index].primaryUse
                                )
                              }
                            >
                              <InputLabel>Primary Use</InputLabel>
                              <Select
                                id={`vehiclePrimaryUse-${index}`}
                                label='primaryUse'
                                value={value}
                                name={name}
                                onChange={onChange}
                                onBlur={onBlur}
                                startAdornment={
                                  <InputAdornment position='start'>
                                    <Tooltip
                                      enterTouchDelay={0}
                                      leaveTouchDelay={3500}
                                      style={{ marginRight: '1em' }}
                                      className='tooltip'
                                      title='Business use is defined as professionals like Realtors, sales reps, or other professionals who drive to different locations for business purposes.  Business use does not include vehicles owned/leased by your company, or for delivery services for people or products.'
                                      placement='top-start'
                                    >
                                      <ToolTipIcon />
                                    </Tooltip>
                                  </InputAdornment>
                                }
                              >
                                {primaryUseMenuList}
                              </Select>
                              <FormHelperText>
                                {methods.errors &&
                                  methods.errors.vehicles &&
                                  methods.errors.vehicles[index] &&
                                  methods.errors.vehicles[index].primaryUse
                                  ? methods.errors.vehicles[index].primaryUse.message
                                  : ''}
                              </FormHelperText>
                            </FormControl>
                          )}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Controller
                          as={
                            <TextField
                              id={`vehiclePrimaryZipCode-${index}`}
                              label='Garaging ZIP Code'
                              variant='outlined'
                              fullWidth
                              inputProps={{ maxLength: 5 }}
                              error={
                                !!(
                                  methods.errors &&
                                  methods.errors.vehicles &&
                                  methods.errors.vehicles[index] &&
                                  methods.errors.vehicles[index].primaryZipCode
                                )
                              }
                              helperText={
                                methods.errors &&
                                  methods.errors.vehicles &&
                                  methods.errors.vehicles[index] &&
                                  methods.errors.vehicles[index].primaryZipCode
                                  ? methods.errors.vehicles[index].primaryZipCode
                                    .message
                                  : ''
                              }
                            />
                          }
                          name={`vehicles[${index}].primaryZipCode`}
                          control={methods.control}
                          defaultValue={primaryZipCode || drivers[0].zip || ''}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Controller
                          control={methods.control}
                          name={`vehicles[${index}].ownershipType`}
                          defaultValue={ownershipType || ''}
                          render={({ onChange, value, name, onBlur }) => (
                            <FormControl
                              variant='outlined'
                              fullWidth
                              error={
                                !!(
                                  methods.errors &&
                                  methods.errors.vehicles &&
                                  methods.errors.vehicles[index] &&
                                  methods.errors.vehicles[index].ownershipType
                                )
                              }
                            >
                              <InputLabel>Ownership</InputLabel>
                              <Select
                                id={`vehicleOwnershipType-${index}`}
                                label='Ownership'
                                value={value}
                                name={name}
                                onChange={onChange}
                                onBlur={onBlur}
                              >
                                {ownershipTypeMenuList}
                              </Select>
                              <FormHelperText>
                                {methods.errors &&
                                  methods.errors.vehicles &&
                                  methods.errors.vehicles[index] &&
                                  methods.errors.vehicles[index].ownershipType
                                  ? methods.errors.vehicles[index].ownershipType.message
                                  : ''}
                              </FormHelperText>
                            </FormControl>
                          )}
                        />
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Controller
                          control={methods.control}
                          name={`vehicles[${index}].ownershipLength`}
                          defaultValue={!ownershipLength || ownershipLength === 'unknown' ? '' : ownershipLength}
                          render={({ onChange, value, name, onBlur }) => (
                            <FormControl
                              variant='outlined'
                              fullWidth
                            >
                              <InputLabel>
                                How long have you had this vehicle?
                              </InputLabel>
                              <Select
                                id={`vehicleOwnershipLength-${index}`}
                                label='How long have you had this vehicle?'
                                value={value}
                                name={name}
                                onChange={onChange}
                                onBlur={onBlur}
                              >
                                {ownershipLengthMenuList}
                              </Select>
                            </FormControl>
                          )}
                        />
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div className='ride-share-agreement-wrapper'>
                          <Controller
                            name={`vehicles[${index}].rideshare`}
                            defaultValue={rideshare || false}
                            control={methods.control}
                            render={(props) => (
                              <CustomFormControlLabel
                                control={
                                  <Checkbox
                                    id={`vehicleRideShare-${index}`}
                                    color='primary'
                                    onChange={(e) => {
                                      props.onChange(e.target.checked);
                                      if (e.target.checked) {
                                        setRideShareModal(true);
                                      }
                                    }}
                                    checked={props.value}
                                  />
                                }
                                label={
                                  <div className='ride-share-label-wrapper'>
                                    <div className='ride-sharing-label'>
                                      I also use this vehicle for Ridesharing
                                    </div>
                                    <div className='ride-share-tooltip-wrapper'>
                                      <Tooltip
                                        enterTouchDelay={0}
                                        leaveTouchDelay={3500}
                                        className='tooltip'
                                        title={`Ridesharing is defined as working for a “Transportation Network Company” and using your personal vehicle for delivery of people or products. This includes Uber, Lyft, Amazon FLEX, Uber EATS, etc. We do not cover your vehicle while you are engaged in these activities.
                                        `}
                                        placement='top-start'
                                      >
                                        <ToolTipIcon />
                                      </Tooltip>
                                    </div>
                                  </div>

                                }
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                <input
                  type='hidden'
                  name={`vehicles[${index}].imageUrl`}
                  defaultValue={imageState}
                  ref={methods.register()}
                />
                {!validVinCheck ? (
                  <div className='find-my-car'>
                    <Button
                      id='vehicleFindMyCarButton'
                      onClick={handleFindMyCar}
                      disabled={isLoading}
                      color='primary'
                      variant='contained'
                    >
                      Find my car
                    </Button>
                  </div>
                ) : null}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </>
    );
  };

export default Vehicle;
