import axios from 'axios';
import { Agentuser, Driver, PaymentInfo, PaymentInfoInput, Vehicle } from '../models/motion';

export const GetVehicleInfo =
  async (vin: string, licensePlate: string, state: string, apihit: boolean) => {

    const vinValue = vin === undefined ? '' : vin
    const validVinTest = /^[A-HJ-NPR-Za-hj-npr-z\d]{17}$/gm.test(vinValue);
    const validVin = validVinTest ? vin : '';

    if (apihit) {
      const { data } = await axios.get(`${process.env.REACT_APP_Q2B_HOST}/q2b/vehicle-info?vin=${validVin}&licensePlate=${licensePlate}&state=${state}`);
      return data.body;
    }

    return {};
  };

export const GetStepFunctionStatus = async (executionArn: string) => {
  const { data } = await axios.post(`${process.env.REACT_APP_QUOTE_TO_BIND_HOST}/step-function-status`, { executionArn });
  return data;
};

export const GetOffer = async (transactionId: string) => {
  const { data } = await axios.post(`${process.env.REACT_APP_QUOTE_TO_BIND_HOST}/get-offer`, { transaction_id: transactionId });
  return data;
};

export const GetLimits = async (stateCode: string) => {
  const { data } = await axios.post(`${process.env.REACT_APP_Q2B_HOST}/q2b/coverages`, { state: stateCode });
  return data;
};


export const GetDisclosures = async (
  motionAutoTransactionId: string,
  paidInFull: string,
  nonDrinker: string,
  vinNumbers: string[],
  selectedTier: string,
  stateCode: string) => {
  const { data } = await axios.post(`${process.env.REACT_APP_QUOTE_TO_BIND_HOST}/disclosure`, {
    transaction_id: motionAutoTransactionId,
    paid_in_full: paidInFull,
    non_drinker: nonDrinker,
    vin: vinNumbers,
    selection: selectedTier,
    state: stateCode
  });

  return data.body;
};

export const GetPartnerQuote = async (transactionId: String) => {
  const { data } = await axios.post(`${process.env.REACT_APP_QUOTE_TO_BIND_HOST}/get-partner-summary`, {
    transaction_id: transactionId
  });

  return data;
};

export const GetAgentLogin = async (payload: Agentuser) => {
  const { data } = await axios.post(`${process.env.REACT_APP_AGENT_LOGIN}/agent-auth/login`, { username: payload.email, password: payload.password });
  return data;
};

export const GetQuotes = async (
  channel_id: string,
  agent_id: string,
  page_limit: number,
  page_number: number) => {
  const { data } = await axios.get(`${process.env.REACT_APP_QUOTE_TO_BIND_HOST}/quote_to_bind/quote?channel_id=${channel_id}&agent_id=${agent_id}&page_limit=${page_limit}&page_number=${page_number}`);
  return data;
};

export const GetPolicies = async (
  channel_id: string,
  agent_id: string,
  page_limit: number,
  page_number: number) => {
  const { data } = await axios.get(`${process.env.REACT_APP_QUOTE_TO_BIND_HOST}/quote_to_bind/policy?channel_id=${channel_id}&agent_id=${agent_id}&page_limit=${page_limit}&page_number=${page_number}`);
  return data;
};

export const GetForgotPassword = async (payload: string) => {
  const { data } = await axios.post(`${process.env.REACT_APP_AGENT_LOGIN}/agent-auth/forgot-password`, { username: payload });
  return data;
};

export const GetConfirmation = async (payload: Agentuser) => {
  const { data } = await axios.post(`${process.env.REACT_APP_AGENT_LOGIN}/agent-auth/confirm-forgot-password`, { username: payload.email, password: payload.password, confirmation_code: payload.code });
  return data;
};

export const GetPaymentInfo = async (payload: PaymentInfoInput) => {
  const { data } = await axios.get(`${process.env.REACT_APP_Q2B_HOST}/policy/bind?selection=${payload.selection}&paidInFull=${payload.paidInFull}&rateId=${payload.rateId}`);

  return data as PaymentInfo;
};

export const GetDriverPrefill = async (payload: any, token?: any) => {
  const { data } = await axios.post(`${process.env.REACT_APP_Q2B_HOST}/q2b/driver-prefill`, payload, {headers : {'Authorization': `Bearer ${token}`}});

  return data;
};

export const GetVehiclePrefill = async (payload: any, token?: any) => {
  const { data } = await axios.post(`${process.env.REACT_APP_Q2B_HOST}/q2b/vehicle-prefill`, payload, {headers : {'Authorization': `Bearer ${token}`}});

  return data;
};

export const ValidateDriver = async (payload: Driver) => {
  const { data } = await axios.get(`${process.env.REACT_APP_Q2B_HOST}/policy/validate/driver?license=${payload.licenseNumber}&birthdate=${payload.birthDate}`);

  return data;
};

export const ValidateVehicle = async (payload: Vehicle) => {
  const { data } = await axios.get(`${process.env.REACT_APP_Q2B_HOST}/policy/validate/vehicle?vin=${payload.vin}`);

  return data;
};