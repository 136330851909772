import React from 'react';
import Modal from '@material-ui/core/Modal';
import './Modal.scss';

const CommonModal = (props: any) => {
  return (
    <div>
      <Modal open={props.open}>{props.modalBody}</Modal>
    </div>
  );
};

export default CommonModal;
