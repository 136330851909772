import { capitalCase } from "change-case";
import { Partner } from '../models/partner';
import { Driver } from '../models/motion';
import { GetPartnerQuote } from './queries';
import { usStateList } from '../models/stateCodes';
import { priorCoverageBiLimits } from "./selectList";

const trackingData = window.sessionStorage.getItem('tracking');
const trackingDataJson = JSON.parse(trackingData!);
  
const partnerData: Partner = {
  partner: null,
  trackingId: null,
  publisher: null,
  campaign: null,
  channel: null,
  policyId: null,
  url: null,
  agentId: null,
  state: null,
  visitorId: null,
};

const primaryInsuredData: Driver = {};

/*
 * Parse primary insured url params
 */
export const getPrimaryInsuredParams = (url: string) => {
  const params = url.split('?')[1];
  const paramsArray = new URLSearchParams(params);

  const firstName = getFirstName(paramsArray);
  const lastName = getLastName(paramsArray);
  const address = getAddress(paramsArray);
  const apartmentNumber = getApartmentNumber(paramsArray);
  const city = getCity(paramsArray);
  const state = getDriverState(paramsArray);
  const zip = getZip(paramsArray);
  const birthDate = getBirthDate(paramsArray);
  const email = getEmail(paramsArray);
  const phone = getPhone(paramsArray);

  if (firstName) primaryInsuredData.firstName = firstName;
  if (lastName) primaryInsuredData.lastName = lastName;
  if (address) primaryInsuredData.address = address;
  if (apartmentNumber) primaryInsuredData.apartmentNumber = apartmentNumber;
  if (city) primaryInsuredData.city = city;
  if (state) primaryInsuredData.state = state;
  if (zip) primaryInsuredData.zip = zip;
  if (birthDate) primaryInsuredData.birthDate = birthDate;
  if (email) primaryInsuredData.email = email;
  if (phone) primaryInsuredData.phone = phone;
  primaryInsuredData.isPrimaryInsured = true;
  primaryInsuredData.canBeRemoved = false;

  return primaryInsuredData;
};

const getFirstName = (paramsArray: URLSearchParams) => {
  let result = null;
  let firstName = paramsArray.get('d_firstName');

  // if partner url is passed in with {}, meaning a value was not provided set to null
  if (firstName != null && firstName.includes('{') && firstName.includes('}')) {
    firstName = null;
  }

  if (firstName !== null && result === null) {
    result = firstName;
  }

  return result;
};

const getLastName = (paramsArray: URLSearchParams) => {
  let result = null;
  let lastName = paramsArray.get('d_lastName');

  // if partner url is passed in with {}, meaning a value was not provided set to null
  if (lastName != null && lastName.includes('{') && lastName.includes('}')) {
    lastName = null;
  }

  if (lastName !== null && result === null) {
    result = lastName;
  }

  return result;
};

const getAddress = (paramsArray: URLSearchParams) => {
  let result = null;
  let address = paramsArray.get('d_address');

  // if partner url is passed in with {}, meaning a value was not provided set to null
  if (address != null && address.includes('{') && address.includes('}')) {
    address = null;
  }

  if (address !== null && result === null) {
    result = address;
  }

  return result;
};

const getApartmentNumber = (paramsArray: URLSearchParams) => {
  let result = null;
  let apartmentNumber = paramsArray.get('d_apartmentNumber');

  // if partner url is passed in with {}, meaning a value was not provided set to null
  if (apartmentNumber != null && apartmentNumber.includes('{') && apartmentNumber.includes('}')) {
    apartmentNumber = null;
  }

  if (apartmentNumber !== null && result === null) {
    result = apartmentNumber;
  }

  return result;
};

const getCity = (paramsArray: URLSearchParams) => {
  let result = null;
  let city = paramsArray.get('d_city');

  // if partner url is passed in with {}, meaning a value was not provided set to null
  if (city != null && city.includes('{') && city.includes('}')) {
    city = null;
  }

  if (city !== null && result === null) {
    result = city;
  }

  return result;
};

const getDriverState = (paramsArray: URLSearchParams) => {
  let result = null;
  let state = paramsArray.get('d_state');

  // if partner url is passed in with {}, meaning a value was not provided set to null
  if (state != null && state.includes('{') && state.includes('}')) {
    state = null;
  }

  // if partner passes full state name attempt to find the state code
  if (state !== null && state.length > 2) {
    usStateList.forEach(currState => {
      if (currState.display.toLowerCase() === state!.toLowerCase()) {
        state = currState.value;
      }
    })
  }

  if (state !== null && result === null) {
    result = state;
  }

  return result;
};

const getZip = (paramsArray: URLSearchParams) => {
  let result = null;
  let zip = paramsArray.get('d_zipCode');

  // if partner url is passed in with {}, meaning a value was not provided set to null
  if (zip != null && zip.includes('{') && zip.includes('}')) {
    zip = null;
  }

  if (zip !== null && result === null) {
    result = zip;
  }

  return result;
};

const getBirthDate = (paramsArray: URLSearchParams) => {
  let result = null;
  let birthDate = paramsArray.get('d_dateOfBirth');

  const birthDateRegex = /^\d{2}\/\d{2}\/\d{4}$/;
  if (birthDate !== null && birthDateRegex.test(birthDate)) {
    let year;
    let month;
    let day;
    [month, day, year] = birthDate.split('/');

    birthDate = `${year}-${month}-${day}`;
  }

  // if partner url is passed in with {}, meaning a birth date was not provided set birthDate to null
  if (birthDate != null && birthDate.includes('{') && birthDate.includes('}')) {
    birthDate = null;
  }

  if (birthDate !== null && result === null) {
    result = birthDate;
  }

  return result;
};

const getEmail = (paramsArray: URLSearchParams) => {
  let result = null;
  let email = paramsArray.get('d_email');

  // if partner url is passed in with {}, meaning a value was not provided set to null
  if (email != null && email.includes('{') && email.includes('}')) {
    email = null;
  }

  if (email !== null && result === null) {
    result = email;
  }

  return result;
};

const getPhone = (paramsArray: URLSearchParams) => {
  let result = null;
  let phone = paramsArray.get('d_phoneNumber');

  // if partner url is passed in with {}, meaning a value was not provided set to null
  if (phone != null && phone.includes('{') && phone.includes('}')) {
    phone = null;
  }

  if (phone !== null && result === null) {
    result = phone;
  }

  return result;
};

/*
 * Parse partner url params
 */
export const getPartnerUrlParams =
  (url: string, referrer: string) => {
    const params = url.split('?')[1];
    const paramsArray = new URLSearchParams(params);
    partnerData.url = referrer;

    partnerData.partner = getPartner(paramsArray);
    partnerData.publisher = getPublisher(paramsArray);
    partnerData.campaign = getCampaign(paramsArray);
    partnerData.trackingId = getTrackingId(paramsArray);
    partnerData.channel = getChannel(paramsArray);
    partnerData.agentId = getAgentId(paramsArray);
    partnerData.state = getState(paramsArray);

    return partnerData;
  };

const getState = (paramsArray: URLSearchParams) => {
  let result = null;
  const state = paramsArray.get('state');
  const stateName = paramsArray.get('state_name');
  const utmContent = paramsArray.get('utm_content');

  if (state !== null && result === null) {
    result = state;
  } else if (stateName !== null && result === null) {
    result = stateName;
  } else if (utmContent !== null && result === null) {
    result = utmContent;
  }

  if (result !== null) {
    result = result.toLowerCase();
  }

  return result;
};

const getAgentId = (paramsArray: URLSearchParams) => {
  let result = '0';
  const agentId = paramsArray.get('agent_id');

  if (agentId !== null && result === '0') {
    result = agentId;
  }

  return result;
};

const getChannel = (paramsArray: URLSearchParams) => {
  let result: string | null = null;
  const channel = paramsArray.get('channel');

  if (channel !== null && result === null) {
    result = channel;
  }

  return result;
};

const getTrackingId = (paramsArray: URLSearchParams) => {
  let result: string | null = null;
  const token = paramsArray.get('token');
  const clickKey = paramsArray.get('clickkey');
  const subValue = paramsArray.get('sub1');

  if (token !== null && result === null) {
    result = token;
  } else if (clickKey !== null && result === null) {
    result = clickKey;
  } else if (subValue !== null && result === null) {
    result = subValue;
  } else if (trackingDataJson && trackingDataJson.quoteId) {
    result = trackingDataJson.quoteId;
  }

  return result;
};

const getCampaign = (paramsArray: URLSearchParams) => {
  let result: string | null = null;
  const campaign = paramsArray.get('campaign');
  const utmCampaign = paramsArray.get('utm_campaign');

  if (campaign !== null && result === null) {
    result = campaign;
  } else if (utmCampaign !== null && result === null) {
    result = utmCampaign;
  } else if (trackingDataJson && trackingDataJson.marketSegmentId) {
    result = trackingDataJson.marketSegmentId;
  }

  return result;
};

const getPublisher = (paramsArray: URLSearchParams) => {
  let result: string | null = null;
  const publisher = paramsArray.get('publisher');
  const utmMedium = paramsArray.get('utm_medium');
  const medium = paramsArray.get('medium');
  const sourceId = paramsArray.get('source_id');

  if (publisher !== null && result === null) {
    result = publisher;
  } else if (medium !== null && result === null) {
    result = medium;
  } else if (utmMedium !== null && result === null) {
    result = utmMedium;
  } else if (sourceId !== null && result === null) {
    result = sourceId;
  }

  if (result !== null) {
    result = result.toLowerCase();
  }

  return result;
};

const getPartner = (paramsArray: URLSearchParams) => {
  let result: string | null = null;
  const source = paramsArray.get('source');
  const utmSource = paramsArray.get('utm_source');
  const partner = paramsArray.get('partner');

  if (source !== null && result === null) {
    result = source;
  } else if (utmSource !== null && result === null) {
    result = utmSource;
  } else if (partner !== null && result === null) {
    result = partner;
  }

  if (result !== null) {
    result = result.toLowerCase();
  }

  return result;
};


/*
 * Parse quotes from rate call partners
 */
export const setQuoteInfo = async (url: string) => {
  window.sessionStorage.clear();
  const quote = await getPartnerQuote(url);
  const partner = quote && quote.channel ? quote.channel : 'not provided';

  // indicate that the user came from a rateCall partner
  window.sessionStorage.setItem('rateCall', 'true');

  const { drivers } = quote.input.policyData;
  addDrivers(drivers);
  const vehicles = quote.input.policyData.vehicles;
  addVehicles(vehicles);

  const policyCoverage = quote.input.policyData.policyCoverage
  if (policyCoverage) {
    window.sessionStorage.setItem('policyCoverage', JSON.stringify(policyCoverage));
  }

  const { currentCoverage } = quote.input.policyData;
  priorCoverage(currentCoverage)

  const tracking = quote.input.tracking;
  if (tracking) {
    window.sessionStorage.setItem('tracking', JSON.stringify(tracking));
  }

  const motionauto_offer_id = quote.body.motionauto_offer_id
  if (motionauto_offer_id) {
    window.sessionStorage.setItem('motionautoOfferId', motionauto_offer_id);
  }

  const policyEffective = quote.input.effectiveAt
  if (policyEffective) {
    const policyEffectiveSplit = policyEffective.split('-')
    const startDate = new Date(policyEffectiveSplit[0], policyEffectiveSplit[1] - 1, policyEffectiveSplit[2])
    window.sessionStorage.setItem('startDate', startDate.toString());
  }

  // const household_id = quote.input.household_id
  // if (household_id) {
  //   window.sessionStorage.setItem('householdId', household_id); 
  // }

  // after loading quote info redirect so user can bind
  const baseUrl = url.split('/partner-quote')[0];
  const params = url.split('?')[1];
  const urlParams = new URLSearchParams(params);
  const everflowId = urlParams.get('ef_transaction_id')
  let bridgeUrl = `${baseUrl}/primary-info?partner=${partner}`
  if (everflowId) {
    bridgeUrl += `&ef_transaction_id=${everflowId}`
  }
  window.location.replace(bridgeUrl);
};


const getPartnerQuote = async (url: string) => {
  const params = url.split('?')[1];
  const paramsArray = new URLSearchParams(params);
  const transactionId = paramsArray.get('transactionId');

  if (transactionId) {
    return GetPartnerQuote(transactionId);
  }
};

const priorCoverage = (currentCoverage: any) => {
  if (currentCoverage) {
    const liabilityPerPerson = parseInt(currentCoverage['liabilityPerPerson']) || 0
    const finalVal = liabilityPerPerson.toString().length > 3 ? liabilityPerPerson / 1000 : liabilityPerPerson
    if (liabilityPerPerson === 0) {
      currentCoverage['liabilityPerPerson'] = 0
      currentCoverage['liabilityPerAccident'] = 0
    } else if (priorCoverageBiLimits.hasOwnProperty(finalVal)) {
      currentCoverage['liabilityPerPerson'] = finalVal
      currentCoverage['liabilityPerAccident'] = priorCoverageBiLimits[finalVal]
    } else {
      const key = Object.keys(priorCoverageBiLimits).filter(key => parseInt(key) < finalVal)
      currentCoverage['liabilityPerPerson'] = parseInt(key[key.length - 1])
      currentCoverage['liabilityPerAccident'] = priorCoverageBiLimits[key[key.length - 1]]

    }

    if (currentCoverage.carrierId) {
      currentCoverage["hasCurrentCoverage"] = "Yes"
    }
    
    window.sessionStorage.setItem('priorCoverage', JSON.stringify(currentCoverage));
  }
}

const addVehicles = (vehicles: any) => {
  const transformedVehicles: any[] = [];
  vehicles.forEach((quoteVehicle: any) => {
    const newVehicle: any = {};
    if (quoteVehicle.vin) {
      newVehicle.vin = quoteVehicle.vin;
    }
    if (quoteVehicle.make) {
      newVehicle.make = quoteVehicle.make;
    }
    if (quoteVehicle.model) {
      newVehicle.model = quoteVehicle.model;
    }
    if (quoteVehicle.year) {
      newVehicle.year = quoteVehicle.year;
    }
    if (quoteVehicle.image) {
      newVehicle.imageUrl = quoteVehicle.image;
    }
    if (quoteVehicle.ownershipLength) {
      newVehicle.ownershipLength = quoteVehicle.ownershipLength;
    }
    if (quoteVehicle.ownershipType) {
      newVehicle.ownershipType = capitalCase(quoteVehicle.ownershipType);
    }
    if (quoteVehicle.primaryUse) {
      newVehicle.primaryUse = capitalCase(quoteVehicle.primaryUse);
    }
    if (quoteVehicle.primaryZipCode) {
      newVehicle.primaryZipCode = quoteVehicle.primaryZipCode;
    }
    if (quoteVehicle.rideshare) {
      newVehicle.rideshare = quoteVehicle.rideshare;
    }
    if (quoteVehicle.vehicleCoverage !== {}) {
      newVehicle.vehicleCoverage = quoteVehicle.vehicleCoverage;
    }
    transformedVehicles.push(newVehicle);
  });
  window.sessionStorage.setItem('vehicles', JSON.stringify(transformedVehicles));
};

const addDrivers = (drivers: any) => {
  const transformedDrivers: Driver[] = [];
  drivers.forEach((quoteDriver: any) => {
    const newDriver: Driver = {};
    if (quoteDriver.address) {
      newDriver.address = quoteDriver.address;
    }
    if (quoteDriver.ageLicensed) {
      newDriver.ageLicensed = parseInt(quoteDriver.ageLicensed) >= 21 ? '21 or older' : quoteDriver.ageLicensed;
    }

    newDriver.driverType = quoteDriver.driverType ? quoteDriver.driverType : 'rated';

    if (quoteDriver.birthDate) {
      newDriver.birthDate = quoteDriver.birthDate;
    }
    if (quoteDriver.city) {
      newDriver.city = quoteDriver.city;
    }
    if (quoteDriver.education) {
      newDriver.education = quoteDriver.education;
    }
    if (quoteDriver.email) {
      newDriver.email = quoteDriver.email;
    }
    if (quoteDriver.firstName) {
      newDriver.firstName = quoteDriver.firstName;
    }
    if (quoteDriver.gender) {
      newDriver.gender = capitalCase(quoteDriver.gender);
    }
    if (quoteDriver.hadDriverTraining) {
      newDriver.hadDriverTraining = quoteDriver.hadDriverTraining[0].toUpperCase();
    }
    if (quoteDriver.isExcluded) {
      newDriver.isExcluded = quoteDriver.isExcluded;
    }
    if (quoteDriver.lastName) {
      newDriver.lastName = quoteDriver.lastName;
    }
    if (quoteDriver.licenseStatus) {
      const licenseStatus = quoteDriver.licenseStatus.toLowerCase()
      if (licenseStatus === 'valid') {
        newDriver.licenseStatus = 'Active / Valid'
      } else if (licenseStatus === 'suspended') {
        newDriver.licenseStatus = 'Suspended / Denied'
      } else if (licenseStatus === 'foreign') {
        newDriver.licenseStatus = 'Foreign license'
      } else {
        newDriver.licenseStatus = quoteDriver.licenseStatus;
      }
    }
    if (quoteDriver.monthsAtResidence) {
      const monthAsNumber = parseInt(quoteDriver.monthsAtResidence, 10) || 0;
      newDriver.monthsAtResidence = monthAsNumber >= 12 ? '12 or more months' : '0-11 months';
    }
    if (quoteDriver.ownResidence) {
      newDriver.residenceType = quoteDriver.ownResidence;
    }
    if (quoteDriver.relationshipStatus) {
      newDriver.relationshipStatus = capitalCase(quoteDriver.relationshipStatus);
    }
    if (quoteDriver.relationshipToPrimaryDriver) {
      if (capitalCase(quoteDriver.relationshipToPrimaryDriver).split(' ').length > 1) {
        const relationshipToPrimaryDriver = capitalCase(quoteDriver.relationshipToPrimaryDriver).split(' ')
        newDriver.relationshipToPrimaryDriver = relationshipToPrimaryDriver.join('_')
      } else {
        newDriver.relationshipToPrimaryDriver = capitalCase(quoteDriver.relationshipToPrimaryDriver);
      }
      if (quoteDriver.relationshipToPrimaryDriver.toLowerCase() === 'self') {
        newDriver.isPrimaryInsured = true;
        newDriver.canBeRemoved = false;
      }
    }
    if (quoteDriver.requireSr22) {
      newDriver.requireSr22 = capitalCase(quoteDriver.requireSr22);
    }
    if (quoteDriver.state) {
      newDriver.state = quoteDriver.state;
    }
    if (quoteDriver.stateLicensed) {
      newDriver.stateLicensed = quoteDriver.stateLicensed;
    }
    if (quoteDriver.zip) {
      newDriver.zip = quoteDriver.zip;
    }
    if (quoteDriver.phone) {
      newDriver.phone = quoteDriver.phone;
    }
    if (quoteDriver.licenseNumber) {
      newDriver.licenseNumber = quoteDriver.licenseNumber;
    }
    if (quoteDriver.occupation) {
      newDriver.occupation = quoteDriver.occupation;
    }
    if (quoteDriver.driverType) {
      newDriver.driverType = quoteDriver.driverType;
    }
    if (newDriver.isPrimaryInsured) {
      transformedDrivers.splice(0, 0, newDriver);
    } else {
      transformedDrivers.push(newDriver);
    }

    window.sessionStorage.setItem('drivers', JSON.stringify(transformedDrivers));
  });
};
