import React, { useState, useContext } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { ReactComponent as HorizontalLine } from '../images/rectangle-line.svg';
import { ReactComponent as Vector } from '../images/attachment.svg';
import { ReactComponent as FileVector } from '../images/file.svg';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip
} from '@material-ui/core';
import './IncidentReport.scss';
import { AuthModel } from '../models/motion';
import { BrandContext, getNovoIconColor, getPrimaryColor } from '../utils/style';
import { useHistory } from 'react-router';
import { accidentList } from '../models/incidents';
import { incidentMvrRep } from '../utils/mutations';
import { ReactComponent as ToolTipIcon } from '../images/tool-tip.svg';


const IncidentReportMvr: React.FC<AuthModel> = () => {
  const history = useHistory()

  var obj: any = {}
  var objArr: any = {}
  incidentMvrRep.forEach((incident: any, index: number) => {
    obj[index] = undefined
    objArr[index] = []
  })

  const [filesAccident, setFilesAccident] = useState<any>({ ...objArr });
  const [filesArrAccident, setFilesArrAccident] = useState<any>({ ...objArr });
  const [filesViolation, setFilesViolation] = useState<any>({ ...objArr });
  const [filesArrViolation, setFilesArrViolation] = useState<any>({ ...objArr });

  const [accidentStates, setAccidentStates] = useState<any>({ ...obj });
  const [violationStates, setViolationStates] = useState<any>({ ...obj });

  const methods = useForm ({
    // default values 
  });
  const { handleSubmit, errors, control, watch } = methods;

  const onSubmit = (data: AuthModel) => {
   // API call
  };

  const [selectionAccidentList] = React.useState(() => {
    return accidentList.map((s: any) => {
      return (
        <MenuItem key={s.value} value={s.value}>
          {s.display}
        </MenuItem>
      );
    });
  });

  const onFileChangeAccident = (event: any) => {
    let name = parseInt(event.target.name)
    let index = filesArrAccident[name].findIndex((file: any) => file.name === event.target.files[0].name)
    if (index < 0) {
      let fileArr = { ...filesArrAccident }
      fileArr[name] = [...filesArrAccident[name], event.target.files[0]]
      setFilesArrAccident({ ...fileArr })
      setFilesAccident({ ...fileArr });
    }
  }

  const onRemoveAccident = (id: number, index: number) => {
    filesArrAccident[index].splice(id, 1)
    let fileArr = { ...filesArrAccident }
    fileArr[index] = [...filesArrAccident[index]]
    setFilesArrAccident({ ...fileArr })
    setFilesAccident({ ...fileArr })
  }

  const onFileChangeViolation = (event: any) => {
    let name = parseInt(event.target.name)
    let index = filesArrViolation[name].findIndex((file: any) => file.name === event.target.files[0].name)
    if (index < 0) {
      let fileArr = { ...filesArrViolation }
      fileArr[name] = [...filesArrViolation[name], event.target.files[0]]
      setFilesArrViolation({ ...fileArr })
      setFilesViolation({ ...fileArr });
    }
  }

  const onRemoveViolation = (id: number, index: number) => {
    filesArrViolation[index].splice(id, 1)
    let fileArr = { ...filesArrViolation }
    fileArr[index] = [...filesArrViolation[index]]
    setFilesArrViolation({ ...fileArr })
    setFilesViolation({ ...fileArr })
  }

  const nextClicked = () => {
    // next page
  };

  return (
    <>
      <div className='import-message-wrapper-scan-report'  >
        <span className='import-title-sub-report' style={{ color: getNovoIconColor() }}>Look What We Found</span>
      </div>
      <div className='import-message-wrapper-scan-report'  >
        <span className='import-title-content-scan-report' style={{ fontSize: '20px' }}>
          Review MVR driver history results. Please accept or duspute.
        </span>
      </div>
      <Grid className="main">
        <div className='import-message-wrapper-scan-report' style={{ marginBottom: '0em' }}>
          <span className='import-title-content-scan-report import-title-head'>
            <h2 style={{ color: getNovoIconColor() }}>Accidents (Past 5 years)</h2>
          </span>
        </div>
        <div className='import-message-wrapper-scan-report-text'  >
          <span className='import-title-content-scan-report import-title-mvr'>
            MVR RESULTS
          </span>
        </div>
        <br />
        {incidentMvrRep.length > 0 && incidentMvrRep.filter((inc: any) => inc['group'] === 'ACCIDENT').map((inc: any, index: number) => {
          return (<React.Fragment>
            <div className='import-message-wrapper-scan-report-text'  >
              <span className='import-title-content-scan-report mvr-date' style={{ marginTop: '-1em' }}>
                {new Date(inc['date'] || '').toLocaleString('default', { month: 'long' }) + ' ' + new Date(inc['date'] || '').getFullYear()}
              </span>
            </div>
            <div className='import-message-wrapper-scan-report-text'  >
              <span className='import-title-content-scan-report mvr-type-history'>
                {accidentList.find(accident => accident.value === inc['code'])?.display}
              </span>
            </div>
            <FormProvider {...methods}>
              <form
                id='authForm'
                className='import-form'
                onSubmit={handleSubmit(onSubmit)}
              >
                <Grid container>
                  <Grid item lg={3} md={3} ></Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12} >
                    <div style={{ textAlign: 'center', marginTop: '-24px' }}  >
                      <span className='import-title-content-scan-report'>
                        <Button
                          id={accidentStates[index] == false ? 'authButtonReportMvrBg' : 'authButtonReportMvr'}
                          className='initial-info-import-submit'
                          type='submit'
                          onClick={() => {
                            let accSt = { ...accidentStates }
                            accSt[index] = false
                            setAccidentStates({ ...accSt })
                          }}
                          variant='contained'
                          style={{ width: '41%' }}
                        >
                          Accept
                        </Button>
                        <Button
                          id={accidentStates[index] == true ? 'authButtonReportMvrBg' : 'authButtonReportMvr'}
                          className='initial-info-import-submit'
                          type='submit'
                          onClick={() => {
                            let accSt = { ...accidentStates }
                            accSt[index] = true
                            setAccidentStates({ ...accSt })
                          }}
                          variant='contained'
                          style={{ width: '41%', marginLeft:'25px' }}
                        >
                          Dispute
                        </Button>
                      </span>
                    </div>
                  </Grid>
                  {accidentStates[index] && <Grid container style={{ marginTop: '' }}>
                    <Grid item lg={3} md={3} ></Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} >
                      <div style={{ textAlign: 'center', marginTop: '1em' }}  >
                        <span className='import-title-content-scan-report'>
                          <Controller
                            name={`disputeType`}
                            render={({ onChange, value, name, onBlur }) => (
                              <FormControl
                                variant='outlined'
                                style={{ width: '87%' }}
                              >
                                <InputLabel>Dispute reason</InputLabel>
                                <Select
                                  label='accidentType'
                                  value={value}
                                  name={name}
                                  onChange={onChange}
                                  onBlur={onBlur}
                                >
                                  {selectionAccidentList}
                                </Select>
                                <FormHelperText>
                                </FormHelperText>
                              </FormControl>
                            )}
                          />
                        </span>

                      </div>
                      <div className='import-message-wrapper-scan-report-text'  >
                        <span className='import-title-content-scan-report mvr-document-title'>
                          Upload document to provide proof
                        </span>
                        <div className='mvr-tooltip'>
                          <Tooltip
                            enterTouchDelay={0}
                            leaveTouchDelay={3500}
                            className='tooltip'
                            title= 'Upload document to provide proof'
                            placement='top-start'
                          >
                            <ToolTipIcon />
                          </Tooltip>
                        </div>
                      </div>
                      
                      <div className='import-message-wrapper-scan-report-text' >
                        <span className='import-title-content-scan-report mvr-attachment' style={{ color: getPrimaryColor() }}>
                          <Vector style={{ marginTop: '-3em', height: '18px' }} /> &nbsp;
                          <label style={{ cursor: 'pointer' }}>
                            <input type="file" name={`${index}`} onChange={onFileChangeAccident} style={{ display: 'none' }} />
                            <span >Attachment</span>
                          </label>
                        </span>
                      </div>
                      <div className='import-message-wrapper-scan-report-text'  >
                        <span className='import-title-content-scan-report mvr-attachment-file' style={{ color: getNovoIconColor() }}>
                          {filesAccident[index] && filesAccident[index].map((file: any, ind: number) => {
                            return (<><FileVector /> &nbsp; {file['name']}
                              &nbsp; &nbsp;&nbsp;<span onClick={() => onRemoveAccident(ind, index)} className='mvr-attachment-file-remove'
                              >Remove</span><br /></>)
                          })
                          }
                        </span>
                      </div>
                    </Grid>
                  </Grid>}
                  {index < incidentMvrRep.length - 1 && <Grid container style={{}}>
                    <Grid item lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'center', marginTop: '2rem' }}>
                    </Grid>
                  </Grid>
                  }
                </Grid>
              </form>
            </FormProvider>
          </React.Fragment>)
        })}
        <Grid container style={{}}>
          <Grid item lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'center', marginTop: '2rem' }}>
            <HorizontalLine style={{ width: '10em' }} />
          </Grid>
        </Grid>
      </Grid>
      <Grid className="main" >
        <div className='import-message-wrapper-scan-report' >
          <span className='import-title-content-scan-report' style={{ fontSize: '13px', textAlign: 'left', fontFamily: 'LatoBold' }}>
            <h2 style={{ color: getNovoIconColor() }}>Traffic violations (past 5 years)</h2>
          </span>
        </div>
        <div className='import-message-wrapper-scan-report-text'  >
          <span className='import-title-content-scan-report' style={{ fontSize: '12px', color: '#00000099', textAlign: 'left', marginTop: '-1em', letterSpacing: '1px', lineHeight: '24px' }}>
            MVR RESULTS
          </span>
        </div>
        <br />
        {incidentMvrRep.length > 0 && incidentMvrRep.filter((inc: any) => inc['group'] === 'VIOLATION').map((inc: any, index: number) => {
          return (<React.Fragment>
            <div className='import-message-wrapper-scan-report-text'  >
              <span className='import-title-content-scan-report mvr-date' style={{ marginTop: '-1em' }}>
                {new Date(inc['date'] || '').toLocaleString('default', { month: 'long' }) + ' ' + new Date(inc['date'] || '').getFullYear()}
              </span>
            </div>
            <div className='import-message-wrapper-scan-report-text'  >
              <span className='import-title-content-scan-report mvr-type-history'>
                {accidentList.find(accident => accident.value === inc.code)?.display}
              </span>
            </div>
            <FormProvider {...methods}>
              <form
                id='authForm'
                className='import-form'
                onSubmit={handleSubmit(onSubmit)}
              >
                <Grid container style={{ marginTop: '' }}>
                  <Grid item lg={3} md={3} ></Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12} >
                    <div style={{ textAlign: 'center', marginTop: '-24px' }}  >
                      <span className='import-title-content-scan-report'>
                        <Button
                          id={violationStates[index] == false ? 'authButtonReportMvrBg' : 'authButtonReportMvr'}
                          className='initial-info-import-submit'
                          type='submit'
                          onClick={() => {
                            let vioSt = { ...violationStates }
                            vioSt[index] = false
                            setViolationStates({ ...vioSt })
                          }}
                          variant='contained'
                          style={{ width: '41%' }}
                        >
                          Accept
                        </Button>
                        <Button
                          id={violationStates[index] == true ? 'authButtonReportMvrBg' : 'authButtonReportMvr'}
                          className='initial-info-import-submit'
                          type='submit'
                          onClick={() => {
                            let vioSt = { ...violationStates }
                            vioSt[index] = true
                            setViolationStates({ ...vioSt })
                          }}
                          variant='contained'
                          style={{ width: '41%', marginLeft:'25px' }}
                        >
                          Dispute
                        </Button>
                      </span>
                    </div>
                  </Grid>
                </Grid>
                {violationStates[index] && <Grid container style={{ marginTop: '' }}>
                  <Grid item lg={3} md={3} ></Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12} >
                    <div style={{ textAlign: 'center', marginTop: '1em' }}  >
                      <span className='import-title-content-scan-report'>
                        <Controller
                          name={`disputeType`}
                          render={({ onChange, value, name, onBlur }) => (
                            <FormControl
                              variant='outlined'
                              style={{ width: '87%' }}
                            >
                              <InputLabel>Dispute reason</InputLabel>
                              <Select
                                label='accidentType'
                                value={value}
                                name={name}
                                onChange={onChange}
                                onBlur={onBlur}
                              >
                                {selectionAccidentList}
                              </Select>
                              <FormHelperText>
                              </FormHelperText>
                            </FormControl>
                          )}
                        />
                      </span>
                    </div>
                    <div className='import-message-wrapper-scan-report-text'  >
                      <span className='import-title-content-scan-report mvr-document-title'>
                        Upload document to provide proof
                      </span>
                      <div className='mvr-tooltip'>
                        <Tooltip
                          enterTouchDelay={0}
                          leaveTouchDelay={3500}
                          className='tooltip'
                          title= 'Upload document to provide proof'
                          placement='top-start'
                        >
                          <ToolTipIcon />
                        </Tooltip>
                      </div>
                    </div>
                    <div className='import-message-wrapper-scan-report-text' >
                      <span className='import-title-content-scan-report mvr-attachment' style={{ color: getPrimaryColor() }}>
                        <Vector style={{ marginTop: '-3em', height: '18px' }} /> &nbsp;
                        <label style={{ cursor: 'pointer' }}>
                          <input type="file" name={`${index}`} onChange={onFileChangeViolation} style={{ display: 'none' }} />
                          <span >Attachment</span>
                        </label>
                      </span>
                    </div>
                    <div className='import-message-wrapper-scan-report-text'  >
                      <span className='import-title-content-scan-report mvr-attachment-file' style={{ color: getNovoIconColor() }}>
                        {filesViolation[index] && filesViolation[index].map((file: any, ind: number) => {
                          return (<><FileVector /> &nbsp; {file['name']}
                            &nbsp; &nbsp;&nbsp;<span onClick={() => onRemoveViolation(ind, index)} className='mvr-attachment-file-remove'
                            >Remove</span><br /></>)
                        })
                        }
                      </span>
                    </div>
                  </Grid>
                </Grid>}
                <Grid container style={{}}>
                  <Grid item lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'center', marginTop: '2rem' }}>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </React.Fragment>)
        })}
        <Grid container style={{ marginTop: '' }}>
                  <Grid item lg={3} md={3} ></Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12} >
                    <div style={{ textAlign: 'center' }}  >
                      <span className='import-title-content-scan-report'>
                        <Button
                          id='authButtonReport'
                          className='initial-info-import-submit'
                          type='submit'
                          color='primary'
                          variant='contained'
                          onClick={() => history.goBack()}
                          style={{ width: '41%' }}
                        >
                          Back
                        </Button>
                        <Button
                          id='authButtonReport'
                          className='initial-info-import-submit'
                          type='submit'
                          color='primary'
                          variant='contained'
                          onClick={nextClicked}
                          style={{ width: '41%', marginLeft:'25px' }}
                        >
                          Next
                        </Button>
                      </span>
                    </div>
                  </Grid>
                </Grid>
      </Grid>
    </>
  );
};

export default IncidentReportMvr;