export const titleCaseWord = (word: string) => {
  if (word.length < 1)
    throw new Error(`Word can not be blank, ${word}`);
  if (word.split(' ').length > 1)
    throw new Error(`titleCaseWord() only accepts single words, ${word}`);

  const lowerCasedWord = word.toLowerCase();
  return lowerCasedWord.charAt(0).toUpperCase() + lowerCasedWord.slice(1);
};

export const titleCaseWords = (words: string) => {
  const splitWords = words.split(' ');
  const formattedWords = splitWords.map((w: string) => titleCaseWord(w));

  return formattedWords.join(' ');
};
