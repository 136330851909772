import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import * as yup from 'yup';
import Bugsnag from '@bugsnag/js';
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
  Button
} from '@material-ui/core';
import { Helmet } from 'react-helmet';
import './SecondaryInfo.scss';
import { Driver } from '../models/motion';
import { educationOptions, genderOptions, getEductionMenuList, getGenderMenuList, getLengthOfResidencyMenuList, getOccupationMenuList, getRelationshipStatusMenuList, getResidenceTypeMenuList, lengthOfResidencyOptions, occupationOptions, relationshipStatusOptions, residenceTypeOptions } from '../utils/selectList';
import { BrandContext } from '../utils/style';
import { GetDriverPrefill, GetVehiclePrefill } from '../utils/queries';
import { identifyPotentialPrimaryInsured } from '../utils/prefill';
import MotionModal from '../components/MotionModal';
import PrimaryInsuredCandidates from '../components/PrimaryInsuredCandidates';


const schema = yup.object().shape({
  gender: yup.string().required('Please select your gender').trim(),
  relationshipStatus: yup.string().required('Please select your martial status').trim(),
  education: yup.string().required('Please select your education').trim(),
  occupation: yup.string().required('Please select your occupation').trim(),
  residenceType: yup.string().required('Please select your primary residence').trim(),
  monthsAtResidence: yup.string().required('Please select your length of residency at this address').trim(),
});

const SecondaryInfo: React.FC = () => {
  useEffect(() => {
    (window as any).nid('start', window.location.pathname);
  }, []);

  const { brand } = useContext(BrandContext);
  const date = window.sessionStorage.getItem('startDate') || '';
  const queryParams = window.sessionStorage.getItem('queryParams') || '';
  const idScanJson = window.sessionStorage.getItem('idScan') || '';
  const idScan = idScanJson ? JSON.parse(idScanJson!) : [];
  const newDate = date ? (new Date(date) < new Date() ? new Date() : new Date(date)) : new Date()
  window.sessionStorage.setItem('startDate', newDate.toString());

  const history = useHistory();
  const driversJson = window.sessionStorage.getItem('drivers') || '{}';
  let drivers = JSON.parse(driversJson);
  const primaryInsured: Driver = drivers.filter(
    (d: Driver) => d.isPrimaryInsured
  )[0];
  let accessToken = '';
  Object.keys(window.localStorage).forEach(key => {
    if (key.endsWith('accessToken')) {
      accessToken = window.localStorage.getItem(key) || ''
    }
  })

  // logic for transunion prefill when readding please test
  const [modalTitle] = useState(`We've researched your household. Please select ${primaryInsured.firstName} ${primaryInsured.lastName} from the members below.`);
  const [modalBody, setModalBody] = useState<string | React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const token = idScan && idScan.accessToken ? idScan.accessToken : ( accessToken ? accessToken : '' )
  useQuery('driverPrefill', () => GetDriverPrefill(primaryInsured, token), {
    enabled: true, // Don't trigger on page load; trigger from useEffect based on conditional logic
    staleTime: Infinity, // never re-query since we pay for each call
    retry: 0, // don't retry on failed query
    onSuccess: (data) => {
      if (data && data.body) {
        const candidates = identifyPotentialPrimaryInsured(primaryInsured, data.body);
        if (candidates.length > 0) {
          setModalBody(
            <PrimaryInsuredCandidates primaryInsuredCandidates={candidates} primaryInsured={primaryInsured} setIsModalOpen={setIsModalOpen} />
          );
          setIsModalOpen(true);
        }
        window.sessionStorage.setItem('prefilledDrivers', JSON.stringify(data.body));
      }
    },
    onError: (error) => {
      Bugsnag.notify({ name: 'Error fetching driver prefill', message: error as string });
    }
  });

  useQuery('vehiclePrefill', () => GetVehiclePrefill(primaryInsured, token), {
    enabled: true, // Don't trigger on page load; trigger from useEffect based on conditional logic
    staleTime: Infinity, // never re-query since we pay for each call
    retry: 0, // don't retry on failed query
    onSuccess: (data: any) => {
      if (data.body && data.body.length !== 0) {
        const vehicleBody = [...data.body]
        const vehicleData = vehicleBody.map(data => {
          data['primaryUse'] = "Commute"
          data['rideshare'] = false
          data['ownershipType'] = "Own"
          return data
        })
        window.sessionStorage.setItem('vehicles', JSON.stringify(vehicleData));
      }
    },
    onError: (error) => {
      Bugsnag.notify({ name: 'Error fetching vehicle prefill', message: error as string });
    }
  });

  const { handleSubmit, errors, control } = useForm<Driver>({
    defaultValues: {
      gender:
        primaryInsured
          && primaryInsured.gender
          && genderOptions.some(g => g === primaryInsured.gender)
          ? primaryInsured.gender
          : '',
      relationshipStatus:
        primaryInsured
          && primaryInsured.relationshipStatus
          && relationshipStatusOptions.some(r => r === primaryInsured.relationshipStatus)
          ? primaryInsured.relationshipStatus
          : 'Single',
      education:
        primaryInsured
          && primaryInsured.education
          && educationOptions.some(e => e === primaryInsured.education)
          ? primaryInsured.education
          : 'High school diploma or GED',
      occupation:
        primaryInsured
          && primaryInsured.occupation
          && occupationOptions.some(o => {
            const hasTitle = primaryInsured.occupation?.includes(o.title);
            const hasOption = o.options.some(o => primaryInsured.occupation?.includes(o));
            return hasTitle && hasOption;
          })
          ? primaryInsured.occupation
          : '',
      residenceType:
        primaryInsured
          && primaryInsured.residenceType
          && residenceTypeOptions.some(r => r === primaryInsured.residenceType)
          ? primaryInsured.residenceType
          : 'Own home',
      monthsAtResidence:
        primaryInsured
          && primaryInsured.monthsAtResidence
          && lengthOfResidencyOptions.some(l => l === primaryInsured.monthsAtResidence)
          ? primaryInsured.monthsAtResidence
          : '12 or more months',
    },
    resolver: yupResolver(schema),
  });
  const onSubmit = (data: Driver) => {
    drivers = drivers.map((d: Driver) => {
      if (d.isPrimaryInsured) {
        return {
          ...d,
          ...data,
        };
      }

      return d;
    });

    window.sessionStorage.setItem('drivers', JSON.stringify(drivers));

    window.scrollTo(0, 0);
    history.push(`/review-info${queryParams}`);
  };
  const [genderMenuList] = React.useState(getGenderMenuList());
  const [relationshipStatusMenuList] = React.useState(getRelationshipStatusMenuList());
  const [educationMenuList] = React.useState(getEductionMenuList());
  const [occupationMenuList] = React.useState(getOccupationMenuList());
  const [residenceTypeMenuList] = React.useState(getResidenceTypeMenuList());
  const [lengthOfResidencyMenuList] = React.useState(getLengthOfResidencyMenuList());

  return (
    <>
      <Helmet>
        <title>{`Secondary Info - ${brand === 'motion' ? 'Motion Insurance' : 'Novo Insurance'}`}</title>
        {
          brand === 'motion' ?
            <link rel='icon' href='/favicon.ico' />
            :
            <link rel='icon' href='/novo-fav.ico' />
        }
      </Helmet>
      {/* <div className='secondary-import-message-wrapper'>
                <div>
                    <CircularProgress />
                </div>
                <span className='import-title'>
                    Importing your information is in progress…
        </span>
            </div>
            <span className='secondary-import-message'>
                While we load your info, let’s take the opportunity to answer a few
                short questions that will help us get a more accurate initial price.
      </span> */}

      {/* modal for transunion prefill */}
      <MotionModal
        title={modalTitle}
        body={modalBody}
        open={isModalOpen}
        confirmText='Call 1 (844) 425-0049'
        closeText='Cancel'
        handleClose={() => { }}
        canClose={false}
      />
      <form
        id='secondaryForm'
        className='secondary-import-form'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={3}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Controller
              control={control}
              name='gender'
              defaultValue=' '
              render={({ onChange, value, name, onBlur }) => (
                <FormControl
                  variant='outlined'
                  fullWidth
                  error={errors.gender?.message !== undefined}
                >
                  <InputLabel>Gender</InputLabel>
                  <Select
                    id='gender'
                    label='Gender'
                    value={value}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue=''
                  >
                    {genderMenuList}
                  </Select>
                  <FormHelperText>
                    {errors.gender?.message !== undefined
                      ? errors.gender?.message
                      : ''}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Controller
              control={control}
              name='relationshipStatus'
              defaultValue=''
              render={({ onChange, value, name, onBlur }) => (
                <FormControl
                  variant='outlined'
                  fullWidth
                  error={errors.relationshipStatus?.message !== undefined}
                >
                  <InputLabel>Marital Status</InputLabel>
                  <Select
                    id='maritalStatus'
                    label='maritalStatus'
                    value={value}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                  >
                    {relationshipStatusMenuList}
                  </Select>
                  <FormHelperText>
                    {errors.relationshipStatus?.message !== undefined
                      ? errors.relationshipStatus?.message
                      : ''}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Controller
              control={control}
              name='education'
              defaultValue=''
              render={({ onChange, value, name, onBlur }) => (
                <FormControl
                  variant='outlined'
                  fullWidth
                  error={errors.education?.message !== undefined}
                >
                  <InputLabel>Education</InputLabel>
                  <Select
                    id='education'
                    label='education'
                    value={value}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                  >
                    {educationMenuList}
                  </Select>
                  <FormHelperText>
                    {errors.education?.message !== undefined
                      ? errors.education?.message
                      : ''}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Controller
              control={control}
              name='occupation'
              defaultValue={null}
              render={({ onChange, value, name, onBlur }) => (
                <FormControl
                  variant='outlined'
                  fullWidth
                  error={errors.occupation?.message !== undefined}
                >
                  <InputLabel>Occupation</InputLabel>
                  <Select
                    id='occupation'
                    label='occupation'
                    value={value || ''}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    native
                  >
                    <option aria-label='None' value='' />
                    {occupationMenuList}
                  </Select>
                  <FormHelperText>
                    {errors.occupation?.message !== undefined
                      ? errors.occupation?.message
                      : ''}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Controller
              control={control}
              name='residenceType'
              defaultValue=''
              render={({ onChange, value, name, onBlur }) => (
                <FormControl
                  variant='outlined'
                  fullWidth
                  error={errors.residenceType?.message !== undefined}
                >
                  <InputLabel>Primary Residence</InputLabel>
                  <Select
                    id='primaryResidence'
                    label='primaryResidence'
                    value={value}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                  >
                    {residenceTypeMenuList}
                  </Select>
                  <FormHelperText>
                    {errors.residenceType?.message !== undefined
                      ? errors.residenceType?.message
                      : ''}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Controller
              control={control}
              name='monthsAtResidence'
              defaultValue=''
              render={({ onChange, value, name, onBlur }) => (
                <FormControl
                  variant='outlined'
                  fullWidth
                  error={errors.monthsAtResidence?.message !== undefined}
                >
                  <InputLabel>Length of residency</InputLabel>
                  <Select
                    id='lengthOfResidency'
                    label='lengthOfResidency'
                    value={value}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                  >
                    {lengthOfResidencyMenuList}
                  </Select>
                  <FormHelperText>
                    {errors.monthsAtResidence?.message !== undefined
                      ? errors.monthsAtResidence?.message
                      : ''}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Button
          id='continue'
          className='secondary-info-import-submit'
          type='submit'
          color='primary'
          variant='contained'
        >
          Continue
        </Button>


      </form>
    </>
  );
};

export default SecondaryInfo;
