import Bugsnag from '@bugsnag/js';
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useQuery } from 'react-query';
import { GetOffer, GetPaymentInfo } from '../utils/queries';

export interface PaymentOptionsProps {

}

const PaymentOptions: React.FC<PaymentOptionsProps> = () => {
  const { control, errors, register, setValue } = useFormContext();
  const watchPaymentAmount = useWatch({
    control,
    name: 'paymentAmount',
    defaultValue: ''
  });
  const watchPaymentType = useWatch({
    control,
    name: 'paymentType',
    defaultValue: ''
  });
  const isPaidInFull = watchPaymentType === 'payInFull';
  const motionTransactionId = window.sessionStorage.getItem('motionTransactionId');
  const selectedTier = window.sessionStorage.getItem('selectedTier');
  const { data: offerData } = useQuery(`offer-${motionTransactionId}`, () => GetOffer(motionTransactionId!), {
    onError: (error) => {
      Bugsnag.notify({ name: 'Error fetching offer', message: error as string });
    }
  });
  const { data: paymentInfoData, refetch: paymentInfoRefetch } = useQuery('paymentInfo', () => GetPaymentInfo({ paidInFull: isPaidInFull, selection: selectedTier!, rateId: motionTransactionId! }), {
    onError: (error) => {
      Bugsnag.notify({ name: 'Error fetching payment info', message: error as string });
    }
  });

  // set payment amount on first load
  useEffect(() => {
    if (paymentInfoData) {
      setValue('paymentAmount', paymentInfoData.initialPayment ?? paymentInfoData.planTotal);
    }
  }, [paymentInfoData, setValue]);

  // update payment amount when toggling payment type
  useEffect(() => {
    paymentInfoRefetch();
  }, [watchPaymentType, paymentInfoRefetch]);

  return (
    <>
      <div className='payment-options-header'>
        Payment options
      </div>
      <Grid container spacing={2}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Controller
            control={control}
            name='paymentType'
            defaultValue='monthly'
            render={({ onChange, value, name, onBlur }) => (
              <FormControl
                variant='outlined'
                fullWidth
                error={
                  !!(
                    errors &&
                    errors.paymentType
                  )
                }
              >
                <InputLabel>Payment type</InputLabel>
                <Select
                  id='paymentType'
                  label='Payment type'
                  value={value}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                >
                  {
                    [
                      <MenuItem key='payInFull' id='optionPayInFull' value='payInFull'>
                        Pay in full
                      </MenuItem>,
                      <MenuItem key='monthly' id='optionMonthly' value='monthly'>
                        Monthly
                      </MenuItem>,
                    ]
                  }
                </Select>
                <FormHelperText>
                  {
                    errors &&
                      errors.paymentType
                      ? errors.paymentType.message
                      : ''
                  }
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
      <div className='payment-due-today-wrapper'>
        {
          paymentInfoData &&
          <>
            <NumberFormat
              value={watchPaymentAmount}
              displayType='text'
              prefix='$'
              suffix=' will be charged today'
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
            />
            <div className='savings-statement'>
              <NumberFormat
                value={offerData.offer[`${selectedTier}_pif_savings`]}
                displayType='text'
                prefix='*Save $'
                suffix=' when you pay in full.'
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </div>
          </>
        }
        <input
          type='hidden'
          name='paymentAmount'
          defaultValue={watchPaymentAmount}
          ref={register}
        />
      </div>
    </>
  );
};

export default PaymentOptions;