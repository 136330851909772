import { createMuiTheme } from '@material-ui/core/styles';

// All the following keys are optional.
// We try our best to provide a great default value.
const theme = (primaryColor: string) => createMuiTheme({
  palette: {
    primary: { main: primaryColor },
    secondary: { main: '#FFFFFF', contrastText: primaryColor, },
    error: { 500: 'rgba(255, 94, 94, 1)' },
    success: { 500: 'rgba(50, 215, 157, 1)' },
  },
  typography: {
    fontFamily: ['AcherusGrotesque'].join(','),
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '1.5625em',
        height: '3em',
        paddingLeft: '2em',
        paddingRight: '2em',
        fontSize: '0.875em',
        fontFamily: 'AcherusGrotesqueBold',
        lineHeight: '1em',
      },
      label: {
        textTransform: 'none',
      },
      containedPrimary: {
        border: 0,
      },
      containedSecondary: {
        border: `2px solid ${primaryColor}`,
        color: primaryColor,
        '&:hover': {
          backgroundColor: 'rgba(0,0,0,0.1)'
        },
      }
    },
  }
});

export default theme;
